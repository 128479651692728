import React from 'react'
import { Trans } from '@lingui/macro'
import Tag from '../../utils/Tag'
import Table from '../../utils/Table'
import Duration from '../../utils/Duration'
import Tooltip from '../../utils/Tooltip'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import DrawerTabError from '../../utils/DrawerTabError'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import { getPageStatusColor, getScanActionIcon } from '../../../utils/entities'
import * as constants from '../../../constants'
import useEvents from '../../hooks/useEvents'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableCellAvatar from '../../utils/TableCellAvatar'
import { useScanDetailActionsDrawerQuery } from './useScanDetailActionsDrawerQuery'

const { SCAN_PAGE_STATUS_FAILED: FAILED } = constants

export default function ScanDetailsDrawerTabActions({ scanId }) {
    const orderBy = { createdAt: 'ASC' }

    const {
        scanActions: actions,
        isFetching,
        error,
        isFetchingMore,
        hasMore,
        handleFetchMore,
    } = useScanDetailActionsDrawerQuery({ scanId, orderBy })

    // useEvents(
    //     ['scan:page:in-progress', 'scan:page:completed', 'scan:page:failed'],
    //     ({ scanPage }) => {
    //         if (scanPage && scanPage.scanId) {
    //             refetch()
    //         }
    //     }
    // )
    if (isFetching) return <DrawerTabLoading />
    if (error) return <DrawerTabError error={error} />

    if (actions.length === 0) {
        return (
            <DrawerEmptyCard
                iconName="hand"
                title={<Trans>No actions</Trans>}
                description={
                    <Trans>No actions were executed this scan. </Trans>
                }
            />
        )
    }
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
        >
            <Table hasMinWidth={false} isRounded hasHeader={false}>
                <TableBody>
                    {actions.map(
                        ({
                            id,
                            actionName,
                            status,
                            errorMessages,
                            duration,
                            action,
                        }) => (
                            <TableRow key={id}>
                                <TableCellAvatar
                                    className="w-full"
                                    truncate
                                    title={actionName}
                                    avatarIconName={getScanActionIcon(
                                        action.action.type
                                    )}
                                />
                                <TableCell py="sm">
                                    <div className="flex justify-end gap-1">
                                        <Tooltip
                                            event="hover"
                                            enabled={status === FAILED}
                                            content={errorMessages.join(' ')}
                                            contentClassName="max-w-md"
                                        >
                                            <Tag
                                                type={getPageStatusColor(
                                                    status
                                                )}
                                                className="capitalize"
                                            >
                                                {status
                                                    .toLowerCase()
                                                    .replace('_', ' ')}
                                            </Tag>
                                        </Tooltip>
                                        <Tooltip
                                            event="hover"
                                            enabled
                                            content={<Trans>Duration</Trans>}
                                        >
                                            <Tag
                                                iconNameBefore="clock"
                                                type="gray"
                                            >
                                                <Duration format="secs-short">
                                                    {duration}
                                                </Duration>
                                            </Tag>
                                        </Tooltip>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}
