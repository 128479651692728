import React from 'react'
import { Trans } from '@lingui/macro'
import { AVAILABLE_PROCESSING_ACTIVITIES } from '@clew/shared/src/constants'

import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalFooter from '../../utils/ModalFooter'
import Button from '../../utils/Button'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormTextarea from '../../utils/FormTextarea'
import PurposesSelect from '../../utils/PurposesSelect'
import FormSelectList from '../../utils/FormSelectList'
import SelectListOption from '../../utils/SelectListOption'

export default function UpdateTrackerDetailsDrawerPurposeIdentifiersModal({
    isLoading,
    onDismiss,
    onSubmit,
    formState,
    highlightedExampleText,
    onUpdateExampleText,
}) {
    const {
        values,
        getErrorMessages,
        getNativeInputProps,
        handleNativeChange,
        handleChange,
    } = formState

    return (
        <Modal onDismiss={onDismiss} className="max-w-2xl">
            <ModalTitle
                iconName="code-search"
                onClose={onDismiss}
                title={<Trans>Update Purpose Identifier</Trans>}
            />
            <FormGroup
                label={<Trans>Consent purpose</Trans>}
                htmlFor="purposes"
                className="mb-4"
                isHorizontal
                isRequired
            >
                <PurposesSelect
                    value={values.purposeIds}
                    errors={getErrorMessages('purposeIds')}
                    onChange={(ids) => {
                        handleChange('purposeIds', ids)
                    }}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Processing Activities</Trans>}
                htmlFor="processingActivities"
                className="mb-4"
                isHorizontal
                isRequired
            >
                <FormSelectList
                    errors={getErrorMessages('processingActivities')}
                >
                    {AVAILABLE_PROCESSING_ACTIVITIES.map((activity) => {
                        const selectedActivities =
                            values.processingActivities || []
                        const isChecked = selectedActivities.includes(activity)

                        return (
                            <SelectListOption
                                key={activity}
                                isCheckbox
                                checked={isChecked}
                                onClick={() => {
                                    const newActivities = isChecked
                                        ? selectedActivities.filter(
                                              (a) => a !== activity
                                          )
                                        : [...selectedActivities, activity]
                                    handleChange(
                                        'processingActivities',
                                        newActivities
                                    )
                                }}
                            >
                                {activity
                                    .replace(/_/g, ' ')
                                    .toLowerCase()
                                    .replace(/\b\w/g, (char) =>
                                        char.toUpperCase()
                                    )}
                            </SelectListOption>
                        )
                    })}
                </FormSelectList>
            </FormGroup>
            <FormGroup
                isRequired
                label={<Trans>Pattern</Trans>}
                htmlFor="pattern"
                className="mb-2"
                isHorizontal
            >
                <FormInput
                    id="pattern"
                    className="max-w-lg"
                    errors={getErrorMessages('pattern')}
                    {...getNativeInputProps('pattern')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Examples</Trans>}
                htmlFor="examples"
                className="mb-0"
                isHorizontal
            >
                <FormTextarea
                    id="examples"
                    name="examples"
                    className="max-w-lg"
                    errors={getErrorMessages('examples')}
                    onChange={(e) => {
                        handleNativeChange(e)
                        onUpdateExampleText(values.pattern, e.target.value)
                    }}
                >
                    {values.examples}
                </FormTextarea>
            </FormGroup>
            <FormGroup
                label={<Trans>Example Matching</Trans>}
                isHorizontal
                className="sm:pt-0"
            >
                <div className="break-words sm:pt-2">
                    <p
                        className="text-sm text-gray-800"
                        dangerouslySetInnerHTML={{
                            __html: highlightedExampleText,
                        }}
                    />
                </div>
            </FormGroup>
            <ModalFooter>
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button type="primary" onClick={onSubmit} loading={isLoading}>
                    <Trans>Update</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    )
}
