import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import AlertCommentInput from '../../utils/AlertCommentInput'
import AlertComment from '../../utils/AlertComment'
import AlertDelegation from '../../utils/AlertDelegation'

export default function GroupedAlertDetailsDrawerTabActivity({
    onSubmit,
    isLoading,
    formState,
    activity = [],
}) {
    if (!alert) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No Acitivity</Trans>}
                description={
                    <Trans>Needs to be implemented, we are working on it</Trans>
                }
            />
        )
    }
    return (
        <div className="space-y-6">
            <AlertCommentInput
                isLoading={isLoading}
                onSubmit={onSubmit}
                formState={formState}
            />
            {activity.length > 0 &&
                activity.map((item) => {
                    switch (item.__typename) {
                        case 'AlertComment':
                            return (
                                <AlertComment
                                    key={item.id}
                                    id={item.id}
                                    comment={item}
                                />
                            )
                        case 'AlertDelegation':
                            return (
                                <AlertDelegation
                                    key={item.id}
                                    id={item.id}
                                    delegation={item}
                                />
                            )
                        default:
                            return null
                    }
                })}
        </div>
    )
}
