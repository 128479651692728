import React, { useState } from 'react'
import { useIdentifiedTrackerObservationPagesQuery } from './useIdentifiedTrackerObservationPagesQuery'
import AuditObservationTableRow from '../audits/AuditObservationTableRow'

export default function IdentifiedTrackerAuditObservationTableRow({
    identifiedTrackerId,
    audit,
    objectName,
}) {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const {
        pages,
        error,
        isFetching: loading,
        isFetchingMore,
        hasMore,
        handleFetchMore,
    } = useIdentifiedTrackerObservationPagesQuery({
        identifiedTrackerId,
        auditId: audit.id,
        skip: isCollapsed,
    })
    return (
        <AuditObservationTableRow
            audit={audit}
            error={error}
            loading={loading}
            isFetchingMore={isFetchingMore}
            hasMore={hasMore}
            handleFetchMore={handleFetchMore}
            pages={pages}
            objectName={objectName}
            nextActiveTabOnClick="requests"
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
        />
    )
}
