import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { Trans } from '@lingui/macro'
import DeleteTrackerDetailsDrawerPurposeIdentifiersModal from './DeleteTrackerDetailsDrawerPurposeIdentifiersModal'
import useNotifications from '../../hooks/useNotifications'

const MUTATION = gql`
    mutation staffDeletePurposeIdentifier(
        $trackerId: ID!
        $purposeIdentifierId: ID!
    ) {
        staffDeletePurposeIdentifier(
            trackerId: $trackerId
            purposeIdentifierId: $purposeIdentifierId
        )
    }
`

const DeleteTrackerDetailsDrawerPurposeIdentifiersModalWithState = ({
    purposeIdentifier,
    trackerId,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deletePurposeIdentifier, { loading }] = useMutation(MUTATION, {
        onCompleted,
        onError,
    })

    return (
        <DeleteTrackerDetailsDrawerPurposeIdentifiersModal
            purposeIdentifier={purposeIdentifier}
            isLoading={loading}
            isDisabled={loading}
            onDismiss={onDismiss}
            onConfirm={async () => {
                try {
                    await deletePurposeIdentifier({
                        variables: {
                            trackerId,
                            purposeIdentifierId: purposeIdentifier.id,
                        },
                    })
                    onDismiss()
                    dispatchSuccess({
                        message: <Trans>Purpose Identifier Deleted</Trans>,
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default DeleteTrackerDetailsDrawerPurposeIdentifiersModalWithState
