import { gql, useMutation } from '@apollo/client'

export const UPDATE_DATA_IDENTIFIER_MUTATION = gql`
    mutation staffUpdateDataIdentifier(
        $id: ID!
        $input: StaffUpdateDataIdentifierInput!
    ) {
        staffUpdateDataIdentifier(id: $id, input: $input) {
            id
            name
        }
    }
`

export default function useUpdateDataIdentifierMutation(options) {
    return useMutation(UPDATE_DATA_IDENTIFIER_MUTATION, options)
}
