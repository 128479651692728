import React from 'react'
import { twMerge } from 'tailwind-merge'

const FormGroup = ({
    label,
    htmlFor,
    isHorizontal = false,
    isRequired = false,

    className,
    children,
}) => (
    <div
        className={twMerge(
            'mb-4',
            isHorizontal &&
                'sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5',
            className
        )}
    >
        <label
            htmlFor={htmlFor}
            className={twMerge(
                'block text-sm font-medium leading-5 text-gray-700',
                isHorizontal && 'sm:mt-px sm:pt-2'
            )}
        >
            <span className="inline-flex items-center gap-0">
                {label}
                {isRequired && <span className="text-xs text-red-600">*</span>}
            </span>
        </label>
        <div
            className={twMerge(
                'my-1 sm:col-span-3 sm:mt-0',
                !isHorizontal && 'sm:mt-1'
            )}
        >
            {children}
        </div>
    </div>
)

export default FormGroup
