import React from 'react'
import { Trans } from '@lingui/macro'
import {
    SCAN_STATUS_CREATED,
    SCAN_TRIGGER_LIVE,
} from '@clew/shared/src/constants'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditHeader from '../../layout/AuditHeader'
import LiveMobileScanPageSidebar from './LiveMobileScanPageSidebar'
import PageLoading from '../../utils/PageLoading'
import Alert from '../../utils/Alert'

export default function LiveMobileScanPage({
    error,
    scan,
    audit,
    buildId,
    liveUrl,
    isFetching,
    isStarting,
    isStarted,
    isStopping,
    isStopped,
    onStart,
    onStop,
    scanRequests,
    scanCookies,
}) {
    return (
        <PageContainer data-testid="AuditScansLiveMobilePage">
            <AuditHeader />
            <SidebarContainer>
                {(() => {
                    if (isFetching) {
                        return (
                            <main className="flex flex-1 items-center justify-center">
                                <PageLoading />
                            </main>
                        )
                    }
                    if (
                        error ||
                        scan.trigger !== SCAN_TRIGGER_LIVE ||
                        scan.status !== SCAN_STATUS_CREATED
                    ) {
                        let message
                        if (error) {
                            message = error.message
                        }
                        if (scan.trigger !== SCAN_TRIGGER_LIVE) {
                            message = <Trans>This is not a live scan</Trans>
                        }
                        if (scan.status !== SCAN_STATUS_CREATED) {
                            message = (
                                <Trans>This scan has already been run</Trans>
                            )
                        }
                        return (
                            <main className="flex flex-1 items-center justify-center">
                                <Alert title="Error">{message}</Alert>
                            </main>
                        )
                    }
                    return (
                        <main className="flex flex-1">
                            <div className="flex flex-1 items-center justify-center bg-slate-25 py-8">
                                <iframe
                                    key={buildId}
                                    id="mobile_recording_iframe"
                                    src={`https://appetize.io/embed/${buildId}`}
                                    height="100%"
                                    width="378px"
                                    className="mx-auto"
                                    title="appetize"
                                />
                            </div>
                            <LiveMobileScanPageSidebar
                                scan={scan}
                                audit={audit}
                                isFetching={isFetching}
                                isStarting={isStarting}
                                liveUrl={liveUrl}
                                isStarted={isStarted}
                                isStopped={isStopped}
                                isStopping={isStopping}
                                scanRequests={scanRequests}
                                scanCookies={scanCookies}
                                onStart={onStart}
                                onStop={onStop}
                            />
                        </main>
                    )
                })()}
            </SidebarContainer>
        </PageContainer>
    )
}
