import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired, isValidUrl } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this vendor.</Trans>,
    },
    {
        path: 'externalId',
        validate: isRequired,
        message: <Trans>Choose a external id for this vendor.</Trans>,
    },
    {
        path: 'sourceUrl',
        validate: (value) => (value ? isValidUrl(value) : true),
        message: <Trans>Field should be a valid URL</Trans>,
    },
    {
        path: 'cookiePolicyUrl',
        validate: (value) => (value ? isValidUrl(value) : true),
        message: <Trans>Field should be a valid URL</Trans>,
    },
    {
        path: 'deviceStorageDisclosureUrl',
        validate: (value) => (value ? isValidUrl(value) : true),
        message: <Trans>Field should be a valid URL</Trans>,
    },
])

const convertToNumber = (items) => items.filter((i) => i !== '').map(Number)
const valuesToInput = ({
    name,
    externalId,
    description,
    legalName,
    domain,
    types,
    isIab,
    isAtp,
    gvlId,
    purposes,
    legIntPurposes,
    flexiblePurposes,
    specialPurposes,
    features,
    specialFeatures,
    activities,
    sourceType,
    sourceUrl,
    websiteUrl,
    privacyPolicyUrl,
    cookiePolicyUrl,
    deviceStorageDisclosureUrl,
}) => {
    return {
        name: name.trim(),
        externalId: externalId.trim(),
        description: description.trim(),
        legalName: legalName.trim(),
        domain: domain?.trim(),
        isIab: !!isIab,
        isAtp: !!isAtp,
        gvlId: parseInt(gvlId, 10),
        purposes: convertToNumber(purposes),
        types,
        legIntPurposes: convertToNumber(legIntPurposes),
        flexiblePurposes: convertToNumber(flexiblePurposes),
        specialPurposes: convertToNumber(specialPurposes),
        features: convertToNumber(features),
        specialFeatures: convertToNumber(specialFeatures),
        activities,
        sourceType,
        sourceUrl: sourceUrl.trim(),
        websiteUrl: websiteUrl.trim(),
        privacyPolicyUrl: privacyPolicyUrl.trim(),
        cookiePolicyUrl: cookiePolicyUrl.trim(),
        deviceStorageDisclosureUrl: deviceStorageDisclosureUrl.trim(),
    }
}

const useCreateVendorFormState = () => {
    const formState = useFormState(
        {
            name: '',
            legalName: '',
            externalId: '',
            description: '',
            privacyPolicyUrl: '',
            types: [],
            domain: '',
            websiteUrl: '',
            purposes: [],
            legIntPurposes: [],
            flexiblePurposes: [],
            specialPurposes: [],
            features: [],
            specialFeatures: [],
            activities: [''],
            isIab: false,
            isAtp: false,
            sourceType: [],
            sourceUrl: '',
            cookiePolicyUrl: '',
            deviceStorageDisclosureUrl: '',
            gvlId: '',
        },
        {
            validation,
            valuesToInput,
        }
    )
    const handleAddArrayInput = (fieldName) => {
        formState.handleChange(fieldName, (values) => [...values, ''])
    }

    const handleRemoveArrayInput = (fieldName, index) => {
        formState.handleChange(fieldName, (values) => {
            if (values.length === 1) {
                return ['']
            }
            return values.filter((_, i) => i !== index)
        })
    }
    return {
        ...formState,
        handleAddArrayInput,
        handleRemoveArrayInput,
    }
}

export default useCreateVendorFormState
