import React, { useState } from 'react'
import AuditObservationTableRow from '../audits/AuditObservationTableRow'
import { useIdentifiedVendorObservationPagesQuery } from './useIdentifiedVendorObservationPagesQuery'

export default function IdentifiedVendorAuditObservationTableRow({
    identifiedVendorId,
    audit,
}) {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const {
        pages,
        error,
        isFetching: loading,
        isFetchingMore,
        hasMore,
        handleFetchMore,
    } = useIdentifiedVendorObservationPagesQuery({
        identifiedVendorId,
        auditId: audit.id,
        skip: isCollapsed,
    })
    return (
        <AuditObservationTableRow
            audit={audit}
            error={error}
            loading={loading}
            pages={pages}
            isFetchingMore={isFetchingMore}
            hasMore={hasMore}
            handleFetchMore={handleFetchMore}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
        />
    )
}
