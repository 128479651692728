import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import AuditSettingsPageAuditScenarioTableRowActionButtons from './AuditSettingsPageAuditScenarioTableRowActionButtons'
import DeleteAuditScenarioModalWithState from './DeleteAuditScenarioModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const AuditSettingsPageAuditScenarioTableRowActionButtonWithState = ({
    auditId,
    auditScenario,
    onDeleteAuditScenarioCompleted,
}) => {
    const { currentUser } = useAppState()
    const history = useHistory()
    const [isDeleteAuditScenarioModalOpen, setDeleteAuditScenarioModalOpen] =
        useState(false)
    return (
        <>
            {isDeleteAuditScenarioModalOpen && (
                <DeleteAuditScenarioModalWithState
                    auditScenario={auditScenario}
                    onDismiss={() => setDeleteAuditScenarioModalOpen(false)}
                    onCompleted={onDeleteAuditScenarioCompleted}
                />
            )}
            <AuditSettingsPageAuditScenarioTableRowActionButtons
                auditScenario={auditScenario}
                canDeleteAuditScenario={currentUser.role === OWNER}
                canUpdateAuditScenario={currentUser.role === OWNER}
                onDeleteAuditScenario={() =>
                    setDeleteAuditScenarioModalOpen(true)
                }
                onUpdateAuditScenario={() =>
                    history.push(
                        `/audits/${auditId}/record-mobile-actions/${auditScenario.id}/update`
                    )
                }
            />
        </>
    )
}

export default AuditSettingsPageAuditScenarioTableRowActionButtonWithState
