import React from 'react'
import { useParams } from 'react-router-dom'
import LiveMobileScanPage from './LiveMobileScanPage'
import { AppetizeClientProvider } from '../audits-record-mobile-actions/AppetizeClientProvider'
import PageLoading from '../../utils/PageLoading'
import { useLiveMobileScanPageQuery } from './useLiveMobileScanPageQueries'
import ErrorPage from '../../utils/ErrorPage'

const LiveMobileScanPageWithState = () => {
    const { scanId } = useParams()
    const { isFetching, scan, error, availableDevices, auditBuilds } =
        useLiveMobileScanPageQuery({ scanId })

    const initialBuild = auditBuilds[0] || null
    if (isFetching) {
        return <PageLoading /> // todo
    }
    if (error) {
        return <ErrorPage error={error} />
    }
    return (
        <AppetizeClientProvider
            isDisabled={false}
            actions={[]}
            buildId={initialBuild.appetizeBuildId}
            deviceOs={initialBuild.deviceOs}
            availableDevices={availableDevices}
        >
            <LiveMobileScanPage
                audit={scan?.audit}
                scan={scan || {}}
                buildId={initialBuild.appetizeBuildId}
            />
        </AppetizeClientProvider>
    )
}

export default LiveMobileScanPageWithState
