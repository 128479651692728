import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Icon from '../../utils/Icon'
import LoadingIndicator from '../../utils/LoadingIndicator'

const AuditsLivePageWebBrowser = ({
    scan,
    audit,
    liveUrl,
    isStopped,
    isStarting,
    onStart,
}) => {
    return (
        <div className="flex h-full w-full flex-col items-center justify-center">
            <div className="w-full flex-shrink-0 border-b border-gray-200 p-2 px-6">
                <div className="flex h-10 w-full cursor-pointer items-center justify-between rounded-full border border-gray-300 px-5 text-sm shadow-xs hover:border-gray-400">
                    <div>{scan?.domain?.url}</div>
                    <Icon name="chevron-down" size="sm" />
                </div>
            </div>
            <div className="flex w-full flex-1 items-center justify-center bg-gray-100">
                {(() => {
                    if (isStopped) {
                        return (
                            <div className="flex flex-col items-center">
                                <Icon
                                    name="globe"
                                    size="2xl"
                                    className="mb-2 text-gray-500"
                                />
                                <div className="mb-1 font-medium text-gray-700">
                                    <Trans>Live browser stopped</Trans>
                                </div>
                                <p className="pb-4 text-center text-sm text-gray-600">
                                    <Trans>
                                        The live scan has been stopped.
                                        <br />
                                        You can save the live scan or start a
                                        new live scan.
                                    </Trans>
                                </p>
                            </div>
                        )
                    }
                    if (isStarting) {
                        return (
                            <div className="flex flex-col items-center">
                                <LoadingIndicator className="mb-2" />
                                <div className="mb-1 font-medium text-gray-700">
                                    <Trans>Starting browser... </Trans>
                                </div>
                            </div>
                        )
                    }
                    if (!liveUrl) {
                        return (
                            <div className="flex flex-col items-center">
                                <Icon
                                    name="globe"
                                    size="2xl"
                                    className="mb-2 text-gray-500"
                                />
                                <div className="mb-1 font-medium text-gray-700">
                                    <Trans>Live browser </Trans>
                                </div>
                                <div className="text-sm text-gray-600">
                                    <Trans>
                                        Tab &quot;Start live scan&quot; to start
                                        scanning your domain.
                                    </Trans>
                                </div>
                            </div>
                        )
                    }
                    return (
                        <iframe
                            title="Live view"
                            src={`${liveUrl}&navbar=false`}
                            sandbox="allow-same-origin allow-scripts"
                            allow="clipboard-read; clipboard-write"
                            className="h-full w-full"
                        />
                    )
                })()}
            </div>
        </div>
    )
}

export default AuditsLivePageWebBrowser
