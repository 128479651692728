import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { DEVICE_OS_ANDROID, DEVICE_OS_IOS } from '@clew/shared/src/constants'
import AnimatedTabs, { AnimatedTab } from '../../utils/AnimatedTabs'
import { useScanDetailDeviceDrawerQuery } from './useScanDetailDeviceDrawerQuery'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import DrawerTabError from '../../utils/DrawerTabError'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableCell from '../../utils/TableCell'
import Tag from '../../utils/Tag'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import DrawerItem from '../../utils/DrawerItem'

const NORMAL_PERMISSIONS = [
    'ACCESS_NETWORK_STATE',
    'ACCESS_WIFI_STATE',
    'BLUETOOTH',
    'INTERNET',
    'NFC',
    'VIBRATE',
    'WAKE_LOCK',
]

const DANGEROUS_PERMISSIONS = [
    'POST_NOTIFICATIONS',
    'ACCESS_FINE_LOCATION',
    'ACCESS_COARSE_LOCATION',
    'READ_EXTERNAL_STORAGE',
    'WRITE_EXTERNAL_STORAGE',
    'READ_CONTACTS',
    'WRITE_CONTACTS',
    'READ_CALENDAR',
    'WRITE_CALENDAR',
    'CAMERA',
    'RECORD_AUDIO',
    'READ_PHONE_STATE',
    'CALL_PHONE',
    'READ_CALL_LOG',
    'WRITE_CALL_LOG',
    'SEND_SMS',
    'RECEIVE_SMS',
    'READ_SMS',
    'BODY_SENSORS',
    'FOREGROUND_SERVICE',
]

const AD_PERMISSIONS = [
    'AD_ID',
    'ACCESS_ADSERVICES_ATTRIBUTION',
    'ACCESS_ADSERVICES_AD_ID',
    'AD_SERVICES_CONFIG',
    'ACCESS_ADSERVICES_TOPICS',
]

const SPECIAL_PERMISSIONS = ['SYSTEM_ALERT_WINDOW', 'WRITE_SETTINGS']

// self curated
const IOS_DANGEROUS_PERMISSIONS = [
    'NSCameraUsageDescription',
    'NSMicrophoneUsageDescription',
    'NSPhotoLibraryUsageDescription',
    'NSPhotoLibraryAddUsageDescription',
    'NSLocationWhenInUseUsageDescription',
    'NSLocationAlwaysUsageDescription',
    'NSLocationAlwaysAndWhenInUseUsageDescription',
    'NSContactsUsageDescription',
    'NSCalendarsUsageDescription',
    'NSRemindersUsageDescription',
    'NSSpeechRecognitionUsageDescription',
    'NSHealthShareUsageDescription',
    'NSHealthUpdateUsageDescription',
    'NSMotionUsageDescription',
    'NSFaceIDUsageDescription',
    'NSHomeKitUsageDescription',
    'NSAppleMusicUsageDescription',
    'NSBluetoothPeripheralUsageDescription',
    'NSUserTrackingUsageDescription',
]

export default function ScanDetailsDrawerTabDevice({ scanId }) {
    const [activeTab, setActiveTab] = useState('settings')

    const { deviceSettings, auditBuild, isFetching, error } =
        useScanDetailDeviceDrawerQuery({ id: scanId })

    if (isFetching) return <DrawerTabLoading />
    if (error) return <DrawerTabError error={error} />

    const { deviceOs, metaData } = auditBuild
    if (!metaData) {
        return null
    }
    const deviceMeta = metaData[deviceOs.toLowerCase()]

    return (
        <div>
            <AnimatedTabs activeTab={activeTab}>
                <AnimatedTab
                    name="settings"
                    onClick={() => setActiveTab('settings')}
                >
                    <Trans>Settings</Trans>
                </AnimatedTab>
                <AnimatedTab
                    name="permissions"
                    onClick={() => setActiveTab('permissions')}
                >
                    <Trans>Permissions</Trans>
                </AnimatedTab>
            </AnimatedTabs>
            {activeTab === 'settings' && (
                <div className="mt-4 space-y-4 pl-2">
                    {!deviceSettings || deviceSettings.length === 0 ? (
                        <DrawerEmptyCard
                            iconName="phone-01"
                            title={<Trans>No Device settings</Trans>}
                            description={
                                <Trans>No device details were found.</Trans>
                            }
                        />
                    ) : (
                        <>
                            <div>
                                <h3 className="text-sm font-medium">
                                    <Trans>Device</Trans>
                                </h3>
                                <p className="text-sm text-slate-600">
                                    {deviceSettings.deviceId}
                                </p>
                            </div>
                            <div>
                                <h3 className="text-sm font-medium">
                                    <Trans>OS Version</Trans>
                                </h3>
                                <p className="text-sm text-slate-600">
                                    {deviceSettings.osVersion}
                                </p>
                            </div>
                            {deviceSettings.language && (
                                <div>
                                    <h3 className="text-sm font-medium">
                                        <Trans>Language</Trans>
                                    </h3>
                                    <p className="text-sm text-slate-600">
                                        {deviceSettings.language}
                                    </p>
                                </div>
                            )}
                            {deviceSettings.location?.length > 0 && (
                                <div>
                                    <h3 className="text-sm font-medium">
                                        <Trans>Location</Trans>
                                    </h3>
                                    {deviceSettings.location.map((location) => {
                                        return (
                                            <p className="text-sm text-slate-600">
                                                {location}
                                            </p>
                                        )
                                    })}
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
            {activeTab === 'permissions' && (
                <div className="mt-4">
                    <div>
                        {!auditBuild || auditBuild.length === 0 ? (
                            <DrawerEmptyCard
                                iconName="check-circle"
                                title={<Trans>No Permissions</Trans>}
                                description={
                                    <Trans>
                                        No permission details were found.
                                    </Trans>
                                }
                            />
                        ) : (
                            <>
                                {deviceOs === DEVICE_OS_IOS && deviceMeta && (
                                    <Table
                                        hasMinWidth={false}
                                        isRounded
                                        hasHeader={false}
                                    >
                                        <TableBody>
                                            {Object.entries(
                                                deviceMeta.UsageDescriptions
                                            )?.map(([key, value]) => {
                                                const isDangerous =
                                                    IOS_DANGEROUS_PERMISSIONS.some(
                                                        (value) =>
                                                            key.includes(value)
                                                    )
                                                return (
                                                    <TableRow>
                                                        <TableCellAvatar
                                                            py="xs"
                                                            px="sm"
                                                            title={key}
                                                            text={value}
                                                        />
                                                        <TableCell
                                                            py="xs"
                                                            className="text-right"
                                                        >
                                                            {isDangerous && (
                                                                <Tag type="yellow">
                                                                    Dangerous
                                                                </Tag>
                                                            )}
                                                            {!isDangerous && (
                                                                <Tag type="green">
                                                                    Normal
                                                                </Tag>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                )}
                                {deviceOs === DEVICE_OS_ANDROID &&
                                    deviceMeta && (
                                        <Table
                                            hasMinWidth={false}
                                            isRounded
                                            hasHeader={false}
                                        >
                                            <TableBody>
                                                {deviceMeta.permissions?.map(
                                                    (permission) => {
                                                        const isDangerous =
                                                            DANGEROUS_PERMISSIONS.some(
                                                                (value) =>
                                                                    permission.includes(
                                                                        value
                                                                    )
                                                            )
                                                        const isSpecial =
                                                            SPECIAL_PERMISSIONS.some(
                                                                (value) =>
                                                                    permission.includes(
                                                                        value
                                                                    )
                                                            )
                                                        const isAd =
                                                            AD_PERMISSIONS.some(
                                                                (value) =>
                                                                    permission.includes(
                                                                        value
                                                                    )
                                                            )
                                                        const isNormal =
                                                            NORMAL_PERMISSIONS.some(
                                                                (value) =>
                                                                    permission.includes(
                                                                        value
                                                                    )
                                                            )
                                                        return (
                                                            <TableRow>
                                                                <TableCell py="xs">
                                                                    {permission}
                                                                </TableCell>
                                                                <TableCell
                                                                    py="xs"
                                                                    className="text-right"
                                                                >
                                                                    {isDangerous && (
                                                                        <Tag type="yellow">
                                                                            Dangerous
                                                                        </Tag>
                                                                    )}
                                                                    {isAd && (
                                                                        <Tag type="blue">
                                                                            Advertising
                                                                        </Tag>
                                                                    )}
                                                                    {isSpecial && (
                                                                        <Tag type="red">
                                                                            Special
                                                                        </Tag>
                                                                    )}
                                                                    {isNormal && (
                                                                        <Tag type="green">
                                                                            Normal
                                                                        </Tag>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                )}
                                            </TableBody>
                                        </Table>
                                    )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
