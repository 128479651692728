import React from 'react'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import useDrawerStack from '../../hooks/useDrawerStack'
import PurposesTags from '../../utils/PurposesTags'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import Tooltip from '../../utils/Tooltip'
import DateTime from '../../utils/DateTime'
import AlertTooltip from '../../utils/AlertTooltip'
import AlertCountTag from '../../utils/AlertCountTag'
import AuditAvatarGroup from '../../utils/AuditAvatarGroup'
import Avatar from '../../utils/Avatar'
import ScanTrackersPageTableRowActionButtonWithState from '../audits-scans-trackers/ScanTrackersPageActionsDropdownMenu'
import IdentifiedTrackersDrawer from './IdentifiedTrackersDetailsDrawer'
import { APPLICATION_LEVEL_ORGANIZATION } from '../../../constants'
import ActionButton from '../../utils/ActionButton'

const FRAGMENT = gql`
    fragment IdentifiedTrackersPageTableRow on IdentifiedTracker {
        id
        lastSeenAt
        occurrence
        rootDomain
        tracker {
            id
            domain
            belongsToOrganization
            purposes {
                id
                name
                parent {
                    id
                    name
                    parent {
                        id
                        name
                    }
                }
            }
            vendor {
                ...PopoverVendorInfo
            }
        }
        alerts {
            id
            message
            type
            severityType
        }
        audits {
            id
            name
            imageUrl
        }
    }
`

const IdentifiedTrackersPageTableRow = ({
    tracker: identifiedTracker,
    startDate,
    endDate,
    period,
    onClassifyTrackerCompleted,
    onUpdateTrackerCompleted,
    onDeleteTrackerCompleted,
}) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const {
        id,
        lastSeenAt,
        occurrence,
        rootDomain,
        tracker,
        alerts = [],
        audits,
    } = identifiedTracker
    return (
        <TableRow
            isSelectable
            selectionValue={identifiedTracker}
            isActive={currentDrawerProps?.id === id}
            selectionCriteria={(row) => row.id === id}
            data-testid="TrackersPageTableRow"
            onClick={() => {
                open(IdentifiedTrackersDrawer, {
                    identifiedTrackerId: id,
                    startDate,
                    endDate,
                    period,
                    level: APPLICATION_LEVEL_ORGANIZATION,
                    onClassifyTrackerCompleted,
                    onDeleteTrackerCompleted,
                    initialDrawerState: {
                        activeTab: 'details',
                    },
                })
            }}
        >
            <TableCell columnId="domain" size="sm">
                {rootDomain}
            </TableCell>

            <TableCell columnId="purposes" size="sm">
                <PurposesTags purposes={tracker?.purposes || []} />
            </TableCell>

            <TableCell columnId="vendor" size="sm">
                {tracker && tracker.vendor != null && (
                    <PopoverVendorInfo vendor={tracker.vendor}>
                        <Avatar
                            src={tracker.vendor.imageUrl}
                            iconName="building-07"
                        />
                    </PopoverVendorInfo>
                )}
            </TableCell>

            <TableCell columnId="audits" size="sm">
                <AuditAvatarGroup size="sm" audits={audits} />
            </TableCell>
            <TableCell columnId="alerts" hasText>
                {alerts.length > 0 && (
                    <AlertTooltip alert={alerts[0]}>
                        <AlertCountTag alerts={alerts} />
                    </AlertTooltip>
                )}
            </TableCell>
            <TableCell columnId="occurrence" size="sm">
                <Tag>{occurrence || 'Unknown'}</Tag>
            </TableCell>
            <TableCell columnId="belongsToOrganization" size="sm">
                {tracker && tracker.belongsToOrganization && (
                    <Tooltip content="This cookies is uniquely classified for your organization">
                        <Tag>Self-classified</Tag>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell hasText>
                <DateTime dateOnly>{lastSeenAt}</DateTime>
            </TableCell>
            <TableCell className="text-right">
                <ScanTrackersPageTableRowActionButtonWithState
                    tracker={tracker}
                    scanTracker={identifiedTracker}
                    onClassifyTrackerCompleted={onClassifyTrackerCompleted}
                    onUpdateTrackerCompleted={onUpdateTrackerCompleted}
                    onDeleteTrackerCompleted={onDeleteTrackerCompleted}
                >
                    <ActionButton />
                </ScanTrackersPageTableRowActionButtonWithState>
            </TableCell>
        </TableRow>
    )
}

IdentifiedTrackersPageTableRow.FRAGMENT = FRAGMENT

export default IdentifiedTrackersPageTableRow
