import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import RadioButton from '../../utils/RadioButton'
import FormSelectList from '../../utils/FormSelectList'
import SelectList from '../../utils/SelectList'
import SelectListOption from '../../utils/SelectListOption'
import ModalFooter from '../../utils/ModalFooter'

const CreateAuditScenarioModal = ({
    purposes,
    formState,
    onDismiss,
    onSubmit,
}) => {
    const { values, getErrorMessages, getNativeInputProps } = formState
    const { consentType } = values
    return (
        <Modal onDismiss={onDismiss} className="max-w-[700px]">
            <ModalTitle
                title={<Trans>Create scenario</Trans>}
                description={
                    <Trans>
                        Add a new scenario to this audit. A scenario is
                        recording of a series of actions that should be
                        performed during a scan.
                    </Trans>
                }
                onClose={onDismiss}
            />
            <FormGroup htmlFor="name">
                <FormInput
                    id="name"
                    errors={getErrorMessages('name')}
                    {...getNativeInputProps('name')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Consent profile</Trans>} className="mb-4">
                <RadioButton.Group>
                    <RadioButton
                        value="NO_CONSENT"
                        checked={consentType === 'NO_CONSENT'}
                        onChange={() => formState.handleNoConsent()}
                    >
                        <Trans>No consent</Trans>
                    </RadioButton>
                    <RadioButton
                        value="REJECT_ALL"
                        checked={consentType === 'REJECT_ALL'}
                        onChange={() => formState.handleRejectAll()}
                    >
                        <Trans>Reject all</Trans>
                    </RadioButton>
                    <RadioButton
                        value="ACCEPT_ALL"
                        checked={consentType === 'ACCEPT_ALL'}
                        onChange={() => formState.handleAcceptAll()}
                    >
                        <Trans>Accept all</Trans>
                    </RadioButton>
                    <RadioButton
                        value="CUSTOM"
                        checked={consentType === 'CUSTOM'}
                        onChange={() => formState.handleCustom()}
                    >
                        <Trans>Custom</Trans>
                    </RadioButton>
                </RadioButton.Group>
            </FormGroup>
            <FormGroup label={<Trans>Consent purposes</Trans>}>
                <FormSelectList disabled={consentType !== 'CUSTOM'}>
                    <SelectList>
                        {purposes.map(({ id, name }) => (
                            <SelectListOption
                                key={id}
                                disabled
                                isCheckbox
                                checked={formState.values.purposeIds.includes(
                                    id
                                )}
                                onClick={() =>
                                    formState.handleChangePurpose(id)
                                }
                            >
                                {name}
                            </SelectListOption>
                        ))}
                    </SelectList>
                </FormSelectList>
            </FormGroup>
            <ModalFooter>
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>

                <Button onClick={onSubmit}>
                    <Trans>Create scenario</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default CreateAuditScenarioModal
