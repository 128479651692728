import React from 'react'
import { Trans } from '@lingui/macro'
import { useCurrentDrawerStack } from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import PurposesTags from '../../utils/PurposesTags'
import CookieDetailsDrawer from '../staff-vendors-cookies/CookieDetailsDrawer'

export default function VendorDetailsDrawerCookiesTab({ vendor }) {
    const { add } = useCurrentDrawerStack()
    const { cookies } = vendor
    if (cookies.length === 0) {
        return null
    }
    return (
        <Table hasMinWidth={false} hasHeader={false} isRounded>
            <TableBody>
                {cookies.map(({ id, name, domain, purposes }) => (
                    <TableRow
                        isSelectable
                        key={id}
                        onClick={() => {
                            add(CookieDetailsDrawer, {
                                cookieId: id,
                            })
                        }}
                    >
                        <TableCellAvatar
                            size="sm"
                            title={name}
                            text={
                                domain || (
                                    <span className="text-sm italic text-gray-300">
                                        <Trans>no domain</Trans>
                                    </span>
                                )
                            }
                        />
                        <TableCell size="sm" className="text-right">
                            <PurposesTags purposes={purposes} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
