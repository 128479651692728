import React from 'react'
import { Trans } from '@lingui/macro'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalFooter from '../../utils/ModalFooter'
import Button from '../../utils/Button'
import StaffUpdateDataIdentifierForm from './StaffUpdateDataIdentifiersForm'

export default function StaffUpdateDataIdentifierModal({
    isLoading,
    onDismiss,
    onSubmit,
    formState,
    onUpdateExampleText,
    highlightedExampleText,

    exampleText,
}) {
    return (
        <Modal onDismiss={onDismiss} className="max-w-2xl">
            <ModalTitle
                iconName="code-search"
                onClose={onDismiss}
                title={<Trans>Update Data Identifier</Trans>}
            />
            <StaffUpdateDataIdentifierForm
                formState={formState}
                onUpdateExampleText={onUpdateExampleText}
                exampleText={exampleText}
                highlightedExampleText={highlightedExampleText}
            />
            <ModalFooter>
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button type="primary" onClick={onSubmit} loading={isLoading}>
                    <Trans>Update</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    )
}
