import React, { useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import { getRootDomain } from '../../../utils/index'
import { useCurrentDrawerStack } from '../../hooks/useDrawerStack'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import Tooltip from '../../utils/Tooltip'
import Tag from '../../utils/Tag'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import AlertCountTag from '../../utils/AlertCountTag'
import ScanRequestDetailsDrawer from './ScanRequestDetailsDrawer'
import useSearch from '../../hooks/useSearch'
import SearchInput from '../../utils/SearchInput'
import AnimatedTabs, { AnimatedTab } from '../../utils/AnimatedTabs'
import useScanPageDetailRequestsDrawerQuery from './useScanPageDetailRequestsDrawerQuery'
import DrawerTabError from '../../utils/DrawerTabError'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import InfiniteScroll from '../../utils/InfiniteScroll'
import useFilters from '../../hooks/useFilters'
import PurposesTags from '../../utils/PurposesTags'
import {
    ScanPageRequestsActiveFilters,
    ScanPageRequestsAvailableFilters,
} from './ScanPageDetailsDrawerTabRequestsFilters'
import Icon from '../../utils/Icon'

export default function ScanPageDetailsDrawerTabRequests({
    scanId,
    scanPageId,
    audit,
    pageUrl,
    initialSearch = '',
}) {
    const { add } = useCurrentDrawerStack()
    const [search, handleSearch] = useSearch(initialSearch)
    const [activeTab, setActiveTab] = useState('all')
    const [typeFilter, onChangeTypeFilter] = useState(null)
    const [purposeFilter, onChangePurposeFilter] = useState(null)
    const [vendorsFilter, onChangeVendorsFilter] = useState([])
    const {
        activeFilters,
        filterValues,
        onRemoveFilter,
        onAddFilter,
        onRemoveAllFilters,
    } = useFilters({
        type: typeFilter,
        purpose: purposeFilter,
        vendors: vendorsFilter,
    })
    const {
        requests,
        isFetching: loading,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        error,
    } = useScanPageDetailRequestsDrawerQuery({
        scanId,
        scanPageId,
        search,
        filters: { ...filterValues },
    })

    const filteredRequests = useMemo(
        () =>
            requests.filter((request) => {
                if (activeTab === 'all') {
                    return true
                }
                return request.alerts.length > 0
            }),
        [requests.length, search, activeTab]
    )
    const requestsWithAlerts = useMemo(
        () => requests.filter((request) => request.alerts.length > 0),
        [requests.length]
    )
    if (error) return <DrawerTabError error={error} />
    return (
        <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
                <AnimatedTabs activeTab={activeTab}>
                    <AnimatedTab name="all" onClick={() => setActiveTab('all')}>
                        <Trans>All</Trans>
                    </AnimatedTab>
                    <AnimatedTab name="new" onClick={() => setActiveTab('new')}>
                        <div className="flex flex-row items-center gap-1">
                            <Trans>Alerts</Trans>
                            <Tag>{requestsWithAlerts.length}</Tag>
                        </div>
                    </AnimatedTab>
                </AnimatedTabs>
                <SearchInput
                    id="scanPageRequestSearch"
                    value={search}
                    onChange={handleSearch}
                    className="w-full"
                />
                <ScanPageRequestsAvailableFilters
                    onAddFilter={onAddFilter}
                    activeFilters={activeFilters}
                    onRemoveAllFilters={onRemoveAllFilters}
                />
            </div>
            <div className="flex gap-2">
                <ScanPageRequestsActiveFilters
                    scanId={scanId}
                    scanPageId={scanPageId}
                    activeFilters={activeFilters}
                    onRemoveFilter={onRemoveFilter}
                    typeFilter={typeFilter}
                    onChangeTypeFilter={onChangeTypeFilter}
                    vendorsFilter={vendorsFilter}
                    onChangeVendorsFilter={onChangeVendorsFilter}
                    purposeFilter={purposeFilter}
                    onChangePurposeFilter={onChangePurposeFilter}
                />
            </div>
            {loading && <DrawerTabLoading />}
            {!loading && (
                <InfiniteScroll
                    isFetchingMore={isFetchingMore}
                    onFetchMore={handleFetchMore}
                    hasMoreRecords={hasMore}
                >
                    <Table hasMinWidth={false} isRounded hasHeader={false}>
                        <TableBody>
                            {filteredRequests.length > 0 ? (
                                filteredRequests.map((request) => {
                                    const {
                                        id,
                                        url,
                                        method,
                                        resourceType,
                                        alerts,
                                        tracker,
                                        hasPostData,
                                    } = request
                                    const { hostname, pathname, searchParams } =
                                        new URL(url)
                                    const hasSearchParams =
                                        searchParams &&
                                        searchParams.toString().length > 0
                                    let tagPurposes = []
                                    if (tracker) {
                                        tagPurposes = tracker.purposes
                                    }
                                    let finalAvatarSrc =
                                        tracker?.vendor?.imageUrl
                                    if (
                                        getRootDomain(pageUrl) ===
                                        getRootDomain(url)
                                    ) {
                                        finalAvatarSrc = audit.imageUrl
                                    }
                                    return (
                                        <TableRow
                                            isSelectable
                                            key={id}
                                            onClick={() => {
                                                add(ScanRequestDetailsDrawer, {
                                                    requestId: request.id,
                                                    initialDrawerState: {
                                                        activeTab: 'details',
                                                    },
                                                })
                                            }}
                                        >
                                            <TableCellAvatar
                                                py="sm"
                                                px="sm"
                                                truncate
                                                avatarIconName="code-browser"
                                                avatarSrc={finalAvatarSrc}
                                                className="w-2/3"
                                                title={hostname}
                                                text={
                                                    <Tooltip
                                                        content={url}
                                                        contentClassName="max-w-[400px] break-all		"
                                                    >
                                                        {pathname}
                                                    </Tooltip>
                                                }
                                            />
                                            <TableCell py="sm">
                                                <div className="flex justify-end gap-1">
                                                    {hasSearchParams && (
                                                        <Tooltip
                                                            as="div"
                                                            className="h-5"
                                                            content={
                                                                <Trans>
                                                                    Has search
                                                                    params
                                                                </Trans>
                                                            }
                                                        >
                                                            <Tag
                                                                type="gray"
                                                                className="!p-0"
                                                                className="h-5"
                                                            >
                                                                <Icon
                                                                    name="search-param"
                                                                    size="sm"
                                                                    className="text-gray-600"
                                                                />
                                                            </Tag>
                                                        </Tooltip>
                                                    )}
                                                    {hasPostData && (
                                                        <Tooltip
                                                            as="div"
                                                            content={
                                                                <Trans>
                                                                    Sends post
                                                                    data
                                                                </Trans>
                                                            }
                                                        >
                                                            <Tag
                                                                type="gray"
                                                                className="!p-0.5"
                                                            >
                                                                <Icon
                                                                    name="arrow-up-circle"
                                                                    size="xs"
                                                                    className="text-gray-600"
                                                                />
                                                            </Tag>
                                                        </Tooltip>
                                                    )}
                                                    <AlertCountTag
                                                        alerts={alerts}
                                                    />
                                                    <PurposesTags
                                                        showAll
                                                        purposes={tagPurposes}
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            ) : (
                                <DrawerEmptyCard
                                    iconName="tracker"
                                    title={<Trans>No requests</Trans>}
                                    description={
                                        <Trans>No requests were found</Trans>
                                    }
                                />
                            )}
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            )}
        </div>
    )
}
