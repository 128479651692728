import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import UpdateTrackerDetailsDrawerPurposeIdentifiersModalWithState from './UpdateTrackerDetailsDrawerPurposeIdentifiersModalWithState'
import DeleteTrackerDetailsDrawerPurposeIdentifiersModalWithState from './DeleteTrackerDetailsDrawerPurposeIdentifiersModalWithState'

const TrackerDetailsDrawerPurposeIdentifiersTabTableDropdownMenu = ({
    trackerId,
    purposeIdentifier,
    onUpdatePurposeIdentifierCompleted,
    onDeletePurposeIdentifierCompleted,
    children,
}) => {
    const [isUpdateTrackerModalOpen, setUpdateTrackerModalOpen] =
        useState(false)
    const [isDeleteTrackerModalOpen, setDeleteTrackerModalOpen] =
        useState(false)

    return (
        <>
            {isUpdateTrackerModalOpen && (
                <UpdateTrackerDetailsDrawerPurposeIdentifiersModalWithState
                    trackerId={trackerId}
                    purposeIdentifier={purposeIdentifier}
                    onDismiss={() => setUpdateTrackerModalOpen(false)}
                    onCompleted={onUpdatePurposeIdentifierCompleted}
                />
            )}
            {isDeleteTrackerModalOpen && (
                <DeleteTrackerDetailsDrawerPurposeIdentifiersModalWithState
                    trackerId={trackerId}
                    purposeIdentifier={purposeIdentifier}
                    onDismiss={() => setDeleteTrackerModalOpen(false)}
                    onCompleted={onDeletePurposeIdentifierCompleted}
                />
            )}
            <DropdownMenu
                as="span"
                position="bottom-start"
                content={({ close }) => (
                    <>
                        <DropdownMenuItem
                            iconName="edit"
                            onClick={(e) => {
                                setUpdateTrackerModalOpen(true)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>Update Purpose Identifier</Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="bin"
                            onClick={(e) => {
                                setDeleteTrackerModalOpen(true)
                                close()
                                e.stopPropagation()
                            }}
                        >
                            <Trans>Delete Purpose Identifier</Trans>
                        </DropdownMenuItem>
                    </>
                )}
            >
                {children}
            </DropdownMenu>
        </>
    )
}

export default TrackerDetailsDrawerPurposeIdentifiersTabTableDropdownMenu
