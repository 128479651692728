import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import Toggle from '../../utils/Toggle'
import FormInputErrors from '../../utils/FormInputErrors'
import FormInput from '../../utils/FormInput'
import Button from '../../utils/Button'
import Select from '../../utils/Select'
import ConfirmModal from '../../utils/ConfirmModal'

export function AuditRecordMobileDeviceSettingsTab({
    appetizeClient,
    formState,
    onUpdateDeviceSettings,
}) {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const { values, handleChange, getErrorMessages, getNativeInputProps } =
        formState

    const { osDevices, availableOsVersionsForDevice, deviceSettings } =
        appetizeClient

    const osVersionsForDevice = availableOsVersionsForDevice(values.deviceId)
    const onDismiss = () => {
        setIsModalOpen(false)
    }

    const onConfirm = async () => {
        setIsUpdating(true)
        await onUpdateDeviceSettings()
        onDismiss()
        setIsUpdating(false)
    }

    return (
        <>
            {isModalOpen && (
                <ConfirmModal
                    type="primary"
                    title={<Trans>Update device settings</Trans>}
                    isLoading={isUpdating}
                    confirmButtonText={<Trans>Confirm</Trans>}
                    onDismiss={onDismiss}
                    onConfirm={onConfirm}
                >
                    <p className="text-md text-gray-900">
                        <Trans>
                            Are you sure you want to update the settings? This
                            will restart the device and stop your current
                            session.
                        </Trans>
                    </p>
                </ConfirmModal>
            )}
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    setIsModalOpen(true)
                }}
            >
                <div className="mb-4 flex justify-end">
                    <Button onClick={() => setIsModalOpen(true)} type="white">
                        <Trans>Update Settings</Trans>
                    </Button>
                </div>
                <div className="grid grid-cols-1 gap-x-6 space-y-4 sm:grid-cols-6">
                    <FormGroup
                        label={<Trans>Device</Trans>}
                        className="mt-4 sm:col-span-3"
                    >
                        <Select
                            {...getNativeInputProps('deviceId')}
                            errors={getErrorMessages('deviceId')}
                        >
                            {osDevices.map((device) => (
                                <option value={device.id} key={device.id}>
                                    {device.name}
                                </option>
                            ))}
                        </Select>
                    </FormGroup>
                    <FormGroup
                        label={<Trans>Os Version</Trans>}
                        className="sm:col-span-3"
                    >
                        <Select
                            {...getNativeInputProps('osVersion')}
                            errors={getErrorMessages('osVersion')}
                        >
                            {osVersionsForDevice.map((version) => (
                                <option key={version} value={version}>
                                    {version}
                                </option>
                            ))}
                        </Select>
                    </FormGroup>
                    <FormGroup
                        label={<Trans>Auto grant all permissions</Trans>}
                        className="sm:col-span-6"
                    >
                        <Toggle
                            name="grantPermissions"
                            value={values.grantPermissions}
                            onClick={(value) => {
                                handleChange('grantPermissions', value)
                            }}
                        />
                        <FormInputErrors
                            errors={getErrorMessages('grantPermissions')}
                        />
                    </FormGroup>
                    <FormGroup
                        label={<Trans>Language</Trans>}
                        className="sm:col-span-6"
                    >
                        <FormInput
                            errors={getErrorMessages('language')}
                            {...getNativeInputProps('language')}
                        />
                    </FormGroup>
                    <div className="sm:col-span-6">
                        <h3 className="font-medium text-gray-900">
                            <Trans>Location</Trans>
                        </h3>
                    </div>
                    <FormGroup
                        label={<Trans>Latitude</Trans>}
                        className="sm:col-span-3"
                    >
                        <FormInput
                            errors={getErrorMessages('locationLat')}
                            {...getNativeInputProps('locationLat')}
                        />
                    </FormGroup>
                    <FormGroup
                        label={<Trans>Longitude</Trans>}
                        className="sm:col-span-3"
                    >
                        <FormInput
                            errors={getErrorMessages('locationLong')}
                            {...getNativeInputProps('locationLong')}
                        />
                    </FormGroup>
                </div>
            </form>
        </>
    )
}
