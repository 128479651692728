import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import { AuditRecordMobileActionsTabTableRow } from './AuditRecordMobileActionsTabTableRow'

export function AuditRecordMobileActionsTab({
    actionEvents,
    actionStatuses,
    onUpdateAction,
    onDeleteAction,
    onToggleRequiredAction,
}) {
    if (!actionEvents.length > 0) {
        return (
            <DrawerEmptyCard
                title={<Trans>No actions</Trans>}
                description={<Trans>No actions were recorded.</Trans>}
                iconName="hand"
            />
        )
    }
    return (
        <Table hasHeader={false} isRounded hasMinWidth={false}>
            <TableBody>
                {actionEvents.map((actionEvent, id) => (
                    <AuditRecordMobileActionsTabTableRow
                        event={actionEvent.action}
                        isRequired={actionEvent.isRequired || false}
                        id={id}
                        key={id}
                        onUpdateAction={onUpdateAction}
                        onDeleteAction={onDeleteAction}
                        onToggleRequiredAction={onToggleRequiredAction}
                        status={actionStatuses[id]}
                    />
                ))}
            </TableBody>
        </Table>
    )
}
