import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import VendorTableItemPageTableRow from './VendorTableItemPageTableRow'
import useFetchMore from '../../hooks/useFetchMore'

const QUERY = gql`
    query vendorTableItemPageQuery(
        $vendorTableId: ID!
        $vendorTableVersionId: ID
        $filters: VendorTableItemFilters
        $orderBy: VendorTableItemOrderByInput
        $search: String
        $offset: Int
        $first: Int
    ) {
        vendorTableItems(
            vendorTableId: $vendorTableId
            vendorTableVersionId: $vendorTableVersionId
            filters: $filters
            orderBy: $orderBy
            search: $search
            offset: $offset
            first: $first
        ) {
            edges {
                node {
                    ...VendorTableItemPageTableRow
                }
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${VendorTableItemPageTableRow.FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`

const useVendorTableItemPageQuery = ({
    vendorTableId,
    vendorTableVersionId,
    search,
    orderBy,
    filters,
    first,
}) => {
    const variables = {
        search,
        vendorTableVersionId,
        vendorTableId,
        orderBy,
        filters,
        first,
    }
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore] = useFetchMore(
        fetchMore,
        data,
        'vendorTableItems'
    )
    return {
        vendorTableItems: connectionToCollection(data.vendorTableItems) || [],
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && data.vendorTableItems?.pageInfo.hasNextPage,
    }
}

export default useVendorTableItemPageQuery

const COMPARE_QUERY = gql`
    query compareVendorTableItemPageQuery(
        $vendorTableId: ID!
        $vendorTableVersionId: ID
        $orderBy: VendorTableItemOrderByInput
        $offset: Int
        $first: Int
    ) {
        latestVendorTableItems: vendorTableItems(
            vendorTableId: $vendorTableId
            orderBy: $orderBy
            offset: $offset
            first: $first
        ) {
            edges {
                node {
                    ...VendorTableItemPageTableRow
                }
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
        comparingVendorTableItems: vendorTableItems(
            vendorTableId: $vendorTableId
            vendorTableVersionId: $vendorTableVersionId
            orderBy: $orderBy
            offset: $offset
            first: $first
        ) {
            edges {
                node {
                    ...VendorTableItemPageTableRow
                }
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${VendorTableItemPageTableRow.FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`

export const useCompareVendorTableItemPageQuery = ({
    vendorTableId,
    vendorTableVersionId,
    orderBy,
    first,
}) => {
    const variables = {
        vendorTableVersionId,
        vendorTableId,
        orderBy,
        first,
    }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(COMPARE_QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        latestVendorTableItems:
            connectionToCollection(data.latestVendorTableItems) || [],
        comparingVendorTableItems:
            connectionToCollection(data.comparingVendorTableItems) || [],
        error,
        isFetching,
        refetch,
    }
}
