/* eslint-disable no-nested-ternary */
import React from 'react'
import { Trans } from '@lingui/macro'
import { twMerge } from 'tailwind-merge'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableRow from '../../utils/TableRow'
import PurposesTags from '../../utils/PurposesTags'
import Tag from '../../utils/Tag'
import CookieVersionCompareModalTableRowActions from './CookieVersionCompareModalTableRowActions'
import Tooltip from '../../utils/Tooltip'

const rowTypeToBgClass = {
    new: 'bg-green-50',
    danger: 'bg-red-50',
    warning: 'bg-orange-50',
    modified: 'bg-amber-50',
    same: 'bg-white',
}

const rowTypeToTagType = {
    new: 'green',
    danger: 'red',
    warning: 'orange',
    modified: 'yellow',
    same: 'gray',
}

const rowTypeToTagLabel = {
    new: <Trans>Added</Trans>,
    danger: <Trans>Not found</Trans>,
    warning: <Trans>Removed</Trans>,
    modified: <Trans>Modified</Trans>,
    same: <Trans>Unchanged</Trans>,
}

export function FieldUpdates({ field }) {
    return (
        <div className="mb-2">
            <p className="mb-1 font-bold capitalize">{field.fieldName}</p>
            <div className="mb-1 ml-2">
                <p className="font-semibold">
                    <Trans>Current</Trans>{' '}
                </p>
                <div className="text-slate-300">
                    {field.fieldName === 'purposes' ? (
                        <PurposesTags purposes={field.current} showAll />
                    ) : (
                        field.current || '/'
                    )}
                </div>
            </div>
            <div className="ml-2">
                <p className="font-semibold">
                    <Trans>Previous</Trans>{' '}
                </p>
                <div className="text-slate-300">
                    {field.fieldName === 'purposes' ? (
                        <PurposesTags purposes={field.previous} showAll />
                    ) : (
                        field.previous || '/'
                    )}
                </div>
            </div>
        </div>
    )
}

const CookieVersionCompareModalTableRow = ({
    cookieItem,
    type,
    onUpdateCompleted,
}) => {
    const { name, domain, purposes, cookie } = cookieItem

    const backgroundClass = rowTypeToBgClass[type] || 'bg-white'
    const tagType = rowTypeToTagType[type] || 'gray'
    const tagLabel = rowTypeToTagLabel[type] || ''

    return (
        <TableRow className={twMerge('bg-white', backgroundClass)}>
            <TableCellAvatar
                className="max-w-32 overflow-hidden"
                columnId="name"
                size="sm"
                avatarSrc={cookie?.vendor?.imageUrl}
                avatarIconName="building-07"
                title={name}
            />
            <TableCell columnId="purposes" size="sm" className="max-w-10">
                <PurposesTags purposes={purposes || []} />
            </TableCell>
            <TableCell columnId="state" className="w-fit text-right">
                {type !== 'modified' ? (
                    <Tag type={tagType}>{tagLabel}</Tag>
                ) : (
                    <Tooltip
                        content={
                            <div className="max-w-lg">
                                <h3 className="text-lg font-semibold">
                                    <Trans>Field updates</Trans>
                                </h3>
                                {cookieItem.changedFields?.map((field) => (
                                    <FieldUpdates
                                        key={field.fieldName}
                                        field={field}
                                    />
                                ))}
                            </div>
                        }
                    >
                        <Tag type={tagType} className="cursor-help">
                            {tagLabel}
                        </Tag>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell size="sm" columnId="actions">
                <CookieVersionCompareModalTableRowActions
                    cookieTableItemId={cookieItem.id}
                    rowType={type}
                    onUpdateCompleted={onUpdateCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

export default CookieVersionCompareModalTableRow
