import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '@clew/shared/src/constants'
import Button from '../../utils/Button'
import { useCompareCookieTableItemPageQuery } from './useCookieTableItemPageQuery'
import CookieTableVersionCompareModalTable from './CookieTableVersionCompareModalTable'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalFooter from '../../utils/ModalFooter'
import DrawerTabError from '../../utils/DrawerTabError'

const {
    COOKIE_TABLE_ITEM_STATUS_ADDED: ADDED,
    COOKIE_TABLE_ITEM_STATUS_DEPRECATED: DEPRECATED,
} = constants

const compareCookieTables = (newItems, oldItems) => {
    // Helper to generate a unique key for a cookie item
    const getKey = (item) => {
        if (item.cookie?.id) {
            return item.cookie.id.toString()
        }
        return `${item.name}`
    }

    // Normalize purposes for consistent comparison
    const normalizePurposes = (purposes) =>
        [...purposes].map((p) => p.id?.toString() || p.toString()).sort()

    // Create maps for previous states
    const [previousActive, previousDeprecated] = oldItems.reduce(
        ([activeMap, deprecatedMap], item) => {
            const key = getKey(item)
            if (item.status === ADDED) {
                activeMap.set(key, {
                    name: item.name,
                    domain: item.domain,
                    description: item.description,
                    purposes: normalizePurposes(item.purposes),
                    occurrence: item.occurrence,
                })
            } else if (item.status === DEPRECATED && key) {
                // Store last active state from activeMap
                const lastActive = activeMap.get(key)
                if (lastActive) {
                    deprecatedMap.set(key, lastActive)
                }
            }
            return [activeMap, deprecatedMap]
        },
        [new Map(), new Map()]
    )

    const results = {
        newComparedCookies: [],
        modifiedStatusCookies: [],
        deprecatedStatusCookies: [],
        deletedComparedCookies: [],
        sameComparedCookies: [],
    }

    // Analyze new items
    newItems.forEach((newItem) => {
        const key = getKey(newItem)
        const previous = previousActive.get(key) || previousDeprecated.get(key)

        // New cookie detection
        if (!previous) {
            results.newComparedCookies.push(newItem)
            return
        }

        // Deprecation handling
        if (newItem.status === DEPRECATED) {
            results.deprecatedStatusCookies.push(newItem)
            return
        }

        // Field comparison
        const currentPurposes = normalizePurposes(newItem.purposes)
        const changes = {
            name:
                newItem.name !== previous.name
                    ? { current: newItem.name, previous: previous.name }
                    : undefined,
            domain:
                newItem.domain !== previous.domain
                    ? {
                          current: newItem.domain,
                          previous: previous.domain,
                      }
                    : undefined,
            description:
                newItem.description !== previous.description
                    ? {
                          current: newItem.description,
                          previous: previous.description,
                      }
                    : undefined,
            purposes:
                JSON.stringify(currentPurposes) !==
                JSON.stringify(previous.purposes)
                    ? {
                          current: newItem.purposes,
                          previous: oldItems.find(
                              (oldItem) => getKey(oldItem) === key
                          )?.purposes,
                      }
                    : undefined,
        }

        const changedFields = Object.entries(changes)
            .filter(([_, value]) => value !== undefined)
            .map(([fieldName, { current, previous: prevValue }]) => ({
                fieldName,
                current,
                previous: prevValue,
            }))

        if (changedFields.length > 0) {
            results.modifiedStatusCookies.push({
                ...newItem,
                changedFields,
            })
        } else {
            results.sameComparedCookies.push(newItem)
        }
    })

    // Detect deletions (items in old active but not in new)
    oldItems.forEach((oldItem) => {
        if (
            oldItem.status === ADDED &&
            !newItems.some((newItem) => getKey(newItem) === getKey(oldItem))
        ) {
            results.deletedComparedCookies.push(oldItem)
        }
    })

    return results
}

const CookieTableVersionCompareModal = ({
    versionId,
    cookieTableId,
    onDismiss,
    onCreate,
    onCancel,
}) => {
    const {
        refetch,
        comparingCookieTableItems,
        latestCookieTableItems,
        isFetching,
        error,
    } = useCompareCookieTableItemPageQuery({
        cookieTableId,
        cookieTableVersionId: versionId,
        first: 9999,
    })

    if (error) {
        return <DrawerTabError error={error} />
    }
    const {
        newComparedCookies,
        sameComparedCookies,
        deletedComparedCookies,
        deprecatedStatusCookies,
        modifiedStatusCookies,
    } = compareCookieTables(comparingCookieTableItems, latestCookieTableItems)

    return (
        <Modal className="max-w-3xl" onDismiss={onDismiss}>
            <ModalTitle
                title={<Trans>Create new cookie table version</Trans>}
                description={
                    <Trans>
                        In the overview below you can compare and adjust the
                        differences between the previous version
                    </Trans>
                }
                iconName="cookie"
                iconColor="none"
                iconSize="md"
                onClose={onCancel}
                newModalTitle
            />
            <div>
                <CookieTableVersionCompareModalTable
                    deletedCookies={deletedComparedCookies}
                    sameCookies={sameComparedCookies}
                    newCookies={newComparedCookies}
                    deprecatedCookies={deprecatedStatusCookies}
                    isFetching={isFetching}
                    modifiedCookies={modifiedStatusCookies}
                    onUpdateCompleted={() => {
                        refetch()
                    }}
                />

                <ModalFooter>
                    <Button type="white" onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button onClick={onCreate}>
                        <Trans>Create</Trans>
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    )
}

export default CookieTableVersionCompareModal
