import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { AuditRecordMobileActionsPage } from './AuditRecordMobileActionsPage'
import useAuditRecordMobileActionsPageQuery, {
    useAuditRecordMobileActionsPageAuditScenarioQuery,
} from './useAuditRecordMobileActionsPageQuery'
import PageLoading from '../../utils/PageLoading'
import { AppetizeClientProvider } from './AppetizeClientProvider'
import CreateAuditScenarioModalWithState from './CreateAuditScenarioModalWithState'
import useNotifications from '../../hooks/useNotifications'
import UpdateAuditScenarioModalWithState from './UpdateAuditScenarioModalWithState'
import useAppetizeClient from '../../hooks/useAppetizeClient'
import { processNetworkEvents } from './processNetworkEvents'
import ErrorPage from '../../utils/ErrorPage'
import useAppState from '../../hooks/useAppState'
import useUpdateDeviceSettingsFormState from './useUpdateDeviceSettingsFormState'

export default function AuditRecordMobileActionsPageWithState() {
    const { id, auditScenarioId = null } = useParams()

    const { audit, availableDevices, error, isFetching } =
        useAuditRecordMobileActionsPageQuery({
            id,
        })

    const {
        auditScenario,
        isFetching: isFetchingAuditScenario,
        error: errorAuditScenario,
    } = useAuditRecordMobileActionsPageAuditScenarioQuery({
        id: auditScenarioId,
    })

    if (error || errorAuditScenario)
        return (
            <ErrorPage
                showNavigation={false}
                error={error || errorAuditScenario}
            />
        )
    if (isFetching || isFetchingAuditScenario) {
        return <PageLoading />
    }
    if (
        auditScenarioId &&
        (!Object.keys(audit).length > 0 ||
            !Object.keys(auditScenario).length > 0)
    ) {
        return <PageLoading />
    }

    const { auditBuilds = [] } = audit
    const initialBuild = auditBuilds[0] || null

    return (
        <AppetizeClientProvider
            isDisabled={!!auditScenarioId}
            deviceSettings={auditScenario?.deviceSettings}
            actions={auditScenario?.actions}
            buildId={initialBuild.appetizeBuildId}
            deviceOs={initialBuild.deviceOs}
            availableDevices={availableDevices}
        >
            <AuditRecordMobileActionsHandlersPage
                audit={audit}
                id={id}
                auditScenario={auditScenario}
            />
        </AppetizeClientProvider>
    )
}

export function AuditRecordMobileActionsHandlersPage({
    id,
    audit,
    auditScenario = {},
}) {
    const appetizeClient = useAppetizeClient()
    const history = useHistory()
    const { currentUser } = useAppState()
    const { dispatchSuccess, dispatchError, dispatchWarning } =
        useNotifications()

    const [isReplayingActions, setIsReplayingActions] = useState(false)
    const [isCreatingAuditScenario, setIsCreatingAuditScenario] =
        useState(false)
    const [isUpdatingAuditScenario, setIsUpdatingAuditScenario] =
        useState(false)
    const [actionStatuses, setActionStatuses] = useState({})

    let initialDeviceSettings = appetizeClient.deviceSettings
    const { deviceSettings } = auditScenario
    if (deviceSettings) {
        initialDeviceSettings = deviceSettings
    }
    const deviceSettingsFormState = useUpdateDeviceSettingsFormState({
        ...initialDeviceSettings,
        locationLat:
            initialDeviceSettings?.location?.[0] != null
                ? String(initialDeviceSettings.location[0])
                : '',
        locationLong:
            initialDeviceSettings?.location?.[1] != null
                ? String(initialDeviceSettings.location[1])
                : '',
    })

    const isUpdate = Object.keys(auditScenario).length !== 0

    const onClearActions = async () => {
        if (!appetizeClient) return
        await appetizeClient.clearActions()
    }

    const onChangeAuditBuild = async (event) => {
        const appetizeId = event.target.value
        const result = appetizeClient.changeBuild(appetizeId)
        if (result.error) {
            dispatchError({
                message: `Error changing build: ${result.error}`,
            })
        }
    }

    const onReplayActions = async () => {
        if (!appetizeClient) return

        setActionStatuses({})
        setIsReplayingActions(true)
        try {
            if (!appetizeClient.hasActiveSession()) {
                await appetizeClient.startSession()
            } else {
                await appetizeClient.reinstallApp()
            }

            await appetizeClient.enableUserInteractions(false)

            const { actionEvents } = appetizeClient
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < actionEvents.length; i++) {
                // Mark the action as in-progress
                setActionStatuses((prev) => ({
                    ...prev,
                    [i]: { status: 'in-progress' },
                }))

                // eslint-disable-next-line no-await-in-loop
                const result = await appetizeClient.playAction(
                    actionEvents[i].action
                )
                if (result.error) {
                    setActionStatuses((prev) => ({
                        ...prev,
                        [i]: { status: 'error', error: result.error },
                    }))
                    if (actionEvents[i].isRequired) {
                        break
                    }
                } else {
                    setActionStatuses((prev) => ({
                        ...prev,
                        [i]: { status: 'success' },
                    }))
                }
            }
            dispatchSuccess({ message: 'Replay has been completed' })
            await appetizeClient.enableUserInteractions(true)
        } catch (e) {
            dispatchError({
                message: `Replay failed: ${e.message}`,
            })
        } finally {
            setIsReplayingActions(false)
            await appetizeClient.enableUserInteractions(true)
        }
    }

    const onDeleteAction = (actionIndex) => {
        if (!appetizeClient) return
        appetizeClient.deleteAction(actionIndex)
    }

    const onToggleRequiredAction = (actionIndex) => {
        if (!appetizeClient) return
        appetizeClient.toggleRequiredAction(actionIndex)
    }

    const onUpdateAction = (actionIndex, action) => {
        const result = appetizeClient.updateAction(actionIndex, action)
        if (!result.success) {
            dispatchError({ message: result.error })
        } else {
            dispatchSuccess({
                message: <Trans>Action successfully updated.</Trans>,
            })
        }
    }

    const onStopSession = async () => {
        if (!appetizeClient) return
        await appetizeClient.stopSession()
    }

    const onShowUiElements = async () => {
        if (!appetizeClient) return

        if (!appetizeClient.hasActiveSession()) {
            dispatchWarning({ message: 'A session needs to be running.' })
            return
        }

        const result = await appetizeClient.getUiElements()
        if (result.error) {
            dispatchError({ message: result.error })
        }
    }

    const onUpdateDeviceSettings = async () => {
        if (!appetizeClient) return
        try {
            const { values } = deviceSettingsFormState
            const { locationLat, locationLong, ...rest } = values
            let location = null
            if (locationLat && locationLong) {
                location = [parseFloat(locationLat), parseFloat(locationLong)]
            }

            await appetizeClient.updateDeviceSettings({
                ...rest,
                location,
            })
            dispatchSuccess({
                message: <Trans>Device settings successfully updated.</Trans>,
            })
        } catch (e) {
            dispatchError({
                message: e.message,
            })
        }
    }

    const { cookies, requests } = processNetworkEvents(
        appetizeClient.networkEvents
    )

    return (
        <>
            {isCreatingAuditScenario && (
                <CreateAuditScenarioModalWithState
                    audit={audit}
                    deviceOs={appetizeClient.deviceOs}
                    actionEvents={appetizeClient.actionEvents}
                    onDismiss={() => setIsCreatingAuditScenario(false)}
                    deviceSettingsFormState={deviceSettingsFormState}
                    onCompleted={async () => {
                        await onStopSession()
                        history.push(`/audits/${id}/settings`)
                    }}
                />
            )}
            {isUpdatingAuditScenario && (
                <UpdateAuditScenarioModalWithState
                    audit={audit}
                    auditScenario={auditScenario}
                    deviceOs={appetizeClient.deviceOs}
                    actionEvents={appetizeClient.actionEvents}
                    onDismiss={() => setIsUpdatingAuditScenario(false)}
                    deviceSettingsFormState={deviceSettingsFormState}
                    onCompleted={async () => {
                        await onStopSession()
                        history.push(`/audits/${id}/settings`)
                    }}
                />
            )}
            <AuditRecordMobileActionsPage
                audit={audit}
                isUpdate={isUpdate}
                currentUser={currentUser}
                appetizeClient={appetizeClient}
                onShowUiElements={onShowUiElements}
                actionStatuses={actionStatuses}
                onChangeAuditBuild={onChangeAuditBuild}
                onClearActions={onClearActions}
                onReplayActions={onReplayActions}
                isReplayingActions={isReplayingActions}
                onCancel={() => history.push(`/audits/${id}/settings`)}
                onDeleteAction={onDeleteAction}
                onUpdateAction={onUpdateAction}
                onSaveScenario={() => setIsCreatingAuditScenario(true)}
                onUpdateScenario={() => setIsUpdatingAuditScenario(true)}
                onToggleRequiredAction={onToggleRequiredAction}
                onUpdateDeviceSettings={onUpdateDeviceSettings}
                cookies={cookies}
                requests={requests}
                deviceSettingsFormState={deviceSettingsFormState}
            />
        </>
    )
}
