import React from 'react'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import DateTime from '../../utils/DateTime'

const FRAGMENT = gql`
    fragment StaffJobsPageTableRow on Job {
        id
        status
        name
        failedReason
        next
        pattern
        startedAt
        finishedAt
    }
`

const StaffJobsPageTableRow = ({ job, activeStatus }) => {
    const {
        id,
        status,
        name,
        queue,
        failedReason,
        next,
        pattern,
        startedAt,
        finishedAt,
    } = job
    return (
        <TableRow data-testid="StaffJobsPageTableRow">
            <TableCell>{id}</TableCell>
            <TableCell hasText>{name}</TableCell>
            <TableCell hasText>{status}</TableCell>
            {activeStatus === 'failed' && <TableCell>{failedReason}</TableCell>}
            {activeStatus === 'repeat' && (
                <>
                    <TableCell>{pattern}</TableCell>
                    <TableCell>
                        <DateTime>{next}</DateTime>
                    </TableCell>
                </>
            )}
            <TableCell />
            {(activeStatus === 'failed' || activeStatus === 'completed') && (
                <>
                    <TableCell>
                        {startedAt ? <DateTime>{startedAt}</DateTime> : '/'}
                    </TableCell>
                    <TableCell>
                        {finishedAt ? <DateTime>{finishedAt}</DateTime> : '/'}
                    </TableCell>
                </>
            )}
        </TableRow>
    )
}

StaffJobsPageTableRow.FRAGMENT = FRAGMENT

export default StaffJobsPageTableRow
