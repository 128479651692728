import React, { useState } from 'react'
import AuditObservationTableRow from '../audits/AuditObservationTableRow'
import { useIdentifiedCookieObservationPagesQuery } from './useIdentifiedCookieObservationPagesQuery'

export default function IdentifiedCookieAuditObservationTableRow({
    identifiedCookieId,
    audit,
    search,
    objectName,
}) {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const {
        pages,
        error,
        isFetching: loading,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useIdentifiedCookieObservationPagesQuery({
        identifiedCookieId,
        auditId: audit.id,
        search,
        skip: isCollapsed,
    })
    return (
        <AuditObservationTableRow
            audit={audit}
            error={error}
            loading={loading}
            hasMore={hasMore}
            isFetchingMore={isFetchingMore}
            handleFetchMore={handleFetchMore}
            pages={pages}
            objectName={objectName}
            nextActiveTabOnClick="cookies"
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
        />
    )
}
