import React from 'react'
import clsx from 'clsx'

const FormButtonGroup = ({ className, ...props }) => (
    <div
        className={clsx(
            'flex flex-row-reverse gap-4 pt-5 sm:border-gray-200',
            className
        )}
        {...props}
    />
)

export default FormButtonGroup
