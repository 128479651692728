import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired, isValidRegex } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>The name is required</Trans>,
    },
    {
        path: 'sensitivity',
        validate: isRequired,
        message: <Trans>Sensitivity is required</Trans>,
    },
    {
        path: 'type',
        validate: isRequired,
        message: <Trans>Type is required</Trans>,
    },
    {
        path: 'patterns',
        validate: (value) =>
            Array.isArray(value) &&
            value.length > 0 &&
            value.every((pattern) => isValidRegex(pattern)),
        message: <Trans>At least one valid regex pattern is required</Trans>,
    },
])

const valuesToInput = ({ name, sensitivity, type, patterns, examples }) => ({
    name,
    sensitivity,
    type,
    patterns,
    examples,
})

const useUpdateDataIdentifierFormState = (initialValues) => {
    const { handleChange, ...formState } = useFormState(
        {
            name: '',
            sensitivity: '',
            type: '',
            patterns: [''],
            examples: '',
            ...initialValues,
        },
        {
            validation,
            valuesToInput,
        }
    )
    const addPatternField = () => {
        handleChange('patterns', (patterns) => [...patterns, ''])
    }
    const removePatternField = (index) => {
        handleChange('patterns', (patterns) => {
            if (patterns.length === 1) {
                return ['']
            }
            return patterns.filter((_, i) => i !== index)
        })
    }

    return {
        ...formState,
        addPatternField,
        removePatternField,
    }
}

export default useUpdateDataIdentifierFormState
