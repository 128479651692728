import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useUpdatePurposeIdentifierMutation from './useUpdatePurposeIdentifierResolver'
import useUpdatePurposeIdentifierFormState from './useUpdateTrackerDetailsDrawerPurposeIdentifiersFormState'
import UpdateTrackerDetailsDrawerPurposeIdentifiersModal from './UpdateTrackerDetailsDrawerPurposeIdentifiersModal'
import useExampleText from '../staff-data-identifiers/useExampleText'

const UpdateTrackerDetailsDrawerPurposeIdentifiersModalWithState = ({
    purposeIdentifier,
    trackerId,
    onDismiss,
    onCompleted,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const formState = useUpdatePurposeIdentifierFormState(purposeIdentifier)

    const [updatePurposeIdentifier, { loading }] =
        useUpdatePurposeIdentifierMutation({
            onCompleted,
        })

    const { exampleText, highlightedExampleText, onUpdateExampleText } =
        useExampleText(dispatchError)
    const { id } = purposeIdentifier
    const purposeIdentifierId = id
    return (
        <UpdateTrackerDetailsDrawerPurposeIdentifiersModal
            exampleText={exampleText}
            highlightedExampleText={highlightedExampleText}
            onUpdateExampleText={onUpdateExampleText}
            formState={formState}
            isLoading={loading}
            onDismiss={onDismiss}
            onSubmit={async () => {
                try {
                    if (formState.validate()) {
                        const variables = {
                            trackerId,
                            purposeIdentifierId,
                            input: formState.valuesToInput(),
                        }
                        await updatePurposeIdentifier({ variables })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Purpose identifier updated successfully!
                                </Trans>
                            ),
                        })
                    }
                } catch (error) {
                    dispatchError({ message: error.message })
                }
            }}
        />
    )
}

export default UpdateTrackerDetailsDrawerPurposeIdentifiersModalWithState
