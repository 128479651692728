import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableBody from '../../utils/TableBody'
import PurposesTags from '../../utils/PurposesTags'
import TrackerDetailsDrawerPurposeIdentifiersTabTableDropdownMenu from './TrackerDetailsDrawerPurposeIdentifiersTabTableDropdownMenu'
import ActionButton from '../../utils/ActionButton'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'

const TrackerDetailsDrawerPurposeIdentifiersTabTable = ({
    purposeIdentifiers,
    trackerId,
    onUpdatePurposeIdentifierCompleted,
    onDeletePurposeIdentifierCompleted,
}) => {
    if (!purposeIdentifiers || purposeIdentifiers.length === 0) {
        return (
            <DrawerEmptyCard
                iconName="cookie"
                title={<Trans>No purposeIdentifiers</Trans>}
                description={<Trans>No purposeIdentifiers were found.</Trans>}
            />
        )
    }
    return (
        <Table hasMinWidth={false} hasHeader={false} isRounded>
            <TableBody>
                {purposeIdentifiers.length > 0 ? (
                    purposeIdentifiers.map((purposeIdentifier) => (
                        <TableRow>
                            <TableCell className="text-gray-900">
                                {purposeIdentifier.pattern}
                            </TableCell>
                            <TableCell size="sm" className="pr-0 text-right">
                                <PurposesTags
                                    purposes={purposeIdentifier.purposes}
                                />
                            </TableCell>
                            <TableCell className="pr-0">
                                <TrackerDetailsDrawerPurposeIdentifiersTabTableDropdownMenu
                                    onUpdatePurposeIdentifierCompleted={
                                        onUpdatePurposeIdentifierCompleted
                                    }
                                    onDeletePurposeIdentifierCompleted={
                                        onDeletePurposeIdentifierCompleted
                                    }
                                    purposeIdentifier={purposeIdentifier}
                                    trackerId={trackerId}
                                >
                                    <ActionButton className="pr-0" />
                                </TrackerDetailsDrawerPurposeIdentifiersTabTableDropdownMenu>
                            </TableCell>
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell colSpan="3" className="text-center">
                            No purposes found
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
}

export default TrackerDetailsDrawerPurposeIdentifiersTabTable
