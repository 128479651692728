/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import { AVAILABLE_VENDOR_SOURCE_TYPES } from '@clew/shared/src/constants'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import VendorTypeSelect from '../../utils/VendorTypeSelect'
import FormSelectList from '../../utils/FormSelectList'
import SelectList from '../../utils/SelectList'
import SelectListOption from '../../utils/SelectListOption'
import Toggle from '../../utils/Toggle'
import FormInputErrors from '../../utils/FormInputErrors'
import ButtonLink from '../../utils/ButtonLink'
import FormTextarea from '../../utils/FormTextarea'

export function VendorModalFormFields({ formState }) {
    const {
        values,
        getErrorMessages,
        getNativeInputProps,
        handleChange,
        handleAddArrayInput,
        handleRemoveArrayInput,
    } = formState
    return (
        <div className="grid grid-cols-1 gap-x-6 sm:grid-cols-6">
            <FormGroup
                label={<Trans>Name</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <FormInput
                    errors={getErrorMessages('name')}
                    {...getNativeInputProps('name')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>External Id</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <FormInput
                    errors={getErrorMessages('externalId')}
                    {...getNativeInputProps('externalId')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Website</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <FormInput
                    errors={getErrorMessages('websiteUrl')}
                    {...getNativeInputProps('websiteUrl')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Legal Name</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <FormInput
                    errors={getErrorMessages('legalName')}
                    {...getNativeInputProps('legalName')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Root Domain</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <FormInput
                    errors={getErrorMessages('domain')}
                    {...getNativeInputProps('domain')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Description</Trans>}
                className="mb-4 sm:col-span-6"
            >
                <FormTextarea
                    rows={6}
                    errors={getErrorMessages('description')}
                    {...getNativeInputProps('description')}
                />
            </FormGroup>
            <FormGroup
                className="sm:col-span-6"
                label={<Trans>Type</Trans>}
                htmlFor="type"
            >
                <VendorTypeSelect
                    value={values.types}
                    errors={getErrorMessages('types')}
                    onChange={(types) => handleChange('types', types)}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Privacy Policy URL</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <FormInput
                    errors={getErrorMessages('privacyPolicyUrl')}
                    {...getNativeInputProps('privacyPolicyUrl')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Cookie Policy URL</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <FormInput
                    errors={getErrorMessages('cookiePolicyUrl')}
                    {...getNativeInputProps('cookiePolicyUrl')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Device Storage Disclosure URL</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <FormInput
                    errors={getErrorMessages('deviceStorageDisclosureUrl')}
                    {...getNativeInputProps('deviceStorageDisclosureUrl')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>TCF Global vendor list id</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <FormInput
                    errors={getErrorMessages('gvlId')}
                    {...getNativeInputProps('gvlId')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Source Type(s)</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <FormSelectList>
                    <SelectList errors={getErrorMessages('sourceType')}>
                        {AVAILABLE_VENDOR_SOURCE_TYPES.map((type) => {
                            const sourceTypeValue = values.sourceType || []
                            const isChecked = sourceTypeValue.includes(type)
                            return (
                                <SelectListOption
                                    key={type}
                                    isCheckbox
                                    checked={isChecked}
                                    onClick={() => {
                                        const newSourceType = isChecked
                                            ? sourceTypeValue.filter(
                                                  (t) => t !== type
                                              )
                                            : [...sourceTypeValue, type]
                                        handleChange(
                                            'sourceType',
                                            newSourceType
                                        )
                                    }}
                                >
                                    {type}
                                </SelectListOption>
                            )
                        })}
                    </SelectList>
                </FormSelectList>
            </FormGroup>
            <FormGroup
                label={<Trans>Source URL</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <FormInput
                    errors={getErrorMessages('sourceUrl')}
                    {...getNativeInputProps('sourceUrl')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>IAB</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <Toggle
                    name="isIab"
                    value={values.isIab}
                    onClick={(value) => {
                        handleChange('isIab', value)
                    }}
                />
                <FormInputErrors errors={getErrorMessages('isIab')} />
            </FormGroup>
            <FormGroup
                label={<Trans>ATP</Trans>}
                className="mb-4 sm:col-span-3"
            >
                <Toggle
                    name="isAtp"
                    value={values.isAtp}
                    onClick={(value) => {
                        handleChange('isAtp', value)
                    }}
                />
                <FormInputErrors errors={getErrorMessages('isAtp')} />
            </FormGroup>
            <FormGroup
                className="sm:col-span-3"
                label={<Trans>IAB Purposes</Trans>}
                htmlFor="iabPurpose"
            >
                {values.purposes.length > 0 &&
                    values.purposes.map((purpose, index) => (
                        <div
                            key={index}
                            className="mb-4 grid w-full grid-cols-8 gap-4"
                        >
                            <FormInput
                                id="iabPurpose"
                                type="number"
                                step={1}
                                min={1}
                                className="col-span-6"
                                errors={getErrorMessages(`purposes.${index}`)}
                                {...getNativeInputProps(`purposes.${index}`)}
                            />
                            <div className="col-span-2 flex items-center">
                                <ButtonLink
                                    type="danger"
                                    onClick={() =>
                                        handleRemoveArrayInput(
                                            'purposes',
                                            index
                                        )
                                    }
                                    disabled={values.purposes.length === 1}
                                >
                                    <Trans>Remove</Trans>
                                </ButtonLink>
                            </div>
                        </div>
                    ))}
                <div>
                    <ButtonLink onClick={() => handleAddArrayInput('purposes')}>
                        <Trans>+ Add IAB purpose</Trans>
                    </ButtonLink>
                </div>
            </FormGroup>
            <FormGroup
                className="sm:col-span-3"
                label={<Trans>Legitimate Interests</Trans>}
                htmlFor="legIntPurpose"
            >
                {values.legIntPurposes.length > 0 &&
                    values.legIntPurposes.map((purpose, index) => (
                        <div
                            key={index}
                            className="mb-4 grid w-full grid-cols-8 gap-4"
                        >
                            <FormInput
                                id="legIntPurpose"
                                type="number"
                                step={1}
                                min={1}
                                className="col-span-6"
                                errors={getErrorMessages(
                                    `legIntPurposes.${index}`
                                )}
                                {...getNativeInputProps(
                                    `legIntPurposes.${index}`
                                )}
                            />
                            <div className="col-span-2 flex items-center">
                                <ButtonLink
                                    type="danger"
                                    onClick={() =>
                                        handleRemoveArrayInput(
                                            'legIntPurposes',
                                            index
                                        )
                                    }
                                    disabled={
                                        values.legIntPurposes.length === 1
                                    }
                                >
                                    <Trans>Remove</Trans>
                                </ButtonLink>
                            </div>
                        </div>
                    ))}
                <div>
                    <ButtonLink
                        onClick={() => handleAddArrayInput('legIntPurposes')}
                    >
                        <Trans>+ Add Leg. Int. purpose</Trans>
                    </ButtonLink>
                </div>
            </FormGroup>
            <FormGroup
                className="sm:col-span-3"
                label={<Trans>Flexible IAB Purposes</Trans>}
                htmlFor="flexiblePurposes"
            >
                {values.legIntPurposes.length > 0 &&
                    values.flexiblePurposes.map((purpose, index) => (
                        <div
                            key={index}
                            className="mb-4 grid w-full grid-cols-8 gap-4"
                        >
                            <FormInput
                                id="flexiblePurposes"
                                type="number"
                                step={1}
                                min={1}
                                className="col-span-6"
                                errors={getErrorMessages(
                                    `flexiblePurposes.${index}`
                                )}
                                {...getNativeInputProps(
                                    `flexiblePurposes.${index}`
                                )}
                            />
                            <div className="col-span-2 flex items-center">
                                <ButtonLink
                                    type="danger"
                                    onClick={() =>
                                        handleRemoveArrayInput(
                                            'flexiblePurposes',
                                            index
                                        )
                                    }
                                    disabled={
                                        values.flexiblePurposes.length === 1
                                    }
                                >
                                    <Trans>Remove</Trans>
                                </ButtonLink>
                            </div>
                        </div>
                    ))}
                <div>
                    <ButtonLink
                        onClick={() => handleAddArrayInput('flexiblePurposes')}
                    >
                        <Trans>+ Add Flexible purpose</Trans>
                    </ButtonLink>
                </div>
            </FormGroup>
            <FormGroup
                className="sm:col-span-3"
                label={<Trans>Special Purposes</Trans>}
                htmlFor="specialPurposes"
            >
                {values.specialPurposes.length > 0 &&
                    values.specialPurposes.map((purpose, index) => (
                        <div
                            key={index}
                            className="mb-4 grid w-full grid-cols-8 gap-4"
                        >
                            <FormInput
                                id="specialPurposes"
                                type="number"
                                step={1}
                                min={1}
                                className="col-span-6"
                                errors={getErrorMessages(
                                    `specialPurposes.${index}`
                                )}
                                {...getNativeInputProps(
                                    `specialPurposes.${index}`
                                )}
                            />
                            <div className="col-span-2 flex items-center">
                                <ButtonLink
                                    type="danger"
                                    onClick={() =>
                                        handleRemoveArrayInput(
                                            'specialPurposes',
                                            index
                                        )
                                    }
                                    disabled={
                                        values.specialPurposes.length === 1
                                    }
                                >
                                    <Trans>Remove</Trans>
                                </ButtonLink>
                            </div>
                        </div>
                    ))}
                <div>
                    <ButtonLink
                        onClick={() => handleAddArrayInput('specialPurposes')}
                    >
                        <Trans>+ Add Special purpose</Trans>
                    </ButtonLink>
                </div>
            </FormGroup>
            <FormGroup
                className="sm:col-span-3"
                label={<Trans>Activities</Trans>}
                htmlFor="activities"
            >
                {values.activities.length > 0 &&
                    values.activities.map((activity, index) => (
                        <div
                            key={index}
                            className="mb-4 grid w-full grid-cols-8 gap-4"
                        >
                            <FormInput
                                id="activities"
                                type="text"
                                className="col-span-6"
                                errors={getErrorMessages(`activities.${index}`)}
                                {...getNativeInputProps(`activities.${index}`)}
                            />
                            <div className="col-span-2 flex items-center">
                                <ButtonLink
                                    type="danger"
                                    onClick={() =>
                                        handleRemoveArrayInput(
                                            'activities',
                                            index
                                        )
                                    }
                                    disabled={values.activities.length === 1}
                                >
                                    <Trans>Remove</Trans>
                                </ButtonLink>
                            </div>
                        </div>
                    ))}
                <div>
                    <ButtonLink
                        onClick={() => handleAddArrayInput('activities')}
                    >
                        <Trans>+ Add Activity</Trans>
                    </ButtonLink>
                </div>
            </FormGroup>
        </div>
    )
}
