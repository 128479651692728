import React, { createContext, Component } from 'react'
import Script from 'next/script'
import { DEVICE_OS_IOS } from '@clew/shared/src/constants'

function createGroupedDevices(availableDevices) {
    return availableDevices.reduce((map, device) => {
        const { platform } = device
        if (!map.has(platform)) {
            map.set(platform, [])
        }
        map.get(platform).push(device)
        return map
    }, new Map())
}

const initialState = {
    client: null,
    session: null,
    appetizeIsLoaded: false,
    isDisabled: false,
    isStopped: false,
    isStartingSession: false,
    buildId: null,
    deviceOs: null,
    actionEvents: [],
    networkEvents: [],
    clientErrors: [],
    availableDevices: [],
    uiElements: {},
    deviceSettings: {
        deviceId: null,
        osVersion: null,
        grantPermissions: false,
        location: null,
        language: null,
    },
}
export const AppetizeClientContext = createContext({
    ...initialState,
})

export class AppetizeClientProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...initialState,
            actionEvents: props.actions || [],
            buildId: props.buildId,
            deviceOs: props.deviceOs,
            availableDevices: props.availableDevices || [],
            isDisabled: props.isDisabled,
            deviceSettings: props.deviceSettings || {},
        }
        this.init = this.init.bind(this)
        this.hasActiveSession = this.hasActiveSession.bind(this)
        this.getUiElements = this.getUiElements.bind(this)
        this.reinstallApp = this.reinstallApp.bind(this)
        this.startSession = this.startSession.bind(this)
        this.stopSession = this.stopSession.bind(this)
        this.setConfig = this.setConfig.bind(this)
        this.playAction = this.playAction.bind(this)
        this.playActions = this.playActions.bind(this)
        this.changeDevice = this.changeDevice.bind(this)
        this.changeOsVersion = this.changeOsVersion.bind(this)
        this.changeDeviceOs = this.changeDeviceOs.bind(this)
        this.changeBuild = this.changeBuild.bind(this)
        this.deleteAction = this.deleteAction.bind(this)
        this.updateAction = this.updateAction.bind(this)
        this.clearActions = this.clearActions.bind(this)
        this.enableUserInteractions = this.enableUserInteractions.bind(this)
        this.toggleRequiredAction = this.toggleRequiredAction.bind(this)
        this.updateDeviceSettings = this.updateDeviceSettings.bind(this)
        this.availableOsVersionsForDevice =
            this.availableOsVersionsForDevice.bind(this)
        this.disableDevice = this.disableDevice.bind(this)
        this.enableDevice = this.enableDevice.bind(this)
    }

    componentDidMount() {
        const { buildId } = this.state
        if (buildId && window.appetize && window.appetize.getClient) {
            this.setState({ appetizeIsLoaded: true })
            this.init()
        }
    }

    async getUiElements() {
        try {
            if (!this.hasActiveSession()) {
                throw new Error('No session started')
            }
            const { session } = this.state
            const uiElements = await session.getUI()
            this.setState((prev) => ({
                ...prev,
                uiElements,
            }))
            return uiElements
        } catch (e) {
            return {
                error: e.message,
            }
        }
    }

    async setConfig(config) {
        try {
            const { client } = this.state
            return client.setConfig(config)
        } catch (e) {
            throw new Error("Can't set config")
        }
    }

    hasActiveSession() {
        const { session } = this.state
        return !!session
    }

    #normalizeActionData(actionData) {
        const action = actionData
        if (action.type === 'swipe') {
            delete action.element
            return action
        }
        return action
    }

    async reinstallApp() {
        if (this.hasActiveSession()) {
            this.setState({
                networkEvents: [],
            })
            const { session } = this.state
            await session.reinstallApp()
        }
        return true
    }

    async startSession(session) {
        try {
            this.setState({ isStartingSession: true })
            let appetizeSession = session
            if (!appetizeSession) {
                const { client } = this.state
                if (this.hasActiveSession()) {
                    return client.endSession()
                }
                appetizeSession = await client.startSession()
                await appetizeSession.waitUntilReady()
            }
            this.setState({
                session: appetizeSession,
                networkEvents: [],
            })
            if (appetizeSession) {
                appetizeSession.on('action', (data) => {
                    const normalizedData = this.#normalizeActionData(data)
                    this.setState((prev) => ({
                        actionEvents: [
                            ...prev.actionEvents,
                            { isRequired: false, action: normalizedData },
                        ],
                    }))
                })
                appetizeSession.on('network', (data) => {
                    this.setState((prev) => ({
                        networkEvents: [...prev.networkEvents, data],
                    }))
                })
            }
            this.setState({ isStartingSession: false })
            return {
                session: appetizeSession,
            }
        } catch (e) {
            return {
                error: e.message,
            }
        }
    }

    async stopSession() {
        try {
            if (this.hasActiveSession()) {
                const { client } = this.state
                await client.endSession()
                this.setState({
                    session: null,
                    isStopped: true,
                    // networkEvents: [],
                })
            }
            return true
        } catch (error) {
            throw new Error("Can't stop the session")
        }
    }

    async playActions(actions) {
        try {
            const { session } = this.state
            if (this.hasActiveSession()) {
                throw new Error('No session started')
            }
            await session.playActions(actions)
            return {
                actions,
            }
        } catch (e) {
            return {
                error: e.message,
                actions,
            }
        }
    }

    async playAction(action) {
        try {
            const { session } = this.state
            if (!this.hasActiveSession()) {
                throw new Error('No session started')
            }
            await session.waitUntilReady()
            await session.playAction(action)
            return {
                action,
            }
        } catch (e) {
            return {
                error: e.message,
                action,
            }
        }
    }

    async enableUserInteractions(bool = true) {
        try {
            return await this.setConfig({
                userInteractionDisabled: !bool,
            })
        } catch (e) {
            return {
                error: e.message,
            }
        }
    }

    disableDevice() {
        this.setState({
            isDisabled: true,
        })
    }

    enableDevice() {
        this.setState({
            isDisabled: false,
        })
    }

    async changeBuild(buildId) {
        if (this.hasActiveSession()) {
            await this.stopSession()
            // when changing the build we reset the actions. this seems logical in most cases
            this.setState({
                actionEvents: [],
            })
        }
        this.setState({ buildId })
        try {
            await this.init()
            return true
        } catch (e) {
            return {
                error: e.message,
            }
        }
    }

    async changeDevice(deviceId) {
        const newConfig = await this.setConfig({
            device: deviceId,
        })
        this.setState((prev) => ({
            deviceSettings: {
                ...prev.deviceSettings,
                deviceId,
                osVersion: newConfig.osVersion,
            },
        }))
    }

    async changeOsVersion(osVersion) {
        this.setState((prev) => ({
            deviceSettings: {
                ...prev.deviceSettings,
                osVersion,
            },
        }))

        await this.setConfig({
            osVersion,
        })
    }

    changeDeviceOs(deviceOs) {
        this.setState({ deviceOs })
    }

    deleteAction(actionIndex) {
        this.setState((prev) => ({
            actionEvents: prev.actionEvents.filter(
                (_, index) => index !== actionIndex
            ),
        }))
    }

    toggleRequiredAction(actionIndex) {
        this.setState(({ actionEvents }) => ({
            actionEvents: actionEvents.map((action, index) =>
                index === actionIndex
                    ? { ...action, isRequired: !action.isRequired }
                    : action
            ),
        }))
    }

    updateAction(actionIndex, action) {
        const { actionEvents } = this.state
        // set state of actionEvents should go here
        if (actionIndex < 0 || actionIndex >= actionEvents.length) {
            return { success: false, error: 'Invalid action index' }
        }
        try {
            const updatedAction = JSON.parse(action)
            this.setState((prev) => {
                const newActionEvents = [...prev.actionEvents]
                newActionEvents[actionIndex].action = updatedAction
                return { actionEvents: newActionEvents }
            })
            return { success: true }
        } catch (e) {
            return {
                success: false,
                error: 'Invalid JSON format in action update.',
            }
        }
    }

    async clearActions() {
        this.setState((prev) => ({ ...prev, actionEvents: [] }))
        await this.reinstallApp()
    }

    async updateDeviceSettings({
        deviceId,
        osVersion,
        grantPermissions,
        language,
        location,
    }) {
        if (this.hasActiveSession()) {
            await this.stopSession()
        }
        await this.setConfig({
            device: deviceId,
            osVersion,
            grantPermissions,
            language,
            location,
        })

        this.setState((prev) => ({
            deviceSettings: {
                ...prev.deviceSettings,
                osVersion,
                deviceId,
                grantPermissions,
                language,
                location,
            },
        }))
        return true
    }

    availableOsVersionsForDevice(deviceId) {
        const { deviceOs, availableDevices } = this.state
        const groupedDevices = createGroupedDevices(availableDevices)
        const iosDevices = groupedDevices.get('ios') || []
        const androidDevices = groupedDevices.get('android') || []

        let osDevices = androidDevices
        if (deviceOs === DEVICE_OS_IOS) {
            osDevices = iosDevices
        }
        const [activeDevice] = osDevices.filter(
            (device) => device.id === deviceId
        )
        return activeDevice?.osVersions || []
    }

    async init() {
        if (!window.appetize) {
            throw new Error('Device not loaded')
        }
        const { deviceSettings } = this.state
        const { deviceId, osVersion, ...otherDeviceSettings } = deviceSettings
        const client = await window.appetize.getClient(
            '#mobile_recording_iframe',
            {
                buildId: this.buildId,
                proxy: 'intercept',
                device: deviceId,
                osVersion,
                ...otherDeviceSettings,
            }
        )
        const { device, app } = client
        this.setState((prevState) => ({
            client,
            deviceOs: app.platform.toUpperCase(),
            deviceSettings: {
                ...prevState.deviceSettings,
                deviceId: device.type,
                osVersion: device.osVersion,
            },
        }))
        client.on('session', async (session) => {
            await this.startSession(session)
        })

        // This is mainly to catch inactivity time-outs.
        client.on('sessionEnded', () => {
            this.setState({
                session: null,
            })
        })
    }

    render() {
        const { deviceOs, client, availableDevices } = this.state
        const groupedDevices = createGroupedDevices(availableDevices)
        const iosDevices = groupedDevices.get('ios') || []
        const androidDevices = groupedDevices.get('android') || []

        let osDevices = androidDevices
        if (deviceOs === DEVICE_OS_IOS) {
            osDevices = iosDevices
        }
        let osVersions = []
        const [activeDevice] = osDevices.filter(
            (device) => device.id === client?.device?.type
        )
        osVersions = activeDevice?.osVersions || []

        // TODO:Optimize context value with useMemo to avoid re-renders
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        const value = {
            init: this.init,
            hasActiveSession: this.hasActiveSession,
            getUiElements: this.getUiElements,
            reinstallApp: this.reinstallApp,
            startSession: this.startSession,
            stopSession: this.stopSession,
            setConfig: this.setConfig,
            playAction: this.playAction,
            playActions: this.playActions,
            changeDevice: this.changeDevice,
            changeOsVersion: this.changeOsVersion,
            changeDeviceOs: this.changeDeviceOs,
            changeBuild: this.changeBuild,
            deleteAction: this.deleteAction,
            updateAction: this.updateAction,
            clearActions: this.clearActions,
            enableUserInteractions: this.enableUserInteractions,
            toggleRequiredAction: this.toggleRequiredAction,
            updateDeviceSettings: this.updateDeviceSettings,
            availableOsVersionsForDevice: this.availableOsVersionsForDevice,
            enableDevice: this.enableDevice,
            disableDevice: this.disableDevice,
            osDevices,
            osVersions,
            ...this.state,
        }
        return (
            <>
                <Script
                    src="https://js.appetize.io/embed.js"
                    onLoad={async () => {
                        // we wait for appetize to be loaded
                        const interval = setInterval(() => {
                            if (window.appetize && window.appetize.getClient) {
                                clearInterval(interval)
                                this.setState({ appetizeIsLoaded: true })
                                this.init()
                            }
                        }, 100)
                    }}
                    onError={() =>
                        console.error('Failed to load the appetize script')
                    }
                />
                <AppetizeClientContext.Provider {...this.props} value={value} />
            </>
        )
    }
}
