import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import AuditHeader from '../../layout/AuditHeader'
import DrawerContent from '../../utils/DrawerContent'
import Tag from '../../utils/Tag'
import InfoCard from '../../utils/InfoCard'
import Tabs, { Tab } from '../../utils/Tabs'
import Button from '../../utils/Button'
import { AuditRecordMobileActionsTab } from './AuditRecordMobileActionsTab'
import { AuditRecordMobileRequestsTab } from './AuditRecordMobileRequestsTab'
import { AuditRecordMobileCookiesTab } from './AuditRecordMobileCookiesTab'
import { AuditRecordMobileDeviceSettingsTab } from './AuditRecordMobileDeviceSettingsTab'
import { AuditRecordMobileActionsDevice } from './AuditRecordMobileActionsDevice'
import { AuditRecordMobileUiTab } from './AuditRecordMobileUiTab'

export function AuditRecordMobileActionsPage({
    currentUser,
    audit,
    appetizeClient,
    actionStatuses,
    isReplayingActions,
    onChangeAuditBuild,
    onCancel,
    onClearActions,
    onReplayActions,
    onDeleteAction,
    onUpdateAction,
    onSaveScenario,
    onUpdateScenario,
    onShowUiElements,
    onToggleRequiredAction,
    onUpdateDeviceSettings,
    deviceSettingsFormState,
    isUpdate,
    cookies,
    requests,
}) {
    const { auditBuilds = [] } = audit
    const TAB_ACTIONS = 'actions'
    const TAB_REQUESTS = 'requests'
    const TAB_COOKIES = 'cookies'
    const TAB_UI = 'ui'
    const TAB_DEVICE_SETTINGS = 'settings'

    const [activeTab, setActiveTab] = useState(TAB_ACTIONS)
    const { buildId, actionEvents, uiElements, isDisabled } = appetizeClient

    return (
        <PageContainer data-testid="AuditRecordMobileActionsPage">
            <AuditHeader />
            <div className="grid h-full grid-cols-6">
                <div className="col-span-4 bg-slate-25">
                    <AuditRecordMobileActionsDevice
                        isLoaded={appetizeClient.appetizeIsLoaded}
                        isDisabled={isDisabled}
                        auditBuilds={auditBuilds}
                        buildId={buildId}
                        onChangeAuditBuild={onChangeAuditBuild}
                    />
                </div>
                {/* Right panel */}
                <div className="col-span-2 flex flex-col overflow-x-auto border-l border-gray-200 bg-white pb-14 pt-8">
                    {isUpdate && (
                        <div className="-mt-8 p-2">
                            <InfoCard
                                type="issue"
                                title="Update Mode"
                                iconName="edit"
                                hasBackgroundcolor
                                text="This is update mode. It's not possible to interact with the device directly to record new actions. Start by clicking on the 'Replay actions' button to test your existing scenario."
                            />
                        </div>
                    )}
                    <div className="mb-6 flex items-center justify-between px-6">
                        <h3 className="text-2xl font-medium leading-6 text-gray-900">
                            <Trans>Mobile Scenario</Trans>
                        </h3>
                        <div className="flex items-center gap-2">
                            <Button onClick={onCancel} type="white">
                                <Trans>Cancel</Trans>
                            </Button>
                            {isUpdate ? (
                                <Button
                                    onClick={onUpdateScenario}
                                    disabled={
                                        !actionEvents.length > 0 ||
                                        isReplayingActions
                                    }
                                >
                                    <Trans>Update scenario</Trans>
                                </Button>
                            ) : (
                                <Button
                                    onClick={onSaveScenario}
                                    disabled={
                                        !actionEvents.length > 0 ||
                                        isReplayingActions
                                    }
                                >
                                    <Trans>Save scenario</Trans>
                                </Button>
                            )}
                        </div>
                    </div>
                    <Tabs className="px-6">
                        <Tab
                            isActive={activeTab === TAB_ACTIONS}
                            onClick={() => setActiveTab(TAB_ACTIONS)}
                        >
                            <Trans>Actions</Trans>
                        </Tab>
                        <Tab
                            isActive={activeTab === TAB_COOKIES}
                            onClick={() => setActiveTab(TAB_COOKIES)}
                        >
                            <Trans>Cookies</Trans>
                            <Tag iconNameBefore="cookie">{cookies.length}</Tag>
                        </Tab>
                        <Tab
                            isActive={activeTab === TAB_REQUESTS}
                            onClick={() => setActiveTab(TAB_REQUESTS)}
                        >
                            <Trans>Requests</Trans>
                            <Tag iconNameBefore="tracker">
                                {requests.length}
                            </Tag>
                        </Tab>
                        <Tab
                            isActive={activeTab === TAB_DEVICE_SETTINGS}
                            onClick={() => setActiveTab(TAB_DEVICE_SETTINGS)}
                        >
                            <Trans>Settings</Trans>
                        </Tab>
                        {currentUser.isStaff && (
                            <Tab
                                isActive={activeTab === TAB_UI}
                                onClick={() => setActiveTab(TAB_UI)}
                            >
                                <Trans>UI</Trans>
                            </Tab>
                        )}
                    </Tabs>
                    <DrawerContent className="pb-6 pt-0">
                        {activeTab === TAB_ACTIONS && (
                            <>
                                {actionEvents.length > 0 && (
                                    <div className="mb-4 flex justify-end gap-2">
                                        {!isUpdate && (
                                            <Button
                                                onClick={onClearActions}
                                                type="white"
                                            >
                                                <Trans>Clear actions</Trans>
                                            </Button>
                                        )}
                                        <Button
                                            onClick={onReplayActions}
                                            loading={isReplayingActions}
                                            type="white"
                                        >
                                            <Trans>Replay actions</Trans>
                                        </Button>
                                    </div>
                                )}
                                <AuditRecordMobileActionsTab
                                    actionEvents={actionEvents}
                                    actionStatuses={actionStatuses}
                                    onDeleteAction={onDeleteAction}
                                    onUpdateAction={onUpdateAction}
                                    onToggleRequiredAction={
                                        onToggleRequiredAction
                                    }
                                />
                            </>
                        )}
                        {activeTab === TAB_COOKIES && (
                            <AuditRecordMobileCookiesTab cookies={cookies} />
                        )}
                        {activeTab === TAB_REQUESTS && (
                            <AuditRecordMobileRequestsTab requests={requests} />
                        )}
                        {activeTab === TAB_DEVICE_SETTINGS && (
                            <AuditRecordMobileDeviceSettingsTab
                                appetizeClient={appetizeClient}
                                formState={deviceSettingsFormState}
                                onUpdateDeviceSettings={onUpdateDeviceSettings}
                            />
                        )}
                        {activeTab === TAB_UI && (
                            <AuditRecordMobileUiTab
                                onShowUiElements={onShowUiElements}
                                uiElements={uiElements}
                            />
                        )}
                    </DrawerContent>
                </div>
            </div>
        </PageContainer>
    )
}
