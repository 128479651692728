import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCellAvatar from '../../utils/TableCellAvatar'
import Tooltip from '../../utils/Tooltip'
import TableCell from '../../utils/TableCell'
import Tag from '../../utils/Tag'
import Icon from '../../utils/Icon'

export function AuditRecordMobileRequestsTab({ requests }) {
    if (!requests.length > 0) {
        return (
            <DrawerEmptyCard
                title={<Trans>No Requests</Trans>}
                description={<Trans>No requests were recorded.</Trans>}
                iconName="tracker"
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {requests.map((request) => {
                    const { url, postData = [] } = request
                    const { hostname, pathname, searchParams } = new URL(url)
                    const hasPostData = postData?.length > 0 || false
                    const hasSearchParams =
                        searchParams && searchParams.toString().length > 0
                    let formattedPostData = ''
                    try {
                        formattedPostData = JSON.stringify(
                            JSON.parse(postData),
                            null,
                            2
                        )
                    } catch (e) {
                        formattedPostData = postData
                    }
                    return (
                        <TableRow>
                            <TableCellAvatar
                                truncate
                                title={hostname}
                                avatarIconName="code-browser"
                                className="w-2/3"
                                text={
                                    <Tooltip
                                        content={url}
                                        contentClassName="max-w-[400px] break-all		"
                                    >
                                        {pathname}
                                    </Tooltip>
                                }
                            />
                            <TableCell py="sm">
                                <div className="max-h flex justify-end gap-1">
                                    {hasSearchParams && (
                                        <Tooltip
                                            as="div"
                                            position="left"
                                            hasArrow={false}
                                            contentClassName="max-w-md break-words max-h-80 overflow-auto"
                                            content={searchParams.toString()}
                                        >
                                            <Tag type="gray" className="h-5">
                                                <Icon
                                                    name="search-param"
                                                    size="sm"
                                                    className="text-gray-600"
                                                />
                                            </Tag>
                                        </Tooltip>
                                    )}
                                    {hasPostData && (
                                        <Tooltip
                                            as="div"
                                            position="left"
                                            hasArrow={false}
                                            contentClassName="break-words whitespace-pre-wrap max-h-80 overflow-auto max-w-md"
                                            content={formattedPostData}
                                        >
                                            <Tag type="gray" className="!p-0.5">
                                                <Icon
                                                    name="arrow-up-circle"
                                                    size="xs"
                                                    className="text-gray-600"
                                                />
                                            </Tag>
                                        </Tooltip>
                                    )}
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
