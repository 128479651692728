import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import PurposesTags from '../../utils/PurposesTags'
import Button from '../../utils/Button'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import Tabs, { Tab } from '../../utils/Tabs'
import TrackersPageActionsDropdownMenu from './TrackersPageActionsDropdownMenu'
import TrackerDetailsDrawerDetailsTab from './TrackerDetailsDrawerDetailsTab'
import TrackerDetailsDrawerPurposeIdentifiersTab from './TrackerDetailsDrawerPurposeIdentifiersTab'

const QUERY = gql`
    query trackerDetailsDrawer($id: ID!) {
        tracker(id: $id) {
            id
            domain
            description
            purposes {
                id
                name
            }

            vendor {
                id
                name
                imageUrl
            }
        }
    }
`

export default function TrackerDetailsDrawer({ trackerId }) {
    const [activeTab, setActiveTab] = useState('details')
    const variables = { id: trackerId }
    const {
        data = {},
        error,
        loading,
        refetch,
    } = useQuery(QUERY, { variables })
    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />
    const { domain, purposes, description, vendor } = data.tracker
    return (
        <>
            <DrawerHeader
                avatarSrc={vendor?.imageUrl}
                avatarIconName="tracker"
                title={domain}
                afterTitle={<PurposesTags purposes={purposes} />}
                description={description}
                buttons={
                    <TrackersPageActionsDropdownMenu
                        tracker={data.tracker}
                        onDeleteTrackerCompleted={() => refetch()}
                        onUpdateTrackerCompleted={() => refetch()}
                    >
                        <Button
                            type="white"
                            size="sm"
                            iconBeforeProps={{ name: 'menu-dots', size: 'sm' }}
                        />
                    </TrackersPageActionsDropdownMenu>
                }
            />
            <Tabs className="px-6">
                <Tab
                    isActive={activeTab === 'details'}
                    onClick={() => setActiveTab('details')}
                >
                    <Trans>Details</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'purposeIdentifiers'}
                    onClick={() => setActiveTab('purposeIdentifiers')}
                >
                    <Trans>Purpose Identifiers</Trans>
                </Tab>
            </Tabs>

            {activeTab === 'details' && (
                <TrackerDetailsDrawerDetailsTab
                    purposes={purposes}
                    description={description}
                />
            )}
            {activeTab === 'purposeIdentifiers' && (
                <TrackerDetailsDrawerPurposeIdentifiersTab
                    trackerId={trackerId}
                />
            )}
        </>
    )
}
