import React from 'react'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Tooltip from '../../utils/Tooltip'
import Tag from '../../utils/Tag'
import StaffDataIdentifiersPageTableRowActionButtonWithState from './StaffDataIdentifiersPageTableRowActionButtonWithState'

const FRAGMENT = gql`
    fragment StaffDataIdentifiersPageTableRow on DataIdentifier {
        id
        type
        name
        externalId
        sensitivity
        patterns
    }
`

const StaffDataIdentifiersPageTableRow = ({
    dataIdentifier,
    onDeleteCompleted,
    onUpdateCompleted,
}) => {
    const { name, type, externalId, sensitivity } = dataIdentifier
    return (
        <TableRow>
            <TableCell>
                <Tooltip content={externalId}>{name}</Tooltip>
            </TableCell>
            <TableCell>{type}</TableCell>
            <TableCell>
                <Tag>{sensitivity}</Tag>
            </TableCell>
            <TableCell className="text-right" size="sm">
                <StaffDataIdentifiersPageTableRowActionButtonWithState
                    dataIdentifier={dataIdentifier}
                    onDeleteCompleted={onDeleteCompleted}
                    onUpdateCompleted={onUpdateCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

StaffDataIdentifiersPageTableRow.FRAGMENT = FRAGMENT

export default StaffDataIdentifiersPageTableRow
