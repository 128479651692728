import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import * as constants from '../../../constants'
import Tag from '../../utils/Tag'
import DrawerTabError from '../../utils/DrawerTabError'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import InfiniteScroll from '../../utils/InfiniteScroll'
import { useIdentifiedTrackersUrlsQuery } from './useIdentifiedTrackersPageQuery'
import Tooltip from '../../utils/Tooltip'
import useSearch from '../../hooks/useSearch'
import SearchInput from '../../utils/SearchInput'

const { APPLICATION_LEVEL_ORGANIZATION } = constants
export default function IdentifiedTrackersDrawerTabUrls({
    identifiedTrackerId,
    level,
}) {
    const [search, handleSearch] = useSearch('')
    const {
        urls,
        error,
        hasMore,
        isFetchingMore,
        handleFetchMore,
        isFetching,
    } = useIdentifiedTrackersUrlsQuery({ id: identifiedTrackerId, search })
    if (error) return <DrawerTabError error={error} />
    return (
        <div className="flex flex-col gap-3">
            <SearchInput
                id="identifiedTrackersUrlsSearch"
                value={search}
                onChange={handleSearch}
                className="w-full"
            />
            {isFetching && <DrawerTabLoading />}
            {!isFetching && (
                <InfiniteScroll
                    isFetchingMore={isFetchingMore}
                    onFetchMore={handleFetchMore}
                    hasMoreRecords={hasMore}
                >
                    <Table hasMinWidth={false} isRounded hasHeader={false}>
                        <TableBody>
                            {urls.length > 0 ? (
                                urls.map((uniqueUrl) => {
                                    const { id, url, scanRequestCount } =
                                        uniqueUrl
                                    const { hostname, pathname } = new URL(url)
                                    return (
                                        <TableRow
                                            isSelectable={
                                                level !==
                                                APPLICATION_LEVEL_ORGANIZATION
                                            }
                                            key={id}
                                        >
                                            <TableCellAvatar
                                                className="w-2/3 truncate"
                                                columnId="scanRequestUrl"
                                                py="xs"
                                                px="sm"
                                                title={
                                                    <div>
                                                        {pathname.length >
                                                        55 ? (
                                                            <Tooltip
                                                                contentClassName="max-w-[400px] break-all"
                                                                content={
                                                                    pathname
                                                                }
                                                            >
                                                                {pathname}
                                                            </Tooltip>
                                                        ) : (
                                                            <span>
                                                                {pathname}
                                                            </span>
                                                        )}
                                                    </div>
                                                }
                                                text={hostname}
                                                truncate
                                            />
                                            <TableCell
                                                size="sm"
                                                hasText
                                                className="text-right"
                                            >
                                                <Tag>{scanRequestCount}</Tag>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            ) : (
                                <DrawerEmptyCard
                                    iconName="check-circle"
                                    title={<Trans>No requests</Trans>}
                                    description={
                                        <Trans>
                                            No requests were found for this
                                            tracker.
                                        </Trans>
                                    }
                                />
                            )}
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            )}
        </div>
    )
}
