import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { formatISO, addDays } from 'date-fns'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'
import InfiniteScroll from '../../utils/InfiniteScroll'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import { useStaffDashboardAuditsQuery } from './useStaffDashboardAuditsQuery'
import Select from '../../utils/Select'
import SkeletonLoadingTable from '../../utils/SkeletonLoadingTable'
import EmptyCard from '../../utils/EmptyCard'
import ErrorCard from '../../utils/ErrorCard'
import { timeAgo } from '../../../utils/dates'

export default function StaffScanPageDashboardPausedAudits() {
    const [selectedDate, setSelectedDate] = useState('last-7')
    let startDate = formatISO(addDays(new Date(), -7))
    let endDate = formatISO(new Date())

    if (selectedDate === 'last-14') {
        startDate = formatISO(addDays(new Date(), -14))
        endDate = formatISO(new Date())
    }
    if (selectedDate === 'last-30') {
        startDate = formatISO(addDays(new Date(), -30))
        endDate = formatISO(new Date())
    }

    const filters = { startDate, endDate }
    const {
        audits,
        isFetching,
        isFetchingMore,
        hasMoreRecords,
        onFetchMore,
        error,
    } = useStaffDashboardAuditsQuery({ first: 5, filters })

    return (
        <Card className="flex h-[468px] flex-col overflow-hidden pb-0 pl-0 pr-0 pt-0">
            <div className="flex items-center pr-4">
                <CardHeader
                    className="pb-4 pl-6 pt-8"
                    title={<Trans>Recently paused audits by staff</Trans>}
                />
                <Select
                    className="w-[200px]"
                    onChange={(e) => setSelectedDate(e.target.value)}
                    value={selectedDate}
                >
                    <option value="last-7">
                        <Trans>Last 7 days</Trans>
                    </option>
                    <option value="last-14">
                        <Trans>Last 14 days</Trans>
                    </option>
                    <option value="last-30">
                        <Trans>Last 30 days</Trans>
                    </option>
                </Select>
            </div>

            <div className="h-full overflow-y-auto">
                {(() => {
                    if (error) {
                        return (
                            <ErrorCard
                                errorMessage={error.message}
                                title="There was an error loading the audits"
                            />
                        )
                    }

                    if (!isFetching && audits?.length === 0) {
                        return (
                            <EmptyCard
                                iconName="scan"
                                description={<Trans>No paused audits</Trans>}
                            />
                        )
                    }

                    if (isFetching) {
                        return <SkeletonLoadingTable rows={4} />
                    }

                    return (
                        <InfiniteScroll
                            isFetchingMore={isFetchingMore}
                            onFetchMore={onFetchMore}
                            hasMoreRecords={hasMoreRecords && !isFetching}
                        >
                            <Table hasMinWidth={false} isFetching={isFetching}>
                                <TableBody>
                                    {audits?.map(
                                        ({ id, name, pausedAt, imageUrl }) => (
                                            <TableRow key={id} className="h-20">
                                                <TableCellAvatar
                                                    columnId="url"
                                                    size="sm"
                                                    avatarSrc={imageUrl}
                                                    avatarIconName="scan"
                                                    title={name}
                                                />
                                                <TableCell className="text-right text-gray-500">
                                                    {timeAgo(pausedAt)}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </InfiniteScroll>
                    )
                })()}
            </div>
        </Card>
    )
}
