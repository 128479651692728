import React from 'react'

const PageContainer = ({ children, ...props }) => (
    <div
        data-testid="PageContainer"
        className="flex h-screen flex-col overflow-hidden bg-white print:overflow-visible print:bg-white"
        {...props}
    >
        {children}
    </div>
)

export default PageContainer
