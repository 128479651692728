import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '../../../constants'
import FormGroup from '../../utils/FormGroup'
import FormSelect from '../../utils/FormSelect'
import Button from '../../utils/Button'
import FormButtonGroup from '../../utils/FormButtonGroup'
import Toggle from '../../utils/Toggle'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'

const { AVAILABLE_AUDIT_SCAN_FREQUENCIES: SCAN_FREQUENCIES, PROXY_TYPES } =
    constants

const SettingsPageAuditFormCard = ({ formState, isLoading, onSubmit }) => (
    <Card className="mb-8">
        <form>
            <CardHeader
                title={<Trans>Default audit settings</Trans>}
                description={
                    <Trans>
                        Below you can edit all information related to the audits
                        of the organization.
                    </Trans>
                }
            />
            <div className="divide-y">
                <FormGroup
                    isHorizontal
                    label={<Trans>Scan frequency</Trans>}
                    htmlFor="url"
                >
                    <FormSelect
                        id="scanFrequency"
                        className="max-w-md"
                        errors={formState.getErrorMessages('scanFrequency')}
                        {...formState.getNativeInputProps('scanFrequency')}
                    >
                        {SCAN_FREQUENCIES.map((frequency) => (
                            <option key={frequency} value={frequency}>
                                {frequency}
                            </option>
                        ))}
                    </FormSelect>
                </FormGroup>
                <FormGroup
                    isHorizontal
                    label={<Trans>Take screenshots</Trans>}
                    htmlFor="takeScreenshots"
                >
                    <Toggle
                        name="takeScreenshots"
                        value={formState.values.takeScreenshots}
                        onClick={(value) =>
                            formState.handleChange('takeScreenshots', value)
                        }
                    />
                </FormGroup>
                {/** <FormGroup 
                    isHorizontal
                    label={<Trans>Use a proxy</Trans>}
                    htmlFor="proxyType"
                >
                    <FormSelect
                        id="proxyType"
                        className="max-w-md"
                        errors={formState.getErrorMessages('proxyType')}
                        {...formState.getNativeInputProps('proxyType')}
                    >
                        <option defaultValue value="NONE">
                            None
                        </option>
                        {PROXY_TYPES.map((type) => (
                            <option key={type} value={type}>
                                {type}
                            </option>
                        ))}
                    </FormSelect>
                </FormGroup>
                * */}

                <FormButtonGroup>
                    <Button
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                        className="w-md"
                    >
                        <Trans>Save changes</Trans>
                    </Button>
                </FormButtonGroup>
            </div>
        </form>
    </Card>
)

export default SettingsPageAuditFormCard
