import gql from 'graphql-tag'
import { useQuery } from '@apollo/client/index'

const QUERY = gql`
    query scanDetailDevice($id: ID!) {
        scan(id: $id) {
            id
            deviceSettings {
                deviceId
                osVersion
                grantPermissions
                language
                location
            }
            auditBuild {
                id
                deviceOs
                metaData
            }
        }
    }
`

export function useScanDetailDeviceDrawerQuery({ id }) {
    const variables = { id }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        deviceSettings: data.scan?.deviceSettings,
        auditBuild: data.scan?.auditBuild,
        isFetching,
        refetch,
    }
}
