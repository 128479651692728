import React from 'react'
import Skeleton from './Skeleton'

const SkeletonLoadingGraph = ({ bars = 7 }) => {
    const heights = [80, 60, 80, 70, 100, 90, 100]
    return (
        <div className="flex h-full items-end justify-between px-6 pb-4 pt-6">
            {[...Array(bars)].map((_, idx) => (
                <Skeleton
                    key={idx}
                    className="w-6 rounded"
                    style={{ height: `${heights[idx % heights.length]}%` }}
                />
            ))}
        </div>
    )
}

export default SkeletonLoadingGraph
