import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

export default function DeleteCookieTableVersionModal({
    cookieTableVersion,
    isLoading,
    onDismiss,
    onConfirm,
}) {
    return (
        <ConfirmModal
            title={<Trans>Delete cookie table version</Trans>}
            description={
                <Trans>
                    Are you sure you want to delete{' '}
                    <strong>{cookieTableVersion.name}</strong> from you
                    organization?
                </Trans>
            }
            confirmButtonText={<Trans>Delete cookie table version</Trans>}
            isLoading={isLoading}
            isDisabled={isLoading}
            onDismiss={onDismiss}
            onConfirm={onConfirm}
        />
    )
}
