import React from 'react'
import { Trans } from '@lingui/macro'
import { AVAILABLE_SCAN_STATUSES } from '@clew/shared/src/constants'
import TableFilterSelect from '../../utils/TableFilterSelect'
import TableFilterSelectOption from '../../utils/TableFilterSelectOption'
import TableFiltersMenu from '../../utils/TableFiltersMenu'
import TableFiltersMenuItem from '../../utils/TableFiltersMenuItem'
import AuditSearchSelect from '../../utils/AuditSearchSelect'
import OrganizationsSearchSelect from '../../utils/OrganizationsSearchSelect'

export default function StaffScansPageTableActionsFilters({
    auditsFilter,
    onChangeAuditsFilter,
    organizationFilter,
    onChangeOrganizationFilter,
    statusFilter,
    onChangeStatusFilter,
    activeFilters,
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
}) {
    const statusTypeLabel = statusFilter ? (
        statusFilter.replace(/_/g, ' ').toLowerCase()
    ) : (
        <Trans>Choose Status</Trans>
    )
    const auditLabel = auditsFilter?.name ? (
        <Trans>{auditsFilter.name}</Trans>
    ) : (
        <Trans>Choose audit</Trans>
    )
    const organizationLabel = organizationFilter?.name ? (
        <Trans>{organizationFilter.name}</Trans>
    ) : (
        <Trans>Choose organization</Trans>
    )
    return (
        <div className="flex items-center gap-2">
            <TableFilterSelect
                name="audit"
                activeFilters={activeFilters}
                onRemove={(name) => {
                    onRemoveFilter(name)
                    onChangeAuditsFilter(null)
                }}
                label={auditLabel}
                position="bottom-start"
                dropdownClassName="max-h-[450px]"
                asChild
            >
                <AuditSearchSelect
                    value={auditsFilter}
                    onSelect={(id, audit) => {
                        onChangeAuditsFilter(audit)
                    }}
                    onDeselect={() => {
                        onChangeAuditsFilter(null)
                    }}
                    isCheckbox={false}
                    className="h-[400px]"
                />
            </TableFilterSelect>

            <TableFilterSelect
                name="organizationId"
                activeFilters={activeFilters}
                onRemove={(name) => {
                    onRemoveFilter(name)
                }}
                label={organizationLabel}
                position="bottom-start"
                dropdownClassName="max-h-[450px]"
                asChild
            >
                <OrganizationsSearchSelect
                    value={organizationFilter}
                    onSelect={(id, organization) =>
                        onChangeOrganizationFilter(organization)
                    }
                    onDeselect={() => {
                        onChangeOrganizationFilter(null)
                    }}
                    className="h-[400px]"
                />
            </TableFilterSelect>

            <TableFilterSelect
                name="status"
                activeFilters={activeFilters}
                onRemove={(name) => {
                    onRemoveFilter(name)
                    onChangeStatusFilter(null)
                }}
                autoClose
                label={statusTypeLabel}
            >
                {AVAILABLE_SCAN_STATUSES.map((type) => (
                    <TableFilterSelectOption
                        key={type}
                        onClick={() => onChangeStatusFilter(type)}
                    >
                        {type.replace(/_/g, ' ').toLowerCase()}
                    </TableFilterSelectOption>
                ))}
            </TableFilterSelect>

            <TableFiltersMenu
                onRemoveAll={onRemoveAllFilters}
                activeFilters={activeFilters}
                autoClose
            >
                <TableFiltersMenuItem
                    iconName="building-07"
                    name="organizationId"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>Organization</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="clock"
                    name="audit"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>Audit</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="review"
                    name="status"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>Status</Trans>
                </TableFiltersMenuItem>
            </TableFiltersMenu>
        </div>
    )
}
