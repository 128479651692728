import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useCreateTrackerDetailsDrawerPurposeIdentifiersFormState from './useCreateTrackerDetailsDrawerPurposeIdentifiersFormState'
import CreateTrackerDetailsDrawerPurposeIdentifiersModal from './CreateTrackerDetailsDrawerPurposeIdentifiersModal'
import useCreatePurposeIdentifierMutation from './useCreatePurposeIdentifierResolver'
import useExampleText from '../staff-data-identifiers/useExampleText'

const CreateTrackerDetailsDrawerPurposeIdentifiersModalWithState = ({
    onDismiss,
    onCompleted,
    trackerId,
}) => {
    const formState = useCreateTrackerDetailsDrawerPurposeIdentifiersFormState()

    const [staffCreatePurposeIdentifier, { loading }] =
        useCreatePurposeIdentifierMutation({
            onCompleted,
        })
    const { dispatchSuccess, dispatchError } = useNotifications()
    const { exampleText, highlightedExampleText, onUpdateExampleText } =
        useExampleText()

    const handleSubmit = async () => {
        try {
            if (formState.validate()) {
                const variables = {
                    trackerId,
                    input: formState.valuesToInput(),
                }

                await staffCreatePurposeIdentifier({ variables })
                formState.resetForm()
                dispatchSuccess({
                    message: (
                        <Trans>Purpose identifier created successfully!</Trans>
                    ),
                })
                onDismiss()
            }
        } catch (error) {
            dispatchError({ message: error.message })
        }
    }

    return (
        <CreateTrackerDetailsDrawerPurposeIdentifiersModal
            exampleText={exampleText}
            highlightedExampleText={highlightedExampleText}
            onUpdateExampleText={onUpdateExampleText}
            formState={formState}
            isLoading={loading}
            onDismiss={() => onDismiss()}
            onSubmit={handleSubmit}
        />
    )
}

export default CreateTrackerDetailsDrawerPurposeIdentifiersModalWithState
