import React, { useState } from 'react'
import * as constants from '@clew/shared/src/constants'
import useAppState from '../../hooks/useAppState'
import DeleteVendorTableVersionModalWithState from './DeleteVendorTableVersionModalWithState'
import UpdateVendorTableVersionActions from './UpdateVendorTableVersionActions'

const { USER_ROLE_OWNER: OWNER } = constants

export default function UpdateVendorTableVersionActionsWithState({
    vendorTableVersion,
    versionCount,
    onDeleteCompleted,
}) {
    const { currentUser } = useAppState()
    const [
        isDeleteVendorTableVersionModalOpen,
        setDeleteVendorTableVersionModalOpen,
    ] = useState(false)

    return (
        <>
            {isDeleteVendorTableVersionModalOpen && (
                <DeleteVendorTableVersionModalWithState
                    vendorTableVersion={vendorTableVersion}
                    onDismiss={() =>
                        setDeleteVendorTableVersionModalOpen(false)
                    }
                    onCompleted={onDeleteCompleted}
                />
            )}
            <UpdateVendorTableVersionActions
                canDelete={currentUser.role === OWNER && versionCount > 1}
                onDelete={() => setDeleteVendorTableVersionModalOpen(true)}
            />
        </>
    )
}
