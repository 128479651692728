import React from 'react'
import Skeleton from './Skeleton'

const SkeletonLoadingTable = ({ rows = 5 }) => {
    return (
        <div className="flex-1">
            {[...Array(rows)].map((_, idx) => (
                <div
                    key={idx}
                    className="flex items-center justify-between border-t border-gray-200 px-4 py-3"
                >
                    <div className="flex h-16 items-center space-x-3">
                        <Skeleton className="h-10 w-10 rounded-full" />
                        <Skeleton className="h-4 w-32" />
                    </div>
                    <Skeleton className="h-4 w-16" />
                </div>
            ))}
        </div>
    )
}

export default SkeletonLoadingTable
