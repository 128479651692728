import React from 'react'
import { Trans } from '@lingui/macro'
import Tag from '../../utils/Tag'
import { DrawerDetailsItemText } from '../../utils/DrawerDetailsItem'
import Avatar from '../../utils/Avatar'
import PurposesTags from '../../utils/PurposesTags'
import Tooltip from '../../utils/Tooltip'
import Duration from '../../utils/Duration'

export default function ScanCookieDetailsDrawerTabDetails({ scanCookie }) {
    const { audit, scan, scanPage, cookie, type, retention, value, name } =
        scanCookie
    return (
        <>
            {audit && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Audit</Trans>}
                >
                    <div className="flex items-center gap-2">
                        <Avatar
                            size="xs"
                            iconName="clock"
                            src={audit.imageUrl}
                        />
                        {audit.name}
                    </div>
                </DrawerDetailsItemText>
            )}

            {cookie && cookie.vendor && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Vendor</Trans>}
                >
                    <div className="flex items-center gap-2">
                        <Avatar
                            size="xs"
                            iconName="building-07"
                            src={cookie.vendor?.imageUrl}
                        />
                        {cookie.vendor?.name}
                    </div>
                </DrawerDetailsItemText>
            )}

            {scan && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Scan</Trans>}>
                    {scan.consentSettings?.name && (
                        <Tag type="primary">{`${
                            scan.startedAt.split('T')[0]
                        } - ${scan.consentSettings?.name}`}</Tag>
                    )}
                </DrawerDetailsItemText>
            )}
            {scanPage && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Page</Trans>}>
                    {scanPage ? new URL(scanPage?.url).pathname : 'N/A'}
                </DrawerDetailsItemText>
            )}
            {name && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Name</Trans>}>
                    {cookie?.name || name}
                </DrawerDetailsItemText>
            )}

            {type && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Type</Trans>}>
                    <Tag type="primary">{type || 'N/A'}</Tag>
                </DrawerDetailsItemText>
            )}

            {cookie?.domain && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Domain</Trans>}
                >
                    {cookie?.domain || ''}
                </DrawerDetailsItemText>
            )}

            {retention && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Retention</Trans>}
                >
                    <Duration format={Duration.DAYS}>{retention}</Duration>
                </DrawerDetailsItemText>
            )}

            {value && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Value</Trans>}
                >
                    <Tooltip
                        event="hover"
                        position="top-end"
                        content={
                            <div className="max-w-[400px] overflow-hidden break-words">
                                {value}
                            </div>
                        }
                        contentClassName="max-w-[400px]"
                    >
                        {value}
                    </Tooltip>
                </DrawerDetailsItemText>
            )}

            {cookie && (
                <>
                    <DrawerDetailsItemText
                        isHorizontal
                        label={<Trans>Purpose</Trans>}
                    >
                        <PurposesTags
                            showAll
                            purposes={
                                cookie?.purposes && cookie?.purposes.length > 0
                                    ? cookie?.purposes
                                    : []
                            }
                        />
                    </DrawerDetailsItemText>
                    {cookie.description && (
                        <DrawerDetailsItemText
                            isHorizontal
                            truncate={false}
                            label={<Trans>Description</Trans>}
                        >
                            {cookie.description}
                        </DrawerDetailsItemText>
                    )}
                </>
            )}
        </>
    )
}
