import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

const StaffDataIdentifiersPageTableRowActionButton = ({
    isLoading,
    canDelete,
    onDelete,
    canUpdate,
    onUpdate,
}) => {
    return (
        <DropdownMenu
            position="bottom-start"
            content={({ close }) => (
                <>
                    <DropdownMenuItem
                        iconName="bin"
                        disabled={!canDelete}
                        onClick={(e) => {
                            onDelete()
                            close()
                            e.stopPropagation()
                        }}
                    >
                        <Trans>Delete data identifier</Trans>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        iconName="edit"
                        disabled={!canUpdate}
                        onClick={(e) => {
                            onUpdate()
                            close()
                            e.stopPropagation()
                        }}
                    >
                        <Trans>Update data identifier</Trans>
                    </DropdownMenuItem>
                </>
            )}
        >
            <ActionButton disabled={isLoading} />
        </DropdownMenu>
    )
}

export default StaffDataIdentifiersPageTableRowActionButton
