import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useDeleteVendorTableVersionMutation from './useDeleteVendorTableVersionMutation'
import DeleteVendorTableVersionModal from './DeleteVendorTableVersionModal'

export default function DeleteVendorTableVersionModalWithState({
    vendorTableVersion,
    onDismiss,
    onCompleted,
    onError,
}) {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteVendorTableVersion, { loading }] =
        useDeleteVendorTableVersionMutation({
            onCompleted,
            onError,
        })
    return (
        <DeleteVendorTableVersionModal
            onDismiss={onDismiss}
            vendorTableVersion={vendorTableVersion}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const variables = { id: vendorTableVersion.id }
                    await deleteVendorTableVersion({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                {vendorTableVersion.name} successfully deleted.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}
