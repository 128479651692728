export const NOTIFICATION_TYPE_ERROR = 'ERROR'
export const NOTIFICATION_TYPE_SUCCESS = 'SUCCESS'
export const NOTIFICATION_TYPE_WARNING = 'WARNING'

export const COUNTRY_BE = 'BE'
export const COUNTRY_IE = 'IE'
export const COUNTRY_US = 'US'
export const AVAILABLE_COUNTRIES = [COUNTRY_BE]

export const LANGUAGE_NL = 'nl'
export const LANGUAGE_EN = 'en'
export const AVAILABLE_LANGUAGES = [LANGUAGE_NL, LANGUAGE_EN]

export const DATE_FORMAT_GRAPHQL_DATE = 'yyyy-MM-dd'
export const DATE_FORMAT_EUROPE = 'dd/mm/yyyy'
export const DATE_FORMAT_US = 'mm/dd/yyyy'
export const AVAILABLE_DATE_FORMATS = [DATE_FORMAT_EUROPE, DATE_FORMAT_US]

export const TIME_FORMAT_EUROPE = 'HH:mm'
export const AVAILABLE_TIME_FORMATS = [TIME_FORMAT_EUROPE]

export const TIME_ZONE_EUROPE_BRUSSELS = 'Europe/Brussels'
export const AVAILABLE_TIME_ZONES = [TIME_ZONE_EUROPE_BRUSSELS]

export const DEFAULT_PAGE_SIZE = 25
export const DEFAULT_DATE_FORMAT = DATE_FORMAT_EUROPE
export const DEFAULT_TIME_FORMAT = TIME_FORMAT_EUROPE
export const DEFAULT_TIMEZONE = TIME_ZONE_EUROPE_BRUSSELS
export const DEFAULT_LANGUAGE = LANGUAGE_NL
export const DEFAULT_COUNTRY = COUNTRY_BE

export const USER_TYPE_STAFF = 'STAFF'
export const USER_TYPE_USER = 'USER'
export const AVAILABLE_USER_TYPES = [USER_TYPE_STAFF, USER_TYPE_USER]

export const USER_ROLE_OWNER = 'OWNER'
export const USER_ROLE_MEMBER = 'MEMBER'
export const AVAILABLE_USER_ROLES = [USER_ROLE_OWNER, USER_ROLE_MEMBER]

export const APPLICATION_LEVEL_ORGANIZATION = 'ORGANIZATION'
export const APPLICATION_LEVEL_AUDIT = 'AUDIT'
export const APPLICATION_LEVEL_SCAN = 'SCAN'
export const AVAILABLE_APPLICATION_LEVELS = [
    APPLICATION_LEVEL_ORGANIZATION,
    APPLICATION_LEVEL_AUDIT,
    APPLICATION_LEVEL_SCAN,
]

export const INVITATION_STATUS_PENDING = 'PENDING'
export const INVITATION_STATUS_EXPIRED = 'EXPIRED'
export const INVITATION_STATUS_ACCEPTED = 'ACCEPTED'

export const SCAN_STATUS_CREATED = 'CREATED'
export const SCAN_STATUS_QUEUED = 'QUEUED'
export const SCAN_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const SCAN_STATUS_COMPLETED = 'COMPLETED'
export const AVAILABLE_SCAN_STATUSES = [
    SCAN_STATUS_CREATED,
    SCAN_STATUS_QUEUED,
    SCAN_STATUS_IN_PROGRESS,
    SCAN_STATUS_COMPLETED,
]

export const SCAN_PAGE_STATUS_CREATED = 'CREATED'
export const SCAN_PAGE_STATUS_QUEUED = 'QUEUED'
export const SCAN_PAGE_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const SCAN_PAGE_STATUS_COMPLETED = 'COMPLETED'
export const SCAN_PAGE_STATUS_FAILED = 'FAILED'
export const AVAILABLE_SCAN_PAGE_STATUSES = [
    SCAN_PAGE_STATUS_CREATED,
    SCAN_PAGE_STATUS_QUEUED,
    SCAN_PAGE_STATUS_IN_PROGRESS,
    SCAN_PAGE_STATUS_COMPLETED,
    SCAN_PAGE_STATUS_FAILED,
]

export const REPORT_STATUS_CREATED = 'CREATED'
export const REPORT_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const REPORT_STATUS_COMPLETED = 'COMPLETED'
export const AVAILABLE_REPORT_STATUSES = [
    REPORT_STATUS_CREATED,
    REPORT_STATUS_IN_PROGRESS,
    REPORT_STATUS_COMPLETED,
]

export const AUDIT_SCAN_FREQUENCY_DISABLED = 'DISABLED'
export const AUDIT_SCAN_FREQUENCY_DAILY = 'DAILY'
export const AUDIT_SCAN_FREQUENCY_WEEKLY = 'WEEKLY'
export const AUDIT_SCAN_FREQUENCY_MONTHLY = 'MONTHLY'
export const AVAILABLE_AUDIT_SCAN_FREQUENCIES = [
    AUDIT_SCAN_FREQUENCY_DISABLED,
    AUDIT_SCAN_FREQUENCY_DAILY,
    AUDIT_SCAN_FREQUENCY_WEEKLY,
    AUDIT_SCAN_FREQUENCY_MONTHLY,
]

export const DOMAIN_CONSENT_RESOLVER_CSS = 'CSS'
export const DOMAIN_CONSENT_RESOLVER_RUNTIME = 'RUNTIME'
export const DOMAIN_CONSENT_RESOLVER_COOKIE = 'COOKIE'
export const AVAILABLE_DOMAIN_CONSENT_RESOLVERS = [
    DOMAIN_CONSENT_RESOLVER_CSS,
    DOMAIN_CONSENT_RESOLVER_RUNTIME,
    DOMAIN_CONSENT_RESOLVER_COOKIE,
]

export const ALERT_TYPE_COOKIE_EXCEEDS_MAX_RETENTION =
    'COOKIE_EXCEEDS_MAX_RETENTION'
export const ALERT_TYPE_COOKIE_NO_CONSENT = 'ALERT_TYPE_COOKIE_NO_CONSENT'
export const ALERT_TYPE_TRACKER_NO_CONSENT = 'ALERT_TYPE_TRACKER_NO_CONSENT'
export const ALERT_TYPE_TRACKER_CONSENTLESS_ADVERTISING =
    'ALERT_TYPE_TRACKER_CONSENTLESS_ADVERTISING'
export const ALERT_TYPE_VENDOR_NOT_IN_VENDOR_TABLE =
    'ALERT_TYPE_VENDOR_NOT_IN_VENDOR_TABLE'
export const ALERT_TYPE_COOKIE_NOT_IN_COOKIE_TABLE =
    'ALERT_TYPE_COOKIE_NOT_IN_COOKIE_TABLE'
export const AVAILABLE_ALERT_TYPES = [
    ALERT_TYPE_COOKIE_EXCEEDS_MAX_RETENTION,
    ALERT_TYPE_COOKIE_NO_CONSENT,
    ALERT_TYPE_TRACKER_NO_CONSENT,
    ALERT_TYPE_TRACKER_CONSENTLESS_ADVERTISING,
    ALERT_TYPE_VENDOR_NOT_IN_VENDOR_TABLE,
    ALERT_TYPE_COOKIE_NOT_IN_COOKIE_TABLE,
]

export const ALERT_SEVERITY_TYPE_WARNING = 'WARNING'
export const ALERT_SEVERITY_TYPE_ISSUE = 'ISSUE'
export const ALERT_SEVERITY_TYPE_CRITICAL = 'CRITICAL'
export const ALERT_SEVERITY_TYPE_UNKNOWN = 'UNKNOWN'
export const AVAILABLE_ALERT_SEVERITY_TYPES = [
    ALERT_SEVERITY_TYPE_WARNING,
    ALERT_SEVERITY_TYPE_ISSUE,
    ALERT_SEVERITY_TYPE_CRITICAL,
    ALERT_SEVERITY_TYPE_UNKNOWN,
]

export const REPORT_TYPE_AUDITS_COOKIE_TABLE = 'AUDITS_COOKIE_TABLE'
export const REPORT_TYPE_AUDITS_VENDOR_TABLE = 'AUDITS_VENDOR_TABLE'
export const REPORT_TYPE_ALERT = 'ALERT'
export const REPORT_TYPE_SCAN = 'SCAN'
export const REPORT_TYPE_SCAN_COOKIES = 'SCAN_COOKIES'
export const REPORT_TYPE_SCAN_TRACKERS = 'SCAN_TRACKERS'
export const REPORT_TYPE_IDENTIFIED_COOKIES = 'IDENTIFIED_COOKIES'
export const REPORT_TYPE_IDENTIFIED_VENDORS = 'IDENTIFIED_VENDORS'
export const AVAILABLE_REPORT_TYPES = [
    REPORT_TYPE_ALERT,
    REPORT_TYPE_SCAN,
    REPORT_TYPE_SCAN_COOKIES,
    REPORT_TYPE_SCAN_TRACKERS,
    REPORT_TYPE_AUDITS_COOKIE_TABLE,
    REPORT_TYPE_AUDITS_VENDOR_TABLE,
    REPORT_TYPE_IDENTIFIED_COOKIES,
    REPORT_TYPE_IDENTIFIED_VENDORS,
]

export const SESSION_DEVICE_TYPE_ANDROID = 'ANDROID'
export const SESSION_DEVICE_TYPE_IOS = 'IOS'
export const SESSION_DEVICE_TYPE_WEB = 'WEB'

export const SESSION_DEVICE_ICONS = {
    [SESSION_DEVICE_TYPE_ANDROID]: 'android',
    [SESSION_DEVICE_TYPE_IOS]: 'ios',
    [SESSION_DEVICE_TYPE_WEB]: 'globe',
}

export const SESSION_DEVICE_NAMES = {
    [SESSION_DEVICE_TYPE_ANDROID]: 'Android',
    [SESSION_DEVICE_TYPE_IOS]: 'iOS',
    [SESSION_DEVICE_TYPE_WEB]: 'Web',
}

export const OPTION_ALL = 'ALL'

export const IDENTIFIED_PERIOD_LAST_DAY = 'LAST_DAY'
export const IDENTIFIED_PERIOD_LAST_7_DAYS = 'LAST_7_DAYS'
export const IDENTIFIED_PERIOD_LAST_14_DAYS = 'LAST_14_DAYS'
export const IDENTIFIED_PERIOD_LAST_30_DAYS = 'LAST_30_DAYS'
export const IDENTIFIED_PERIOD_THIS_YEAR = 'THIS_YEAR'
export const IDENTIFIED_PERIOD_DAY_SUMMARY = 'DAY_SUMMARY'
export const IDENTIFIED_PERIOD_YEAR_SUMMARY = 'YEAR_SUMMARY'
export const IDENTIFIED_PERIOD_MONTH_SUMMARY = 'MONTH_SUMMARY'
export const IDENTIFIED_PERIOD_CUSTOM = 'CUSTOM'
export const AVAILABLE_IDENTIFIED_PERIODS = [
    IDENTIFIED_PERIOD_LAST_DAY,
    IDENTIFIED_PERIOD_LAST_7_DAYS,
    IDENTIFIED_PERIOD_LAST_14_DAYS,
    IDENTIFIED_PERIOD_LAST_30_DAYS,
    IDENTIFIED_PERIOD_THIS_YEAR,
    IDENTIFIED_PERIOD_YEAR_SUMMARY,
    IDENTIFIED_PERIOD_MONTH_SUMMARY,
    IDENTIFIED_PERIOD_DAY_SUMMARY,
    IDENTIFIED_PERIOD_CUSTOM,
]

export const IDENTIFIED_PERIOD_LAST_30_DAYS_OPTION = 'last-30'
export const IDENTIFIED_PERIOD_LAST_14_DAYS_OPTION = 'last-14'
export const IDENTIFIED_PERIOD_LAST_7_DAYS_OPTION = 'last-7'
export const IDENTIFIED_PERIOD_LAST_DAY_OPTION = 'last-day'
export const IDENTIFIED_PERIOD_DAY_OPTION = 'day'
export const IDENTIFIED_PERIOD_MONTH_OPTION = 'month'
export const IDENTIFIED_PERIOD_CUSTOM_OPTION = 'period'

export const IDENTIFIED_PERIODS = {
    [IDENTIFIED_PERIOD_LAST_DAY_OPTION]: IDENTIFIED_PERIOD_LAST_DAY,
    [IDENTIFIED_PERIOD_LAST_7_DAYS_OPTION]: IDENTIFIED_PERIOD_LAST_7_DAYS,
    [IDENTIFIED_PERIOD_LAST_14_DAYS_OPTION]: IDENTIFIED_PERIOD_LAST_14_DAYS,
    [IDENTIFIED_PERIOD_LAST_30_DAYS_OPTION]: IDENTIFIED_PERIOD_LAST_30_DAYS,
    [IDENTIFIED_PERIOD_DAY_OPTION]: IDENTIFIED_PERIOD_DAY_SUMMARY,
    [IDENTIFIED_PERIOD_MONTH_OPTION]: IDENTIFIED_PERIOD_MONTH_SUMMARY,
    [IDENTIFIED_PERIOD_CUSTOM_OPTION]: IDENTIFIED_PERIOD_CUSTOM,
}

export const ALERT_DELEGATION_TYPE_INTERNAL = 'DELEGATE_INTERNAL'
export const ALERT_DELEGATION_TYPE_EXTERNAL = 'DELEGATE_EXTERNAL'
export const ALERT_DELEGATION_TYPE_CLEAR = 'DELEGATE_CLEAR'
export const ALERT_DELEGATION_TYPES = [
    ALERT_DELEGATION_TYPE_INTERNAL,
    ALERT_DELEGATION_TYPE_EXTERNAL,
    ALERT_DELEGATION_TYPE_CLEAR,
]

export const ALERT_STATUS_LEVEL_AUDIT = 'AUDIT'
export const ALERT_STATUS_LEVEL_ORGANIZATION = 'ORGANIZATION'

export const WAIT_FOR_CSS_SELECTOR = 'WAIT_FOR_CSS_SELECTOR'
export const WAIT_FOR_XPATH_SELECTOR = 'WAIT_FOR_XPATH_SELECTOR'
export const WAIT_FOR_NAVIGATION = 'WAIT_FOR_NAVIGATION'
export const CLICK_CSS_SELECTOR = 'CLICK_CSS_SELECTOR'
export const CLICK_XPATH_SELECTOR = 'CLICK_XPATH_SELECTOR'
export const WAIT_FOR_COOKIE_NAME = 'WAIT_FOR_COOKIE_NAME'
export const SELECT_FRAME = 'SELECT_FRAME'
export const ENTER_EMAIL = 'ENTER_EMAIL'
export const ENTER_PASSWORD = 'ENTER_PASSWORD'

export const BULK_UPLOAD_CREATE = 'BULK_UPLOAD_CREATE'
export const BULK_UPLOAD_UPDATE = 'BULK_UPLOAD_UPDATE'

export const COOKIE_TABLE_ITEM_STATUS_ADDED = 'ADDED'
export const COOKIE_TABLE_ITEM_STATUS_DEPRECATED = 'DEPRECATED'
export const COOKIE_TABLE_ITEM_STATUS_STATUSES = [
    COOKIE_TABLE_ITEM_STATUS_ADDED,
    COOKIE_TABLE_ITEM_STATUS_DEPRECATED,
]

export const VENDOR_TABLE_ITEM_STATUS_ADDED = 'ADDED'
export const VENDOR_TABLE_ITEM_STATUS_DEPRECATED = 'DEPRECATED'
export const VENDOR_TABLE_ITEM_STATUS_STATUSES = [
    VENDOR_TABLE_ITEM_STATUS_ADDED,
    VENDOR_TABLE_ITEM_STATUS_DEPRECATED,
]

export const FOCUS_NEW_TAB = 'FOCUS_NEW_TAB'
export const RUN_CONSOLE_COMMANDS = 'RUN_CONSOLE_COMMANDS'

export const PROXY_TYPE_RESIDENTIAL = 'RESIDENTIAL'
export const PROXY_TYPE_DATACENTER = 'DATACENTER'
export const PROXY_TYPE_ISP = 'ISP'
export const PROXY_TYPE_MOBILE = 'MOBILE'
export const PROXY_TYPES = [
    PROXY_TYPE_RESIDENTIAL,
    PROXY_TYPE_DATACENTER,
    PROXY_TYPE_ISP,
    PROXY_TYPE_MOBILE,
]
