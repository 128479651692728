import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableRow from '../../utils/TableRow'
import Tooltip from '../../utils/Tooltip'
import PurposesTags from '../../utils/PurposesTags'
import Tag from '../../utils/Tag'
import Icon from '../../utils/Icon'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import DrawerTabError from '../../utils/DrawerTabError'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'

export function LiveMobileScanPageSidebarRequestsTab({
    error,
    isFetching,
    scanRequests,
}) {
    if (isFetching) {
        return <DrawerTabLoading />
    }

    if (error) {
        return <DrawerTabError error={error} />
    }

    const hasRequests = scanRequests.length > 0

    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {!hasRequests && (
                    <DrawerEmptyCard
                        iconName="tracker"
                        title={<Trans>No requests</Trans>}
                        description={<Trans>No requests were found</Trans>}
                    />
                )}
                {hasRequests &&
                    scanRequests.map((request) => {
                        const { url, tracker, hasPostData } = request
                        const { hostname, pathname, searchParams } = new URL(
                            url
                        )
                        const hasSearchParams =
                            searchParams && searchParams.toString().length > 0
                        let tagPurposes = []
                        if (tracker) {
                            tagPurposes = tracker.purposes
                        }
                        const finalAvatarSrc = tracker?.vendor?.imageUrl
                        return (
                            <TableRow isSelectable key={url}>
                                <TableCellAvatar
                                    py="sm"
                                    px="sm"
                                    truncate
                                    avatarIconName="code-browser"
                                    avatarSrc={finalAvatarSrc}
                                    className="w-2/3"
                                    title={hostname}
                                    text={
                                        <Tooltip
                                            content={url}
                                            contentClassName="max-w-[400px] break-all		"
                                        >
                                            {pathname}
                                        </Tooltip>
                                    }
                                />
                                <TableCell py="sm">
                                    <div className="flex justify-end gap-1">
                                        {hasSearchParams && (
                                            <Tooltip
                                                as="div"
                                                className="h-5"
                                                content={
                                                    <Trans>
                                                        Has search params
                                                    </Trans>
                                                }
                                            >
                                                <Tag
                                                    type="gray"
                                                    className="h-5"
                                                >
                                                    <Icon
                                                        name="search-param"
                                                        size="sm"
                                                        className="text-gray-600"
                                                    />
                                                </Tag>
                                            </Tooltip>
                                        )}
                                        {hasPostData && (
                                            <Tooltip
                                                as="div"
                                                content={
                                                    <Trans>
                                                        Sends post data
                                                    </Trans>
                                                }
                                            >
                                                <Tag
                                                    type="gray"
                                                    className="!p-0.5"
                                                >
                                                    <Icon
                                                        name="arrow-up-circle"
                                                        size="xs"
                                                        className="text-gray-600"
                                                    />
                                                </Tag>
                                            </Tooltip>
                                        )}
                                        <PurposesTags
                                            showAll
                                            purposes={tagPurposes}
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    })}
            </TableBody>
        </Table>
    )
}
