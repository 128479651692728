import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

export function DeleteActionModal({ isLoading, onDismiss, onConfirm }) {
    return (
        <ConfirmModal
            title={<Trans>Delete Action</Trans>}
            description={
                <Trans>Are you sure you want to delete this action?</Trans>
            }
            confirmButtonText={<Trans>Delete Action</Trans>}
            isLoading={isLoading}
            isDisabled={isLoading}
            onDismiss={onDismiss}
            onConfirm={onConfirm}
        />
    )
}
