import useFormState, { createFormValidation } from 'use-form-state'
import React from 'react'
import { Trans } from '@lingui/macro'
import { isValidRegex } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'purposeIds',
        validate: (value) => Array.isArray(value) && value.length > 0,
        message: <Trans>Consent purpose is required</Trans>,
    },
    {
        path: 'processingActivities',
        validate: (value) => Array.isArray(value) && value.length > 0,
        message: <Trans>Processing activity is required</Trans>,
    },
    {
        path: 'pattern',
        validate: (value) => isValidRegex(value),
        message: <Trans>Pattern is required</Trans>,
    },
])

const valuesToInput = ({
    pattern,
    examples,
    purposeIds,
    processingActivities,
}) => ({
    pattern,
    examples,
    purposeIds,
    processingActivities,
})

const useCreateTrackerDetailsDrawerPurposeIdentifiersForm = () => {
    const formState = useFormState(
        {
            pattern: '',
            examples: '',
            purposeIds: [],
            processingActivities: [],
        },
        {
            validation,
            valuesToInput,
        }
    )
    return { ...formState }
}

export default useCreateTrackerDetailsDrawerPurposeIdentifiersForm
