import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const DELETE_LIVE_SCAN_MUTATION = gql`
    mutation deleteLiveScan($scanId: ID!) {
        deleteLiveScan(scanId: $scanId)
    }
`

export function useDeleteLiveScanMutation(options) {
    return useMutation(DELETE_LIVE_SCAN_MUTATION, options)
}
