import React, { useState } from 'react'
import * as constants from '@clew/shared/src/constants'
import useAppState from '../../hooks/useAppState'
import UpdateCookieTableVersionActions from './UpdateCookieTableVersionActions'
import DeleteCookieTableVersionModalWithState from './DeleteCookieTableVersionModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

export default function UpdateCookieTableVersionActionsWithState({
    cookieTableVersion,
    versionCount,
    onDeleteCompleted,
}) {
    const { currentUser } = useAppState()
    const [
        isDeleteCookieTableVersionModalOpen,
        setDeleteCookieTableVersionModalOpen,
    ] = useState(false)

    return (
        <>
            {isDeleteCookieTableVersionModalOpen && (
                <DeleteCookieTableVersionModalWithState
                    cookieTableVersion={cookieTableVersion}
                    onDismiss={() =>
                        setDeleteCookieTableVersionModalOpen(false)
                    }
                    onCompleted={onDeleteCompleted}
                />
            )}
            <UpdateCookieTableVersionActions
                canDelete={currentUser.role === OWNER && versionCount > 1}
                onDelete={() => setDeleteCookieTableVersionModalOpen(true)}
            />
        </>
    )
}
