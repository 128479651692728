import gql from 'graphql-tag'
import { useQuery } from '@apollo/client/index'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query scanDetailsDrawerTabActions(
        $scanId: ID!
        $after: String
        $orderBy: ScanActionsOrderByInput
    ) {
        scanActions(scanId: $scanId, after: $after, orderBy: $orderBy) {
            edges {
                node {
                    id
                    actionName
                    status
                    duration
                    errorMessages
                    action
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export function useScanDetailActionsDrawerQuery({ scanId, search, orderBy }) {
    const variables = { scanId, search, orderBy }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'scanActions'
    )

    return {
        error,
        scanActions: connectionToCollection(data.scanActions),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}
