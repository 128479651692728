import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import PageContainer from '../../layout/PageContainer'
import AuditSummaryHeader from './AuditAlertSummaryHeader'
import AlertSummaryDataCard from './AlertSummaryDataCard'
import AlertSummaryInfoCard from './AlertSummaryInfoCard'
import AlertSummarySection, {
    AlertSummarySectionTitle,
    AlertSummarySectionDescription,
} from './AlertSummarySection'
import { toTitleCase } from '../../../utils/text'
import PurposesTags from '../../utils/PurposesTags'
import AuditAlertSummaryCookieDetails from './AuditAlertSummaryCookieDetails'
import AuditAlertSummaryRequestDetails from './AuditAlertSummaryRequestDetails'
import AuditAlertSummaryRequestChain from './AuditAlertSummaryRequestChain'
import DateTime from '../../utils/DateTime'
import Tag from '../../utils/Tag'

export default function AuditCookieAlertSummaryReportPage({ alert }) {
    const {
        vendor,
        message,
        severityType,
        subjectType,
        type,
        lastScanCookie,
        lastCreatedAt,
    } = alert

    // Alert cookie for orgs, lastScan for single
    const cookie = alert?.cookie || lastScanCookie
    const { scanRequest = null } = lastScanCookie

    if (!alert) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No alerts</Trans>}
                description={
                    <Trans>No alerts were found during this scan. </Trans>
                }
            />
        )
    }

    let purposes = []
    if (lastScanCookie && Array.isArray(lastScanCookie.cookie.purposes)) {
        purposes = lastScanCookie.cookie.purposes.filter(
            (purpose) => purpose !== null
        )
    }

    return (
        <PageContainer className="mx-auto max-w-5xl p-8">
            <AuditSummaryHeader
                description={<DateTime>{lastCreatedAt}</DateTime>}
            />

            {/* Summary */}
            <AlertSummarySection>
                <AlertSummaryInfoCard
                    iconName={subjectType?.toLowerCase()}
                    type={severityType?.toLowerCase()}
                    title={toTitleCase(
                        type?.toLowerCase().split('_').join(' ')
                    )}
                    text={message}
                />
            </AlertSummarySection>

            {/* AUDIT Section */}
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Audit</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryDataCard
                    avatarSrc={alert.audit?.imageUrl}
                    avatarIconName="clock"
                    title={alert?.audit?.name}
                    description={alert.audit?.domains.map(
                        (domain) => domain?.url
                    )}
                />
            </AlertSummarySection>

            {/* Vendor and Subject Section */}
            <AlertSummarySection className="grid grid-cols-2 gap-x-8 gap-y-12">
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Vendor</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={
                            vendor?.name || <Trans>No vendor name found</Trans>
                        }
                        description="Vendor"
                        afterTitle={
                            purposes ? (
                                <PurposesTags showAll purposes={purposes} />
                            ) : (
                                <Tag>Unclassified</Tag>
                            )
                        }
                        avatarSrc={vendor?.imageUrl}
                        avatarIconName="building-07"
                        text={
                            vendor?.description ||
                            'No description found for this vendor'
                        }
                    />
                </div>
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Subject</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={cookie.name}
                        description="Cookie"
                        avatarIconName="cookie"
                        afterTitle={
                            purposes ? (
                                <PurposesTags showAll purposes={purposes} />
                            ) : (
                                <Tag>Unclassified</Tag>
                            )
                        }
                        text={
                            cookie.description ||
                            'No description found for this cookie'
                        }
                    />
                </div>
            </AlertSummarySection>

            {/* Cookie Instance Example */}
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Cookie instance example</Trans>
                </AlertSummarySectionTitle>
                <AlertSummarySectionDescription>
                    <Trans>
                        This table shows the characteristics of the last
                        observation of [cookie|local storage object] [
                    </Trans>
                    {cookie.name}
                    <Trans>
                        ] in this audit. The domain refers to the domain that
                        writes the [cookie|local strage object].
                    </Trans>
                </AlertSummarySectionDescription>
                <div className="flex flex-col gap-2">
                    {lastScanCookie !== undefined ? (
                        <div className="flex flex-col gap-2">
                            <AuditAlertSummaryCookieDetails
                                scanCookie={lastScanCookie}
                            />
                        </div>
                    ) : (
                        <p>No cookie instance example found</p>
                    )}
                </div>
            </AlertSummarySection>

            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Alert initiator example</Trans>
                </AlertSummarySectionTitle>
                <AlertSummarySectionDescription>
                    <Trans>
                        This table shows the characteristics of the request that
                        initiated the [cookie|local storage object] [
                    </Trans>
                    {cookie.name}
                    <Trans>] in this audit.</Trans>
                </AlertSummarySectionDescription>
                {scanRequest ? (
                    <div className="flex flex-col gap-2">
                        <AuditAlertSummaryRequestDetails
                            scanRequest={scanRequest}
                        />
                    </div>
                ) : (
                    <p>No initiator example found</p>
                )}
            </AlertSummarySection>

            {scanRequest && (
                <AlertSummarySection>
                    <AlertSummarySectionTitle>
                        <Trans>Alert request initiator chain example</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummarySectionDescription>
                        <Trans>
                            This initiator chain includes all requests that led
                            to the creation of this [cookie | local storage
                            object] (above the bold line) and any subsequent
                            requests triggered by this request (below the bold
                            line).
                        </Trans>
                    </AlertSummarySectionDescription>
                    <AuditAlertSummaryRequestChain
                        initiatorScanRequests={
                            scanRequest?.initiatorScanRequests
                        }
                        currentRequest={{
                            url: scanRequest?.url,
                            imageUrl: scanRequest?.tracker?.vendor?.imageUrl,
                        }}
                        downstreamScanRequests={
                            scanRequest?.downstreamScanRequests || []
                        }
                    />
                </AlertSummarySection>
            )}
        </PageContainer>
    )
}
