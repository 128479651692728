import React from 'react'
import clsx from 'clsx'

const Checkbox = ({
    className,
    hasError,
    onChange,
    value,
    name,
    disabled,
    ...props
}) => (
    <input
        type="checkbox"
        className={clsx(
            'form-checkbox h-4 w-4 rounded border-slate-300 text-primary-600 transition duration-150 ease-in-out',
            disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
            className
        )}
        name={name}
        onChange={onChange}
        checked={value}
        disabled={disabled}
        {...props}
    />
)

export default Checkbox
