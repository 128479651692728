import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const PAGE_QUERY = gql`
    query auditsLivePage($scanId: ID!) {
        scan(id: $scanId) {
            id
            trigger
            status
            source
            liveSettings {
                liveUrl
            }
            domain {
                id
                name
                url
            }
            audit {
                id
                name
                isMobile
                isWeb
                domains {
                    id
                    name
                }
            }
        }
    }
`

export function useAuditsLivePageQuery({ scanId }) {
    const variables = {
        scanId,
    }
    const {
        data = {},
        loading: isFetching,
        error,
    } = useQuery(PAGE_QUERY, {
        variables,
        fetchPolicy: 'no-cache',
    })
    return {
        error,
        isFetching,
        scan: data.scan || {},
    }
}

const LOGS_QUERY = gql`
    query auditsLiveWebScanLogs($scanId: ID!) {
        liveWebScanLogs(scanId: $scanId) {
            scanRequests {
                url
                tracker {
                    id
                    purposes {
                        id
                        name
                    }
                    vendor {
                        imageUrl
                    }
                }
                hasPostData
            }
            scanCookies {
                name
                value
                tracker {
                    id
                    purposes {
                        id
                        name
                    }
                    vendor {
                        imageUrl
                    }
                }
                cookie {
                    id
                    purposes {
                        id
                        name
                    }
                    vendor {
                        imageUrl
                    }
                }
            }
        }
    }
`

export function useAuditsLiveWebScanLogsQuery({ scanId, isStarted }) {
    const variables = {
        scanId,
    }
    const {
        data = {},
        loading: isFetching,
        error,
    } = useQuery(LOGS_QUERY, {
        variables,
        fetchPolicy: 'no-cache',
        pollInterval: 3000,
        skip: !isStarted,
    })
    return {
        error,
        isFetching,
        liveWebScanLogs: data.liveWebScanLogs || {},
    }
}
