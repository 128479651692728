import React from 'react'
import PageHeader from '../../layout/PageHeader'
import AuditHeaderActions from './AuditHeaderActions'

const AuditHeader = ({
    title,
    description,
    audit,
    isLoading,
    onUploadSession,
    onStartScanCompleted,
    onCreateLiveScanCompleted,
}) => {
    const {
        isMobile,
        isWeb,
        isCode,
        domains,
        auditScenarios,
        consentProfiles,
        auditBuilds,
    } = audit
    return (
        <PageHeader
            title={title}
            description={description}
            rightChildren={
                <AuditHeaderActions
                    domains={domains}
                    auditScenarios={auditScenarios}
                    consentProfiles={consentProfiles}
                    auditBuilds={auditBuilds}
                    isMobile={isMobile}
                    isWeb={isWeb}
                    isCode={isCode}
                    isLoading={isLoading}
                    onUploadSession={onUploadSession}
                    onStartScanCompleted={onStartScanCompleted}
                    onCreateLiveScanCompleted={onCreateLiveScanCompleted}
                />
            }
        />
    )
}

export default AuditHeader
