import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useUpdateDataIdentifierMutation from './useUpdateDataIdentifierMutation'
import useUpdateDataIdentifierFormState from './useUpdateDataIdentifierFormState'
import StaffUpdateDataIdentifierModal from './StaffUpdateDataIdentifiersModal'
import useExampleText from './useExampleText'

const StaffUpdateDataIdentifierModalWithState = ({
    dataIdentifier,
    onDismiss,
    onCompleted,
}) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const formState = useUpdateDataIdentifierFormState({
        name: dataIdentifier.name,
        sensitivity: dataIdentifier.sensitivity,
        type: dataIdentifier.type,
        patterns: dataIdentifier.patterns,
    })
    const [staffUpdateDataIdentifier, { loading }] =
        useUpdateDataIdentifierMutation({
            onCompleted,
        })

    const { exampleText, highlightedExampleText, onUpdateExampleText } =
        useExampleText(dispatchError)

    return (
        <StaffUpdateDataIdentifierModal
            exampleText={exampleText}
            highlightedExampleText={highlightedExampleText}
            onUpdateExampleText={onUpdateExampleText}
            formState={formState}
            isLoading={loading}
            onDismiss={onDismiss}
            onSubmit={async () => {
                try {
                    if (formState.validate()) {
                        const variables = {
                            id: dataIdentifier.id,
                            input: {
                                name: formState.values.name,
                                sensitivity: formState.values.sensitivity,
                                type: formState.values.type,
                                patterns: formState.values.patterns,
                            },
                        }
                        await staffUpdateDataIdentifier({ variables })
                        formState.resetForm()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    Data identifier updated successfully!
                                </Trans>
                            ),
                        })
                    }
                } catch (error) {
                    dispatchError({ message: error.message })
                }
            }}
        />
    )
}

export default StaffUpdateDataIdentifierModalWithState
