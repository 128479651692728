import React from 'react'
import Table from '../../utils/Table'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableBody from '../../utils/TableBody'
import CookieTableVersionCompareModalTableHeaderRow from './CookieTableVersionCompareModalTableHeaderRow'
import CookieTableVersionCompareModalTableActions from './CookieTableVersionCompareModalTableActions'
import CookieTableVersionCompareModalTableRow from './CookieTableVersionCompareModalTableRow'

const CookieTableVersionCompareModalTable = ({
    deletedCookies = [],
    sameCookies = [],
    newCookies = [],
    deprecatedCookies = [],
    modifiedCookies = [],
    onUpdateCompleted,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
}) => {
    const [activeTab, setActiveTab] = React.useState('all')

    const allCookies = React.useMemo(() => {
        return [
            ...newCookies.map((cookie) => ({ ...cookie, type: 'new' })),
            ...deletedCookies.map((cookie) => ({ ...cookie, type: 'danger' })),
            ...sameCookies.map((cookie) => ({ ...cookie, type: 'same' })),
            ...deprecatedCookies.map((cookie) => ({
                ...cookie,
                type: 'warning',
            })),
            ...modifiedCookies.map((cookie) => ({
                ...cookie,
                type: 'modified',
            })),
        ]
    }, [
        newCookies,
        deletedCookies,
        sameCookies,
        deprecatedCookies,
        modifiedCookies,
    ])

    const getCookiesToRender = () => {
        switch (activeTab) {
            case 'new':
                return newCookies.map((cookie) => ({ ...cookie, type: 'new' }))
            case 'deleted':
                return deletedCookies.map((cookie) => ({
                    ...cookie,
                    type: 'danger',
                }))
            case 'deprecated':
                return deprecatedCookies.map((cookie) => ({
                    ...cookie,
                    type: 'warning',
                }))
            case 'modified':
                return modifiedCookies.map((cookie) => ({
                    ...cookie,
                    type: 'modified',
                }))
            case 'same':
                return sameCookies.map((cookie) => ({
                    ...cookie,
                    type: 'same',
                }))
            case 'all':
            default:
                return allCookies
        }
    }
    const cookiesToRender = getCookiesToRender()

    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            <CookieTableVersionCompareModalTableActions
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                allCount={allCookies.length}
                addedCount={newCookies.length}
                deletedCount={deletedCookies.length}
                deprecatedCount={deprecatedCookies.length}
                modifiedCount={modifiedCookies.length}
            />
            <Table
                className="h-[55vh] overflow-x-hidden"
                isFetching={isFetching}
            >
                <thead>
                    <CookieTableVersionCompareModalTableHeaderRow />
                </thead>
                <TableBody>
                    {cookiesToRender.map((cookie) => (
                        <CookieTableVersionCompareModalTableRow
                            key={cookie.id}
                            cookieItem={cookie}
                            type={cookie.type}
                            onUpdateCompleted={onUpdateCompleted}
                        />
                    ))}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}

export default CookieTableVersionCompareModalTable
