import React from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import useSearch from '../../hooks/useSearch'
import useRefetch from '../../hooks/useRefetch'
import OrganizationTrackersPage from './OrganizationTrackersPage'
import useOrganizationTrackersPageQuery from './useOrganizationTrackersPageQuery'
import CreateOrganizationTrackerModalWithState from './CreateOrganizationTrackerModalWithState'

const OrganizationTrackersPageWithState = () => {
    const history = useHistory()
    const [search, handleSearch] = useSearch('')
    const createModalIsOpen = useRouteMatch({
        path: '/classifications/organization/trackers/create',
    })
    const {
        trackers,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useOrganizationTrackersPageQuery(search)
    useRefetch(refetch)
    return (
        <>
            <OrganizationTrackersPage
                search={search}
                trackers={trackers}
                isFetching={isFetching}
                isFetchingMore={isFetchingMore}
                hasMoreRecords={hasMore}
                onFetchMore={handleFetchMore}
                onSearch={handleSearch}
                onCreateTracker={() =>
                    history.push(
                        '/classifications/organization/trackers/create'
                    )
                }
                onUpdateCompleted={() => refetch()}
                onDeleteCompleted={() => refetch()}
            />
            {createModalIsOpen && (
                <CreateOrganizationTrackerModalWithState
                    onDismiss={() =>
                        history.push('/classifications/organization/trackers')
                    }
                    onCompleted={() => refetch()}
                />
            )}
        </>
    )
}

export default OrganizationTrackersPageWithState
