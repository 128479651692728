import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'action',
        validate: isRequired,
        message: <Trans>The action is required</Trans>,
    },
])

export default function useUpdateActionFormState(initialValues = {}) {
    const formState = useFormState(
        {
            action: '',
            ...initialValues,
        },
        {
            validation,
        }
    )
    return formState
}
