import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useDeleteCookieTableVersionMutation from './useDeleteCookieTableVersionMutation'
import DeleteCookieTableVersionModal from './DeleteCookieTableVersionModal'

export default function DeleteCookieTableVersionModalWithState({
    cookieTableVersion,
    onDismiss,
    onCompleted,
    onError,
}) {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteCookieTableVersion, { loading }] =
        useDeleteCookieTableVersionMutation({
            onCompleted,
            onError,
        })
    return (
        <DeleteCookieTableVersionModal
            onDismiss={onDismiss}
            cookieTableVersion={cookieTableVersion}
            isLoading={loading}
            onConfirm={async () => {
                try {
                    const variables = { id: cookieTableVersion.id }
                    await deleteCookieTableVersion({ variables })
                    onDismiss()
                    dispatchSuccess({
                        message: (
                            <Trans>
                                {cookieTableVersion.name} successfully deleted.
                            </Trans>
                        ),
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}
