import React from 'react'
import { Trans } from '@lingui/macro'
import PageHeader from '../../layout/PageHeader'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import PageContent from '../../utils/PageContent'
import StaffHeader from '../../layout/StaffHeader'
import StaffScansPageSidebar from '../staff-scans/StaffScansPageSidebar'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'
import StaffScanPageDashboardPausedAudits from './StaffScanPageDashboardPausedAudits'
import SkeletonLoadingGraph from '../../utils/SkeletonLoadingGraph'

export default function StaffScansPageDashboard() {
    return (
        <PageContainer>
            <StaffHeader />
            <SidebarContainer>
                <StaffScansPageSidebar />
                <PageContent size="full" className="pb-12 pl-6 pr-6 pt-12">
                    <PageHeader title={<Trans>Dashboard</Trans>} />
                    <div className="grid gap-6 sm:grid-cols-2">
                        <div className="sm:col-span-2">
                            <Card className="h-[456px] w-full overflow-hidden">
                                <CardHeader
                                    title={<Trans>Automated scans</Trans>}
                                    description={
                                        <Trans>
                                            Below here comes an automated scans
                                            table with the failed scans and
                                            succeeded scans.
                                        </Trans>
                                    }
                                />
                                <SkeletonLoadingGraph bars={25} />
                            </Card>
                        </div>
                        <div className="sm:col-span-2 lg:col-span-1">
                            <Card className="h-[468px] w-full overflow-hidden">
                                <CardHeader
                                    title={
                                        <Trans>
                                            Failing audits per organization
                                        </Trans>
                                    }
                                    description={
                                        <Trans>
                                            The procent of failed audits per
                                            organization.
                                        </Trans>
                                    }
                                />
                                <SkeletonLoadingGraph bars={14} />
                            </Card>{' '}
                        </div>
                        <div className="sm:col-span-2 lg:col-span-1">
                            <StaffScanPageDashboardPausedAudits />
                        </div>
                        <div className="overflow-y-hidden sm:col-span-2">
                            <Card className="h-[456px] w-full">
                                <CardHeader
                                    title={<Trans>Scans over time</Trans>}
                                    description={
                                        <Trans>
                                            How many scans are running at which
                                            time.
                                        </Trans>
                                    }
                                />
                            </Card>{' '}
                        </div>
                    </div>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
