import React from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import useCookieTablesPageQuery from '../pages/vendors-cookie-tables/useCookieTablesPageQuery'
import SearchInput from './SearchInput'
import SelectListOption from './SelectListOption'
import InfiniteScroll from './InfiniteScroll'
import SelectList from './SelectList'
import useSearch from '../hooks/useSearch'
import Button from './Button'
import EmptyPageCard from './EmptyPageCard'

const CookieTableSearchSelect = ({
    className,
    selectedCookieTable,
    onHandleSelectCookieTable,
}) => {
    const [search, handleSearch] = useSearch('')
    const history = useHistory()

    const {
        cookieTables,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useCookieTablesPageQuery(search)

    const hasCookieTables = cookieTables.length > 0

    return (
        <SelectList
            className={className}
            isFetching={isFetching}
            searchInput={
                <SearchInput
                    disabled={!hasCookieTables}
                    value={search}
                    onChange={handleSearch}
                    className="w-full"
                />
            }
        >
            <InfiniteScroll
                isFetchingMore={isFetchingMore}
                onFetchMore={handleFetchMore}
                hasMoreRecords={hasMore}
            >
                <ul>
                    {cookieTables.length === 0 && (
                        <EmptyPageCard
                            className="border-none"
                            iconName="cookie"
                            title={<Trans>No Cookie tables</Trans>}
                            description={
                                <Trans>
                                    Click the button below to start creating
                                    your cookie table.
                                </Trans>
                            }
                        >
                            <Button
                                className="mt-6"
                                iconBeforeProps={{
                                    name: 'plus',
                                    size: 'sm',
                                }}
                                onClick={() =>
                                    history.push('/vendors/cookie-tables')
                                }
                            >
                                <Trans>Create cookie table</Trans>
                            </Button>
                        </EmptyPageCard>
                    )}
                    {hasCookieTables &&
                        cookieTables.map((cookieTable) => (
                            <SelectListOption
                                key={cookieTable.id}
                                disabled={false}
                                avatarDefaultIcon="cookie"
                                isCheckbox
                                checked={selectedCookieTable === cookieTable.id}
                                onClick={() => {
                                    if (
                                        selectedCookieTable === cookieTable.id
                                    ) {
                                        onHandleSelectCookieTable(null)
                                    } else {
                                        onHandleSelectCookieTable(
                                            cookieTable.id
                                        )
                                    }
                                }}
                            >
                                {cookieTable.name}
                            </SelectListOption>
                        ))}
                </ul>
            </InfiniteScroll>
        </SelectList>
    )
}

export default CookieTableSearchSelect
