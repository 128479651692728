import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import Tag from './Tag'
import Popover from './Popover'
import DetailsCard from './DetailsCard'
import Avatar from './Avatar'
import DetailsList from './DetailsList'
import DetailsListItem from './DetailsListItem'
import VendorTypeTags from './VendorTypeTags'

export const POPOVER_VENDOR_INFO_FRAGMENT = gql`
    fragment PopoverVendorInfo on Vendor {
        id
        name
        types
        description
        imageUrl
        belongsToOrganization
    }
`

const PopoverVendorInfo = ({ vendor, ...props }) => (
    <Popover
        event="hover"
        position="bottom-start"
        content={
            <div className="my-2 w-96">
                <DetailsCard className="border border-gray-200 shadow-xl">
                    <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-4">
                                <Avatar
                                    iconName="building-07"
                                    src={vendor.imageUrl}
                                />
                                <h3 className="text-md font-medium leading-6 text-gray-900">
                                    {vendor.name}
                                </h3>
                            </div>
                            <div>
                                <VendorTypeTags types={vendor.types} />
                            </div>
                        </div>
                        <p className="mt-1 text-sm leading-5 text-slate-500">
                            {vendor.description}
                        </p>
                    </div>
                    <DetailsList>
                        {vendor.type && (
                            <DetailsListItem label={<Trans>Category</Trans>}>
                                <Tag type="blue">{vendor.type}</Tag>
                            </DetailsListItem>
                        )}
                    </DetailsList>
                </DetailsCard>
            </div>
        }
        {...props}
    />
)

PopoverVendorInfo.FRAGMENT = POPOVER_VENDOR_INFO_FRAGMENT

export default PopoverVendorInfo
