import React from 'react'
import * as constants from '@clew/shared/src/constants'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import VendorTableVersionCompareModalTable from './VendorTableVersionCompareModalTable'
import ModalTitle from '../../utils/ModalTitle'
import Modal from '../../utils/Modal'
import { useCompareVendorTableItemPageQuery } from './useVendorTableItemPageQuery'
import ModalFooter from '../../utils/ModalFooter'
import DrawerTabError from '../../utils/DrawerTabError'

const {
    VENDOR_TABLE_ITEM_STATUS_ADDED: ADDED,
    VENDOR_TABLE_ITEM_STATUS_DEPRECATED: DEPRECATED,
} = constants

const compareVendorTables = (newItems, oldItems) => {
    // Helper to normalize and sort purposes for consistent comparison
    const normalizePurposes = (purposes) =>
        [...purposes].map((p) => p.id).sort()

    // Create maps for previous states
    const [previousActive, previousDeprecated] = oldItems.reduce(
        ([activeMap, deprecatedMap], item) => {
            const key = item.vendor?.id
            if (item.status === ADDED) {
                activeMap.set(key, {
                    name: item.name,
                    domain: item.domain,
                    description: item.description,
                    purposes: normalizePurposes(item.purposes),
                    vendorId: key,
                })
            } else if (item.status === DEPRECATED && key) {
                deprecatedMap.set(key, activeMap.get(key)) // Store last active state
            }
            return [activeMap, deprecatedMap]
        },
        [new Map(), new Map()]
    )

    const results = {
        newComparedVendors: [],
        modifiedStatusVendors: [],
        deprecatedStatusVendors: [],
        deletedComparedVendors: [],
        sameComparedVendors: [],
    }

    // Analyze new items
    newItems.forEach((newItem) => {
        const vendorId = newItem.vendor?.id
        const previous =
            previousActive.get(vendorId) || previousDeprecated.get(vendorId)

        // New vendor detection
        if (!previous) {
            results.newComparedVendors.push(newItem)
            return
        }

        // Deprecation handling
        if (newItem.status === DEPRECATED) {
            results.deprecatedStatusVendors.push(newItem)
            return
        }

        // Field comparison
        const currentPurposes = normalizePurposes(newItem.purposes)
        const changes = {
            name:
                newItem.name !== previous.name
                    ? { current: newItem.name, previous: previous.name }
                    : undefined,
            domain:
                newItem.domain !== previous.domain
                    ? { current: newItem.domain, previous: previous.domain }
                    : undefined,
            description:
                newItem.description !== previous.description
                    ? {
                          current: newItem.description,
                          previous: previous.description,
                      }
                    : undefined,
            purposes:
                JSON.stringify(currentPurposes) !==
                JSON.stringify(previous.purposes)
                    ? {
                          current: newItem.purposes,
                          previous: oldItems.find(
                              (i) => i.vendor?.id === vendorId
                          )?.purposes,
                      }
                    : undefined,
        }

        const changedFields = Object.entries(changes)
            .filter(([_, value]) => value !== undefined)
            .map(([fieldName, { current, previous: prevValue }]) => ({
                fieldName,
                current,
                previous: prevValue,
            }))

        if (Object.keys(changedFields).length > 0) {
            results.modifiedStatusVendors.push({
                ...newItem,
                changedFields,
            })
        } else {
            results.sameComparedVendors.push(newItem)
        }
    })

    // Detect deletions
    oldItems.forEach((oldItem) => {
        if (
            oldItem.status === ADDED &&
            !newItems.some(
                (newItem) => newItem.vendor?.id === oldItem.vendor?.id
            )
        ) {
            results.deletedComparedVendors.push(oldItem)
        }
    })

    return results
}

const VendorTableVersionCompareModal = ({
    versionId,
    vendorTableId,
    onDismiss,
    onCreate,
    onCancel,
}) => {
    const {
        refetch,
        latestVendorTableItems,
        comparingVendorTableItems,
        isFetching,
        error,
    } = useCompareVendorTableItemPageQuery({
        vendorTableId,
        vendorTableVersionId: versionId,
        first: 9999,
    })

    if (error) {
        return <DrawerTabError error={error} />
    }

    const {
        newComparedVendors,
        sameComparedVendors,
        deletedComparedVendors,
        deprecatedStatusVendors,
        modifiedStatusVendors,
    } = compareVendorTables(comparingVendorTableItems, latestVendorTableItems)

    return (
        <Modal className="max-w-4xl" onDismiss={onDismiss}>
            <ModalTitle
                title={<Trans>Create new vendor table version</Trans>}
                description={
                    <Trans>
                        In the overview below you can compare and adjust the
                        differences between the previous version
                    </Trans>
                }
                iconName="building-07"
                iconColor="none"
                iconSize="md"
                onClose={onCancel}
                newModalTitle
            />
            <div>
                <VendorTableVersionCompareModalTable
                    isFetching={isFetching}
                    deletedVendors={deletedComparedVendors}
                    sameVendors={sameComparedVendors}
                    newVendors={newComparedVendors}
                    deprecatedVendors={deprecatedStatusVendors}
                    modifiedVendors={modifiedStatusVendors}
                    onUpdateCompleted={() => {
                        refetch()
                    }}
                />

                <ModalFooter>
                    <Button type="white" onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button onClick={onCreate}>
                        <Trans>Create</Trans>
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    )
}

export default VendorTableVersionCompareModal
