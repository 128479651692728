import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import { DrawerDetailsItemText } from '../../utils/DrawerDetailsItem'
import Tag from '../../utils/Tag'
import EmptyPageCard from '../../utils/EmptyPageCard'

export default function IdentifiedVendorDrawerTabDetails({ identifiedVendor }) {
    const { vendor } = identifiedVendor || {}

    // Check if any vendor fields have data
    const hasVendorData =
        vendor?.name ||
        vendor?.description ||
        vendor?.domain ||
        (vendor?.types && vendor.types.length > 0) ||
        vendor?.privacyPolicyUrl

    if (!identifiedVendor) {
        return (
            <DrawerEmptyCard
                iconName="building-07"
                title={<Trans>No details</Trans>}
                description={
                    <Trans>
                        There were no details found for this identified vendor.
                    </Trans>
                }
            />
        )
    }

    return (
        <div className="flex flex-col gap-4">
            {hasVendorData ? (
                <>
                    {vendor?.name && (
                        <DrawerDetailsItemText
                            isHorizontal
                            truncate={false}
                            label={<Trans>Name</Trans>}
                        >
                            {vendor.name}
                        </DrawerDetailsItemText>
                    )}
                    {vendor?.description && (
                        <DrawerDetailsItemText
                            isHorizontal
                            truncate={false}
                            label={<Trans>Description</Trans>}
                        >
                            {vendor.description}
                        </DrawerDetailsItemText>
                    )}

                    {vendor?.domain && (
                        <DrawerDetailsItemText
                            isHorizontal
                            label={<Trans>Domain</Trans>}
                        >
                            {vendor.domain}
                        </DrawerDetailsItemText>
                    )}
                    {vendor?.websiteUrl && (
                        <DrawerDetailsItemText
                            isHorizontal
                            label={<Trans>Website</Trans>}
                        >
                            <a
                                href={vendor.websiteUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary"
                            >
                                {vendor.websiteUrl}
                            </a>
                        </DrawerDetailsItemText>
                    )}

                    {vendor?.types && vendor.types.length > 0 && (
                        <DrawerDetailsItemText
                            isHorizontal
                            label={<Trans>Types</Trans>}
                        >
                            <div className="flex flex-row gap-2">
                                {vendor.types.map((type) => (
                                    <Tag key={type} type="primary">
                                        {type}
                                    </Tag>
                                ))}
                            </div>
                        </DrawerDetailsItemText>
                    )}

                    {vendor?.privacyPolicyUrl && (
                        <DrawerDetailsItemText
                            isHorizontal
                            label={<Trans>Privacy Policy</Trans>}
                        >
                            <a
                                href={vendor.privacyPolicyUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className="text-primary">
                                    <Trans>Click Here</Trans>
                                </span>
                            </a>
                        </DrawerDetailsItemText>
                    )}
                </>
            ) : (
                <EmptyPageCard
                    iconName="building-07"
                    title={<Trans>Unknown Vendor</Trans>}
                    description={
                        <Trans>No details available for this vendor</Trans>
                    }
                />
            )}
        </div>
    )
}
