import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import { CodeBlock } from '../../utils/PostDataCodeBlock'

export function AuditRecordMobileUiTab({ onShowUiElements, uiElements }) {
    return (
        <div>
            <div className="mb-6 flex justify-end gap-2">
                <Button onClick={onShowUiElements} type="white">
                    <Trans>Show current UI elements</Trans>
                </Button>
            </div>
            {Object.keys(uiElements).length > 0 && (
                <CodeBlock code={JSON.stringify(uiElements, null, 2)} />
            )}
        </div>
    )
}
