import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query IdentifiedTrackersDrawerTabObservations(
        $id: ID!
        $auditId: ID!
        $offset: Int
        $search: String
    ) {
        identifiedTrackerPages(
            id: $id
            auditId: $auditId
            offset: $offset
            search: $search
        ) {
            edges {
                cursor
                node {
                    id
                    url
                    scanPageCount
                    auditIds
                    scanPages {
                        id
                        scan {
                            id
                            startedAt
                            consentProfile {
                                id
                                name
                                type
                            }
                            consentSettings {
                                name
                                consentType
                            }
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export function useIdentifiedTrackerObservationPagesQuery({
    identifiedTrackerId,
    auditId,
    skip,
}) {
    const variables = { id: identifiedTrackerId, auditId }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        skip,
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'identifiedTrackerPages'
    )
    return {
        error,
        pages: connectionToCollection(data.identifiedTrackerPages),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}
