import { getRootDomain } from '../../../utils/index'

export function processNetworkEvents(networkEvents) {
    const requests = networkEvents.map(
        ({ request, response, startedDateTime }) => {
            const { method, postData } = request
            const timestamp = new Date(startedDateTime) || new Date()
            const data = {
                method,
                url: request.url,
                rootDomain: request ? getRootDomain(request.url) : null,
                headers: request ? request.headers : null,
                timestamp,
            }
            if (postData && request.postData.text !== '""') {
                data.postData = postData.text
            }
            if (
                response &&
                response.content &&
                response.content.text !== '""'
            ) {
                data.responseContent = response.content.text
            }
            return data
        }
    )
    const cookies = networkEvents
        .filter(({ type }) => type === 'response')
        .flatMap(({ response, request, startedDateTime }) =>
            response.cookies.map((cookie) => {
                const { domain, expires, ...rest } = cookie
                let finalDomain = domain
                if (domain && domain[0] === '.') {
                    finalDomain = domain.substring(1)
                }

                let expiresAt = null
                let retention = null
                if (expires && expires !== -1) {
                    expiresAt = new Date(expires).toISOString()
                    retention = new Date(expires) - new Date(startedDateTime)
                }
                return {
                    ...rest,
                    domain: finalDomain || getRootDomain(request.url),
                    requestUrl: request.url,
                    createdAt: new Date(startedDateTime) || new Date(),
                    expiresAt,
                    retention,
                }
            })
        )

    return { requests, cookies }
}
