import React from 'react'
import { Trans } from '@lingui/macro'
import { Route, Switch, Router } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import DrawerStackProvider from './providers/DrawerStackProvider'
import * as constants from '../constants'
import HasPermissionRoute from './utils/HasPermissionRoute'
import AlertsPageWithState from './pages/alerts/AlertsPageWithState'
import ScansPageWithState from './pages/scans/ScansPageWithState'
import LoginPageWithState from './pages/login/LoginPageWithState'
import ResetPasswordPageWithState from './pages/reset-password/ResetPasswordPageWithState'
import ChoosePasswordPageWithState from './pages/choose-password/ChoosePasswordPageWithState'
import UsersPageWithState from './pages/settings-users/UsersPageWithState'
import SettingsPageWithState from './pages/settings/SettingsPageWithState'
import CustomCmpsPageWithState from './pages/settings-cmps/CustomCmpsPageWithState'
import UpdateCustomCmpPageWithState from './pages/settings-cmps-update/UpdateCustomCmpPageWithState'
import ProfilePageWithState from './pages/profile/ProfilePageWithState'
import UserInvitationsPageWithState from './pages/settings-users-invitations/UserInvitationsPageWithState'
import InviteUserPageWithState from './pages/settings-users-invite/InviteUserPageWithState'
import MyOrganizationsPageWithState from './pages/my-organizations/MyOrganizationsPageWithState'
import AuditsPageWithState from './pages/audits/AuditsPageWithState'
import CreateAuditPageWithState from './pages/audits-create/CreateAuditPageWithState'
import ReportsPageWithState from './pages/reports/ReportsPageWithState'
import AuditDetailsPageWithState from './pages/audits-details/AuditDetailsPageWithState'
import AuditSettingsPageWithState from './pages/audits-settings/AuditSettingsPageWithState'
import AuditScansPageWithState from './pages/audits-scans/AuditScansPageWithState'
import AuditVendorsPageWithState from './pages/audits-vendors/AuditVendorsPageWithState'
import AuditAlertsPageWithState from './pages/audits-alerts/AuditAlertsPageWithState'
import AuditCookiesPageWithState from './pages/audit-cookies/AuditCookiesPageWithState'
import AuditTrackersPageWithState from './pages/audit-trackers/AuditTrackersPageWithState'
import UpdateDomainPageWithState from './pages/audits-domains-update/UpdateDomainPageWithState'
import CreateDomainPageWithState from './pages/audits-domains-create/CreateDomainPageWithState'
import VendorsPageWithState from './pages/staff-vendors/VendorsPageWithState'
import VendorDetailsPageWithState from './pages/staff-vendor-details/VendorDetailsPageWithState'
import VendorCookiesPageWithState from './pages/staff-vendor-cookies/VendorCookiesPageWithState'
import VendorTrackersPageWithState from './pages/staff-vendor-trackers/VendorTrackersPageWithState'
import CookiesPageWithState from './pages/staff-vendors-cookies/CookiesPageWithState'
import OrganizationCookiesPageWithState from './pages/classifications-organization-cookies/OrganizationCookiesPageWithState'
import OrganizationTrackersPageWithState from './pages/classifications-organization-trackers/OrganizationTrackersPageWithState'
import GlossaryPageWithState from './pages/glossary/GlossaryPageWithState'
import AuditsReportsPageWithState from './pages/audits-reports/AuditsReportsPageWithState'
import IdentifiedVendorsPageWithState from './pages/vendors/IdentifiedVendorsPageWithState'
import IdentifiedTrackersPageWithState from './pages/vendors-trackers/IdentifiedTrackersPageWithState'
import IdentifiedCookiesPageWithState from './pages/vendors-cookies/IdentifiedCookiesPageWithState'
import AcceptInvitationPageWithState from './pages/accept-invitation/AcceptInvitationPageWithState'
import ConsentProfilesPageWithState from './pages/settings-consent-profiles/ConsentProfilesPageWithState'
import LoginProfilesPageWithState from './pages/settings-login-profiles/LoginProfilesPageWithState'
import UpdateConsentProfilePageWithState from './pages/settings-consent-profiles-update/UpdateConsentProfilePageWithState'
import UpdateLoginProfilePageWithState from './pages/settings-login-profiles-update/UpdateLoginProfilePageWithState'
import VendorTablesPageWithState from './pages/vendors-vendor-tables/VendorTablesPageWithState'
import CookieTablesPageWithState from './pages/vendors-cookie-tables/CookieTablesPageWithState'
import CookieTableItemsPageWithState from './pages/vendors-cookie-tables-details/CookieTableItemsPageWithState'
import OrganizationVendorsPageWithState from './pages/classifications-organization-vendors/OrganizationVendorsPageWithState'
import StaffOrganizationsPageWithState from './pages/staff-organizations/StaffOrganizationsPageWithState'
import StaffUsersPageWithState from './pages/staff-users/StaffUsersPageWithState'
import StaffJobsPageWithState from './pages/staff-jobs/StaffJobsPageWithState'
import DashboardPage from './pages/dashboard/DashboardPageWithState'
import StaffVendorsTrackers from './pages/staff-vendors-trackers/TrackersPageWithState'
import StaffScansPageWithState from './pages/staff-scans/StaffScansPageWithState'
import StaffUnclassifiedTrackersPageWithState from './pages/staff-vendors-unclassified-trackers/StaffUnclassifiedTrackersPageWithState'
import StaffVendorsOrganizationVendors from './pages/staff-vendors-organization-vendors/StaffVendorsOrganizationVendors'
import StaffVendorsOrganizationCookies from './pages/staff-vendors-organization-cookies/StaffVendorsOrganizationCookies'
import StaffVendorsOrganizationTrackers from './pages/staff-vendors-organization-trackers/StaffVendorsOrganizationTrackers'
import StaffGlossaryPage from './pages/staff-glossary/StaffGlossaryPage'
import StaffDataIdentifiersPageWithState from './pages/staff-data-identifiers/StaffDataIdentifiersPageWithState'
import StaffUnclassifiedCookiesPageWithState from './pages/staff-vendors-unclassified-cookies/StaffUnclassifiedCookiesPageWithState'
import VendorTableItemsPageWithState from './pages/vendors-vendor-tables-details/VendorTableItemsPageWithState'
import StaffOrganizationUsersPageWithState from './pages/staff-organization-users/StaffOrganizationUsersPageWithState'
import AuditAlertSummaryReportPage from './pages/audits-alert-summary/AlertSummaryReportPage'
import CreateMobileAuditPageWithState from './pages/audits-create-mobile/CreateMobileAuditPageWithState'
import AuditRecordMobileActionsPageWithState from './pages/audits-record-mobile-actions/AuditRecordMobileActionsPageWithState'
import StaffScansPageDashboard from './pages/staff-scans-dashboard/StaffScansPageDashboard'
import AuditsLivePageWithState from './pages/audits-scans-live-web/AuditsLivePageWithState'
import LiveMobileScanPageWithState from './pages/audits-scans-live-mobile/LiveMobileScanPageWithState'

const { USER_TYPE_STAFF: STAFF, USER_ROLE_OWNER: OWNER } = constants

const AppRouter = ({ history, isAuthenticated, currentUser, ...props }) => {
    return (
        <Router history={history}>
            <QueryParamProvider adapter={ReactRouter5Adapter}>
                <DrawerStackProvider>
                    <Switch>
                        <Route exact path="/invite/:id/accept">
                            <AcceptInvitationPageWithState
                                isAuthenticated={isAuthenticated}
                                {...props}
                            />
                        </Route>
                        {!isAuthenticated ? (
                            <Switch>
                                <Route exact path="/reset-password">
                                    <ResetPasswordPageWithState {...props} />
                                </Route>
                                <Route exact path="/choose-password">
                                    <ChoosePasswordPageWithState {...props} />
                                </Route>
                                <Route path="/">
                                    <LoginPageWithState {...props} />
                                </Route>
                            </Switch>
                        ) : (
                            <Switch>
                                <Route exact path="/profile">
                                    <ProfilePageWithState {...props} />
                                </Route>
                                <Route exact path="/my-organizations">
                                    <MyOrganizationsPageWithState {...props} />
                                </Route>
                                <HasPermissionRoute
                                    exact
                                    path="/staff"
                                    type={STAFF}
                                >
                                    <StaffOrganizationsPageWithState
                                        {...props}
                                    />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/organization-users"
                                    type={STAFF}
                                >
                                    <StaffOrganizationUsersPageWithState
                                        {...props}
                                    />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/staff-users"
                                    type={STAFF}
                                >
                                    <StaffUsersPageWithState {...props} />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/vendors"
                                    type={STAFF}
                                >
                                    <VendorsPageWithState {...props} />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/vendors/cookies"
                                    type={STAFF}
                                >
                                    <CookiesPageWithState {...props} />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/vendors/trackers"
                                    type={STAFF}
                                >
                                    <StaffVendorsTrackers {...props} />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/vendors/unclassified-cookies"
                                    type={STAFF}
                                >
                                    <StaffUnclassifiedCookiesPageWithState
                                        {...props}
                                    />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/vendors/unclassified-trackers"
                                    type={STAFF}
                                >
                                    <StaffUnclassifiedTrackersPageWithState
                                        {...props}
                                    />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/vendors/organization-vendors"
                                    type={STAFF}
                                >
                                    <StaffVendorsOrganizationVendors
                                        {...props}
                                    />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/vendors/organization-cookies"
                                    type={STAFF}
                                >
                                    <StaffVendorsOrganizationCookies
                                        {...props}
                                    />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/vendors/organization-trackers"
                                    type={STAFF}
                                >
                                    <StaffVendorsOrganizationTrackers
                                        {...props}
                                    />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/vendors/:id/trackers"
                                    type={STAFF}
                                >
                                    <VendorTrackersPageWithState {...props} />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/vendors/:id/cookies"
                                    type={STAFF}
                                >
                                    <VendorCookiesPageWithState {...props} />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/vendors/:id"
                                    type={STAFF}
                                >
                                    <VendorDetailsPageWithState {...props} />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/scans"
                                    type={STAFF}
                                >
                                    <StaffScansPageWithState {...props} />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/scans/dashboard"
                                    type={STAFF}
                                >
                                    <StaffScansPageDashboard {...props} />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/jobs/audit"
                                    type={STAFF}
                                >
                                    <StaffJobsPageWithState
                                        activeQueue="audit"
                                        title={<Trans>Audit jobs</Trans>}
                                        {...props}
                                    />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/jobs/scan"
                                    type={STAFF}
                                >
                                    <StaffJobsPageWithState
                                        activeQueue="scan"
                                        title={<Trans>Scan jobs</Trans>}
                                        {...props}
                                    />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/jobs/identified-period"
                                    type={STAFF}
                                >
                                    <StaffJobsPageWithState
                                        activeQueue="identified_period"
                                        title={
                                            <Trans>
                                                Identified period jobs
                                            </Trans>
                                        }
                                        {...props}
                                    />
                                </HasPermissionRoute>
                                <HasPermissionRoute
                                    exact
                                    path="/staff/jobs/report"
                                    type={STAFF}
                                >
                                    <StaffJobsPageWithState
                                        activeQueue="report"
                                        title={<Trans>Report jobs</Trans>}
                                        {...props}
                                    />
                                </HasPermissionRoute>
                                <Route exact path="/staff/glossary">
                                    <StaffGlossaryPage {...props} />
                                </Route>
                                <Route exact path="/staff/data-identifiers">
                                    <StaffDataIdentifiersPageWithState
                                        {...props}
                                    />
                                </Route>
                                {currentUser.currentOrganization ? (
                                    <Switch>
                                        <Route path="/dashboard">
                                            <DashboardPage {...props} />
                                        </Route>
                                        <Route exact path="/alerts">
                                            <AlertsPageWithState {...props} />
                                        </Route>
                                        <Route exact path="/audits">
                                            <AuditsPageWithState {...props} />
                                        </Route>
                                        <Route exact path="/vendors">
                                            <IdentifiedVendorsPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route exact path="/vendors/trackers">
                                            <IdentifiedTrackersPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route exact path="/vendors/cookies">
                                            <IdentifiedCookiesPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path={[
                                                '/classifications/organization',
                                                '/classifications/organization/create',
                                                '/classifications/organization/:id/delete',
                                                '/classifications/organization/:id/update',
                                            ]}
                                        >
                                            <OrganizationVendorsPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path={[
                                                '/classifications/organization/cookies',
                                                '/classifications/organization/cookies/create',
                                                '/classifications/organization/cookies/:id/delete',
                                                '/classifications/organization/cookies/:id/update',
                                            ]}
                                        >
                                            <OrganizationCookiesPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path={[
                                                '/classifications/organization/trackers',
                                                '/classifications/organization/trackers/create',
                                                '/classifications/organization/trackers/:id/delete',
                                                '/classifications/organization/trackers/:id/update',
                                            ]}
                                        >
                                            <OrganizationTrackersPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route exact path="/audits/create">
                                            <CreateAuditPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/audits/create-mobile"
                                        >
                                            <CreateMobileAuditPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route exact path="/audits/:id">
                                            <AuditDetailsPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/audits/:id/settings"
                                        >
                                            <AuditSettingsPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route exact path="/audits/:id/alerts">
                                            <AuditAlertsPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route exact path="/audits/:id/vendors">
                                            <AuditVendorsPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route exact path="/audits/:id/cookies">
                                            <AuditCookiesPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/audits/:id/trackers"
                                        >
                                            <AuditTrackersPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/audits/:id/domains/create"
                                        >
                                            <CreateDomainPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route exact path="/audits/:id/reports">
                                            <AuditsReportsPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route exact path="/audits/:id/scans">
                                            <AuditScansPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/audits/:id/record-mobile-actions/:auditScenarioId/update"
                                        >
                                            <AuditRecordMobileActionsPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/audits/:id/record-mobile-actions"
                                        >
                                            <AuditRecordMobileActionsPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/audits/:id/scans/:scanId/live/web"
                                        >
                                            <AuditsLivePageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/audits/:id/scans/:scanId/live/mobile"
                                        >
                                            <LiveMobileScanPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route exact path="/domains/:id/update">
                                            <UpdateDomainPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <Route exact path="/reports">
                                            <ReportsPageWithState {...props} />
                                        </Route>
                                        <Route
                                            exact
                                            path="/report/alert-summary/:id/:groupingId"
                                        >
                                            <AuditAlertSummaryReportPage
                                                {...props}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/report/alert-summary/:groupingId"
                                        >
                                            <AuditAlertSummaryReportPage
                                                {...props}
                                            />
                                        </Route>
                                        <Route exact path="/vendors">
                                            <ReportsPageWithState {...props} />
                                        </Route>
                                        <Route exact path="/settings/users">
                                            <UsersPageWithState {...props} />
                                        </Route>
                                        <Route
                                            exact
                                            path="/settings/users/invitations"
                                        >
                                            <UserInvitationsPageWithState
                                                {...props}
                                            />
                                        </Route>
                                        <HasPermissionRoute
                                            exact
                                            path="/settings/users/invite"
                                            role={OWNER}
                                        >
                                            <InviteUserPageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <Route exact path="/glossary">
                                            <GlossaryPageWithState {...props} />
                                        </Route>
                                        <HasPermissionRoute
                                            exact
                                            path="/settings"
                                            role={OWNER}
                                        >
                                            <SettingsPageWithState {...props} />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            path="/settings/cmps"
                                            role={OWNER}
                                        >
                                            <CustomCmpsPageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            path="/settings/cmps/:id"
                                            role={OWNER}
                                        >
                                            <UpdateCustomCmpPageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            role={OWNER}
                                            path={[
                                                '/settings/consent-profiles',
                                                '/settings/consent-profiles/:id/delete',
                                                '/settings/consent-profiles/create',
                                            ]}
                                        >
                                            <ConsentProfilesPageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            path="/settings/consent-profiles/:id"
                                            role={OWNER}
                                        >
                                            <UpdateConsentProfilePageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            role={OWNER}
                                            path={[
                                                '/settings/login-profiles',
                                                '/settings/login-profiles/:id/delete',
                                                '/settings/login-profiles/create',
                                            ]}
                                        >
                                            <LoginProfilesPageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            path="/settings/login-profiles/:id"
                                            role={OWNER}
                                        >
                                            <UpdateLoginProfilePageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            role={OWNER}
                                            path={[
                                                '/vendors/vendor-tables',
                                                '/vendors/vendor-tables/create',
                                                '/vendors/vendor-tables/:id/delete',
                                            ]}
                                        >
                                            <VendorTablesPageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            path="/vendors/vendor-table-items/:id"
                                            role={OWNER}
                                        >
                                            <VendorTableItemsPageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            role={OWNER}
                                            path={[
                                                '/vendors/cookie-tables',
                                                '/vendors/cookie-tables/create',
                                                '/vendors/cookie-tables/:id/delete',
                                            ]}
                                        >
                                            <CookieTablesPageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            path="/vendors/cookie-table-items/:id"
                                            role={OWNER}
                                        >
                                            <CookieTableItemsPageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            role={OWNER}
                                            path={[
                                                '/settings/vendors',
                                                '/settings/vendors/create',
                                                '/settings/vendors/:id/delete',
                                                '/settings/vendors/:id/update',
                                            ]}
                                        >
                                            <OrganizationVendorsPageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            role={OWNER}
                                            path={[
                                                '/settings/cookies',
                                                '/settings/cookies/create',
                                                '/settings/cookies/:id/delete',
                                                '/settings/cookies/:id/update',
                                            ]}
                                        >
                                            <OrganizationCookiesPageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <HasPermissionRoute
                                            exact
                                            role={OWNER}
                                            path={[
                                                '/settings/trackers',
                                                '/settings/trackers/create',
                                                '/settings/trackers/:id/delete',
                                                '/settings/trackers/:id/update',
                                            ]}
                                        >
                                            <OrganizationTrackersPageWithState
                                                {...props}
                                            />
                                        </HasPermissionRoute>
                                        <Route exact path="/scans">
                                            <ScansPageWithState {...props} />
                                        </Route>
                                        <Route exact path="/invite/:id/accept">
                                            <AcceptInvitationPageWithState
                                                isAuthenticated={
                                                    isAuthenticated
                                                }
                                                {...props}
                                            />
                                        </Route>
                                        <Route path="/">
                                            <AuditsPageWithState {...props} />
                                        </Route>
                                    </Switch>
                                ) : (
                                    <Route path="/">
                                        <MyOrganizationsPageWithState
                                            {...props}
                                        />
                                    </Route>
                                )}
                            </Switch>
                        )}
                    </Switch>
                </DrawerStackProvider>
            </QueryParamProvider>
        </Router>
    )
}

export default AppRouter
