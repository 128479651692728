import React from 'react'
import { Trans } from '@lingui/macro'
import { LANGUAGE_EN, LANGUAGE_NL } from '@clew/shared/src/constants'
import ModalTitle from '../../utils/ModalTitle'
import Modal from '../../utils/Modal'
import FormColorPicker from '../../utils/FormColorPicker'
import FormGroup from '../../utils/FormGroup'
import SelectListOption from '../../utils/SelectListOption'
import SelectList from '../../utils/SelectList'
import FormSelectList from '../../utils/FormSelectList'
import Button from '../../utils/Button'
import FormSelect from '../../utils/FormSelect'
import PurposesSelect from '../../utils/PurposesSelect'
import ModalFooter from '../../utils/ModalFooter'

const CustomizeCookieTableModal = ({
    onDismiss,
    onCompleted,
    font,
    setFont,
    rowSize,
    setRowSize,
    textColor,
    setTextColor,
    linkColor,
    setLinkColor,
    borderColor,
    setBorderColor,
    columnNames,
    selectedColumns,
    handleColumnClick,
    handleEmbedClick,
    handlePreviewClick,
    formState,
}) => {
    const { getNativeInputProps, getErrorMessages, values, handleChange } =
        formState

    return (
        <Modal className="max-w-2xl">
            <ModalTitle
                title={<Trans>Customize layout</Trans>}
                description={
                    <Trans>
                        You can customise the layout of your cookie table below.
                    </Trans>
                }
                iconName="palette"
                iconColor="none"
                iconSize="md"
                onClose={onDismiss}
                onCompleted={onCompleted}
                newModalTitle
            />
            <FormGroup
                label={<Trans>Text Color</Trans>}
                isHorizontal
                className="mb-0"
            >
                <FormColorPicker
                    errors={getErrorMessages('textColor')}
                    {...getNativeInputProps('textColor')}
                    color={textColor}
                    setColor={setTextColor}
                />
            </FormGroup>

            <FormGroup
                label={<Trans>Link Color</Trans>}
                isHorizontal
                className="mb-0"
            >
                <FormColorPicker
                    errors={getErrorMessages('linkColor')}
                    {...getNativeInputProps('linkColor')}
                    color={linkColor}
                    setColor={setLinkColor}
                />
            </FormGroup>
            <FormGroup label={<Trans>Border Color</Trans>} isHorizontal>
                <FormColorPicker
                    errors={getErrorMessages('borderColor')}
                    {...getNativeInputProps('borderColor')}
                    color={borderColor}
                    setColor={setBorderColor}
                />
            </FormGroup>
            <FormGroup
                errors={getErrorMessages('font')}
                {...getNativeInputProps('font')}
                isHorizontal
                label={<Trans>Font</Trans>}
            >
                <FormSelect
                    id="font"
                    className="max-w-md"
                    value={font}
                    onChange={(event) => setFont(event.target.value)}
                >
                    <option key="serif" value="serif">
                        <Trans>Serif</Trans>
                    </option>
                    <option key="sansSerif" value="sansSerif">
                        <Trans>Sans Serif</Trans>
                    </option>
                </FormSelect>
            </FormGroup>
            <FormGroup
                errors={getErrorMessages('rowSize')}
                {...getNativeInputProps('rowSize')}
                isHorizontal
                label={<Trans>Rowsize</Trans>}
            >
                <FormSelect
                    id="rowSize"
                    className="max-w-md"
                    value={rowSize}
                    onChange={(event) => setRowSize(event.target.value)}
                >
                    <option key="small" value="small">
                        <Trans>Small</Trans>
                    </option>
                    <option key="medium" value="medium">
                        <Trans>Medium</Trans>
                    </option>
                    <option key="big" value="big">
                        <Trans>Big</Trans>
                    </option>
                </FormSelect>
            </FormGroup>
            <FormGroup isHorizontal label={<Trans>Language</Trans>}>
                <FormSelect
                    errors={getErrorMessages('language')}
                    id="language"
                    className="max-w-md"
                    {...getNativeInputProps('language')}
                >
                    <option key={LANGUAGE_EN} value={LANGUAGE_EN}>
                        <Trans>English</Trans>
                    </option>
                    <option key={LANGUAGE_NL} value={LANGUAGE_NL}>
                        <Trans>Dutch</Trans>
                    </option>
                    <option key="fr" value="fr">
                        <Trans>French</Trans>
                    </option>
                </FormSelect>
            </FormGroup>
            <FormGroup label={<Trans>Show Column</Trans>} isHorizontal>
                <FormSelectList errors={getErrorMessages('selectedColumns')}>
                    <SelectList>
                        {columnNames.map((name) => {
                            const isSelected = selectedColumns.includes(name)
                            return (
                                <SelectListOption
                                    key={name}
                                    onClick={() => handleColumnClick(name)}
                                    isCheckbox
                                    checked={isSelected}
                                >
                                    {name}
                                </SelectListOption>
                            )
                        })}
                    </SelectList>
                </FormSelectList>
            </FormGroup>
            <FormGroup isHorizontal label={<Trans>Filter Purposes</Trans>}>
                <PurposesSelect
                    value={values.purposeIds}
                    errors={getErrorMessages('purposeIds')}
                    onChange={(id) => handleChange('purposeIds', id)}
                />
            </FormGroup>

            <ModalFooter>
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    iconAfterProps={{
                        name: 'link-external-01',
                        className: 'text-gray-800',
                        size: 'sm',
                    }}
                    type="white"
                    onClick={handlePreviewClick}
                >
                    <Trans>Preview</Trans>
                </Button>
                <Button
                    iconAfterProps={{
                        name: 'code-01',
                        className: 'text-gray-800',
                        size: 'sm',
                    }}
                    type="white"
                    onClick={handleEmbedClick}
                >
                    <Trans>Embed</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default CustomizeCookieTableModal
