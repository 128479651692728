import React, { useState } from 'react'
import useSearch from '../../hooks/useSearch'
import useRefetch from '../../hooks/useRefetch'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import VendorsPage from './VendorsPage'
import useVendorPageQuery from './useVendorPageQuery'
import useFilters from '../../hooks/useFilters'
import VendorsBulkUploadModalWithState from './VendorsBulkUploadModalWithState'
import CreateVendorModalWithState from './CreateVendorModalWithState'

const VendorsPageWithState = () => {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const [vendorTypeFilter, setVendorTypeFilter] = useState()
    const [isCreateVendorModalOpen, setIsCreateVendorModalOpen] =
        useState(false)
    const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false)
    const {
        activeFilters,
        filterValues,
        onRemoveFilter,
        onAddFilter,
        onRemoveAllFilters,
    } = useFilters({
        type: vendorTypeFilter,
        hasNoCookies: true,
        hasNoDescription: true,
        hasNoDomain: true,
        hasNoTrackers: true,
    })
    const [orderBy, onChangeOrderBy] = useState({ occurrence: 'DESC' })
    const {
        vendors,
        error,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useVendorPageQuery({
        search,
        filters: filterValues,
        orderBy,
    })
    useRefetch(refetch)
    return (
        <>
            <VendorsPage
                search={search}
                vendors={vendors}
                isFetchingMore={isFetchingMore}
                hasMoreRecords={hasMore}
                onFetchMore={handleFetchMore}
                onSearch={handleSearch}
                isFetching={isFetching}
                error={error}
                vendorTypeFilter={vendorTypeFilter}
                activeFilters={activeFilters}
                onAddFilter={onAddFilter}
                onRemoveFilter={onRemoveFilter}
                onRemoveAllFilters={onRemoveAllFilters}
                onChangeVendorTypeFilter={(type) => setVendorTypeFilter(type)}
                initialOrderBy={orderBy}
                onChangeOrderBy={(value, direction) =>
                    onChangeOrderBy({ [value]: direction })
                }
                onCreateVendor={() => setIsCreateVendorModalOpen(true)}
                onBulkUpload={() => setIsBulkUploadModalOpen(true)}
                onDeleteVendorCompleted={() => refetch()}
                onUpdateVendorCompleted={() => refetch()}
            />
            {isBulkUploadModalOpen && (
                <VendorsBulkUploadModalWithState
                    onDismiss={() => setIsBulkUploadModalOpen(false)}
                />
            )}
            {isCreateVendorModalOpen && (
                <CreateVendorModalWithState
                    onDismiss={() => setIsCreateVendorModalOpen(false)}
                    onCompleted={() => refetch()}
                />
            )}
        </>
    )
}

export default VendorsPageWithState
