import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableHeaderCell from '../../utils/TableHeaderCell'
import InfiniteScroll from '../../utils/InfiniteScroll'
import StaffJobsPageTableRow from './StaffJobsPageTableRow'

const StaffJobsPageTable = ({
    activeStatus,
    jobs,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords && !isFetching}
    >
        <Table
            data-testid="StaffJobsPageTable"
            isFullWidth
            isFetching={isFetching}
        >
            <thead>
                <tr>
                    <TableHeaderCell>
                        <Trans>Id</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <Trans>Name</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <Trans>Status</Trans>
                    </TableHeaderCell>
                    {activeStatus === 'failed' && (
                        <TableHeaderCell>
                            <Trans>Reason</Trans>
                        </TableHeaderCell>
                    )}
                    {activeStatus === 'repeat' && (
                        <>
                            <TableHeaderCell>
                                <Trans>Pattern</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <Trans>Next</Trans>
                            </TableHeaderCell>
                        </>
                    )}
                    <TableHeaderCell />
                    {(activeStatus === 'failed' ||
                        activeStatus === 'completed') && (
                        <>
                            {' '}
                            <TableHeaderCell>
                                <Trans>Started at</Trans>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <Trans>Finished at</Trans>
                            </TableHeaderCell>
                        </>
                    )}
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {jobs.map((job) => (
                    <StaffJobsPageTableRow
                        key={job.id}
                        activeStatus={activeStatus}
                        job={job}
                    />
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
)

export default StaffJobsPageTable
