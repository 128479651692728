import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import UploadScanModalWithState from '../audits-scans/UploadScanModalWithState'
import useAuditHeaderQuery from './useAuditHeaderQuery'
import AuditHeader from './AuditHeader'

const AuditHeaderWithState = ({
    title,
    description,
    onUploadCompleted,
    onStartScanCompleted,
    onCreateLiveScanCompleted,
}) => {
    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false)
    const history = useHistory()
    const { audit } = useAuditHeaderQuery()
    return (
        <>
            <AuditHeader
                title={title}
                description={description}
                audit={audit}
                onStartScanCompleted={onStartScanCompleted}
                onCreateLiveScanCompleted={onCreateLiveScanCompleted}
                onUploadSession={() => setUploadModalIsOpen(true)}
            />
            {uploadModalIsOpen && (
                <UploadScanModalWithState
                    audit={audit}
                    onDismiss={() => {
                        setUploadModalIsOpen(false)
                    }}
                    onCompleted={() => {
                        onUploadCompleted()
                        history.push(`/audits/${audit.id}/scans`)
                    }}
                />
            )}
        </>
    )
}

export default AuditHeaderWithState
