import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCellAvatar from '../../utils/TableCellAvatar'
import Tooltip from '../../utils/Tooltip'
import TableCell from '../../utils/TableCell'
import Tag from '../../utils/Tag'
import Icon from '../../utils/Icon'

export function AuditRecordMobileCookiesTab({ cookies }) {
    if (!cookies.length > 0) {
        return (
            <DrawerEmptyCard
                title={<Trans>No Cookies</Trans>}
                description={<Trans>No cookies were recorded.</Trans>}
                iconName="cookie"
            />
        )
    }
    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {cookies.map((cookie) => {
                    const { name, value } = cookie
                    return (
                        <TableRow>
                            <TableCellAvatar
                                truncate
                                title={name}
                                avatarIconName="cookie"
                                className="w-2/3"
                                text={cookie.domain}
                            />
                            <TableCell py="sm">
                                <div className="flex justify-end gap-1">
                                    <Tooltip
                                        content={value}
                                        contentClassName="max-w-lg text-wrap"
                                    >
                                        <Tag type="gray">Show value</Tag>
                                    </Tooltip>
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
