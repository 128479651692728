import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import Select from '../../utils/Select'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'
import ButtonLink from '../../utils/ButtonLink'
import SearchInput from '../../utils/SearchInput'
import useSearch from '../../hooks/useSearch'
import IdentifiedVendorAuditObservationTableRow from './IdentifiedVendorAuditObservationTableRow'

export default function IdentifiedVendorDrawerTabObservations({
    identifiedVendorId,
    auditId,
    audits,
    pages = [],
}) {
    const [consentProfileType, setConsentProfileType] = useState('ALL')
    const [search, handleSearch] = useSearch('')
    const [showOtherAudits, setShowOtherAudits] = useState(!auditId)

    if (!audits) {
        return (
            <DrawerEmptyCard
                iconName="scan"
                title={<Trans>No observations</Trans>}
                description={<Trans>There were no observations found.</Trans>}
            />
        )
    }

    let finalPages = pages
    let finalAudits = audits

    if (consentProfileType !== 'ALL') {
        finalPages = pages.filter((page) =>
            page.scanPages.some(
                ({ scan }) => scan.consentProfile.type === consentProfileType
            )
        )
        finalAudits = audits.filter((audit) =>
            finalPages.some(({ auditIds }) => auditIds.includes(audit.id))
        )
    }

    if (search) {
        const lowercasedSearch = search.toLowerCase()
        finalPages = finalPages.filter(
            (page) =>
                page.id.toLowerCase().includes(lowercasedSearch) ||
                page.url?.toLowerCase().includes(lowercasedSearch) ||
                page.scanPages.some(({ scan }) =>
                    scan.id.toLowerCase().includes(lowercasedSearch)
                )
        )
        finalAudits = audits.filter((audit) =>
            finalPages.some(({ auditIds }) => auditIds.includes(audit.id))
        )
    }

    return (
        <>
            <div className="mb-4 flex w-full flex-row gap-4">
                <SearchInput
                    className="w-full"
                    onChange={handleSearch}
                    value={search}
                />
                <Select
                    className="w-[200px]"
                    onChange={(e) => setConsentProfileType(e.target.value)}
                    value={consentProfileType}
                >
                    <option value="ALL">All consent profiles</option>
                    <option value="NO_CONSENT">No consent</option>
                    <option value="ACCEPT_ALL">Accept all</option>
                    <option value="REJECT_ALL">Reject all</option>
                </Select>
            </div>
            <Table hasMinWidth={false}>
                <thead>
                    <TableHeaderRow>
                        <TableHeaderCell />
                        <TableHeaderCell columnId="rootDomain">
                            <Trans>Domain</Trans>
                        </TableHeaderCell>
                    </TableHeaderRow>
                </thead>
                <TableBody>
                    {audits
                        .filter(({ id }) => showOtherAudits || id === auditId)
                        .map((audit) => (
                            <IdentifiedVendorAuditObservationTableRow
                                key={audit.id}
                                identifiedVendorId={identifiedVendorId}
                                audit={audit}
                            />
                        ))}
                </TableBody>
            </Table>
            <div className="mt-8 flex items-center justify-center">
                {auditId && audits.length > 1 && (
                    <ButtonLink
                        onClick={() => setShowOtherAudits(!showOtherAudits)}
                    >
                        {showOtherAudits ? (
                            <Trans>Hide other audits</Trans>
                        ) : (
                            <Trans>Show other audits</Trans>
                        )}
                    </ButtonLink>
                )}
            </div>
        </>
    )
}
