import React from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { SCAN_SOURCE_HAR, SCAN_TRIGGER_LIVE } from '@clew/shared/src/constants'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import Button from '../../utils/Button'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import AlertCountTag from '../../utils/AlertCountTag'
import Tabs, { Tab } from '../../utils/Tabs'
import ScanDetailsDrawerTabPages from './ScanDetailsDrawerTabPages'
import ScanDetailsDrawerTabAlerts from './ScanDetailsDrawerTabAlerts'
import ScanDetailsDrawerTabIdentifiedCookies from './ScanDetailsDrawerTabIdentifiedCookies'
import ScanDetailsDrawerTabIdentifiedTrackers from './ScanDetailsDrawerTabIdentifiedTrackers'
import ScanPageDetailsDrawerTabCookies from './ScanPageDetailsDrawerTabCookies'
import ScanPageDetailsDrawerTabRequests from './ScanPageDetailsDrawerTabRequests'
import ScanDetailsDrawerTabGroupedDataIdentifiers from './ScanDetailsDrawerTabGroupedDataIdentifiers'
import ScanPageActionsDropdownMenu from '../staff-scans/ScanPageActionsDropdownMenu'
import useDrawerStack from '../../hooks/useDrawerStack'
import * as constants from '../../../constants'
import ScanDetailsDrawerTabActions from './ScanDetailsDrawerTabActions'
import ScanDetailsDrawerTabDevice from './ScanDetailsDrawerTabDevice'

const { APPLICATION_LEVEL_AUDIT: AUDIT } = constants

const QUERY = gql`
    query scanDetailsDrawer($id: ID!) {
        scan(id: $id) {
            id
            url
            name
            isWeb
            isMobile
            source
            trigger
            createdAt
            alertsCount
            criticalAlertsCount
            warningAlertsCount
            issueAlertsCount
            requestsCount
            cookiesCount
            audit {
                id
                name
                imageUrl
            }
            consentSettings {
                name
                purposes {
                    id
                    name
                }
            }
            video {
                id
                url
            }
        }
    }
`

export default function ScanDetailsDrawer({
    scanId,
    onDeleteScanCompleted,
    onClose,
    level,
    drawerStackState,
    drawerState,
    setDrawerState,
}) {
    const isComparingStack = drawerStackState.name === 'comparing'
    const { close, isComparing, startComparing } = useDrawerStack()
    const activeTab = drawerState.activeTab || 'pages'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { id: scanId }
    const {
        data = {},
        error,
        loading,
    } = useQuery(QUERY, {
        variables,
    })
    if (loading) {
        return <DrawerLoading />
    }
    if (error) {
        return <DrawerError />
    }
    const {
        audit,
        url,
        name,
        isMobile,
        isWeb,
        source,
        consentSettings,
        createdAt,
        criticalAlertsCount,
        warningAlertsCount,
        issueAlertsCount,
        requestsCount,
        cookiesCount,
        video,
        trigger,
    } = data.scan
    return (
        <>
            <DrawerHeader
                avatarSrc={audit.imageUrl}
                avatarIconName="scan"
                title={url || name}
                afterDescription={
                    <>
                        {consentSettings?.name && (
                            <Tag size="md">{consentSettings.name}</Tag>
                        )}
                    </>
                }
                onClose={onClose}
                description={<DateTime>{createdAt}</DateTime>}
                buttons={
                    <>
                        {!level === AUDIT && (
                            <ScanPageActionsDropdownMenu
                                scan={data.scan}
                                auditId={audit.id}
                                onDeleteScanCompleted={() => {
                                    close()
                                    onDeleteScanCompleted()
                                }}
                            >
                                <Button
                                    type="white"
                                    size="sm"
                                    iconBeforeProps={{
                                        name: 'menu-dots',
                                        size: 'sm',
                                    }}
                                />
                            </ScanPageActionsDropdownMenu>
                        )}
                        {!isComparingStack && !isComparing && (
                            <Button
                                type="white"
                                size="sm"
                                onClick={() => startComparing()}
                            >
                                <Trans>Compare this scan</Trans>
                            </Button>
                        )}
                    </>
                }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'alerts'}
                    onClick={() => setActiveTab('alerts')}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag
                        criticalAlertsCount={criticalAlertsCount}
                        issueAlertsCount={issueAlertsCount}
                        warningAlertsCount={warningAlertsCount}
                    />
                </Tab>

                {isWeb && (
                    <>
                        <Tab
                            isActive={activeTab === 'pages'}
                            onClick={() => setActiveTab('pages')}
                        >
                            <Trans>Pages</Trans>
                        </Tab>
                        <Tab
                            isActive={activeTab === 'identified-cookies'}
                            onClick={() => setActiveTab('identified-cookies')}
                        >
                            <Trans>Cookies</Trans>
                        </Tab>
                        <Tab
                            isActive={activeTab === 'identified-trackers'}
                            onClick={() => setActiveTab('identified-trackers')}
                        >
                            <Trans>Trackers</Trans>
                        </Tab>
                    </>
                )}
                {isMobile && (
                    <>
                        {trigger !== SCAN_TRIGGER_LIVE && (
                            <Tab
                                isActive={activeTab === 'actions'}
                                onClick={() => setActiveTab('actions')}
                            >
                                <Trans>Actions</Trans>
                            </Tab>
                        )}
                        <Tab
                            isActive={activeTab === 'cookies'}
                            onClick={() => setActiveTab('cookies')}
                        >
                            <Trans>Cookies</Trans>
                            <Tag>{cookiesCount}</Tag>
                        </Tab>
                        <Tab
                            isActive={activeTab === 'requests'}
                            onClick={() => setActiveTab('requests')}
                        >
                            <Trans>Requests</Trans>
                            <Tag>{requestsCount}</Tag>
                        </Tab>
                    </>
                )}
                {isMobile && video && (
                    <Tab
                        isActive={activeTab === 'video'}
                        onClick={() => setActiveTab('video')}
                    >
                        <Trans>Video</Trans>
                    </Tab>
                )}
                {isMobile && (
                    <>
                        <Tab
                            isActive={activeTab === 'data-identifiers'}
                            onClick={() => setActiveTab('data-identifiers')}
                        >
                            <Trans>Data flows</Trans>
                            <Tag type="yellow">Beta</Tag>
                        </Tab>
                        {source !== SCAN_SOURCE_HAR && (
                            <Tab
                                isActive={activeTab === 'device'}
                                onClick={() => setActiveTab('device')}
                            >
                                <Trans>Device</Trans>
                            </Tab>
                        )}
                    </>
                )}
            </Tabs>
            <DrawerContent>
                {activeTab === 'pages' && (
                    <ScanDetailsDrawerTabPages scanId={scanId} />
                )}
                {activeTab === 'actions' && (
                    <ScanDetailsDrawerTabActions scanId={scanId} />
                )}
                {activeTab === 'alerts' && (
                    <ScanDetailsDrawerTabAlerts scanId={scanId} />
                )}
                {activeTab === 'identified-cookies' && (
                    <ScanDetailsDrawerTabIdentifiedCookies scanId={scanId} />
                )}
                {activeTab === 'identified-trackers' && (
                    <ScanDetailsDrawerTabIdentifiedTrackers scanId={scanId} />
                )}
                {activeTab === 'cookies' && (
                    <ScanPageDetailsDrawerTabCookies scanId={scanId} />
                )}
                {activeTab === 'requests' && (
                    <ScanPageDetailsDrawerTabRequests scanId={scanId} />
                )}
                {activeTab === 'data-identifiers' && (
                    <ScanDetailsDrawerTabGroupedDataIdentifiers
                        scanId={scanId}
                    />
                )}
                {activeTab === 'video' && (
                    <div>
                        <video
                            src={video.url}
                            controls
                            className="mx-auto w-1/2"
                        />
                    </div>
                )}
                {activeTab === 'device' && (
                    <ScanDetailsDrawerTabDevice scanId={scanId} />
                )}
            </DrawerContent>
        </>
    )
}
