export const COUNTRY_BE = 'BE'
export const COUNTRY_US = 'US'

export const PROCESSING_ACTIVITY_CONTENT_PERFORMANCE = 'CONTENT_PERFORMANCE'
export const PROCESSING_ACTIVITY_AD_PERFORMANCE = 'AD_PERFORMANCE'
export const PROCESSING_ACTIVITY_FREQUENCY_CAPPING = 'FREQUENCY_CAPPING'
export const PROCESSING_ACTIVITY_ADVERTISING_PROFILING = 'ADVERTISING_PROFILING'
export const PROCESSING_ACTIVITY_CONTENT_PROFILING = 'CONTENT_PROFILING'
export const PROCESSING_ACTIVITY_AD_SERVING = 'AD_SERVING'
export const PROCESSING_ACTIVITY_ID_SYNC = 'ID_SYNC'
export const PROCESSING_ACTIVITY_PRODUCT_IMPROVEMENT = 'PRODUCT_IMPROVEMENT'
export const PROCESSING_ACTIVITY_CONTENT_DELIVERY = 'CONTENT_DELIVERY'

export const AVAILABLE_PROCESSING_ACTIVITIES = [
    PROCESSING_ACTIVITY_CONTENT_PERFORMANCE,
    PROCESSING_ACTIVITY_AD_PERFORMANCE,
    PROCESSING_ACTIVITY_FREQUENCY_CAPPING,
    PROCESSING_ACTIVITY_ADVERTISING_PROFILING,
    PROCESSING_ACTIVITY_CONTENT_PROFILING,
    PROCESSING_ACTIVITY_AD_SERVING,
    PROCESSING_ACTIVITY_ID_SYNC,
    PROCESSING_ACTIVITY_PRODUCT_IMPROVEMENT,
    PROCESSING_ACTIVITY_CONTENT_DELIVERY,
]

export const AVAILABLE_COUNTRIES = [COUNTRY_BE, COUNTRY_US]

export const LANGUAGE_NL = 'nl'
export const LANGUAGE_EN = 'en'
export const AVAILABLE_LANGUAGES = [LANGUAGE_NL, LANGUAGE_EN]

export const DATE_FORMAT_EUROPE = 'dd/mm/yyyy'
export const DATE_FORMAT_US = 'mm/dd/yyyy'
export const AVAILABLE_DATE_FORMATS = [DATE_FORMAT_EUROPE, DATE_FORMAT_US]

export const TIME_FORMAT_EUROPE = 'HH:mm'
export const AVAILABLE_TIME_FORMATS = [TIME_FORMAT_EUROPE]

export const TIME_ZONE_EUROPE_BRUSSELS = 'Europe/Brussels'
export const AVAILABLE_TIME_ZONES = [TIME_ZONE_EUROPE_BRUSSELS]

export const DEFAULT_PAGE_SIZE = 25
export const DEFAULT_DATE_FORMAT = DATE_FORMAT_EUROPE
export const DEFAULT_TIME_FORMAT = TIME_FORMAT_EUROPE
export const DEFAULT_TIMEZONE = TIME_ZONE_EUROPE_BRUSSELS
export const DEFAULT_LANGUAGE = LANGUAGE_NL
export const DEFAULT_COUNTRY = COUNTRY_BE

export const USER_TYPE_STAFF = 'STAFF'
export const USER_TYPE_USER = 'USER'
export const AVAILABLE_USER_TYPES = [USER_TYPE_STAFF, USER_TYPE_USER]

export const USER_ROLE_OWNER = 'OWNER'
export const USER_ROLE_MEMBER = 'MEMBER'
export const AVAILABLE_USER_ROLES = [USER_ROLE_OWNER, USER_ROLE_MEMBER]

export const INVITATION_STATUS_PENDING = 'PENDING'
export const INVITATION_STATUS_EXPIRED = 'EXPIRED'
export const INVITATION_STATUS_ACCEPTED = 'ACCEPTED'

export const AUDIT_TYPE_WEB = 'WEB'
export const AUDIT_TYPE_MOBILE = 'MOBILE'
export const AUDIT_TYPE_CODE = 'CODE'
export const AVAILABLE_AUDIT_TYPES = [
    AUDIT_TYPE_WEB,
    AUDIT_TYPE_MOBILE,
    AUDIT_TYPE_CODE,
]

export const SCAN_TYPE_WEB = 'WEB'
export const SCAN_TYPE_MOBILE = 'MOBILE'
export const AVAILABLE_SCAN_TYPES = [SCAN_TYPE_WEB, SCAN_TYPE_MOBILE]

export const SCAN_STATUS_CREATED = 'CREATED'
export const SCAN_STATUS_QUEUED = 'QUEUED'
export const SCAN_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const SCAN_STATUS_COMPLETED = 'COMPLETED'
export const SCAN_STATUS_FAILED = 'FAILED'
export const SCAN_STATUS_STOPPED = 'STOPPED'
export const AVAILABLE_SCAN_STATUSES = [
    SCAN_STATUS_CREATED,
    SCAN_STATUS_QUEUED,
    SCAN_STATUS_IN_PROGRESS,
    SCAN_STATUS_COMPLETED,
    SCAN_STATUS_STOPPED,
    SCAN_STATUS_FAILED,
]

export const SCAN_TRIGGER_AUTOMATED = 'AUTOMATED'
export const SCAN_TRIGGER_MANUAL = 'MANUAL'
export const SCAN_TRIGGER_LIVE = 'LIVE'
export const AVAILABLE_SCAN_TRIGGERS = [
    SCAN_TRIGGER_AUTOMATED,
    SCAN_TRIGGER_MANUAL,
    SCAN_TRIGGER_LIVE,
]

export const SCAN_SOURCE_PUPPETEER = 'PUPPETEER'
export const SCAN_SOURCE_HAR = 'HAR'
export const SCAN_SOURCE_APPETIZE = 'APPETIZE'
export const SCAN_SOURCE_CORELLIUM = 'CORELLIUM'
export const SCAN_SOURCE_BROWSERBASE = 'BROWSERBASE'

export const AVAILABLE_SCAN_SOURCES = [
    SCAN_SOURCE_PUPPETEER,
    SCAN_SOURCE_HAR,
    SCAN_SOURCE_APPETIZE,
    SCAN_SOURCE_CORELLIUM,
    SCAN_SOURCE_BROWSERBASE,
]

export const SESSION_DEVICE_TYPE_ANDROID = 'ANDROID'
export const SESSION_DEVICE_TYPE_IOS = 'IOS'
export const SESSION_DEVICE_TYPE_WEB = 'WEB'
export const SESSION_DEVICE_TYPES = [
    SESSION_DEVICE_TYPE_ANDROID,
    SESSION_DEVICE_TYPE_IOS,
    SESSION_DEVICE_TYPE_WEB,
]

export const SCAN_PAGE_STATUS_CREATED = 'CREATED'
export const SCAN_PAGE_STATUS_QUEUED = 'QUEUED'
export const SCAN_PAGE_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const SCAN_PAGE_STATUS_COMPLETED = 'COMPLETED'
export const SCAN_PAGE_STATUS_FAILED = 'FAILED'
export const AVAILABLE_SCAN_PAGE_STATUSES = [
    SCAN_PAGE_STATUS_CREATED,
    SCAN_PAGE_STATUS_QUEUED,
    SCAN_PAGE_STATUS_IN_PROGRESS,
    SCAN_PAGE_STATUS_COMPLETED,
    SCAN_PAGE_STATUS_FAILED,
]

export const SCAN_DATA_IDENTIFIERS_TYPE_POST_DATA = 'POST_DATA'
export const SCAN_DATA_IDENTIFIERS_TYPE_SEARCH_PARAMS = 'SEARCH_PARAMS'
export const SCAN_DATA_IDENTIFIERS_TYPE_CODE = 'CODE'
export const AVAILABLE_SCAN_DATA_IDENTIFIERS_TYPES = [
    SCAN_DATA_IDENTIFIERS_TYPE_POST_DATA,
    SCAN_DATA_IDENTIFIERS_TYPE_SEARCH_PARAMS,
    SCAN_DATA_IDENTIFIERS_TYPE_CODE,
]

export const COOKIE_TABLE_VERSIONS_STATUS_FINISHED = 'FINISHED'
export const COOKIE_TABLE_VERSIONS_STATUS_COMPARING = 'COMPARING'
export const COOKIE_TABLE_VERSIONS_STATUS_STATUSES = [
    COOKIE_TABLE_VERSIONS_STATUS_FINISHED,
    COOKIE_TABLE_VERSIONS_STATUS_COMPARING,
]

export const COOKIE_TABLE_ITEM_STATUS_ADDED = 'ADDED'
export const COOKIE_TABLE_ITEM_STATUS_DEPRECATED = 'DEPRECATED'
export const COOKIE_TABLE_ITEM_STATUS_STATUSES = [
    COOKIE_TABLE_ITEM_STATUS_ADDED,
    COOKIE_TABLE_ITEM_STATUS_DEPRECATED,
]

export const VENDOR_TABLE_VERSIONS_STATUS_FINISHED = 'FINISHED'
export const VENDOR_TABLE_VERSIONS_STATUS_COMPARING = 'COMPARING'
export const VENDOR_TABLE_VERSIONS_STATUS_STATUSES = [
    VENDOR_TABLE_VERSIONS_STATUS_FINISHED,
    VENDOR_TABLE_VERSIONS_STATUS_COMPARING,
]

export const VENDOR_TABLE_ITEM_STATUS_ADDED = 'ADDED'
export const VENDOR_TABLE_ITEM_STATUS_DEPRECATED = 'DEPRECATED'
export const VENDOR_TABLE_ITEM_STATUS_STATUSES = [
    VENDOR_TABLE_ITEM_STATUS_ADDED,
    VENDOR_TABLE_ITEM_STATUS_DEPRECATED,
]

export const VENDOR_SOURCE_TYPE_TCF = 'TCF'
export const VENDOR_SOURCE_TYPE_ATP = 'ATP'
export const AVAILABLE_VENDOR_SOURCE_TYPES = [
    VENDOR_SOURCE_TYPE_TCF,
    VENDOR_SOURCE_TYPE_ATP,
]

export const REPORT_STATUS_CREATED = 'CREATED'
export const REPORT_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const REPORT_STATUS_COMPLETED = 'COMPLETED'
export const REPORT_STATUS_FAILED = 'FAILED'
export const AVAILABLE_REPORT_STATUSES = [
    REPORT_STATUS_CREATED,
    REPORT_STATUS_IN_PROGRESS,
    REPORT_STATUS_COMPLETED,
    REPORT_STATUS_FAILED,
]

export const REPORT_TYPE_IDENTIFIED_COOKIES = 'IDENTIFIED_COOKIES'
export const REPORT_TYPE_IDENTIFIED_VENDORS = 'IDENTIFIED_VENDORS'
export const REPORT_TYPE_AUDITS_COOKIE_TABLE = 'AUDITS_COOKIE_TABLE'
export const REPORT_TYPE_AUDITS_VENDOR_TABLE = 'AUDITS_VENDOR_TABLE'
export const REPORT_TYPE_ALERT = 'ALERT'
export const REPORT_TYPE_SCAN = 'SCAN'
export const REPORT_TYPE_SCAN_COOKIES = 'SCAN_COOKIES'
export const REPORT_TYPE_SCAN_TRACKERS = 'SCAN_TRACKERS'
export const AVAILABLE_REPORT_TYPES = [
    REPORT_TYPE_ALERT,
    REPORT_TYPE_AUDITS_COOKIE_TABLE,
    REPORT_TYPE_AUDITS_VENDOR_TABLE,
    REPORT_TYPE_SCAN,
    REPORT_TYPE_SCAN_COOKIES,
    REPORT_TYPE_SCAN_TRACKERS,
    REPORT_TYPE_IDENTIFIED_COOKIES,
    REPORT_TYPE_IDENTIFIED_VENDORS,
]

export const AUDIT_SCAN_FREQUENCY_DISABLED = 'DISABLED'
export const AUDIT_SCAN_FREQUENCY_DAILY = 'DAILY'
export const AUDIT_SCAN_FREQUENCY_WEEKLY = 'WEEKLY'
export const AUDIT_SCAN_FREQUENCY_MONTHLY = 'MONTHLY'
export const AVAILABLE_AUDIT_SCAN_FREQUENCIES = [
    AUDIT_SCAN_FREQUENCY_DISABLED,
    AUDIT_SCAN_FREQUENCY_DAILY,
    AUDIT_SCAN_FREQUENCY_WEEKLY,
    AUDIT_SCAN_FREQUENCY_MONTHLY,
]

export const DOMAIN_CONSENT_RESOLVER_CSS = 'CSS'
export const DOMAIN_CONSENT_RESOLVER_RUNTIME = 'RUNTIME'
export const DOMAIN_CONSENT_RESOLVER_COOKIE = 'COOKIE'
export const AVAILABLE_DOMAIN_CONSENT_RESOLVERS = [
    DOMAIN_CONSENT_RESOLVER_CSS,
    DOMAIN_CONSENT_RESOLVER_RUNTIME,
    DOMAIN_CONSENT_RESOLVER_COOKIE,
]

export const CONSENT_PROFILE_ACCEPT_ALL = 'accept-all'
export const CONSENT_PROFILE_REJECT_ALL = 'reject-all'
export const CONSENT_PROFILE_CUSTOM = 'custom'

export const COOKIE_TYPE_COOKIE = 'COOKIE'
export const COOKIE_TYPE_LOCAL_STORAGE = 'LOCAL_STORAGE'
export const AVAILABLE_COOKIE_TYPES = [
    COOKIE_TYPE_COOKIE,
    COOKIE_TYPE_LOCAL_STORAGE,
]

export const TRACKER_RESOURCE_TYPE_SCRIPT = 'Script'
export const TRACKER_RESOURCE_TYPE_STYLESHEET = 'Stylesheet'
export const TRACKER_RESOURCE_TYPE_DOCUMENT = 'Document'
export const TRACKER_RESOURCE_TYPE_REDIRECT = 'REDIRECT'
export const TRACKER_RESOURCE_TYPE_IMAGE = 'Image'
export const TRACKER_RESOURCE_TYPE_FONT = 'Font'
export const TRACKER_RESOURCE_TYPE_PING = 'Ping'
export const TRACKER_RESOURCE_TYPE_XHR = 'XHR'
export const TRACKER_RESOURCE_TYPE_OTHER = 'Other'
export const AVAILABLE_TRACKER_RESOURCE_TYPES = [
    TRACKER_RESOURCE_TYPE_SCRIPT,
    TRACKER_RESOURCE_TYPE_STYLESHEET,
    TRACKER_RESOURCE_TYPE_DOCUMENT,
    TRACKER_RESOURCE_TYPE_REDIRECT,
    TRACKER_RESOURCE_TYPE_IMAGE,
    TRACKER_RESOURCE_TYPE_FONT,
    TRACKER_RESOURCE_TYPE_PING,
    TRACKER_RESOURCE_TYPE_XHR,
    TRACKER_RESOURCE_TYPE_OTHER,
]

export const IDENTIFIED_PERIOD_LAST_DAY = 'LAST_DAY'
export const IDENTIFIED_PERIOD_LAST_7_DAYS = 'LAST_7_DAYS'
export const IDENTIFIED_PERIOD_LAST_14_DAYS = 'LAST_14_DAYS'
export const IDENTIFIED_PERIOD_LAST_30_DAYS = 'LAST_30_DAYS'
export const IDENTIFIED_PERIOD_THIS_YEAR = 'THIS_YEAR'
export const IDENTIFIED_PERIOD_DAY_SUMMARY = 'DAY_SUMMARY'
export const IDENTIFIED_PERIOD_YEAR_SUMMARY = 'YEAR_SUMMARY'
export const IDENTIFIED_PERIOD_MONTH_SUMMARY = 'MONTH_SUMMARY'
export const IDENTIFIED_PERIOD_CUSTOM = 'CUSTOM'
export const AVAILABLE_IDENTIFIED_PERIODS = [
    IDENTIFIED_PERIOD_LAST_DAY,
    IDENTIFIED_PERIOD_LAST_7_DAYS,
    IDENTIFIED_PERIOD_LAST_14_DAYS,
    IDENTIFIED_PERIOD_LAST_30_DAYS,
    IDENTIFIED_PERIOD_THIS_YEAR,
    IDENTIFIED_PERIOD_YEAR_SUMMARY,
    IDENTIFIED_PERIOD_MONTH_SUMMARY,
    IDENTIFIED_PERIOD_DAY_SUMMARY,
    IDENTIFIED_PERIOD_CUSTOM,
]

export const IDENTIFIED_PERIOD_LAST_30_DAYS_OPTION = 'last-30'
export const IDENTIFIED_PERIOD_LAST_14_DAYS_OPTION = 'last-14'
export const IDENTIFIED_PERIOD_LAST_7_DAYS_OPTION = 'last-7'
export const IDENTIFIED_PERIOD_LAST_DAY_OPTION = 'last-day'
export const IDENTIFIED_PERIOD_DAY_OPTION = 'day'
export const IDENTIFIED_PERIOD_MONTH_OPTION = 'month'
export const IDENTIFIED_PERIOD_CUSTOM_OPTION = 'period'

export const IDENTIFIED_PERIODS = {
    [IDENTIFIED_PERIOD_LAST_DAY_OPTION]: IDENTIFIED_PERIOD_LAST_DAY,
    [IDENTIFIED_PERIOD_LAST_7_DAYS_OPTION]: IDENTIFIED_PERIOD_LAST_7_DAYS,
    [IDENTIFIED_PERIOD_LAST_14_DAYS_OPTION]: IDENTIFIED_PERIOD_LAST_14_DAYS,
    [IDENTIFIED_PERIOD_LAST_30_DAYS_OPTION]: IDENTIFIED_PERIOD_LAST_30_DAYS,
    [IDENTIFIED_PERIOD_DAY_OPTION]: IDENTIFIED_PERIOD_DAY_SUMMARY,
    [IDENTIFIED_PERIOD_MONTH_OPTION]: IDENTIFIED_PERIOD_MONTH_SUMMARY,
    [IDENTIFIED_PERIOD_CUSTOM_OPTION]: IDENTIFIED_PERIOD_CUSTOM,
}

export const USER_STATUS_NEW = 'NEW'
export const USER_STATUS_ACTIVE = 'ACTIVE'

export const REQUEST_PASSWORD_TYPE_RESET = 'RESET'
export const REQUEST_PASSWORD_TYPE_INVITE = 'INVITE'

export const ORDER_BY_ASC = 'ASC'
export const ORDER_BY_DESC = 'DESC'

export const PROGRAMMING_LANGUAGE_ALL = 'ALL'
export const PROGRAMMING_LANGUAGE_JAVASCRIPT = 'JS'
export const PROGRAMMING_LANGUAGE_TYPESCRIPT = 'TS'
export const PROGRAMMING_LANGUAGE_CSHARP = 'CSHARP'
export const PROGRAMMING_LANGUAGE_GO = 'GO'
export const PROGRAMMING_LANGUAGE_JAVA = 'JAVA'
export const PROGRAMMING_LANGUAGE_KOTLIN = 'KOTLIN'
export const PROGRAMMING_LANGUAGE_PHP = 'PHP'
export const PROGRAMMING_LANGUAGE_PYTHON = 'PYTHON'
export const PROGRAMMING_LANGUAGE_RUBY = 'RUBY'
export const AVAILABLE_PROGRAMMING_LANGUAGES = [
    PROGRAMMING_LANGUAGE_ALL,
    PROGRAMMING_LANGUAGE_JAVASCRIPT,
    PROGRAMMING_LANGUAGE_TYPESCRIPT,
    PROGRAMMING_LANGUAGE_CSHARP,
    PROGRAMMING_LANGUAGE_GO,
    PROGRAMMING_LANGUAGE_JAVA,
    PROGRAMMING_LANGUAGE_KOTLIN,
    PROGRAMMING_LANGUAGE_PHP,
    PROGRAMMING_LANGUAGE_PYTHON,
    PROGRAMMING_LANGUAGE_RUBY,
]

export const DATA_SINK_TYPE_STORAGE = 'STORAGE'
export const DATA_SINK_TYPE_LOGS = 'LOGS'
export const DATA_SINK_TYPE_VENDOR = 'VENDOR'
export const DATA_SINK_TYPE_OTHER = 'OTHER'
export const AVAILABLE_DATA_SINK_TYPES = [
    DATA_SINK_TYPE_STORAGE,
    DATA_SINK_TYPE_LOGS,
    DATA_SINK_TYPE_VENDOR,
    DATA_SINK_TYPE_OTHER,
]

export const WAIT_FOR_CSS_SELECTOR = 'WAIT_FOR_CSS_SELECTOR'
export const WAIT_FOR_XPATH_SELECTOR = 'WAIT_FOR_XPATH_SELECTOR'
export const WAIT_FOR_NAVIGATION = 'WAIT_FOR_NAVIGATION'
export const CLICK_CSS_SELECTOR = 'CLICK_CSS_SELECTOR'
export const CLICK_XPATH_SELECTOR = 'CLICK_XPATH_SELECTOR'
export const WAIT_FOR_COOKIE_NAME = 'WAIT_FOR_COOKIE_NAME'
export const SELECT_FRAME = 'SELECT_FRAME'
export const ENTER_EMAIL = 'ENTER_EMAIL'
export const ENTER_PASSWORD = 'ENTER_PASSWORD'

export const EXTERNAL_ID_NOT_FOUND = 'EXTERNAL_ID_NOT_FOUND'
export const BULK_UPLOAD_VALIDATION_WARNINGS = [EXTERNAL_ID_NOT_FOUND]
export const MISSING_FIELD = 'MISSING_FIELD'
export const INVALID_FIELD = 'INVALID_FIELD'
export const BULK_UPLOAD_VALIDATION_ERRORS = [MISSING_FIELD, INVALID_FIELD]

export const FOCUS_NEW_TAB = 'FOCUS_NEW_TAB'
export const RUN_CONSOLE_COMMANDS = 'RUN_CONSOLE_COMMANDS'

export const DEVICE_OS_ANDROID = 'ANDROID'
export const DEVICE_OS_IOS = 'IOS'

export const AVAILABLE_DEVICE_OSS = [DEVICE_OS_ANDROID, DEVICE_OS_IOS]
export const READABLE_DEVICE_OSS = {
    [DEVICE_OS_ANDROID]: 'Android',
    [DEVICE_OS_IOS]: 'iOS',
}

export const DATA_IDENTIFIER_TYPE_PII = 'PII'
export const DATA_IDENTIFIER_TYPE_ID = 'ID'
export const DATA_IDENTIFIER_TYPE_SENSITIVE_DATA = 'SENSITIVE_DATA'
export const DATA_IDENTIFIER_TYPE_OTHER = 'OTHER'
export const AVAILABLE_DATA_IDENTIFIER_TYPES = [
    DATA_IDENTIFIER_TYPE_PII,
    DATA_IDENTIFIER_TYPE_ID,
    DATA_IDENTIFIER_TYPE_SENSITIVE_DATA,
    DATA_IDENTIFIER_TYPE_OTHER,
]

export const DATA_SENSITIVITY_LOW = 'LOW'
export const DATA_SENSITIVITY_MEDIUM = 'MEDIUM'
export const DATA_SENSITIVITY_HIGH = 'HIGH'
export const AVAILABLE_DATA_SENSITIVITY = [
    DATA_SENSITIVITY_LOW,
    DATA_SENSITIVITY_MEDIUM,
    DATA_SENSITIVITY_HIGH,
]

export const VENDOR_TYPE_ADEX = 'ADEX'
export const VENDOR_TYPE_AD_SERVER = 'AD_SERVER'
export const VENDOR_TYPE_AD_SERVER_ADVERTISER = 'AD_SERVER_ADVERTISER'
export const VENDOR_TYPE_AD_NETWORK = 'AD_NETWORK'
export const VENDOR_TYPE_AD_CDN = 'AD_CDN'
export const VENDOR_TYPE_AD_ANALYTICS = 'AD_ANALYTICS'
export const VENDOR_TYPE_AD_SAFETY = 'AD_SAFETY'
export const VENDOR_TYPE_DMP = 'DMP'
export const VENDOR_TYPE_DSP = 'DSP'
export const VENDOR_TYPE_SSP = 'SSP'
export const VENDOR_TYPE_VAST_PROVIDER = 'VAST_PROVIDER'
export const VENDOR_TYPE_DATA_PROVIDER = 'DATA_PROVIDER'
export const AVAILABLE_VENDOR_TYPES = [
    VENDOR_TYPE_ADEX,
    VENDOR_TYPE_AD_SERVER,
    VENDOR_TYPE_AD_SERVER_ADVERTISER,
    VENDOR_TYPE_AD_NETWORK,
    VENDOR_TYPE_DMP,
    VENDOR_TYPE_DSP,
    VENDOR_TYPE_SSP,
    VENDOR_TYPE_AD_CDN,
    VENDOR_TYPE_VAST_PROVIDER,
    VENDOR_TYPE_AD_ANALYTICS,
    VENDOR_TYPE_AD_SAFETY,
    VENDOR_TYPE_DATA_PROVIDER,
]

export const CRITICAL_VENDOR_TYPES = [
    VENDOR_TYPE_AD_SERVER,
    VENDOR_TYPE_SSP,
    VENDOR_TYPE_DMP,
    VENDOR_TYPE_AD_ANALYTICS,
]

export const ALERT_DELEGATION_TYPE_INTERNAL = 'DELEGATE_INTERNAL'
export const ALERT_DELEGATION_TYPE_EXTERNAL = 'DELEGATE_EXTERNAL'
export const ALERT_DELEGATION_TYPE_CLEAR = 'DELEGATE_CLEAR'
export const ALERT_DELEGATION_TYPES = [
    ALERT_DELEGATION_TYPE_INTERNAL,
    ALERT_DELEGATION_TYPE_EXTERNAL,
    ALERT_DELEGATION_TYPE_CLEAR,
]

export const LOGIN_PROFILE_CUSTOM = 'CUSTOM'
export const AVAILABLE_LOGIN_PROFILES = [LOGIN_PROFILE_CUSTOM]

export const ALERT_TYPE_COOKIE_EXCEEDS_MAX_RETENTION =
    'COOKIE_EXCEEDS_MAX_RETENTION'
export const ALERT_TYPE_COOKIE_NO_CONSENT = 'ALERT_TYPE_COOKIE_NO_CONSENT'
export const ALERT_TYPE_TRACKER_NO_CONSENT = 'ALERT_TYPE_TRACKER_NO_CONSENT'
export const ALERT_TYPE_TRACKER_CONSENTLESS_ADVERTISING =
    'ALERT_TYPE_TRACKER_CONSENTLESS_ADVERTISING'
export const ALERT_TYPE_VENDOR_NOT_IN_VENDOR_TABLE =
    'ALERT_TYPE_VENDOR_NOT_IN_VENDOR_TABLE'
export const ALERT_TYPE_COOKIE_NOT_IN_COOKIE_TABLE =
    'ALERT_TYPE_COOKIE_NOT_IN_COOKIE_TABLE'
export const AVAILABLE_ALERT_TYPES = [
    ALERT_TYPE_COOKIE_EXCEEDS_MAX_RETENTION,
    ALERT_TYPE_COOKIE_NO_CONSENT,
    ALERT_TYPE_TRACKER_NO_CONSENT,
    ALERT_TYPE_TRACKER_CONSENTLESS_ADVERTISING,
    ALERT_TYPE_VENDOR_NOT_IN_VENDOR_TABLE,
    ALERT_TYPE_COOKIE_NOT_IN_COOKIE_TABLE,
]

export const MONGO_COLLECTION_VENDORS = 'Vendors'
export const MONGO_COLLECTION_VENDOR_TABLE_ITEMS = 'VendorsTableItems'
export const MONGO_COLLECTION_VENDOR_TABLE_VERSIONS = 'VendorsTablesVersions'
export const MONGO_COLLECTION_VENDOR_TABLES = 'VendorsTables'
export const MONGO_COLLECTION_COOKIE_TABLE_ITEMS = 'CookieTableItems'
export const MONGO_COLLECTION_COOKIE_TABLE_VERSIONS = 'CookieTableVersions'
export const MONGO_COLLECTION_COOKIE_TABLES = 'CookieTables'
export const MONGO_COLLECTION_TRACKERS = 'Trackers'
export const MONGO_COLLECTION_ORGANIZATIONS = 'Organizations'
export const MONGO_COLLECTION_USERS = 'Users'
export const MONGO_COLLECTION_REFRESH_TOKENS = 'RefreshToken'
export const MONGO_COLLECTION_INVITATIONS = 'Invitations'
export const MONGO_COLLECTION_AUDITS = 'Audits'
export const MONGO_COLLECTION_DOMAINS = 'Domains'
export const MONGO_COLLECTION_MOBILE_APPS = 'MobileApps'
export const MONGO_COLLECTION_SCANS = 'Scans'
export const MONGO_COLLECTION_SESSIONS = 'Sessions'
export const MONGO_COLLECTION_SCAN_PAGES = 'ScanPages'
export const MONGO_COLLECTION_SCAN_REQUESTS = 'ScanRequests'
export const MONGO_COLLECTION_SCAN_COOKIES = 'ScanCookies'
export const MONGO_COLLECTION_REPORTS = 'Report'
export const MONGO_COLLECTION_FILES = 'Files'
export const MONGO_COLLECTION_COOKIES = 'Cookies'
export const MONGO_COLLECTION_ALERTS = 'Alerts'
export const MONGO_COLLECTION_CMPS = 'Cmps'
export const MONGO_COLLECTION_CONSENT_PROFILES = 'ConsentProfiles'
export const MONGO_COLLECTION_LOGIN_PROFILES = 'LoginProfiles'
export const MONGO_COLLECTION_PURPOSES = 'Purposes'
export const MONGO_COLLECTION_SCAN_BATCHES = 'ScanBatches'
export const MONGO_COLLECTION_IDENTIFIED_COOKIES = 'IdentifiedCookies'
export const MONGO_COLLECTION_IDENTIFIED_COOKIE_PERIODS =
    'IdentifiedCookiePeriods'
export const MONGO_COLLECTION_IDENTIFIED_TRACKERS = 'IdentifiedTrackers'
export const MONGO_COLLECTION_IDENTIFIED_TRACKER_PERIODS =
    'IdentifiedTrackerPeriods'
export const MONGO_COLLECTION_IDENTIFIED_VENDORS = 'IdentifiedVendors'
export const MONGO_COLLECTION_IDENTIFIED_VENDOR_PERIODS =
    'IdentifiedVendorPeriods'
export const MONGO_COLLECTION_ALERT_COMMENTS = 'AlertComments'
export const MONGO_COLLECTION_ALERT_DELEGATIONS = 'AlertDelegations'
export const MONGO_COLLECTION_AUDIT_BUILDS = 'AuditBuilds'
export const MONGO_COLLECTION_AUDIT_SCENARIOS = 'AuditScenarios'
export const MONGO_COLLECTION_DATA_IDENTIFIERS = 'DataIdentifiers'
export const MONGO_COLLECTION_DATA_SINKS = 'DataSinks'
export const MONGO_COLLECTION_SCAN_DATA_IDENTIFIERS = 'ScanDataIdentifiers'
export const MONGO_COLLECTION_SCAN_DATA_SINKS = 'ScanDataSinks'
export const MONGO_COLLECTION_SCAN_ACTIONS = 'ScanActions'

export const PROXY_TYPE_RESIDENTIAL = 'RESIDENTIAL'
export const PROXY_TYPE_DATACENTER = 'DATACENTER'
export const PROXY_TYPE_ISP = 'ISP'
export const PROXY_TYPE_MOBILE = 'MOBILE'
export const PROXY_TYPES = [
    PROXY_TYPE_RESIDENTIAL,
    PROXY_TYPE_DATACENTER,
    PROXY_TYPE_ISP,
    PROXY_TYPE_MOBILE,
]
