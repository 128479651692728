import clsx from 'clsx'
import React from 'react'
import Icon from './Icon'

const EmptyCard = ({
    iconName,
    iconColor = 'primary',
    description,
    className,
}) => (
    <div
        className={clsx(
            'flex h-full flex-col items-center justify-center px-4 text-center',
            className
        )}
    >
        {iconName && <Icon name={iconName} color={iconColor} size="lg" />}
        {description && (
            <p className="mt-4 max-w-sm text-sm leading-5 text-slate-500">
                {description}
            </p>
        )}
    </div>
)

export default EmptyCard
