import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import PageHeader from '../../layout/PageHeader'
import OrganizationHeader from '../../layout/OrganizationHeader'
import CookieTableItemsPageTable from './CookieTableItemsPageTable'
import SidebarContainer from '../../layout/SidebarContainer'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'
import CookieTableItemsPageTableActions from './CookieTableItemsPageTableActions'
import TableStateProvider from '../../providers/TableStateProvider'
import AvatarGroup from '../../utils/AvatarGroup'
import UpdateCookieTableDrawer from './UpdateCookieTableDrawer'
import CreateCookieTableVersionModalWithState from './CreateCookieTableVersionModalWithState'
import useDrawerStack from '../../hooks/useDrawerStack'
import CustomizeCookieTableModalWithState from './CustomizeCookieTableModalWithState'
import CookieTableItemsPageMenuDotsButton from './CookieTableItemsPageMenuDotsButton'
import EmbedCookieTableModal from './EmbedCookieTableModal'

const CookieTableItemsPage = ({
    cookieTable,
    cookieTableItems = [],
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    search,
    onSearch,
    initialOrderBy,
    onChangeOrderBy,
    onUpdateCookieTableCompleted,
    onCreateCookieTableVersionCompleted,
    onUpdateCookieTableItemsCompleted,
    onDeleteCookieTableCompleted,
}) => {
    const { open, close } = useDrawerStack()
    const { NEXT_PUBLIC_API_URL } = process.env
    const [
        isCreateCookieTableVersionModalOpen,
        setCreateCookieTableVersionModalOpen,
    ] = useState(false)
    const [isCustomizeCookieTableModalOpen, setCustomizeCookieTableModalOpen] =
        useState(false)
    const [isEmbedCookieTableModalOpen, setEmbedCookieTableModalOpen] =
        useState(false)
    const auditImageUrls =
        cookieTable?.latestVersion?.audits?.map((audit) => audit.imageUrl) || []
    const auditIds =
        cookieTable?.latestVersion?.audits?.map((audit) => audit.id) || []

    return (
        <>
            {isCustomizeCookieTableModalOpen && (
                <CustomizeCookieTableModalWithState
                    onDismiss={() => setCustomizeCookieTableModalOpen(false)}
                    onCompleted={() => {
                        setCustomizeCookieTableModalOpen(false)
                    }}
                />
            )}
            {isEmbedCookieTableModalOpen && (
                <EmbedCookieTableModal
                    link={`${NEXT_PUBLIC_API_URL}/cookie-table/${cookieTable.id}`}
                    onDismiss={() => setEmbedCookieTableModalOpen(false)}
                    onCompleted={() => {
                        setEmbedCookieTableModalOpen(false)
                    }}
                />
            )}
            {isCreateCookieTableVersionModalOpen && (
                <CreateCookieTableVersionModalWithState
                    onDismiss={() =>
                        setCreateCookieTableVersionModalOpen(false)
                    }
                    cookieTable={cookieTable}
                    onCompleted={() => {
                        setCreateCookieTableVersionModalOpen(false)
                        onCreateCookieTableVersionCompleted()
                    }}
                />
            )}
            <PageContainer data-testid="CookieTableItemsPage">
                <OrganizationHeader />
                <SidebarContainer>
                    <IdentifiedVendorsPageSidebar />
                    <PageContent size="full">
                        <PageHeader
                            avatar={<AvatarGroup imageUrls={auditImageUrls} />}
                            title={cookieTable?.name}
                            description={cookieTable?.latestVersion?.name}
                            rightChildren={
                                <div className="flex gap-2">
                                    <CookieTableItemsPageMenuDotsButton
                                        cookieTable={cookieTable}
                                        onDeleteCompleted={
                                            onDeleteCookieTableCompleted
                                        }
                                    />
                                    <Button
                                        className="h-[40px]"
                                        type="white"
                                        onClick={() =>
                                            open(UpdateCookieTableDrawer, {
                                                cookieTableId: cookieTable?.id,
                                                onDismiss: () => close(),
                                                onCreateNewVersion: () =>
                                                    setCreateCookieTableVersionModalOpen(
                                                        true
                                                    ),
                                                onCustomizeCookieTable: () =>
                                                    setCustomizeCookieTableModalOpen(
                                                        true
                                                    ),
                                                onEmbedCookieTable: () => {
                                                    setEmbedCookieTableModalOpen(
                                                        true
                                                    )
                                                },
                                                onCompleted:
                                                    onUpdateCookieTableCompleted,
                                            })
                                        }
                                        iconBeforeProps={{
                                            name: 'pencil-02',
                                            size: 'sm',
                                        }}
                                    >
                                        <Trans>Edit</Trans>
                                    </Button>
                                    <Button
                                        className="h-fit"
                                        type="primary"
                                        onClick={() =>
                                            setCreateCookieTableVersionModalOpen(
                                                true
                                            )
                                        }
                                        iconBeforeProps={{
                                            name: 'plus',
                                            size: 'sm',
                                        }}
                                    >
                                        <Trans>Create new version</Trans>
                                    </Button>
                                </div>
                            }
                        />
                        {(() => {
                            return (
                                <TableStateProvider
                                    initialOrderBy={initialOrderBy}
                                    onChangeOrderBy={onChangeOrderBy}
                                >
                                    <CookieTableItemsPageTableActions
                                        search={search}
                                        onSearch={onSearch}
                                        onCompleted={
                                            onUpdateCookieTableItemsCompleted
                                        }
                                    />
                                    <CookieTableItemsPageTable
                                        auditIds={auditIds}
                                        cookieTableItems={cookieTableItems}
                                        isFetching={isFetching}
                                        isFetchingMore={isFetchingMore}
                                        hasMoreRecords={hasMoreRecords}
                                        onFetchMore={onFetchMore}
                                        onUpdateCookieTableItemsCompleted={
                                            onUpdateCookieTableItemsCompleted
                                        }
                                    />
                                </TableStateProvider>
                            )
                        })()}
                    </PageContent>
                </SidebarContainer>
            </PageContainer>
        </>
    )
}

export default CookieTableItemsPage
