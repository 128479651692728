import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import DrawerContent from '../../utils/DrawerContent'
import Button from '../../utils/Button'
import CreateTrackerDetailsDrawerPurposeIdentifiersModalWithState from './CreateTrackerDetailsDrawerPurposeIdentifiersModalWithState'
import TrackerDetailsDrawerPurposeIdentifiersTabTable from './TrackerDetailsDrawerPurposeIdentifiersTabTable'
// import DrawerError from '../../utils/DrawerError'

const QUERY = gql`
    query trackerDetailsDrawerPurposeIdentifiersTab($id: ID!) {
        tracker(id: $id) {
            id
            purposeIdentifiers {
                id
                purposes {
                    id
                    name
                }
                pattern
                examples
                processingActivities
            }
        }
    }
`

export default function TrackerDetailsDrawerPurposeIdentifiersTab({
    trackerId,
}) {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { data = {}, refetch } = useQuery(QUERY, {
        variables: { id: trackerId },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const purposeIdentifiers = data?.tracker?.purposeIdentifiers || []

    return (
        <DrawerContent className="p-4">
            <div className="mb-4 flex items-center justify-between">
                <Button
                    className="ml-auto"
                    type="primary"
                    size="sm"
                    onClick={() => setIsModalOpen(true)}
                >
                    <Trans>Add Purpose Identifier</Trans>
                </Button>
            </div>
            {isModalOpen && (
                <CreateTrackerDetailsDrawerPurposeIdentifiersModalWithState
                    trackerId={trackerId}
                    onDismiss={() => setIsModalOpen(false)}
                    onCompleted={() => {
                        refetch()
                    }}
                />
            )}
            <TrackerDetailsDrawerPurposeIdentifiersTabTable
                trackerId={trackerId}
                purposeIdentifiers={purposeIdentifiers}
                onUpdatePurposeIdentifierCompleted={() => refetch()}
                onDeletePurposeIdentifierCompleted={() => refetch()}
            />
        </DrawerContent>
    )
}
