import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import CustomizeVendorTableModal from './CustomizeVendorTableModal'
import useCustomizeVendorTableFormState from './useCustomizeVendorTableFormState'
import EmbedVendorTableModal from './EmbedVendorTableModal'
import useCustomizeVendorTableModalPurposesQuery from './useCustomizeVendorTableModalQuery'
import ModalLoading from '../../utils/ModalLoading'

const columnMap = {
    vendorName: 'Vendor Name',
    domain: 'Domain',
    iabPurposes: 'IAB Purposes',
    purposes: 'Purpose(s)',
    description: 'Description',
    privacyPolicy: 'Privacy Policy Link',
}

const reverseColumnMap = Object.fromEntries(
    Object.entries(columnMap).map(([key, value]) => [value, key])
)

export default function CustomizeVendorTableModalWithState({
    onDismiss,
    onCompleted,
}) {
    const { NEXT_PUBLIC_API_URL } = process.env
    const { id } = useParams()
    const [step, setStep] = useState(1)

    const [textColor, setTextColor] = useState('#000000')
    const [linkColor, setLinkColor] = useState('#444CE7')
    const [borderColor, setBorderColor] = useState('#CCCCCC')
    const [font, setFont] = useState('sansSerif')
    const [rowSize, setRowSize] = useState('small')
    const [selectedColumns, setSelectedColumns] = useState(
        Object.keys(columnMap)
    )
    const formState = useCustomizeVendorTableFormState({
        textColor,
        linkColor,
        borderColor,
        selectedColumns,
    })

    const { data, isFetching } = useCustomizeVendorTableModalPurposesQuery({
        onCompleted: (fetchedData) => {
            const purposeIds = fetchedData.purposes?.map((p) => p.id) ?? []
            return formState.setValues({ purposeIds })
        },
    })
    const handleColumnClick = (displayName) => {
        const internalName = reverseColumnMap[displayName]
        setSelectedColumns((prevSelected) =>
            prevSelected.includes(internalName)
                ? prevSelected.filter((col) => col !== internalName)
                : [...prevSelected, internalName]
        )
    }

    const getTableLink = () => {
        const excludedColumns = Object.keys(columnMap).filter(
            (name) => !selectedColumns.includes(name)
        )

        const cleanColor = (color) => color.replace('#', '')

        const params = new URLSearchParams({
            text_color: cleanColor(textColor),
            link_color: cleanColor(linkColor),
            border_color: cleanColor(borderColor),
            font,
            row_size: rowSize,
            excluded_columns: excludedColumns.join(','),
            purpose_ids: formState.values.purposeIds,
            language: formState.values.language,
        })

        return `${NEXT_PUBLIC_API_URL}/vendor-table/${id}?${params.toString()}`
    }

    const handlePreviewClick = () => window.open(getTableLink(), '_blank')
    const handleEmbedClick = () => setStep(2)
    const displayColumnNames = Object.values(columnMap)

    if (isFetching) {
        return <ModalLoading />
    }

    return (
        <>
            {step === 1 && (
                <CustomizeVendorTableModal
                    onDismiss={onDismiss}
                    onCompleted={onCompleted}
                    font={font}
                    setFont={setFont}
                    rowSize={rowSize}
                    setRowSize={setRowSize}
                    setTextColor={setTextColor}
                    textColor={textColor}
                    setLinkColor={setLinkColor}
                    linkColor={linkColor}
                    setBorderColor={setBorderColor}
                    borderColor={borderColor}
                    columnNames={displayColumnNames}
                    selectedColumns={selectedColumns.map(
                        (name) => columnMap[name] || name
                    )}
                    handleColumnClick={handleColumnClick}
                    handlePreviewClick={handlePreviewClick}
                    handleEmbedClick={handleEmbedClick}
                    formState={formState}
                />
            )}

            {step === 2 && (
                <EmbedVendorTableModal
                    onDismiss={onDismiss}
                    onBack={() => setStep(1)}
                    onCompleted={onCompleted}
                    link={getTableLink()}
                />
            )}
        </>
    )
}
