import React, { useState } from 'react'
import useAuditsPageQuery from '../pages/audits/useAuditsPageQuery'
import SearchInput from './SearchInput'
import Select from './Select'
import SelectListOption from './SelectListOption'
import useSearch from '../hooks/useSearch'
import InfiniteScroll from './InfiniteScroll'
import SelectList from './SelectList'

const AuditSearchSelect = ({
    className,
    value = null,
    onChange,
    onSelect,
    onDeselect,
    isCheckbox = true,
    isRadio = false,
}) => {
    const hasArrayValue = Array.isArray(value)
    const [search, handleSearch] = useSearch('')
    const [selectedTag, setSelectedTag] = useState('ALL')
    const {
        audits,
        auditTags,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useAuditsPageQuery({ search, tag: selectedTag })
    return (
        <SelectList
            className={className}
            isFetching={isFetching}
            searchInput={
                <div className="flex gap-2">
                    <SearchInput
                        value={search}
                        onChange={handleSearch}
                        className="w-full"
                    />
                    <Select
                        className="w-[140px]"
                        selectClassName="h-full"
                        size="sm"
                        value={selectedTag}
                        onChange={(e) => {
                            setSelectedTag(e.target.value)
                        }}
                    >
                        <option value="ALL">All Tags</option>
                        {auditTags.map((tag) => (
                            <option key={tag} value={tag}>
                                {tag}
                            </option>
                        ))}
                    </Select>
                </div>
            }
        >
            <InfiniteScroll
                isFetchingMore={isFetchingMore}
                onFetchMore={handleFetchMore}
                hasMoreRecords={hasMore}
            >
                {audits.map((audit) => {
                    let isSelected = false
                    if (value) {
                        if (hasArrayValue) {
                            isSelected = value.includes(audit.id)
                        } else {
                            isSelected = value === audit.id
                        }
                    }
                    return (
                        <SelectListOption
                            avatarSrc={audit.imageUrl}
                            avatarDefaultIcon="clock"
                            key={audit.id}
                            disabled
                            isCheckbox={isCheckbox}
                            isRadio={isRadio}
                            checked={
                                hasArrayValue
                                    ? value.includes(audit.id)
                                    : value === audit.id
                            }
                            onClick={() => {
                                if (isSelected) {
                                    if (typeof onDeselect === 'function') {
                                        onDeselect(audit.id, audit)
                                    }
                                    if (typeof onChange === 'function') {
                                        if (hasArrayValue) {
                                            onChange(
                                                value.filter(
                                                    (v) => v !== audit.id
                                                )
                                            )
                                        } else {
                                            onChange(null)
                                        }
                                    }
                                } else {
                                    if (typeof onSelect === 'function') {
                                        onSelect(audit.id, audit)
                                    }
                                    if (typeof onChange === 'function') {
                                        if (hasArrayValue) {
                                            onChange([...value, audit.id])
                                        } else {
                                            onChange(audit.id)
                                        }
                                    }
                                }
                            }}
                        >
                            {audit.name}
                        </SelectListOption>
                    )
                })}
            </InfiniteScroll>
        </SelectList>
    )
}

export default AuditSearchSelect
