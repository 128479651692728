import { gql, useMutation } from '@apollo/client'

export const CREATE_PURPOSE_IDENTIFIER_MUTATION = gql`
    mutation staffCreatePurposeIdentifier(
        $trackerId: ID!
        $input: CreatePurposeIdentifierInput!
    ) {
        staffCreatePurposeIdentifier(trackerId: $trackerId, input: $input) {
            purposeIdentifier {
                id
                pattern
                examples
                purposes {
                    id
                    name
                }
                processingActivities
            }
        }
    }
`

export default function useCreatePurposeIdentifierMutation(options) {
    return useMutation(CREATE_PURPOSE_IDENTIFIER_MUTATION, options)
}
