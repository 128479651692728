import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import VendorTableItemPageTableHeaderRow from './VendorTableItemPageTableHeaderRow'
import VendorTableItemPageTableRow from './VendorTableItemPageTableRow'

const VendorTableItemsPageTable = ({
    onUpdateVendorTableItemsCompleted,
    auditIds,
    vendorTableItems,
    isFetching,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table
            isFetching={isFetching}
            data-testid="VendorTableItemsPageTable"
            isFullWidth
            hasMinWidth={false}
        >
            <thead>
                <VendorTableItemPageTableHeaderRow />
            </thead>
            <TableBody>
                {vendorTableItems
                    .filter((tableItem) => tableItem.status === 'ADDED')
                    .map((vendorTableItem) => (
                        <VendorTableItemPageTableRow
                            key={vendorTableItem.id}
                            auditIds={auditIds}
                            vendorTableItem={vendorTableItem}
                            onUpdateVendorTableItemsCompleted={
                                onUpdateVendorTableItemsCompleted
                            }
                        />
                    ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default VendorTableItemsPageTable
