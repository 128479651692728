import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import { connectionToCollection } from '../../../utils/graphql'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import IdentifiedTrackersPageTableRow from './IdentifiedTrackersPageTableRow'

const QUERY = gql`
    query identifiedTrackersPage(
        $search: String
        $after: String
        $offset: Int
        $orderBy: TrackerOrderByInput
        $filters: IdentifiedTrackersFilters
    ) {
        identifiedTrackers(
            search: $search
            after: $after
            offset: $offset
            orderBy: $orderBy
            filters: $filters
        ) {
            edges {
                node {
                    ...IdentifiedTrackersPageTableRow
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
        audits {
            edges {
                cursor
                node {
                    id
                    name
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
        purposes {
            id
            name
        }
    }
    ${IdentifiedTrackersPageTableRow.FRAGMENT}
    ${PopoverVendorInfo.FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`

const useIdentifiedTrackersPageQuery = (
    search,
    auditId,
    purposeId,
    vendorId,
    startDate,
    endDate,
    period
) => {
    const orderBy = { occurrence: 'DESC' }
    const filters = {
        auditId,
        purposeId,
        vendorId,
        startDate,
        endDate,
        period,
    }
    const variables = { search, filters, orderBy }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        skip: period === null || startDate === null,
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'identifiedTrackers'
    )
    return {
        error,
        audits: connectionToCollection(data.audits),
        trackers: connectionToCollection(data.identifiedTrackers),
        purposes: data.purposes || [],
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}

export default useIdentifiedTrackersPageQuery

const REQUEST_URLS_QUERY = gql`
    query IdentifiedTrackersDrawerTabUrls(
        $id: ID!
        $offset: Int
        $search: String
    ) {
        identifiedTrackerUrls(id: $id, offset: $offset, search: $search) {
            edges {
                node {
                    id
                    url
                    scanRequestCount
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export function useIdentifiedTrackersUrlsQuery({ id, search }) {
    const variables = { id, search }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(REQUEST_URLS_QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'identifiedTrackerUrls'
    )

    return {
        error,
        urls: connectionToCollection(data.identifiedTrackerUrls),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}
