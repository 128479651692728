import React from 'react'
import { Trans } from '@lingui/macro'
import Sidebar from '../../layout/Sidebar'
import SidebarMenu from '../../layout/SidebarMenu'
import SidebarTitle from '../../layout/SidebarTitle'
import SidebarMenuItem from '../../layout/SidebarMenuItem'

export default function StaffScansPageSidebar() {
    return (
        <Sidebar>
            <SidebarMenu>
                <SidebarTitle>
                    <Trans>Scans</Trans>
                </SidebarTitle>
                <SidebarMenuItem
                    to="/staff/scans/dashboard"
                    iconName="home-line"
                >
                    <Trans>Dashboard</Trans>
                </SidebarMenuItem>
                <SidebarMenuItem to="/staff/scans" iconName="scan">
                    <Trans>All scans</Trans>
                </SidebarMenuItem>
            </SidebarMenu>
        </Sidebar>
    )
}
