import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import useCreateDataIdentifierMutation from './useCreateDataIdentifierMutation'
import useCreateDataIdentifierFormState from './useCreateDataIdentifierFormState'
import CreateDataIdentifierModal from './CreateDataIdentifierModal'
import useExampleText from './useExampleText'

const CreateDataIdentifierModalWithState = ({ onDismiss, onCompleted }) => {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const formState = useCreateDataIdentifierFormState()
    const [createDataIdentifier, { loading }] = useCreateDataIdentifierMutation(
        { onCompleted }
    )
    const { exampleText, highlightedExampleText, onUpdateExampleText } =
        useExampleText()

    const handleSubmit = async () => {
        try {
            if (formState.validate()) {
                const variables = {
                    input: {
                        name: formState.values.name,
                        sensitivity: formState.values.sensitivity,
                        type: formState.values.type,
                        patterns: formState.values.patterns,
                    },
                }
                await createDataIdentifier({ variables })
                formState.resetForm()
                dispatchSuccess({
                    message: (
                        <Trans>Data identifier created successfully!</Trans>
                    ),
                })
            }
        } catch (error) {
            dispatchError({ message: error.message })
        }
    }

    return (
        <CreateDataIdentifierModal
            exampleText={exampleText}
            highlightedExampleText={highlightedExampleText}
            onUpdateExampleText={onUpdateExampleText}
            formState={formState}
            isLoading={loading}
            onDismiss={onDismiss}
            onSubmit={handleSubmit}
        />
    )
}

export default CreateDataIdentifierModalWithState
