import React from 'react'
import { Trans } from '@lingui/macro'
import useStaffScansFilterOrganizationsQuery from '../pages/staff-scans/useStaffScansFilterOrganizationsQuery'
import SearchInput from './SearchInput'
import SelectListOption from './SelectListOption'
import useSearch from '../hooks/useSearch'
import InfiniteScroll from './InfiniteScroll'
import SelectList from './SelectList'

const OrganizationsSearchSelect = ({
    value = null,
    onChange,
    onSelect,
    onDeselect,
}) => {
    const hasArrayValue = Array.isArray(value)
    const [search, handleSearch] = useSearch('')

    const {
        organizations,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        error,
    } = useStaffScansFilterOrganizationsQuery({
        search,
    })

    const isEmpty = !organizations || organizations.length === 0
    if (error) {
        return { error }
    }
    return (
        <SelectList
            isFetching={isFetching}
            searchInput={
                <div className="flex gap-2">
                    <SearchInput
                        value={search}
                        onChange={handleSearch}
                        className="w-full"
                    />
                </div>
            }
        >
            {error ? (
                <div className="rounded-md bg-red-50 p-4 text-sm text-red-500">
                    <Trans>
                        An error occurred while loading organizations.{' '}
                        {error.message}
                    </Trans>
                </div>
            ) : isEmpty ? (
                <div className="p-4 text-center text-gray-500">
                    {search ? (
                        <Trans>No matching organizations</Trans>
                    ) : (
                        <Trans>No organizations</Trans>
                    )}
                </div>
            ) : (
                <InfiniteScroll
                    isFetchingMore={isFetchingMore}
                    onFetchMore={handleFetchMore}
                    hasMoreRecords={hasMore}
                >
                    {organizations.map((organization) => {
                        let isSelected = false
                        if (value) {
                            if (hasArrayValue) {
                                isSelected = value.includes(organization.id)
                            } else {
                                isSelected = value === organization.id
                            }
                        }

                        return (
                            <SelectListOption
                                avatarSrc={organization.imageUrl}
                                avatarDefaultIcon="building-07"
                                key={organization.id}
                                checked={isSelected}
                                onClick={() => {
                                    if (isSelected) {
                                        if (typeof onDeselect === 'function') {
                                            onDeselect(
                                                organization.id,
                                                organization
                                            )
                                        }
                                        if (typeof onChange === 'function') {
                                            if (hasArrayValue) {
                                                onChange(
                                                    value.filter(
                                                        (v) =>
                                                            v !==
                                                            organization.id
                                                    )
                                                )
                                            } else {
                                                onChange(null)
                                            }
                                        }
                                    } else {
                                        if (typeof onSelect === 'function') {
                                            onSelect(
                                                organization.id,
                                                organization
                                            )
                                        }
                                        if (typeof onChange === 'function') {
                                            if (hasArrayValue) {
                                                onChange([
                                                    ...value,
                                                    organization.id,
                                                ])
                                            } else {
                                                onChange(organization.id)
                                            }
                                        }
                                    }
                                }}
                            >
                                {organization.name}
                            </SelectListOption>
                        )
                    })}
                </InfiniteScroll>
            )}
        </SelectList>
    )
}

export default OrganizationsSearchSelect
