import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableHeaderCell from '../../utils/TableHeaderCell'
import TableHeaderRow from '../../utils/TableHeaderRow'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Avatar from '../../utils/Avatar'
import Tag from '../../utils/Tag'

export default function AlertSummaryAuditTable({ audits }) {
    return (
        <Table hasMinWidth={false}>
            <thead>
                <TableHeaderRow>
                    <TableHeaderCell columnId="audits" initialWidth={200}>
                        <Trans>Audits</Trans>
                    </TableHeaderCell>
                    <TableHeaderCell columnId="consent" initialWidth={200}>
                        <Trans>Consent Scenarios</Trans>
                    </TableHeaderCell>
                </TableHeaderRow>
            </thead>
            <TableBody>
                {audits.map((audit) => (
                    <TableRow key={audit.id}>
                        <TableCell columnId="audits">
                            <div className="flex items-center gap-3">
                                <Avatar src={audit.imageUrl} iconName="clock" />
                                <p>{audit.name}</p>
                            </div>
                        </TableCell>
                        <TableCell columnId="consent">
                            <div className="flex items-center gap-3">
                                {audit?.consentProfiles.map((consent) => (
                                    <Tag type="primary" key={consent.id}>
                                        {consent.name}
                                    </Tag>
                                ))}
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
