import React, { useState } from 'react'
import * as constants from '@clew/shared/src/constants'
import StaffDataIdentifiersPageTableRowActionButton from './StaffDataIdentifiersPageTableRowActionButton'
import StaffDeleteDataIdentifiersModalWithState from './StaffDeleteDataIdentifiersModalWithState'
import StaffUpdateDataIdentifiersModalWithState from './StaffUpdateDataIdentifiersModalWithState'
import useAppState from '../../hooks/useAppState'

const { USER_TYPE_STAFF: STAFF } = constants

const StaffDataIdentifiersPageTableRowActionButtonWithState = ({
    dataIdentifier,
    onDeleteCompleted,
    onUpdateCompleted,
}) => {
    const [isDeleting, setIsDeleting] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const { currentUser } = useAppState()

    return (
        <>
            {' '}
            {isUpdating && (
                <StaffUpdateDataIdentifiersModalWithState
                    dataIdentifier={dataIdentifier}
                    onDismiss={() => setIsUpdating(false)}
                    onCompleted={onUpdateCompleted}
                />
            )}
            {isDeleting && (
                <StaffDeleteDataIdentifiersModalWithState
                    dataIdentifier={dataIdentifier}
                    onDismiss={() => setIsDeleting(false)}
                    onCompleted={onDeleteCompleted}
                />
            )}
            <StaffDataIdentifiersPageTableRowActionButton
                isLoading={isDeleting}
                canDelete={currentUser.type === STAFF}
                canUpdate={currentUser.type === STAFF}
                onDelete={() => setIsDeleting(true)}
                onUpdate={() => setIsUpdating(true)}
            />
        </>
    )
}

export default StaffDataIdentifiersPageTableRowActionButtonWithState
