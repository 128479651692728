import clsx from 'clsx'
import React from 'react'
import Icon from './Icon'

const ErrorCard = ({ title, errorMessage, className }) => (
    <div
        className={clsx(
            'flex h-full flex-col items-center justify-center bg-white px-4 text-center',
            className
        )}
    >
        <Icon name="alert-triangle" color="red" size="lg" />
        {title && (
            <h3 className="mt-4 text-sm font-medium text-red-600">{title}</h3>
        )}
        {errorMessage && (
            <p className="mt-2 max-w-sm text-sm text-red-600">{errorMessage}</p>
        )}
    </div>
)

export default ErrorCard
