/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DeleteActionModalWithState from './DeleteActionModalwithState'
import UpdateActionModalWithState from './UpdateActionModalwithState'
import useAppState from '../../hooks/useAppState'

export default function AuditRecordMobileActionsDropdownMenu({
    onDeleteAction,
    onUpdateAction,
    action,
    eventActionId,
    onToggleRequiredAction,
    children,
}) {
    const [isDeleteActionModalOpen, setDeleteActionModalOpen] = useState(false)
    const [isUpdateActionModalOpen, setUpdateActionModalOpen] = useState(false)
    const { currentUser } = useAppState()
    return (
        <>
            {isDeleteActionModalOpen && (
                <DeleteActionModalWithState
                    id={eventActionId}
                    onDeleteAction={onDeleteAction}
                    onDismiss={() => setDeleteActionModalOpen(false)}
                />
            )}
            {isUpdateActionModalOpen && (
                <UpdateActionModalWithState
                    id={eventActionId}
                    action={action}
                    onUpdateAction={onUpdateAction}
                    onDismiss={() => setUpdateActionModalOpen(false)}
                />
            )}
            <DropdownMenu
                as="span"
                position="bottom-start"
                content={({ close }) => (
                    <>
                        <DropdownMenuItem
                            iconName="settings-04"
                            onClick={() => {
                                onToggleRequiredAction(eventActionId)
                                close()
                            }}
                        >
                            <Trans>Toggle Required/Optional</Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="edit"
                            disabled={!currentUser.isStaff}
                            onClick={() => {
                                setUpdateActionModalOpen(true)
                                close()
                            }}
                        >
                            <Trans>Update Action</Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="bin"
                            onClick={() => {
                                setDeleteActionModalOpen(true)
                                close()
                            }}
                        >
                            <Trans>Delete Action</Trans>
                        </DropdownMenuItem>
                    </>
                )}
            >
                {children}
            </DropdownMenu>
        </>
    )
}
