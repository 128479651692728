import { gql, useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import { connectionToCollection } from '../../../utils/graphql'

const STAFF_DASHBOARD_AUDITS_QUERY = gql`
    query StaffDashboardAudits(
        $first: Int
        $offset: Int
        $filters: StaffPausedAuditsFilters
    ) {
        staffPausedAudits(first: $first, offset: $offset, filters: $filters) {
            edges {
                cursor
                node {
                    id
                    name
                    pausedAt
                    imageUrl
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export const useStaffDashboardAuditsQuery = ({ first, filters }) => {
    const variables = { first, filters }
    const {
        data = {},
        loading: isFetching,
        fetchMore,
        error,
    } = useQuery(STAFF_DASHBOARD_AUDITS_QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })

    const [onFetchMore, isFetchingMore] = useFetchMore(
        fetchMore,
        data,
        'staffPausedAudits'
    )

    return {
        audits: connectionToCollection(data.staffPausedAudits),
        error,
        isFetching,
        onFetchMore,
        isFetchingMore,
        hasMoreRecords:
            !isFetching &&
            !error &&
            data?.staffPausedAudits?.pageInfo?.hasNextPage,
    }
}
