/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DeleteScanModalWithState from './DeleteScanModalWithState'

export default function ScanPageActionsDropdownMenu({
    scan,
    auditId,
    onDeleteScanCompleted,
    children,
}) {
    const [isDeleteScanModalOpen, setDeleteScanModalOpen] = useState(false)
    const history = useHistory()
    return (
        <>
            {isDeleteScanModalOpen && (
                <DeleteScanModalWithState
                    scan={scan}
                    onDismiss={() => setDeleteScanModalOpen(false)}
                    onCompleted={onDeleteScanCompleted}
                />
            )}
            <DropdownMenu
                as="span"
                position="bottom-start"
                content={({ close }) => (
                    <>
                        <DropdownMenuItem
                            iconName="bin"
                            onClick={() => {
                                setDeleteScanModalOpen(true)
                                close()
                            }}
                        >
                            <Trans>Delete Scan</Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="link-external-01"
                            onClick={() => {
                                history.push(`/audits/${auditId}`)
                                close()
                            }}
                        >
                            <Trans>Go to audit</Trans>
                        </DropdownMenuItem>
                    </>
                )}
            >
                {children}
            </DropdownMenu>
        </>
    )
}
