import { useState } from 'react'

export default function useExampleText() {
    const [exampleText, setExampleText] = useState('')
    const [highlightedExampleText, setHighlightedExampleText] = useState('')

    const onUpdateExampleText = (pattern, text) => {
        try {
            const pattern1 = Array.isArray(pattern) ? pattern : [pattern]
            const patterns = pattern1.join('|')
            const regex1 = new RegExp(patterns, 'g')

            setExampleText(text)

            const highlightedText = text.replace(
                regex1,
                (match) =>
                    `<span class="bg-yellow-300 font-bold">${match}</span>`
            )

            setHighlightedExampleText(highlightedText)
        } catch (err) {
            throw new Error(err)
        }
    }

    return {
        exampleText,
        highlightedExampleText,
        onUpdateExampleText,
    }
}
