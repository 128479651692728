import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const Select = ({
    id,
    size = 'md',
    errors,
    hasError,
    iconNameBefore,
    iconNameAfter,
    className,
    selectClassName,
    disabled,
    ...props
}) => {
    return (
        <div className={clsx('relative rounded-lg shadow-xs', className)}>
            {iconNameBefore && (
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Icon size="sm" name={iconNameBefore} />
                </div>
            )}
            <select
                id={id}
                disabled={disabled}
                className={clsx(
                    'form-select block w-full rounded-md border-gray-300 text-sm leading-5 transition duration-150 ease-in-out',
                    size === 'sm' && 'px-2 py-1',
                    !hasError &&
                        'border-gray-300 enabled:hover:border-gray-400',
                    hasError &&
                        'border-red-300 text-red-600 placeholder-red-300 enabled:hover:border-red-400',
                    disabled && 'cursor-not-allowed opacity-50',
                    iconNameBefore && 'pl-9',
                    (iconNameAfter || hasError) && 'pr-15',
                    selectClassName
                )}
                {...props}
            />
            {(iconNameAfter || hasError) && (
                <>
                    {' '}
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8">
                        <Icon
                            size="sm"
                            name={iconNameAfter || 'warning-circle'}
                            className={clsx(hasError && 'text-red-500')}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default Select
