/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useAppState from '../../hooks/useAppState'
import IdentifiedVendorsPageTableRowActions from './IdentifiedVendorsPageTableRowActions'
import IdentifiedVendorsPageTableRowActionsStaff from './IdentifiedVendorsPageTableRowActionsStaff'
import UpdateOrganizationVendorModalWithState from '../classifications-organization-vendors/UpdateOrganizationVendorModalWithState'
import UpdateVendorModal from '../staff-vendors/UpdateVendorModal'

const IdentifiedVendorsPageTableRowActionsWithState = ({
    vendor,
    onUpdateCompleted,
    children,
}) => {
    const { currentUser } = useAppState()
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
    const [updateOrganizationModalIsOpen, setUpdateOrganizationModalIsOpen] =
        useState(false)
    if (currentUser.isStaff) {
        return (
            <>
                {updateModalIsOpen && (
                    <UpdateVendorModal
                        vendorId={vendor.id}
                        onDismiss={() => setUpdateModalIsOpen(false)}
                        onCompleted={onUpdateCompleted}
                    />
                )}
                {updateOrganizationModalIsOpen && (
                    <UpdateOrganizationVendorModalWithState
                        title={
                            <Trans>Update organization classification</Trans>
                        }
                        vendorId={vendor.id}
                        onDismiss={() =>
                            setUpdateOrganizationModalIsOpen(false)
                        }
                        onCompleted={onUpdateCompleted}
                    />
                )}
                <IdentifiedVendorsPageTableRowActionsStaff
                    vendor={vendor}
                    canUpdate={vendor !== null}
                    onUpdate={() => setUpdateModalIsOpen(true)}
                    onUpdateOrganization={() =>
                        setUpdateOrganizationModalIsOpen(true)
                    }
                >
                    {children}
                </IdentifiedVendorsPageTableRowActionsStaff>
            </>
        )
    }
    return (
        <>
            {updateOrganizationModalIsOpen && (
                <UpdateOrganizationVendorModalWithState
                    title={<Trans>Update your classification</Trans>}
                    vendorId={vendor.id}
                    onDismiss={() => setUpdateOrganizationModalIsOpen(false)}
                    onCompleted={onUpdateCompleted}
                />
            )}
            <IdentifiedVendorsPageTableRowActions
                vendor={vendor}
                onUpdate={() => setUpdateOrganizationModalIsOpen(true)}
            >
                {children}
            </IdentifiedVendorsPageTableRowActions>
        </>
    )
}

export default IdentifiedVendorsPageTableRowActionsWithState
