import React from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import useFormState, { createFormValidation } from 'use-form-state'
import { useMutation, useQuery } from '@apollo/client'
import useNotifications from '../../hooks/useNotifications'
import SaveLiveScanModal from './SaveLiveScanModal'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'consentType',
        validate: isRequired,
        message: (
            <Trans>
                Choose a consent type that you would like to validate.
            </Trans>
        ),
    },
])

const useSaveLiveScanFormState = (allPurposes) => {
    const formState = useFormState(
        {
            consentType: 'NO_CONSENT',
            purposeIds: [],
        },
        {
            validation,
        }
    )
    formState.handleNoConsent = () => {
        formState.handleChange('consentType', 'NO_CONSENT')
        formState.handleChange(
            'purposeIds',
            allPurposes
                .filter(({ externalId }) => externalId === 'essential')
                .map(({ id }) => id)
        )
    }
    formState.handleRejectAll = () => {
        formState.handleChange('consentType', 'REJECT_ALL')
        formState.handleChange(
            'purposeIds',
            allPurposes
                .filter(({ externalId }) => externalId === 'essential')
                .map(({ id }) => id)
        )
    }
    formState.handleAcceptAll = () => {
        formState.handleChange('consentType', 'ACCEPT_ALL')
        formState.handleChange(
            'purposeIds',
            allPurposes.map(({ id }) => id)
        )
    }
    formState.handleCustom = () => {
        formState.handleChange('consentType', 'CUSTOM')
        formState.handleChange('purposeIds', [])
    }
    formState.handleChangePurpose = (purposeId) => {
        formState.handleChange('purposeIds', (purposeIds) => {
            if (purposeIds.includes(purposeId)) {
                return purposeIds.filter((id) => id !== purposeId)
            }
            return [...purposeIds, purposeId]
        })
    }
    return formState
}

const PURPOSES_QUERY = gql`
    query saveLiveScanModalQuery {
        purposes {
            id
            externalId
            name
        }
    }
`

const SAVE_MUTATION = gql`
    mutation saveLiveScan($scanId: ID!, $input: SaveLiveScanInput!) {
        saveLiveScan(scanId: $scanId, input: $input) {
            scan {
                id
            }
        }
    }
`

const SaveLiveScanModalWithState = ({
    scanId,
    networkEvents,
    deviceSettings,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { data = {}, loading: isFetching } = useQuery(PURPOSES_QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const { purposes = [] } = data
    const [saveLiveScan, { loading: isSaving }] = useMutation(SAVE_MUTATION, {
        variables: { scanId },
        onCompleted,
        onError,
    })
    const formState = useSaveLiveScanFormState(purposes)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <SaveLiveScanModal
            isSaving={isSaving}
            purposes={purposes}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const { consentType, purposeIds } = formState.values
                        const input = {
                            consentType,
                            purposeIds,
                        }
                        if (networkEvents) {
                            input.networkEvents = networkEvents
                        }
                        if (deviceSettings) {
                            input.deviceSettings = deviceSettings
                        }
                        await saveLiveScan({
                            variables: { scanId, input },
                        })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: <Trans>Scan scan Saved!</Trans>,
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default SaveLiveScanModalWithState
