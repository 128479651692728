/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import Avatar from './Avatar'
import Button from './Button'
import useAppState from '../hooks/useAppState'

const AlertCommentInput = ({ formState, onSubmit, isLoading = false }) => {
    const errorMessages = formState.getErrorMessages('text')
    const hasErrors = errorMessages.length > 0
    const { currentUser } = useAppState()
    return (
        <form
            className="flex gap-2"
            onSubmit={(e) => {
                e.preventDefault()
                onSubmit()
            }}
        >
            <Avatar name={currentUser.fullName} />
            <div className="w-full rounded-lg border border-gray-200">
                <div className="flex items-center justify-between border-b border-gray-200 px-4 py-2">
                    <div className="text-sm font-medium">
                        {currentUser.fullName}
                    </div>
                    <div className="text-sm text-gray-500">Today</div>
                </div>
                <textarea
                    className={clsx(
                        'h-24 w-full border-b border-gray-200 px-4 py-2 text-sm',
                        hasErrors && 'placeholder:text-red-600'
                    )}
                    placeholder="Write a comment..."
                    {...formState.getNativeInputProps('text')}
                />
                <div className="flex items-center justify-between px-2 pb-2 pt-1">
                    <div className="ml-2 text-sm text-red-600">
                        {errorMessages}
                    </div>
                    <div className="flex items-center">
                        <Button loading={isLoading}>
                            <Trans>Comment</Trans>
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default AlertCommentInput
