/* eslint-disable max-len */
import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '@clew/shared/src/constants'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormSelect from '../../utils/FormSelect'
import TagSelect from '../../utils/TagSelect'
import Button from '../../utils/Button'
import FormButtonGroup from '../../utils/FormButtonGroup'
import Toggle from '../../utils/Toggle'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'
import Tooltip from '../../utils/Tooltip'
import FormCheckbox from '../../utils/FormCheckbox'

const {
    AVAILABLE_AUDIT_SCAN_FREQUENCIES: SCAN_FREQUENCIES,
    AUDIT_TYPE_MOBILE,
    PROXY_TYPE_RESIDENTIAL,
} = constants

export const OverrideCheckbox = (props) => (
    <FormCheckbox label={<Trans>Override</Trans>} {...props} />
)

export const DefaultsTooltip = (props) => (
    <Tooltip
        as="div"
        content={
            <Trans>
                The organization default settings are used unless you override
                this setting.
            </Trans>
        }
        {...props}
    />
)

const AuditSettingsFormCard = ({
    organization,
    formState,
    loginProfiles,
    auditType,
    isLoading,
    onSubmit,
}) => {
    const { values, handleChange } = formState
    let finalScanFrequency = values.scanFrequency
    const finalProxyType = values.proxyType
    let finalTakeScreenshots = values.takeScreenshots
    let finalPrivacySandbox = values.privacySandbox
    let finalAdvancedScanning = values.advancedScanning

    if (finalScanFrequency === null) {
        finalScanFrequency = organization.scanFrequency
    }
    let hasProxy = false
    if (finalProxyType === PROXY_TYPE_RESIDENTIAL) {
        hasProxy = true
    }
    if (finalTakeScreenshots === null) {
        finalTakeScreenshots = organization.takeScreenshots
    }
    if (finalPrivacySandbox === null) {
        finalPrivacySandbox = organization.privacySandbox
    }
    if (finalAdvancedScanning === null) {
        finalAdvancedScanning = organization.advancedScanning
    }
    return (
        <Card className="mb-8">
            <form>
                <CardHeader
                    title={<Trans>Audit Information</Trans>}
                    description={
                        <Trans>
                            Below you can edit all basic information related to
                            the audit.
                        </Trans>
                    }
                />
                <div className="divide-y">
                    <FormGroup
                        isHorizontal
                        label={<Trans>Name</Trans>}
                        htmlFor="name"
                    >
                        <FormInput
                            id="name"
                            className="max-w-lg"
                            errors={formState.getErrorMessages('name')}
                            {...formState.getNativeInputProps('name')}
                        />
                    </FormGroup>
                    <FormGroup isHorizontal label={<Trans>Tags</Trans>}>
                        <TagSelect
                            value={formState.values.tags}
                            onChange={(tags) =>
                                formState.handleChange('tags', tags)
                            }
                        />
                    </FormGroup>
                    {auditType !== AUDIT_TYPE_MOBILE && (
                        <FormGroup
                            isHorizontal
                            label={<Trans>Scan frequency</Trans>}
                            htmlFor="url"
                        >
                            <div className="flex items-center justify-between">
                                <DefaultsTooltip
                                    className="max-w-md flex-1"
                                    enabled={values.scanFrequency === null}
                                >
                                    <FormSelect
                                        id="scanFrequency"
                                        className="max-w-md flex-1"
                                        disabled={values.scanFrequency === null}
                                        errors={formState.getErrorMessages(
                                            'scanFrequency'
                                        )}
                                        value={finalScanFrequency}
                                        onChange={(e) =>
                                            handleChange(
                                                'scanFrequency',
                                                e.target.value
                                            )
                                        }
                                    >
                                        {SCAN_FREQUENCIES.map((frequency) => (
                                            <option
                                                key={frequency}
                                                value={frequency}
                                            >
                                                {frequency}
                                            </option>
                                        ))}
                                    </FormSelect>
                                </DefaultsTooltip>
                                <OverrideCheckbox
                                    id="overrideScanFrequency"
                                    htmlFor="overrideScanFrequency"
                                    checked={values.scanFrequency !== null}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            handleChange(
                                                'scanFrequency',
                                                organization.scanFrequency
                                            )
                                        } else {
                                            handleChange('scanFrequency', null)
                                        }
                                    }}
                                />
                            </div>
                        </FormGroup>
                    )}
                    {auditType !== AUDIT_TYPE_MOBILE && (
                        <FormGroup
                            isHorizontal
                            label={<Trans>Use a proxy</Trans>}
                            htmlFor="proxyType"
                        >
                            <div className="flex items-center justify-between">
                                <Toggle
                                    id="proxyType"
                                    name="proxyType"
                                    value={hasProxy}
                                    onClick={(value) =>
                                        formState.handleChange(
                                            'proxyType',
                                            value
                                                ? PROXY_TYPE_RESIDENTIAL
                                                : null
                                        )
                                    }
                                />
                            </div>
                        </FormGroup>
                    )}
                    <FormGroup
                        isHorizontal
                        label={<Trans>Take screenshots</Trans>}
                        htmlFor="takeScreenshots"
                    >
                        <div className="flex items-center justify-between">
                            <DefaultsTooltip
                                enabled={values.takeScreenshots === null}
                            >
                                <Toggle
                                    name="takeScreenshots"
                                    disabled={values.takeScreenshots === null}
                                    value={finalTakeScreenshots}
                                    onClick={(value) =>
                                        formState.handleChange(
                                            'takeScreenshots',
                                            value
                                        )
                                    }
                                />
                            </DefaultsTooltip>
                            <OverrideCheckbox
                                id="overrideTakeScreenshots"
                                htmlFor="overrideTakeScreenshots"
                                checked={values.takeScreenshots !== null}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        handleChange(
                                            'takeScreenshots',
                                            organization.takeScreenshots
                                        )
                                    } else {
                                        handleChange('takeScreenshots', null)
                                    }
                                }}
                            />
                        </div>
                    </FormGroup>
                    {auditType !== AUDIT_TYPE_MOBILE && (
                        <>
                            {' '}
                            <FormGroup
                                isHorizontal
                                label={<Trans>Advanced Scanning</Trans>}
                                htmlFor="advancedScanning"
                            >
                                <div className="flex items-center justify-between">
                                    <DefaultsTooltip
                                        enabled={
                                            values.advancedScanning === null
                                        }
                                    >
                                        <Toggle
                                            name="advancedScanning"
                                            disabled={
                                                values.advancedScanning === null
                                            }
                                            value={finalAdvancedScanning}
                                            onClick={(value) => {
                                                formState.handleChange(
                                                    'advancedScanning',
                                                    value
                                                )
                                            }}
                                        />
                                    </DefaultsTooltip>
                                    <OverrideCheckbox
                                        id="overrideAdvancedScanning"
                                        htmlFor="overrideAdvancedScanning"
                                        checked={
                                            values.advancedScanning !== null
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                handleChange(
                                                    'advancedScanning',
                                                    organization.advancedScanning
                                                )
                                            } else {
                                                handleChange(
                                                    'advancedScanning',
                                                    null
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup
                                isHorizontal
                                label={<Trans>Privacy Sandbox</Trans>}
                                htmlFor="privacySandbox"
                            >
                                <div className="flex items-center justify-between">
                                    <DefaultsTooltip
                                        enabled={values.privacySandbox === null}
                                    >
                                        <Toggle
                                            name="privacySandbox"
                                            value={finalPrivacySandbox}
                                            onClick={(value) => {
                                                formState.handleChange(
                                                    'privacySandbox',
                                                    value
                                                )
                                            }}
                                        />
                                    </DefaultsTooltip>
                                </div>
                            </FormGroup>
                            <FormGroup
                                isHorizontal
                                label={<Trans>Authenticated scan</Trans>}
                            >
                                <div className="flex items-center justify-between">
                                    <FormSelect
                                        id="loginProfileId"
                                        className="max-w-md flex-1"
                                        errors={formState.getErrorMessages(
                                            'loginProfileId'
                                        )}
                                        value={values.loginProfileId}
                                        onChange={(e) =>
                                            handleChange(
                                                'loginProfileId',
                                                e.target.value
                                            )
                                        }
                                    >
                                        <option key={null} value={null}>
                                            None
                                        </option>
                                        {loginProfiles.map(({ id, name }) => (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        ))}
                                    </FormSelect>
                                </div>
                            </FormGroup>
                        </>
                    )}
                    <FormButtonGroup>
                        <Button
                            onClick={onSubmit}
                            loading={isLoading}
                            disabled={isLoading}
                            className="w-md"
                        >
                            <Trans>Save changes</Trans>
                        </Button>
                    </FormButtonGroup>
                </div>
            </form>
        </Card>
    )
}

export default AuditSettingsFormCard
