import React from 'react'
import { Trans } from '@lingui/macro'
import ModalTitle from '../../utils/ModalTitle'
import Modal from '../../utils/Modal'
import Button from '../../utils/Button'
import ModalFooter from '../../utils/ModalFooter'
import { VendorModalFormFields } from './VendorModalFormFields'

const CreateVendorModal = ({ formState, onDismiss, onSubmit }) => {
    return (
        <Modal onDismiss={onDismiss} className="max-w-3xl">
            <ModalTitle
                title={<Trans>Create new vendor</Trans>}
                description={
                    <Trans>Fill in all details about the vendor below.</Trans>
                }
                onClose={onDismiss}
            />
            <VendorModalFormFields formState={formState} />
            <ModalFooter>
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button onClick={onSubmit}>
                    <Trans>Done</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default CreateVendorModal
