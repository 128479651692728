import React from 'react'
import TableCell from '../../utils/TableCell'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import AuditAvatar from '../../utils/AuditAvatar'
import CollapseButton from '../../utils/CollapseButton'
import AuditObservationTablePageTableRow from './AuditObservationTablePageTableRow'
import LoadingIndicator from '../../utils/LoadingIndicator'
import DrawerTabError from '../../utils/DrawerTabError'
import InfiniteScroll from '../../utils/InfiniteScroll'

const AuditObservationTableRow = ({
    audit,
    objectName = '',
    nextActiveTabOnClick = '',
    isCollapsed,
    setIsCollapsed,
    loading,
    error,
    pages = [],
    isFetchingMore,
    hasMore,
    handleFetchMore,
}) => {
    if (error) <DrawerTabError error={error} />
    return (
        <>
            <TableRow>
                <td className="w-[45px] pl-6">
                    {loading ? (
                        <LoadingIndicator />
                    ) : (
                        <CollapseButton
                            isCollapsed={isCollapsed}
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsCollapsed(!isCollapsed)
                            }}
                        />
                    )}
                </td>
                <TableCell px="none">
                    <AuditAvatar audit={audit} />
                </TableCell>
            </TableRow>
            {!isCollapsed && (
                <tr>
                    <td colSpan="10">
                        <div className="max-h-[400px] overflow-y-scroll">
                            <InfiniteScroll
                                isFetchingMore={isFetchingMore}
                                onFetchMore={handleFetchMore}
                                hasMoreRecords={hasMore && !loading}
                            >
                                {pages.length > 0 && (
                                    <table className="w-full">
                                        <TableBody>
                                            {pages.map((page) => (
                                                <AuditObservationTablePageTableRow
                                                    objectName={objectName}
                                                    nextActiveTabOnClick={
                                                        nextActiveTabOnClick
                                                    }
                                                    key={page.id}
                                                    page={page}
                                                />
                                            ))}
                                        </TableBody>
                                    </table>
                                )}
                            </InfiniteScroll>
                        </div>
                    </td>
                </tr>
            )}
        </>
    )
}

export default AuditObservationTableRow
