import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import UpdateAuditScenarioModal from './UpdateAuditScenarioModal'
import {
    useUpdateAuditScenario,
    useUpdateAuditScenarioModalQuery,
} from './useAuditScenarioModalQueries'
import useUpdateAuditScenarioFormState from './useUpdateAuditScenarioFormState'

export default function UpdateAuditScenarioModalWithState({
    auditScenario,
    actionEvents,
    deviceOs,
    deviceSettingsFormState,
    onDismiss,
    onCompleted,
    onError,
}) {
    const {
        refetch,
        auditScenario: auditScenarioData,
        purposes,
        isFetching,
    } = useUpdateAuditScenarioModalQuery(auditScenario.id)
    const [updateAuditScenario, { loading: isUpdating }] =
        useUpdateAuditScenario({
            onCompleted,
            onError,
        })
    const formState = useUpdateAuditScenarioFormState(
        purposes,
        auditScenarioData
    )
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <UpdateAuditScenarioModal
            isFetching={isFetching}
            isUpdating={isUpdating}
            formState={formState}
            purposes={purposes}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const input = formState.values
                        const variables = {
                            id: auditScenario.id,
                            input: {
                                actions: actionEvents,
                                deviceOs,
                                ...input,
                                deviceSettings: deviceSettingsFormState.values,
                            },
                        }
                        await updateAuditScenario({ variables })
                        await refetch()
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>Scenario updated successfully!</Trans>
                            ),
                        })
                    } catch (e) {
                        dispatchGraphqlError(e)
                    }
                }
            }}
        />
    )
}
