import React from 'react'
import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import AuditsLivePage from './AuditsLivePage'
import {
    useAuditsLivePageQuery,
    useAuditsLiveWebScanLogsQuery,
} from './useAuditsLivePageWebBrowserQueries'

const START_MUTATION = gql`
    mutation startLiveScan($scanId: ID!) {
        startLiveScan(scanId: $scanId) {
            liveUrl
            sessionId
        }
    }
`

const STOP_MUTATION = gql`
    mutation stopLiveScan($scanId: ID!) {
        stopLiveScan(scanId: $scanId)
    }
`

const AuditsLivePageWithState = () => {
    const { id: auditId, scanId } = useParams()
    const variables = { scanId }
    const [startLiveScan, startMutation] = useMutation(START_MUTATION, {
        variables,
    })
    const { scan, isFetching, error } = useAuditsLivePageQuery({ scanId })
    let finalLiveUrl = scan?.liveSettings?.liveUrl
    const liveUrl = startMutation?.data?.startLiveScan?.liveUrl
    if (liveUrl) {
        finalLiveUrl = liveUrl
    }
    const isStarted = !!finalLiveUrl

    const { liveWebScanLogs, error: logsError } = useAuditsLiveWebScanLogsQuery(
        {
            scanId,
            isStarted,
        }
    )
    const [stopLiveScan, stopMutation] = useMutation(STOP_MUTATION, {
        variables: { scanId },
    })
    return (
        <AuditsLivePage
            audit={scan?.audit}
            scan={scan}
            error={error || logsError}
            isFetching={isFetching}
            isStarting={startMutation.loading}
            liveUrl={finalLiveUrl}
            isStarted={isStarted}
            isStopped={stopMutation.called}
            isStopping={stopMutation.loading}
            scanRequests={liveWebScanLogs?.scanRequests || []}
            scanCookies={liveWebScanLogs?.scanCookies || []}
            onStart={() => {
                startLiveScan()
            }}
            onStop={() => {
                stopLiveScan()
            }}
        />
    )
}

export default AuditsLivePageWithState
