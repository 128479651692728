import React from 'react'
import { Trans } from '@lingui/macro'
import ScanRangeSelect from '../alerts/ScanRangeSelect'
import useTableState from '../../hooks/useTableState'
import SearchInput from '../../utils/SearchInput'
import TableViewSettingsMenu from '../../utils/TableViewSettingsMenu'
import TableActionsContainer from '../../utils/TableActionsContainer'
import Button from '../../utils/Button'
import StaffScansPageTableActionsFilters from './StaffScansPageTableActionsFilters'

export default function StaffScansPageTableActions({
    onChangeStatusFilter,
    statusFilter,
    auditsFilter,
    onChangeAuditsFilter,
    dateRangeState,
    search,
    onSearch,
    activeFilters,
    onAddFilter,
    onRemoveAllFilters,
    organizationFilter,
    onChangeOrganizationFilter,
    onRemoveFilter,
}) {
    const { isSelectingRows, startSelectingRows, stopSelectingRows } =
        useTableState()
    return (
        <TableActionsContainer isSticky>
            {isSelectingRows && (
                <Button
                    type="white"
                    size="sm"
                    onClick={() => stopSelectingRows()}
                >
                    <Trans>Cancel</Trans>
                </Button>
            )}
            {!isSelectingRows && (
                <div className="flex items-center gap-2">
                    <Button
                        type="white"
                        size="sm"
                        iconBeforeProps={{
                            name: 'check-done-01',
                            size: 'sm',
                        }}
                        onClick={() => startSelectingRows()}
                    />
                    <SearchInput value={search} onChange={onSearch} />
                    <StaffScansPageTableActionsFilters
                        auditsFilter={auditsFilter}
                        onChangeAuditsFilter={onChangeAuditsFilter}
                        activeFilters={activeFilters}
                        onAddFilter={onAddFilter}
                        onRemoveFilter={onRemoveFilter}
                        onRemoveAllFilters={onRemoveAllFilters}
                        organizationFilter={organizationFilter}
                        onChangeOrganizationFilter={onChangeOrganizationFilter}
                        onChangeStatusFilter={onChangeStatusFilter}
                        statusFilter={statusFilter}
                    />
                </div>
            )}
            <div className="flex gap-2">
                <ScanRangeSelect
                    hasEmptyOption
                    onChange={dateRangeState.onChangeDate}
                    period={dateRangeState.selectedPeriod}
                    startDate={dateRangeState.startDate}
                    endDate={dateRangeState.endDate}
                />
                <TableViewSettingsMenu />
            </div>
        </TableActionsContainer>
    )
}
