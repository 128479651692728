import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const PAGE_QUERY = gql`
    query liveMobileScanPageWithState($scanId: ID!) {
        scan(id: $scanId) {
            id
            trigger
            status
            source
            liveSettings {
                liveUrl
            }
            audit {
                id
                name
                isMobile
                auditBuilds {
                    id
                    name
                    deviceOs
                    version
                    iconUrl
                    bundle
                    appetizeBuildId
                }
            }
        }
        availableDevices {
            id
            platform
            osVersions
            name
        }
    }
`

export function useLiveMobileScanPageQuery({ scanId }) {
    const variables = { scanId }
    const {
        data = {},
        error,
        loading: isFetching,
    } = useQuery(PAGE_QUERY, {
        variables,
        fetchPolicy: 'no-cache',
    })

    return {
        error,
        isFetching,
        scan: data.scan,
        availableDevices: data.availableDevices || [],
        auditBuilds: data.scan?.audit?.auditBuilds || [],
    }
}

const LOGS_QUERY = gql`
    query auditsLivePageLogs(
        $scanId: ID!
        $auditId: ID!
        $input: ParseLiveMobileScanLogsInput!
    ) {
        parseLiveMobileScanLogs(
            scanId: $scanId
            auditId: $auditId
            input: $input
        ) {
            scanRequests {
                url
                rootDomain
                tracker {
                    id
                    purposes {
                        id
                        name
                    }
                    vendor {
                        imageUrl
                    }
                }
                hasPostData
            }
            scanCookies {
                name
                value
                tracker {
                    id
                    purposes {
                        id
                        name
                    }
                    vendor {
                        imageUrl
                    }
                }
                cookie {
                    id
                    purposes {
                        id
                        name
                    }
                    vendor {
                        imageUrl
                    }
                }
            }
        }
    }
`

export function useParseLiveMobileScanLogsQuery({ auditId, scanId, input }) {
    const variables = {
        auditId,
        scanId,
        input,
    }
    const {
        data = {},
        loading: isFetching,
        error,
    } = useQuery(LOGS_QUERY, {
        variables,
        fetchPolicy: 'no-cache',
    })
    return {
        error,
        isFetching,
        mobileScanLogs: data.parseLiveMobileScanLogs || {},
    }
}
