import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const DeleteTrackerDetailsDrawerPurposeIdentifiersModal = ({
    purposeIdentifier,
    isLoading,
    isDisabled,
    onDismiss,
    onConfirm,
}) => {
    return (
        <ConfirmModal
            title={<Trans>Delete Purpose Identifier</Trans>}
            description={
                <Trans>
                    Are you sure you want to delete{' '}
                    <strong>{purposeIdentifier.pattern}</strong>? This action
                    cannot be undone!
                </Trans>
            }
            confirmButtonText={
                <Trans>Delete {purposeIdentifier.pattern}</Trans>
            }
            isLoading={isLoading}
            isDisabled={isDisabled}
            onDismiss={onDismiss}
            onConfirm={onConfirm}
        />
    )
}

export default DeleteTrackerDetailsDrawerPurposeIdentifiersModal
