import React from 'react'
import ScrollSentinel from './ScrollSentinel'
import Icon from './Icon'

const InfiniteScroll = ({
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    children,
}) => (
    <div className="relative">
        {children}
        {isFetchingMore && (
            <div className="flex h-10 items-center justify-center">
                <Icon name="spinner" className="mr-2 animate-spin" />
            </div>
        )}
        {!isFetchingMore && hasMoreRecords && (
            <ScrollSentinel onIntersect={onFetchMore} />
        )}
    </div>
)
export default InfiniteScroll
