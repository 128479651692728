/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, useMemo } from 'react'
import Drawer from '../utils/Drawer'
import DrawerStack from '../utils/DrawerStack'

export const DrawerStackContext = React.createContext()
export const CurrentDrawerStackContext = React.createContext()

const useStackState = (name, initialState = []) => {
    const [stack, setStack] = useState(initialState)
    const [currentIndex, setCurrentIndex] = useState(0)
    const canGoBack = currentIndex > 0
    const canGoForward = currentIndex < stack.length - 1
    const currentDrawer = stack[currentIndex] || null
    let currentDrawerProps
    if (currentDrawer) {
        currentDrawerProps = currentDrawer.props
    }
    const state = useMemo(() => {
        function open(component, props) {
            setStack([{ component, props }])
            setCurrentIndex(0)
        }
        function add(component, props) {
            const isAtLast = currentIndex === stack.length - 1
            if (isAtLast) {
                setStack((prev) => [...prev, { component, props }])
            } else {
                setStack((prev) => [
                    ...prev.slice(0, currentIndex + 1),
                    { component, props },
                ])
            }
            setCurrentIndex((prev) => prev + 1)
        }
        function close() {
            setStack([])
            setCurrentIndex(0)
        }
        function forward() {
            if (canGoForward) {
                setCurrentIndex((prev) => prev + 1)
            }
        }
        function back() {
            if (canGoBack) {
                setCurrentIndex((prev) => prev - 1)
            }
        }
        return {
            name,
            stack,
            currentIndex,
            currentDrawer,
            currentDrawerProps,
            canGoBack,
            canGoForward,
            open,
            add,
            close,
            forward,
            back,
        }
    }, [stack, currentIndex, canGoBack, canGoForward])
    return state
}

const DrawerWithState = ({
    isOpen,
    drawerComponent,
    drawerProps,
    drawerStackState,
}) => {
    const [state, setState] = useState(drawerProps.initialDrawerState || {})
    const [searchState, setSearchState] = useState(
        drawerProps.initialSearchState || ''
    )
    const Component = drawerComponent
    return (
        <Drawer isOpen={isOpen}>
            <Component
                {...drawerProps}
                drawerStackState={drawerStackState}
                drawerState={state}
                setDrawerState={setState}
                searchState={searchState}
                setSearchState={setSearchState}
            />
        </Drawer>
    )
}

export default function DrawerStackProvider({ children }) {
    const [isComparing, setIsComparing] = useState(false)
    const mainStackState = useStackState('main')
    const comparingStackState = useStackState('comparing')
    const finalMainStackState = useMemo(
        () => ({
            isComparing,
            ...mainStackState,
            compare: (component, props) => {
                if (mainStackState.stack.length > 0) {
                    comparingStackState.open(component, props)
                } else {
                    throw new Error('Cannot compare without a main drawer open')
                }
            },
            close: () => {
                mainStackState.close()
                comparingStackState.close()
                setIsComparing(false)
            },
            startComparing: () => setIsComparing(true),
            stopComparing: () => setIsComparing(false),
        }),
        [isComparing, mainStackState, comparingStackState]
    )
    return (
        <DrawerStackContext.Provider value={finalMainStackState}>
            {children}
            {mainStackState.stack.length > 0 && (
                <DrawerStack
                    showComparingStack={comparingStackState.stack.length > 0}
                    comparingStack={
                        <CurrentDrawerStackContext.Provider
                            value={comparingStackState}
                        >
                            {comparingStackState.stack.map((drawer, index) => (
                                <DrawerWithState
                                    key={index}
                                    isOpen={
                                        drawer ===
                                        comparingStackState.currentDrawer
                                    }
                                    drawerComponent={drawer.component}
                                    drawerProps={drawer.props}
                                    drawerStackState={comparingStackState}
                                />
                            ))}
                        </CurrentDrawerStackContext.Provider>
                    }
                >
                    <CurrentDrawerStackContext.Provider
                        value={finalMainStackState}
                    >
                        {mainStackState.stack.map((drawer, index) => (
                            <DrawerWithState
                                key={index}
                                isOpen={drawer === mainStackState.currentDrawer}
                                drawerComponent={drawer.component}
                                drawerProps={drawer.props}
                                drawerStackState={finalMainStackState}
                            />
                        ))}
                    </CurrentDrawerStackContext.Provider>
                </DrawerStack>
            )}
        </DrawerStackContext.Provider>
    )
}
