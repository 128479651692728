import React from 'react'
import { Trans } from '@lingui/macro'
import ConfirmModal from '../../utils/ConfirmModal'

const StaffDeleteDataIdentifiersModal = ({
    dataIdentifier,
    isLoading,
    isDisabled,
    onDismiss,
    onConfirm,
}) => {
    return (
        <ConfirmModal
            title={<Trans>Delete Data Identifier</Trans>}
            description={
                <Trans>
                    Are you sure you want to delete{' '}
                    <strong>{dataIdentifier.name}</strong>? This action cannot
                    be undone!
                </Trans>
            }
            confirmButtonText={<Trans>Delete {dataIdentifier.name}</Trans>}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onDismiss={onDismiss}
            onConfirm={onConfirm}
        />
    )
}

export default StaffDeleteDataIdentifiersModal
