import React from 'react'
import { Trans } from '@lingui/macro'
import PageHeader from '../../layout/PageHeader'
import PageContent from '../../utils/PageContent'
import TableStateProvider from '../../providers/TableStateProvider'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import StaffHeader from '../../layout/StaffHeader'
import StaffScansPageTableActions from './StaffScansPageTableActions'
import StaffScansPageTable from './StaffScansPageTable'
import StaffScansPageSidebar from './StaffScansPageSidebar'

export default function StaffScansPageWithState({
    onChangeStatusFilter,
    statusFilter,
    auditsFilter,
    onChangeAuditsFilter,
    search,
    scans,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onSearch,
    isFetching,
    activeFilters,
    dateRangeState,
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
    initialOrderBy,
    onChangeOrderBy,
    onDeleteScanCompleted,
    organizationFilter,
    onChangeOrganizationFilter,
}) {
    return (
        <PageContainer>
            <StaffHeader />
            <SidebarContainer>
                <StaffScansPageSidebar />
                <PageContent size="full">
                    <div className="flex items-center justify-between">
                        <PageHeader title={<Trans>Scans</Trans>} />
                    </div>
                    <TableStateProvider
                        onChangeOrderBy={onChangeOrderBy}
                        initialOrderBy={initialOrderBy}
                    >
                        <StaffScansPageTableActions
                            onChangeStatusFilter={onChangeStatusFilter}
                            statusFilter={statusFilter}
                            auditsFilter={auditsFilter}
                            onChangeAuditsFilter={onChangeAuditsFilter}
                            dateRangeState={dateRangeState}
                            search={search}
                            onSearch={onSearch}
                            activeFilters={activeFilters}
                            onAddFilter={onAddFilter}
                            onRemoveFilter={onRemoveFilter}
                            onRemoveAllFilters={onRemoveAllFilters}
                            organizationFilter={organizationFilter}
                            onChangeOrganizationFilter={
                                onChangeOrganizationFilter
                            }
                        />
                        <StaffScansPageTable
                            scans={scans}
                            onDeleteScanCompleted={onDeleteScanCompleted}
                            isFetchingMore={isFetchingMore}
                            onFetchMore={onFetchMore}
                            hasMoreRecords={hasMoreRecords}
                            isFetching={isFetching}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
