import React from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import Button from '../../utils/Button'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import Tabs, { Tab } from '../../utils/Tabs'
import PurposesTags from '../../utils/PurposesTags'
import AlertCountTag from '../../utils/AlertCountTag'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import IdentifiedTrackersDrawerTabDetails from './IdentifiedTrackersDrawerTabDetails'
import IdentifiedTrackersDrawerTabObservations from './IdentifiedTrackersDrawerTabObservations'
import IdentifiedTrackersDrawerTabAlerts from './IdentifiedTrackersDrawerTabAlerts'
import ScanTrackersPageTableRowActionButtonWithState from '../audits-scans-trackers/ScanTrackersPageActionsDropdownMenu'
import IdentifiedTrackersDrawerTabUrls from './IdentifiedTrackersDrawerTabUrls'

const QUERY = gql`
    query IdentifiedTrackersDetailsDrawer($id: ID!) {
        identifiedTracker(id: $id) {
            id
            lastSeenAt
            rootDomain
            lastScanRequest {
                id
                method
                resourceType
                url
                search
                initiator
                cookies
                status
            }
            tracker {
                id
                domain
                belongsToOrganization
                purposes {
                    id
                    name
                    parent {
                        id
                        name
                        parent {
                            id
                            name
                        }
                    }
                }
                vendor {
                    ...PopoverVendorInfo
                }
            }
            alerts {
                id
                groupingId
                message
                type
                subjectName
                subjectType
                severityType
                occurrences
                audits {
                    id
                    name
                    imageUrl
                }
            }
            audits {
                id
                type
                name
                isMobile
                isWeb
                scanFrequency
                createdAt
                imageUrl
                tags
                domains {
                    id
                    url
                }
            }
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

export default function IdentifiedTrackersDetailsDrawer({
    identifiedTrackerId,
    auditId,
    level,
    startDate,
    endDate,
    period,
    onClassifyTrackerCompleted,
    onDeleteTrackerCompleted,
    drawerState,
    setDrawerState,
}) {
    const activeTab = drawerState.activeTab || 'details'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { id: identifiedTrackerId }
    const {
        data = {},
        error,
        loading,
    } = useQuery(QUERY, {
        variables,
    })
    if (loading) {
        return <DrawerLoading />
    }
    if (error) {
        return <DrawerError error={error} />
    }
    const { id, alerts, audits, tracker, rootDomain, lastSeenAt } =
        data.identifiedTracker
    return (
        <>
            <DrawerHeader
                avatarSrc={tracker?.vendor?.imageUrl}
                avatarIconName="cookie"
                title={rootDomain}
                afterTitle={
                    <PurposesTags
                        showAll
                        purposes={
                            tracker?.purposes && tracker?.purposes.length > 0
                                ? tracker?.purposes
                                : []
                        }
                    />
                }
                description={tracker?.description}
                buttons={
                    <ScanTrackersPageTableRowActionButtonWithState
                        tracker={tracker}
                        scanTracker={data.identifiedTracker}
                        onClassifyTrackerCompleted={onClassifyTrackerCompleted}
                        onDeleteTrackerCompleted={onDeleteTrackerCompleted}
                    >
                        <Button
                            type="white"
                            size="sm"
                            iconBeforeProps={{
                                name: 'menu-dots',
                                size: 'sm',
                            }}
                        />
                    </ScanTrackersPageTableRowActionButtonWithState>
                }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'details'}
                    onClick={() => setActiveTab('details')}
                >
                    <Trans>Details</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'observations'}
                    onClick={() => setActiveTab('observations')}
                >
                    <Trans>Observations</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'alerts'}
                    onClick={() => setActiveTab('alerts')}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag alerts={alerts} />
                </Tab>
                <Tab
                    isActive={activeTab === 'urls'}
                    onClick={() => setActiveTab('urls')}
                >
                    <Trans>Requests</Trans>
                </Tab>
            </Tabs>
            <DrawerContent>
                {activeTab === 'details' && (
                    <IdentifiedTrackersDrawerTabDetails
                        lastSeenAt={lastSeenAt}
                        identifiedTracker={data.identifiedTracker}
                    />
                )}
                {activeTab === 'observations' && (
                    <IdentifiedTrackersDrawerTabObservations
                        identifiedTrackerId={identifiedTrackerId}
                        auditId={auditId}
                        audits={audits}
                        rootDomain={rootDomain}
                    />
                )}
                {activeTab === 'alerts' && (
                    <IdentifiedTrackersDrawerTabAlerts
                        alerts={alerts}
                        startDate={startDate}
                        endDate={endDate}
                        period={period}
                        level={level}
                        auditId={auditId}
                    />
                )}
                {activeTab === 'urls' && (
                    <IdentifiedTrackersDrawerTabUrls
                        identifiedTrackerId={id}
                        level={level}
                        auditId={auditId}
                    />
                )}
            </DrawerContent>
        </>
    )
}
