import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'

export const ORGANIZATION_FRAGMENT = gql`
    fragment OrganizationPage on Organization {
        id
        name
        websiteUrl
        scanFrequency
        proxyType
        takeScreenshots
        maxCookieRetention
        showVendorTableAlerts
        showCookieTableAlerts
        vendorTable {
            id
        }
        cookieTable {
            id
        }
    }
`

const QUERY = gql`
    query settingsPage {
        me {
            currentOrganization {
                ...OrganizationPage
            }
        }
        vendorTables {
            edges {
                node {
                    id
                    name
                }
            }
        }
        cookieTables {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
    ${ORGANIZATION_FRAGMENT}
`

const useSettingsPageQuery = () => {
    const variables = {}
    const {
        data = { me: {} },
        error,
        loading,
        ...result
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
    })
    return {
        error,
        organization: data.me.currentOrganization || {},
        cookieTables: connectionToCollection(data.cookieTables),
        vendorTables: connectionToCollection(data.vendorTables),
        isFetching: loading,
        ...result,
    }
}

export default useSettingsPageQuery
