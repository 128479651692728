import { gql, useMutation } from '@apollo/client'

export const UPDATE_PURPOSE_IDENTIFIER_MUTATION = gql`
    mutation staffUpdatePurposeIdentifier(
        $trackerId: ID!
        $purposeIdentifierId: ID!
        $input: UpdatePurposeIdentifierInput!
    ) {
        staffUpdatePurposeIdentifier(
            trackerId: $trackerId
            purposeIdentifierId: $purposeIdentifierId
            input: $input
        ) {
            purposeIdentifier {
                id
            }
        }
    }
`

export default function useCreatePurposeIdentifierMutation(options) {
    return useMutation(UPDATE_PURPOSE_IDENTIFIER_MUTATION, options)
}
