import React from 'react'
import { Trans } from '@lingui/macro'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import ActionButton from '../../utils/ActionButton'

export default function UpdateVendorTableVersionActions({
    isLoading,
    canDelete,
    onDelete,
}) {
    return (
        <DropdownMenu
            position="bottom-end"
            content={({ close }) => (
                <DropdownMenuItem
                    iconName="bin"
                    disabled={!canDelete}
                    onClick={(e) => {
                        onDelete()
                        close()
                        e.stopPropagation()
                    }}
                >
                    <Trans>Delete version</Trans>
                </DropdownMenuItem>
            )}
        >
            <ActionButton disabled={isLoading} />
        </DropdownMenu>
    )
}
