import React from 'react'
import { Trans } from '@lingui/macro'
import { ORDER_BY_DESC } from '@clew/shared/src/constants'
import Button from '../../utils/Button'
import DrawerDetailsItem from '../../utils/DrawerDetailsItem'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerLoading from '../../utils/DrawerLoading'
import useNotifications from '../../hooks/useNotifications'
import Icon from '../../utils/Icon'
import DrawerItem from '../../utils/DrawerItem'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import Tag from '../../utils/Tag'
import ButtonLink from '../../utils/ButtonLink'
import DateTime from '../../utils/DateTime'
import useUpdateVendorTableMutation from './useUpdateVendorTableMutation'
import useUpdateVendorTablePageQuery, {
    useVendorTableVersionsQuery,
} from './useUpdateVendorTablePageQuery'
import useUpdateVendorTableFormState from './useUpdateVendorTableFormState'
import DrawerError from '../../utils/DrawerError'
import InfiniteScroll from '../../utils/InfiniteScroll'
import UpdateVendorTableVersionActionsWithState from './UpdateVendorTableVersionActionsWithState'

export default function UpdateVendorTableDrawer({
    vendorTableId,
    onCompleted,
    onCreateNewVersion,
    onCustomizeVendorTable,
    onDismiss,
}) {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const { NEXT_PUBLIC_API_URL } = process.env
    const { isFetching, vendorTable, error } = useUpdateVendorTablePageQuery({
        vendorTableId,
    })

    const {
        isFetching: isLoadingVersions,
        isFetchingMore,
        handleFetchMore,
        hasMore,
        vendorTableVersions,
        error: versionsError,
        refetch,
    } = useVendorTableVersionsQuery({
        vendorTableId,
        orderBy: { createdAt: ORDER_BY_DESC },
    })

    const [updateVendorTable] = useUpdateVendorTableMutation({
        onCompleted,
    })
    const formState = useUpdateVendorTableFormState(vendorTable)
    const { getNativeInputProps, getErrorMessages } = formState

    const onSubmit = async () => {
        if (formState.validate()) {
            try {
                const { name } = formState.values
                const input = {
                    name,
                }
                const variables = {
                    id: vendorTableId,
                    input,
                }
                await updateVendorTable({ variables })
                formState.resetForm()
                onDismiss()
                dispatchSuccess({
                    message: (
                        <Trans>
                            Vendor table <strong>{input.name}</strong> updated!
                        </Trans>
                    ),
                })
            } catch (e) {
                dispatchGraphqlError(e)
            }
        }
    }

    const url = `${NEXT_PUBLIC_API_URL}/vendor-table/${vendorTableId}`

    if (isFetching || isLoadingVersions) return <DrawerLoading />
    if (error || versionsError) return <DrawerError error={error} />
    return (
        <>
            <DrawerHeader
                title={vendorTable.name}
                buttons={
                    <>
                        <Button
                            type="white"
                            iconBeforeProps={{
                                name: 'palette',
                                size: 'sm',
                            }}
                            onClick={onCustomizeVendorTable}
                        >
                            Customize Embed
                        </Button>
                        <Button
                            type="white"
                            iconBeforeProps={{
                                name: 'link-external-01',
                                size: 'sm',
                            }}
                            onClick={() => window.open(url, '_blank')}
                        >
                            Preview
                        </Button>
                    </>
                }
            />
            <DrawerDetailsItem label="Link to published table" className="px-6">
                <div className="flex items-center gap-1">
                    <Icon name="link-01" size="xs" />
                    <p className="text-sm text-gray-600">{url}</p>
                </div>
            </DrawerDetailsItem>
            <DrawerItem>
                <FormGroup label={<Trans>Name</Trans>}>
                    <FormInput
                        errors={getErrorMessages('name')}
                        {...getNativeInputProps('name')}
                    />
                </FormGroup>
                <div className="flex justify-end gap-2">
                    <Button type="white" onClick={onDismiss}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button onClick={onSubmit}>
                        <Trans>Update</Trans>
                    </Button>
                </div>
            </DrawerItem>
            <DrawerItem className="flex-col px-6">
                <p className="text-sm font-medium">Versions</p>
                <p className="w-full text-sm text-gray-600">
                    You can create multiple versions of each vendor table. By
                    creating a new version you can compare both old and new
                    versions.
                </p>
            </DrawerItem>
            <DrawerItem className="mt-4 h-full overflow-auto">
                <div className="pb-4">
                    <ButtonLink onClick={onCreateNewVersion}>
                        <Trans>Add new version</Trans>
                    </ButtonLink>
                </div>
                <InfiniteScroll
                    isFetchingMore={isFetchingMore}
                    onFetchMore={handleFetchMore}
                    hasMoreRecords={hasMore}
                >
                    {vendorTableVersions.map((version, index) => (
                        <div
                            key={version?._id || index}
                            className="flex flex-row items-center justify-between border-b border-gray-200 py-4"
                        >
                            <div>
                                <h2 className="text-sm font-semibold text-gray-700">
                                    {version?.name}
                                </h2>
                                <p className="text-sm text-gray-600">
                                    <Trans>Created on:</Trans>{' '}
                                    <DateTime>{version?.createdAt}</DateTime>
                                </p>
                                <div className="flex items-end text-sm text-gray-600">
                                    <p>
                                        <Trans>Period from</Trans>{' '}
                                        <DateTime dateOnly dateFormat="dd MMMM">
                                            {version?.startDate}
                                        </DateTime>
                                    </p>
                                    <p className="mx-1">
                                        <Trans>until</Trans>
                                    </p>
                                    <p>
                                        <DateTime dateOnly dateFormat="dd MMMM">
                                            {version?.endDate}
                                        </DateTime>{' '}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-2">
                                {index === 0 && (
                                    <Tag type="green">
                                        <Trans>Published</Trans>
                                    </Tag>
                                )}
                                <UpdateVendorTableVersionActionsWithState
                                    vendorTableVersion={version}
                                    versionCount={vendorTableVersions.length}
                                    onDeleteCompleted={() => refetch()}
                                />
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            </DrawerItem>
        </>
    )
}
