import { useState, useEffect, useRef } from 'react'

/**
 * Custom hook to throttle state updates.
 * - The first update happens immediately.
 * - Subsequent updates are throttled to avoid excessive re-renders.
 *
 * @param {any} value - The value to be throttled.
 * @param {number} delay - The delay in milliseconds.
 * @returns {any} - The throttled value.
 */
const useThrottledState = (value, delay = 3000) => {
    const [throttledValue, setThrottledValue] = useState(value)
    const lastUpdated = useRef(Date.now())

    useEffect(() => {
        const now = Date.now()
        const timeSinceLastUpdate = now - lastUpdated.current

        if (timeSinceLastUpdate >= delay) {
            // If the delay has passed, update immediately
            setThrottledValue(value)
            lastUpdated.current = now
            return () => {}
        }
        // Otherwise, schedule an update after the remaining delay
        const handler = setTimeout(() => {
            setThrottledValue(value)
            lastUpdated.current = Date.now()
        }, delay - timeSinceLastUpdate)

        return () => clearTimeout(handler)
    }, [value, delay])

    return throttledValue
}

export default useThrottledState
