import React from 'react'
import { Trans } from '@lingui/macro'
import * as constants from '@clew/shared/src/constants'
import TableFilterClearButton from '../../utils/TableFilterClearButton'
import TableFilterSelect from '../../utils/TableFilterSelect'
import TableFilterSelectOption from '../../utils/TableFilterSelectOption'
import TableFiltersMenu from '../../utils/TableFiltersMenu'
import TableFiltersMenuItem from '../../utils/TableFiltersMenuItem'

const { AVAILABLE_VENDOR_TYPES } = constants

export default function VendorsPageTableActionsFilters({
    vendorTypeFilter,
    activeFilters = [],
    onAddFilter,
    onRemoveFilter,
    onRemoveAllFilters,
    onChangeVendorTypeFilter,
}) {
    let vendorTypeLabel = AVAILABLE_VENDOR_TYPES.find(
        (type) => type === vendorTypeFilter
    )
    if (!vendorTypeLabel) {
        vendorTypeLabel = <Trans>Choose type</Trans>
    }
    return (
        <div className="flex items-center gap-2">
            <TableFilterClearButton
                name="hasNoDescription"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No description</Trans>
            </TableFilterClearButton>
            <TableFilterClearButton
                name="hasNoCookies"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No cookies</Trans>
            </TableFilterClearButton>
            <TableFilterClearButton
                name="hasNoTrackers"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No trackers</Trans>
            </TableFilterClearButton>
            <TableFilterClearButton
                name="hasNoDomain"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
            >
                <Trans>No Domain</Trans>
            </TableFilterClearButton>
            <TableFilterSelect
                name="type"
                activeFilters={activeFilters}
                onRemove={onRemoveFilter}
                label={vendorTypeLabel}
            >
                {AVAILABLE_VENDOR_TYPES.map((type) => (
                    <TableFilterSelectOption
                        key={type}
                        onClick={() => onChangeVendorTypeFilter(type)}
                    >
                        {type}
                    </TableFilterSelectOption>
                ))}
            </TableFilterSelect>
            <TableFiltersMenu
                onRemoveAll={onRemoveAllFilters}
                activeFilters={activeFilters}
            >
                <TableFiltersMenuItem
                    iconName="help-octagon"
                    name="hasNoDescription"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No description</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="cookie"
                    name="hasNoCookies"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No cookies</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="mark"
                    name="hasNoTrackers"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No trackers</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="globe"
                    name="hasNoDomain"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>No domain</Trans>
                </TableFiltersMenuItem>
                <TableFiltersMenuItem
                    iconName="bell"
                    name="type"
                    activeFilters={activeFilters}
                    onAdd={onAddFilter}
                >
                    <Trans>Vendor type</Trans>
                </TableFiltersMenuItem>
            </TableFiltersMenu>
        </div>
    )
}
