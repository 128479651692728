import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { Trans } from '@lingui/macro'
import StaffDeleteDataIdentifiersModal from './StaffDeleteDataIdentifiersModal'
import useNotifications from '../../hooks/useNotifications'

const MUTATION = gql`
    mutation staffDeleteDataIdentifier($id: ID!) {
        staffDeleteDataIdentifier(id: $id)
    }
`

const useDeleteDataIdentifier = (options) => useMutation(MUTATION, options)

const StaffDeleteDataIdentifiersModalWithState = ({
    dataIdentifier,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [deleteDataIdentifier, { loading }] = useDeleteDataIdentifier({
        onCompleted,
        onError,
    })

    return (
        <StaffDeleteDataIdentifiersModal
            dataIdentifier={dataIdentifier}
            isLoading={loading}
            isDisabled={loading}
            onDismiss={onDismiss}
            onConfirm={async () => {
                try {
                    await deleteDataIdentifier({
                        variables: { id: dataIdentifier.id },
                    })
                    onDismiss()
                    dispatchSuccess({
                        message: <Trans>dataIdentifier Deleted</Trans>,
                    })
                } catch (error) {
                    dispatchGraphqlError(error)
                }
            }}
        />
    )
}

export default StaffDeleteDataIdentifiersModalWithState
