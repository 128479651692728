import React from 'react'
import { Trans } from '@lingui/macro'
import SearchInput from '../../utils/SearchInput'
import TableActionsContainer from '../../utils/TableActionsContainer'
import Button from '../../utils/Button'

export default function StaffDataIdentifiersPageTableActions({
    search,
    onSearch,
    onCreateDataIdentifier,
}) {
    return (
        <TableActionsContainer isSticky>
            <div className="flex w-full items-center justify-between">
                <div className="flex items-center gap-2">
                    <Button
                        type="white"
                        size="sm"
                        iconBeforeProps={{
                            name: 'check-done-01',
                            size: 'sm',
                        }}
                        disabled
                    />
                    <SearchInput value={search} onChange={onSearch} />
                </div>

                <Button
                    onClick={onCreateDataIdentifier}
                    type="primary"
                    size="sm"
                    iconBeforeProps={{
                        name: 'plus',
                        size: 'sm',
                    }}
                    className="ml-auto"
                >
                    <Trans>Create Data Identifier</Trans>
                </Button>
            </div>
        </TableActionsContainer>
    )
}
