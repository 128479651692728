import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query auditRecordMobileActionsPage($id: ID!) {
        audit(id: $id) {
            id
            name
            type
            auditBuilds {
                id
                name
                deviceOs
                version
                iconUrl
                bundle
                appetizeBuildId
            }
        }
        availableDevices {
            id
            platform
            osVersions
            name
        }
    }
`

export default function useAuditRecordMobileActionsPageQuery({ id }) {
    const variables = { id }
    const {
        data = {},
        error,
        loading,
        ...result
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        audit: data.audit || {},
        availableDevices: data.availableDevices || [],
        isFetching: loading,
        ...result,
    }
}

const AUDIT_SCENARIO_QUERY = gql`
    query auditScenario($id: ID!) {
        auditScenario(id: $id) {
            id
            name
            type
            deviceOs
            purposes {
                id
                name
            }
            customPurposes
            actions {
                action
                isRequired
            }
            deviceSettings
        }
    }
`

export function useAuditRecordMobileActionsPageAuditScenarioQuery({
    id = null,
}) {
    const variables = { id }
    const {
        data = {},
        error,
        loading,
        ...result
    } = useQuery(AUDIT_SCENARIO_QUERY, {
        variables,
        skip: id === null,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        auditScenario: data.auditScenario || {},
        isFetching: loading,
        ...result,
    }
}
