import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableRow from '../../utils/TableRow'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import Button from '../../utils/Button'
import Tabs, { Tab } from '../../utils/Tabs'
import Tag from '../../utils/Tag'
import Tooltip from '../../utils/Tooltip'
import Icon from '../../utils/Icon'
import PurposesTags from '../../utils/PurposesTags'
import Alert from '../../utils/Alert'
import SaveLiveScanModalWithState from './SaveLiveScanModalWithState'

const AuditsLivePageSidebar = ({
    scan,
    audit,
    isStarting,
    isStarted,
    isStopping,
    isStopped,
    onStart,
    onStop,
    scanRequests,
    scanCookies,
}) => {
    const history = useHistory()
    const [activeTab, setActiveTab] = useState('requests')
    const [saveModalIsOpen, setSaveModalIsOpen] = useState(false)
    return (
        <>
            <div className="flex w-1/3 flex-col border-l border-gray-200 bg-white">
                <div className="px-6 py-8">
                    <h3 className="text-2xl font-medium leading-6 text-gray-900">
                        Record live scan
                    </h3>
                </div>
                <Tabs hasMarginBottom={false} className="px-6">
                    <Tab
                        isActive={activeTab === 'requests'}
                        onClick={() => setActiveTab('requests')}
                    >
                        <Trans>Requests</Trans>
                        <Tag>{scanRequests.length}</Tag>
                    </Tab>
                    <Tab
                        isActive={activeTab === 'cookies'}
                        onClick={() => setActiveTab('cookies')}
                    >
                        <Trans>Cookies</Trans>
                        <Tag>{scanCookies.length}</Tag>
                    </Tab>
                    <Tab
                        isActive={activeTab === 'alerts'}
                        onClick={() => setActiveTab('alerts')}
                    >
                        <Trans>Alerts</Trans>
                    </Tab>
                </Tabs>
                <div className="flex-1 overflow-auto px-6 pt-6">
                    {activeTab === 'requests' && scanRequests.length > 0 && (
                        <Table hasMinWidth={false} isRounded hasHeader={false}>
                            <TableBody>
                                {scanRequests.map((request) => {
                                    const {
                                        id,
                                        url,
                                        method,
                                        resourceType,
                                        tracker,
                                        hasPostData,
                                    } = request
                                    const { hostname, pathname, searchParams } =
                                        new URL(url)
                                    const hasSearchParams =
                                        searchParams &&
                                        searchParams.toString().length > 0
                                    let tagPurposes = []
                                    if (tracker) {
                                        tagPurposes = tracker.purposes
                                    }
                                    const finalAvatarSrc =
                                        tracker?.vendor?.imageUrl
                                    // if (
                                    //     getRootDomain(pageUrl) ===
                                    //     getRootDomain(url)
                                    // ) {
                                    //     finalAvatarSrc = audit.imageUrl
                                    // }
                                    return (
                                        <TableRow isSelectable key={url}>
                                            <TableCellAvatar
                                                py="sm"
                                                px="sm"
                                                truncate
                                                avatarIconName="code-browser"
                                                avatarSrc={finalAvatarSrc}
                                                className="w-2/3"
                                                title={hostname}
                                                text={
                                                    <Tooltip
                                                        content={url}
                                                        contentClassName="max-w-[400px] break-all		"
                                                    >
                                                        {pathname}
                                                    </Tooltip>
                                                }
                                            />
                                            <TableCell py="sm">
                                                <div className="flex justify-end gap-1">
                                                    {hasSearchParams && (
                                                        <Tooltip
                                                            as="div"
                                                            className="h-5"
                                                            content={
                                                                <Trans>
                                                                    Has search
                                                                    params
                                                                </Trans>
                                                            }
                                                        >
                                                            <Tag
                                                                type="gray"
                                                                className="h-5"
                                                            >
                                                                <Icon
                                                                    name="search-param"
                                                                    size="sm"
                                                                    className="text-gray-600"
                                                                />
                                                            </Tag>
                                                        </Tooltip>
                                                    )}
                                                    {hasPostData && (
                                                        <Tooltip
                                                            as="div"
                                                            content={
                                                                <Trans>
                                                                    Sends post
                                                                    data
                                                                </Trans>
                                                            }
                                                        >
                                                            <Tag
                                                                type="gray"
                                                                className="!p-0.5"
                                                            >
                                                                <Icon
                                                                    name="arrow-up-circle"
                                                                    size="xs"
                                                                    className="text-gray-600"
                                                                />
                                                            </Tag>
                                                        </Tooltip>
                                                    )}
                                                    <PurposesTags
                                                        showAll
                                                        purposes={tagPurposes}
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    )}
                    {activeTab === 'cookies' && scanCookies.length > 0 && (
                        <Table hasMinWidth={false} isRounded hasHeader={false}>
                            <TableBody>
                                {scanCookies.map((scanCookie) => {
                                    const {
                                        name,
                                        value,
                                        rootDomain,
                                        cookie,
                                        tracker,
                                    } = scanCookie
                                    let vendor = null
                                    let tagPurposes = []
                                    if (cookie) {
                                        tagPurposes = cookie.purposes
                                        vendor = cookie.vendor
                                    } else if (tracker) {
                                        tagPurposes = tracker.purposes
                                        vendor = tracker.vendor
                                    }
                                    return (
                                        <TableRow isSelectable key={name}>
                                            <TableCellAvatar
                                                py="sm"
                                                px="sm"
                                                truncate
                                                avatarIconName="cookie"
                                                avatarSrc={vendor?.imageUrl}
                                                className="w-2/3"
                                                title={name}
                                                text={rootDomain}
                                            />
                                            <TableCell py="sm">
                                                <div className="flex justify-end gap-1">
                                                    <PurposesTags
                                                        showAll
                                                        purposes={tagPurposes}
                                                    />
                                                    <Tooltip
                                                        content={value}
                                                        contentClassName="max-w-lg text-wrap"
                                                    >
                                                        <Tag type="gray">
                                                            Show value
                                                        </Tag>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    )}
                    {activeTab === 'alerts' && (
                        <Alert
                            type="warning"
                            className="mb-6"
                            title={<Trans>No alerts during recording</Trans>}
                        >
                            <Trans>
                                We cannot show you alerts while you are
                                recording save the scan to see the alerts.
                            </Trans>
                        </Alert>
                    )}
                </div>
                <div className="flex justify-between border-t border-gray-200 px-6 py-4">
                    <div />
                    <div className="flex gap-2">
                        {isStarted && !isStopped && (
                            <Button
                                type="danger"
                                iconBeforeProps={{
                                    name: 'stop',
                                }}
                                onClick={() => onStop()}
                                loading={isStopping}
                            >
                                Stop live scan
                            </Button>
                        )}
                        {isStopped && (
                            <Button
                                onClick={() => {
                                    setSaveModalIsOpen(true)
                                }}
                            >
                                <Trans>Save live scan</Trans>
                            </Button>
                        )}
                        {!isStarted && (
                            <Button
                                onClick={() => onStart()}
                                loading={isStarting}
                                iconBeforeProps={{
                                    name: 'play',
                                }}
                            >
                                <Trans>Start live scan</Trans>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            {saveModalIsOpen && (
                <SaveLiveScanModalWithState
                    scanId={scan.id}
                    onDismiss={() => setSaveModalIsOpen(false)}
                    onCompleted={() => {
                        history.push(`/audits/${audit.id}/scans`)
                    }}
                />
            )}
        </>
    )
}

export default AuditsLivePageSidebar
