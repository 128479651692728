import React from 'react'
import { Trans } from '@lingui/macro'
import PageLoading from '../../utils/PageLoading'
import useNotifications from '../../hooks/useNotifications'
import SettingsPage from './SettingsPage'
import useUpdateSettingsMutation from './useUpdateSettingsMutation'
import useUpdateSettingsFormState from './useUpdateSettingsFormState'
import useUpdateSettingsAuditFormState from './useUpdateSettingsAuditFormState'
import useUpdateSettingsAuditAlertsFormState from './useUpdateSettingsAuditAlertsFormState'
import useSettingsPageQuery from './useSettingsPageQuery'

const SettingsPageWithState = () => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const { organization, cookieTables, vendorTables, isFetching } =
        useSettingsPageQuery()
    const [updateOrganization, { loading: isUpdating }] =
        useUpdateSettingsMutation()
    const settingsAuditFormState = useUpdateSettingsAuditFormState(organization)
    const settingsFormState = useUpdateSettingsFormState(organization)
    const alertSettingsFormState =
        useUpdateSettingsAuditAlertsFormState(organization)
    return (
        <SettingsPage
            organization={organization}
            cookieTables={cookieTables}
            vendorTables={vendorTables}
            isUpdating={isUpdating}
            isFetching={isFetching}
            settingsFormState={settingsFormState}
            settingsAuditFormState={settingsAuditFormState}
            alertSettingsFormState={alertSettingsFormState}
            onUpdateSettings={async () => {
                if (settingsFormState.validate()) {
                    try {
                        const input = settingsFormState.valuesToInput()
                        const variables = {
                            id: organization.id,
                            input,
                        }
                        await updateOrganization({ variables })
                        dispatchSuccess({
                            message: (
                                <Trans>Organization settings updated!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
            onUpdateAuditSettings={async () => {
                if (settingsAuditFormState.validate()) {
                    try {
                        const input = settingsAuditFormState.valuesToInput()
                        if (input.proxyType === 'NONE') {
                            input.proxyType = null
                        }
                        const variables = {
                            id: organization.id,
                            input,
                        }
                        await updateOrganization({ variables })
                        dispatchSuccess({
                            message: (
                                <Trans>Organization settings updated!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
            onUpdateAlertSettings={async () => {
                if (alertSettingsFormState.validate()) {
                    try {
                        const originalInput =
                            alertSettingsFormState.valuesToInput()
                        const input = {
                            ...originalInput,
                            maxCookieRetention: parseInt(
                                originalInput.maxCookieRetention,
                                10
                            ),
                        }
                        if (input.vendorTableId === 'NONE') {
                            input.vendorTableId = null
                        }
                        if (input.cookieTableId === 'NONE') {
                            input.cookieTableId = null
                        }
                        const variables = {
                            id: organization.id,
                            input,
                        }
                        await updateOrganization({ variables })
                        dispatchSuccess({
                            message: (
                                <Trans>Organization alert rules updated!</Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default SettingsPageWithState
