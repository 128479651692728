import React, { useState } from 'react'
import useSearch from '../../hooks/useSearch'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import StaffScansPage from './StaffScansPage'
import useStaffScansPageQuery from './useStaffScansPageQuery'
import useFilters from '../../hooks/useFilters'
import useScanRangeSelect from '../alerts/useScanRangeSelect'
import useRefetch from '../../hooks/useRefetch'

export default function StaffScansPageWithState() {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const dateRangeState = useScanRangeSelect('none')

    const [auditsFilter, onChangeAuditsFilter] = useState(null)
    const [organizationFilter, onChangeOrganizationFilter] = useState(null)
    const [statusFilter, onChangeStatusFilter] = useState(null)

    const { startDate, endDate } = dateRangeState

    const {
        activeFilters,
        onRemoveFilter,
        onAddFilter,
        onRemoveAllFilters,
        filterValues,
    } = useFilters({
        audit: auditsFilter,
        organizationId: organizationFilter?.id,
        status: statusFilter,
    })

    const [orderBy, onChangeOrderBy] = useState({ id: 'DESC' })
    const { audit, ...scanFilterValues } = filterValues

    const {
        scans,
        error,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useStaffScansPageQuery({
        search,
        filters: {
            ...scanFilterValues,
            auditId: audit?.id,
            startDate,
            endDate,
        },
        orderBy,
    })

    useRefetch(refetch)

    return (
        <StaffScansPage
            activeFilters={activeFilters}
            onRemoveFilter={onRemoveFilter}
            onAddFilter={onAddFilter}
            onRemoveAllFilters={onRemoveAllFilters}
            search={search}
            onSearch={handleSearch}
            dateRangeState={dateRangeState}
            auditsFilter={filterValues.audit}
            onChangeAuditsFilter={onChangeAuditsFilter}
            organizationFilter={filterValues.organizationId}
            onChangeOrganizationFilter={onChangeOrganizationFilter}
            statusFilter={filterValues.status}
            onChangeStatusFilter={onChangeStatusFilter}
            scans={scans}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            hasMoreRecords={hasMore}
            onFetchMore={handleFetchMore}
            initialOrderBy={orderBy}
            onChangeOrderBy={(value, direction) =>
                onChangeOrderBy({ [value]: direction })
            }
            onDeleteScanCompleted={() => refetch()}
            error={error}
        />
    )
}
