import React, { useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import { Trans } from '@lingui/macro'
import Avatar from '../utils/Avatar'
import Icon from '../utils/Icon'
import DropdownMenu from '../utils/DropdownMenu'
import * as constants from '../../constants'
import DropdownMenuItem from '../utils/DropdownMenuItem'
import DropdownMenuItemDivider from '../utils/DropdownMenuItemDivider'
import { CreateAuditModal } from '../pages/audits/CreateAuditModal'

const { USER_TYPE_STAFF: STAFF } = constants

export default function HeaderProfile({
    currentUser,
    onLogout,
    onOpen,
    onClose,
    isOpen,
}) {
    const { NEXT_PUBLIC_APP_ENV } = process.env
    const [isCreateAuditModalOpen, setCreateAuditModalOpen] = useState(false)
    const isProduction = NEXT_PUBLIC_APP_ENV === 'production'
    const isViewingStaff = useRouteMatch({
        path: '/staff',
    })
    if (isCreateAuditModalOpen) {
        return (
            <CreateAuditModal
                onDismiss={() => setCreateAuditModalOpen(false)}
            />
        )
    }
    return (
        <div className="flex items-center gap-8">
            {!isProduction && (
                <div className="left top-full rounded-full border border-amber-300 bg-amber-100 px-2 py-0.5 text-[10px] font-bold uppercase text-amber-700">
                    {NEXT_PUBLIC_APP_ENV} Env
                </div>
            )}
            <DropdownMenu
                onOpen={onOpen}
                onClose={onClose}
                content={
                    <div className="text-slate-700">
                        <div className="flex items-center gap-3 px-4 py-3">
                            <Avatar name1={currentUser.firstName} />
                            <div className="text-sm">
                                <h3 className="font-semibold">
                                    {currentUser.fullName}
                                </h3>
                                <p className="text-slate-600">
                                    {currentUser.email}
                                </p>
                            </div>
                        </div>
                        <DropdownMenuItemDivider />
                        <div className="font-medium">
                            {currentUser.type === STAFF && !isViewingStaff && (
                                <Link to="/staff" className="text-inherit">
                                    <DropdownMenuItem
                                        spacing="md"
                                        iconName="dashboard"
                                    >
                                        <Trans>Staff portal</Trans>
                                    </DropdownMenuItem>
                                </Link>
                            )}
                            {isViewingStaff && (
                                <Link to="/" className="text-inherit">
                                    <DropdownMenuItem
                                        spacing="md"
                                        iconName="building-07"
                                    >
                                        <Trans>Current organization </Trans>
                                    </DropdownMenuItem>
                                </Link>
                            )}
                            <DropdownMenuItem
                                onClick={() => setCreateAuditModalOpen(true)}
                                spacing="md"
                                iconName="clock-fast-forward"
                            >
                                <Trans>Create audit</Trans>
                            </DropdownMenuItem>
                            <Link
                                to="/settings/users/invite"
                                className="text-inherit"
                            >
                                <DropdownMenuItem
                                    spacing="md"
                                    iconName="user-plus-01"
                                >
                                    <Trans>Invite user</Trans>
                                </DropdownMenuItem>
                            </Link>
                            <DropdownMenuItemDivider />
                            <button onClick={onLogout} type="button">
                                <DropdownMenuItem
                                    spacing="md"
                                    iconName="log-out-01"
                                >
                                    <Trans>Logout</Trans>
                                </DropdownMenuItem>
                            </button>
                        </div>
                    </div>
                }
            >
                <button
                    type="button"
                    className={clsx(
                        'group flex items-center gap-1 rounded-lg',
                        isOpen &&
                            'ring-opacity-15 transition-shadow duration-300 ease-in-out'
                    )}
                    aria-label="Toggle Profile Menu"
                >
                    <Avatar name={currentUser.firstName} />
                    <div className="rounded-md px-0.5 py-2 group-hover:bg-slate-100">
                        <Icon
                            name="chevron-selector-vertical"
                            size="sm"
                            className="text-slate-400"
                        />
                    </div>
                </button>
            </DropdownMenu>
        </div>
    )
}
