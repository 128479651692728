import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import AuditAlertPageTableHeaderRow from './AuditAlertPageTableHeaderRow'
import AuditAlertPageTableRow from './AuditAlertsPageTableRow'
import EmptyPageCard from '../../utils/EmptyPageCard'
import Button from '../../utils/Button'

export default function AuditAlertsPageTable({
    scanRangeState,
    alerts = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    onAlertStatusChanged,
}) {
    const hasAlerts = isFetching || alerts.length > 0
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            {hasAlerts ? (
                <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                    <thead>
                        <AuditAlertPageTableHeaderRow />
                    </thead>
                    <TableBody>
                        {alerts.map((alert) => (
                            <AuditAlertPageTableRow
                                key={alert.id}
                                alert={alert}
                                scanRangeState={scanRangeState}
                                onAlertStatusChanged={onAlertStatusChanged}
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyPageCard
                    iconName="check-circle"
                    title={<Trans>No alerts</Trans>}
                    description={
                        <Trans>
                            No alerts were found for this audit
                            <br />
                            Expand your filters or start a new scan to find the
                            alerts for your domains.
                        </Trans>
                    }
                />
            )}
        </InfiniteScroll>
    )
}
