import React from 'react'
import clsx from 'clsx'

const FormInputErrors = ({ errors = [], className }) => {
    if (errors.length === 0) {
        return null
    }
    return errors.map((error, index) => (
        <p
            key={error}
            data-testid="FormInputError"
            className={clsx(
                'ml-1 text-sm text-red-600',
                index === 0 && 'mt-1',
                className
            )}
        >
            {error}
        </p>
    ))
}

export default FormInputErrors
