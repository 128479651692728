import React from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router'
import FormGroup from '../../utils/FormGroup'
import Select from '../../utils/Select'
import Button from '../../utils/Button'

export function AuditRecordMobileActionsDevice({
    isLoaded,
    isDisabled,
    auditBuilds,
    buildId,
    onChangeAuditBuild,
}) {
    const history = useHistory()
    return (
        <div className="mt-10 flex items-center justify-center">
            <div>
                <div className="mb-6 flex gap-3">
                    {buildId && auditBuilds.length > 0 && (
                        <FormGroup label={<Trans>Build</Trans>}>
                            <Select
                                value={buildId}
                                onChange={onChangeAuditBuild}
                            >
                                {auditBuilds.map((auditBuild) => (
                                    <option
                                        key={auditBuild.appetizeBuildId}
                                        value={auditBuild.appetizeBuildId}
                                    >
                                        {`${auditBuild.name} (${auditBuild.version})`}
                                    </option>
                                ))}
                            </Select>
                        </FormGroup>
                    )}
                </div>
                {buildId ? (
                    <iframe
                        key={buildId}
                        id="mobile_recording_iframe"
                        className={`mx-auto ${
                            isDisabled ? 'pointer-events-none' : ''
                        }`}
                        src={`https://appetize.io/embed/${buildId}`}
                        width="378px"
                        height="800px"
                        title="appetize"
                    />
                ) : (
                    <div>
                        <div className="flex flex-col items-center justify-center">
                            <div className="mx-auto mt-6 w-full max-w-xs">
                                <div className="relative aspect-[5/11] w-full overflow-hidden rounded-3xl border-8 border-gray-300">
                                    <div className="absolute inset-0 animate-pulse bg-gradient-to-b from-gray-200 via-gray-300 to-gray-200" />

                                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                                        <Button
                                            type="white"
                                            onClick={() => history.go(0)}
                                        >
                                            <Trans>Force restart</Trans>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
