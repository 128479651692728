import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import Button from '../../utils/Button'
import PageContent from '../../utils/PageContent'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageHeader from '../../layout/PageHeader'
import AuditAlertsPageDashboardWithState from './AuditAlertsPageDashboardWithState'
import TableStateProvider from '../../providers/TableStateProvider'
import AuditHeader from '../../layout/AuditHeader'
import AuditAlertsPageWithActions from './AuditAlertsPageTableWithActions'

export default function AuditAlertsPage({
    audit,
    isFetching,
    alerts,
    hasMoreRecords,
    isFetchingMore,
    scanRangeState,
    selectedAlertStatus,
    selectedType,
    totalAlertsCount,
    ignoredAlertsCount,
    reviewedAlertsCount,
    onAlertStatusChanged,
    onChangeType,
    onFetchMore,
    onFilterAlerts,
    isSelecting,
    onSelectAlert,
    onSelectAllAlerts,
    selectedAlerts,
    onIgnoreAlerts,
    onDelegateAlertsInternal,
    onClearAlertsDelegate,
    onDelegateAlertsExternal,
    onRestoreAlerts,
}) {
    return (
        <PageContainer data-testid="AuditAlertsPage">
            <AuditHeader />
            <SidebarContainer>
                <PageContent size="full">
                    <PageHeader
                        className="mb-4"
                        title={<Trans>Alerts</Trans>}
                        description={
                            <Trans>
                                Here you can find all your domain alerts and
                                manage them.
                            </Trans>
                        }
                    />
                    <AuditAlertsPageDashboardWithState
                        selectedType={selectedType}
                        startDate={scanRangeState.startDate}
                        endDate={scanRangeState.endDate}
                    />
                    <TableStateProvider>
                        <AuditAlertsPageWithActions
                            onIgnoreAlerts={onIgnoreAlerts}
                            onDelegateAlertsInternal={onDelegateAlertsInternal}
                            onClearAlertsDelegate={onClearAlertsDelegate}
                            onDelegateAlertsExternal={onDelegateAlertsExternal}
                            onRestoreAlerts={onRestoreAlerts}
                            selectedAlertStatus={selectedAlertStatus}
                            totalAlertsCount={totalAlertsCount}
                            ignoredAlertsCount={ignoredAlertsCount}
                            reviewedAlertsCount={reviewedAlertsCount}
                            scanRangeState={scanRangeState}
                            onFilterAlerts={onFilterAlerts}
                            onChangeType={onChangeType}
                            selectedType={selectedType}
                            alerts={alerts}
                            hasMoreRecords={hasMoreRecords}
                            isFetchingMore={isFetchingMore}
                            onAlertStatusChanged={onAlertStatusChanged}
                            onFetchMore={onFetchMore}
                            isSelecting={isSelecting}
                            onSelectAlert={onSelectAlert}
                            onSelectAllAlerts={onSelectAllAlerts}
                            selectedAlerts={selectedAlerts}
                            isFetching={isFetching}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
