import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import { DeleteActionModal } from './DeleteActionModal'

export default function DeleteActionModalWithState({
    id,
    onDeleteAction,
    onDismiss,
}) {
    const { dispatchSuccess, dispatchError } = useNotifications()
    return (
        <DeleteActionModal
            onDismiss={onDismiss}
            isLoading={false}
            onConfirm={async () => {
                try {
                    onDeleteAction(id)
                    onDismiss()
                    dispatchSuccess({
                        message: <Trans>Action successfully deleted.</Trans>,
                    })
                } catch (error) {
                    dispatchError()
                }
            }}
        />
    )
}
