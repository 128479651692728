import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import {
    COOKIE_TYPE_LOCAL_STORAGE,
    COOKIE_TYPE_COOKIE,
} from '@clew/shared/src/constants'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import PurposesTags from '../../utils/PurposesTags'
import TableCellAvatar from '../../utils/TableCellAvatar'
import Tooltip from '../../utils/Tooltip'
import useDrawerStack from '../../hooks/useDrawerStack'
import CookieTableItemTableRowActionButtonWithState from './CookieTableItemTableRowActionButtonWithState'
import Tag from '../../utils/Tag'
import IdentifiedCookieDetailsDrawer from '../vendors-cookies/IdentifiedCookieDetailsDrawer'
import VendorTypeTags from '../../utils/VendorTypeTags'
import useScanRangeSelect from '../alerts/useScanRangeSelect'
import Duration from '../../utils/Duration'

const FRAGMENT = gql`
    fragment CookieTableItemPageTableRow on CookieTableItem {
        id
        name
        description
        cookieType
        retention
        identifiedCookie {
            id
            audits {
                id
                name
            }
        }
        occurrence
        status
        purposes {
            id
            name
        }
        tracker {
            id
            domain
            vendor {
                id
                name
                privacyPolicyUrl
                imageUrl
                types
            }
        }
        cookie {
            id
            description
            vendor {
                id
                name
                privacyPolicyUrl
                imageUrl
                types
            }
        }
    }
`

const CookieTableItemPageTableRow = ({
    auditIds,
    cookieTableItem,
    onUpdateCookieTableItemsCompleted,
}) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const {
        id,
        name,
        description,
        cookie,
        tracker,
        purposes,
        cookieType,
        retention,
        occurrence,
    } = cookieTableItem
    const dateRange = 'last-30'

    const { startDate, endDate, period } = useScanRangeSelect(dateRange)
    let vendor = null
    if (cookie) {
        vendor = cookie.vendor
    } else if (tracker) {
        vendor = tracker.vendor
    }
    return (
        <TableRow
            data-testid="CookieTablesPageTableRow"
            isSelectable
            isActive={currentDrawerProps?.cookieId === id}
            selectionCriteria={(row) => row.id === id}
            selectionValue={cookieTableItem}
            onClick={() => {
                if (cookieTableItem.identifiedCookie !== null) {
                    open(IdentifiedCookieDetailsDrawer, {
                        identifiedCookieId: cookieTableItem.identifiedCookie.id,
                        auditIds,
                        startDate,
                        endDate,
                        period,
                    })
                }
            }}
        >
            <TableCellAvatar
                columnId="name"
                size="sm"
                truncate
                avatarSrc={vendor?.imageUrl}
                avatarIconName="building-07"
                title={name}
            />
            <TableCell hasText columnId="purposes" size="sm" truncate>
                <PurposesTags purposes={purposes || []} />
            </TableCell>
            <TableCellAvatar
                columnId="audits"
                size="sm"
                truncate
                avatarSrc={vendor?.imageUrl}
                avatarIconName="building-07"
                title={
                    vendor?.name || (
                        <span className="text-slate-300">
                            <Trans>Unclassified</Trans>
                        </span>
                    )
                }
            />
            <TableCell columnId="vendorTypes" size="sm" truncate>
                <VendorTypeTags types={vendor?.types || []} />
            </TableCell>
            <TableCell hasText columnId="description" size="sm" truncate>
                {description && (
                    <Tooltip
                        className="block overflow-hidden text-ellipsis"
                        contentClassName="max-w-lg"
                        title={<Trans>Description</Trans>}
                        content={description}
                    >
                        {description}
                    </Tooltip>
                )}
            </TableCell>
            <TableCell columnId="occurrence" size="sm" truncate>
                <Tag>{occurrence}</Tag>
            </TableCell>
            <TableCell columnId="retention" size="sm" truncate>
                {!cookieType && ''}
                {cookieType === COOKIE_TYPE_LOCAL_STORAGE && (
                    <Trans>Local Storage</Trans>
                )}
                {cookieType === COOKIE_TYPE_COOKIE && retention !== null && (
                    <Duration format={Duration.DAYS}>{retention}</Duration>
                )}
                {cookieType === COOKIE_TYPE_COOKIE && !retention && (
                    <Trans>Session</Trans>
                )}
            </TableCell>
            <TableCell hasText columnId="privacyPolicyUrl" size="sm" truncate>
                {vendor?.privacyPolicyUrl && (
                    <Tooltip
                        className="block overflow-hidden text-ellipsis"
                        contentClassName="max-w-lg"
                        title={<Trans>Link to privacy policy</Trans>}
                        content={vendor?.privacyPolicyUrl}
                    >
                        {vendor?.privacyPolicyUrl}
                    </Tooltip>
                )}
            </TableCell>
            <TableCell size="sm" className="text-right">
                <CookieTableItemTableRowActionButtonWithState
                    cookieTableItem={cookieTableItem}
                    onUpdateCookieTableItemsCompleted={
                        onUpdateCookieTableItemsCompleted
                    }
                />
            </TableCell>
        </TableRow>
    )
}

CookieTableItemPageTableRow.FRAGMENT = FRAGMENT

export default CookieTableItemPageTableRow
