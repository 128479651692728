import React from 'react'
import clsx from 'clsx'

const Toggle = ({ name, value, onClick, disabled }) => (
    <button
        value={value}
        name={name}
        type="button"
        disabled={disabled}
        className={clsx(
            'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
            value ? 'bg-primary' : 'bg-gray-200',
            disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
        )}
        aria-pressed="false"
        onClick={() => {
            if (!disabled) {
                onClick(!value)
            }
        }}
    >
        <span className="sr-only">Toggle</span>
        <span
            aria-hidden="true"
            className={clsx(
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                value ? 'translate-x-5' : 'translate-x-0'
            )}
        />
    </button>
)

export default Toggle
