import React from 'react'
import gql from 'graphql-tag'
import { useHistory, useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { useMutation } from '@apollo/client'
import Button from '../../utils/Button'
import * as constants from '../../../constants'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import DropdownMenuItemDivider from '../../utils/DropdownMenuItemDivider'
import DropdownMenuTitle from '../../utils/DropdownMenuTitle'
import Tag from '../../utils/Tag'
import useAppState from '../../hooks/useAppState'

const { USER_TYPE_STAFF: STAFF } = constants

const CREATE_MUTATION = gql`
    mutation createLiveScan($auditId: ID!, $domainId: ID, $buildId: ID) {
        createLiveScan(
            auditId: $auditId
            domainId: $domainId
            buildId: $buildId
        ) {
            scan {
                id
            }
        }
    }
`

const START_WEB_SCAN_MUTATION = gql`
    mutation startWebScan(
        $auditId: ID!
        $domainId: ID!
        $consentProfileId: ID!
        $loginProfileId: ID
    ) {
        startWebScan(
            auditId: $auditId
            domainId: $domainId
            consentProfileId: $consentProfileId
            loginProfileId: $loginProfileId
        ) {
            scan {
                id
            }
        }
    }
`

const START_MOBILE_SCAN_MUTATION = gql`
    mutation startMobileScan($auditId: ID!, $buildId: ID!, $scenarioId: ID!) {
        startMobileScan(
            auditId: $auditId
            buildId: $buildId
            scenarioId: $scenarioId
        ) {
            scan {
                id
            }
        }
    }
`

export default function AuditHeaderActions({
    auditScenarios,
    domains,
    consentProfiles,
    auditBuilds,
    isMobile,
    isWeb,
    isCode,
    isLoading,
    onUploadSession,
    onStartScanCompleted,
    onCreateLiveScanCompleted,
}) {
    const { id } = useParams()
    const { currentUser } = useAppState()
    const history = useHistory()
    const [createLiveScan, { loading: isCreatingScan }] = useMutation(
        CREATE_MUTATION,
        {
            onCompleted: onCreateLiveScanCompleted,
        }
    )
    const [startWebScan, { loading: isStartingWebScan }] = useMutation(
        START_WEB_SCAN_MUTATION,
        {
            onCompleted: onStartScanCompleted,
        }
    )
    const [startMobileScan, { loading: isStartingMobileScan }] = useMutation(
        START_MOBILE_SCAN_MUTATION,
        {
            onCompleted: onStartScanCompleted,
        }
    )
    const canLiveScan = currentUser.type === STAFF
    return (
        <div className="flex items-center gap-2">
            <DropdownMenu
                autoClose
                position="bottom-end"
                // eslint-disable-next-line react/no-unstable-nested-components
                content={({ close }) => (
                    <>
                        {isWeb && (
                            <>
                                <DropdownMenuTitle>
                                    <Trans>Automated crawler scans</Trans>
                                </DropdownMenuTitle>
                                {consentProfiles.length === 0 && (
                                    <DropdownMenuItem
                                        iconName="warning-triangle"
                                        disabled
                                    >
                                        No consent profiles
                                    </DropdownMenuItem>
                                )}
                                {domains.map(({ id: domainId, name }) => {
                                    return consentProfiles.map((profile) => (
                                        <DropdownMenuItem
                                            iconName="globe"
                                            disabled={isStartingWebScan}
                                            onClick={async () => {
                                                await startWebScan({
                                                    variables: {
                                                        auditId: id,
                                                        domainId,
                                                        consentProfileId:
                                                            profile.id,
                                                    },
                                                })
                                                history.push(
                                                    `/audits/${id}/scans`
                                                )
                                            }}
                                        >
                                            {name}{' '}
                                            <Tag className="ml-4">
                                                {profile.name}
                                            </Tag>
                                        </DropdownMenuItem>
                                    ))
                                })}
                            </>
                        )}
                        {isMobile && (
                            <>
                                <DropdownMenuTitle>
                                    <Trans>Pre recorded scenarios</Trans>
                                </DropdownMenuTitle>
                                {auditScenarios.length === 0 && (
                                    <DropdownMenuItem
                                        iconName="warning-triangle"
                                        disabled
                                    >
                                        <Trans>No scan scenarios</Trans>
                                    </DropdownMenuItem>
                                )}
                                {auditScenarios.length > 0 &&
                                    auditBuilds.length === 0 && (
                                        <DropdownMenuItem
                                            iconName="warning-triangle"
                                            disabled
                                        >
                                            <Trans>No app builds</Trans>
                                        </DropdownMenuItem>
                                    )}
                                {auditScenarios.map(
                                    ({ id: scenarioId, name, deviceOs }) => (
                                        <DropdownMenuItem
                                            key={scenarioId}
                                            iconName="app"
                                            disabled={isStartingMobileScan}
                                            onClick={async () => {
                                                const [firstBuild] =
                                                    auditBuilds.filter(
                                                        (build) =>
                                                            build.deviceOs ===
                                                            deviceOs
                                                    )
                                                await startMobileScan({
                                                    variables: {
                                                        auditId: id,
                                                        scenarioId,
                                                        buildId: firstBuild.id,
                                                    },
                                                })
                                                history.push(
                                                    `/audits/${id}/scans`
                                                )
                                            }}
                                        >
                                            {name} - {deviceOs}
                                        </DropdownMenuItem>
                                    )
                                )}
                                <DropdownMenuItem
                                    iconName="plus"
                                    onClick={() => {
                                        history.push(
                                            `/audits/${id}/record-mobile-actions`
                                        )
                                    }}
                                >
                                    <Trans>Record new scenario</Trans>
                                </DropdownMenuItem>
                            </>
                        )}
                        <DropdownMenuItemDivider />
                        <DropdownMenuTitle>
                            <Trans>Upload from file</Trans>
                        </DropdownMenuTitle>
                        <DropdownMenuItem
                            iconName="upload"
                            disabled={isLoading}
                            onClick={() => {
                                close()
                                onUploadSession()
                            }}
                        >
                            <Trans>Upload HAR file</Trans>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="upload"
                            disabled={isLoading}
                            onClick={() => {
                                close()
                                onUploadSession()
                            }}
                        >
                            <Trans>Upload PCAP file</Trans>
                        </DropdownMenuItem>
                        {canLiveScan && (
                            <>
                                <DropdownMenuItemDivider />
                                <DropdownMenuTitle>
                                    <Trans>Record one-off live scan</Trans>
                                </DropdownMenuTitle>
                            </>
                        )}
                        {isWeb && canLiveScan && (
                            <>
                                {domains.length === 0 && (
                                    <DropdownMenuItem
                                        iconName="warning-triangle"
                                        disabled
                                    >
                                        <Trans>No web domains</Trans>
                                    </DropdownMenuItem>
                                )}
                                {domains.map(({ id: domainId, name }) => (
                                    <DropdownMenuItem
                                        iconName="browser-play"
                                        disabled={isCreatingScan}
                                        onClick={async () => {
                                            const { data } =
                                                await createLiveScan({
                                                    variables: {
                                                        auditId: id,
                                                        domainId,
                                                    },
                                                })
                                            history.push(
                                                `/audits/${id}/scans/${data.createLiveScan.scan.id}/live/web`
                                            )
                                        }}
                                    >
                                        {name}
                                    </DropdownMenuItem>
                                ))}
                            </>
                        )}
                        {isMobile && canLiveScan && (
                            <>
                                {auditBuilds.length === 0 && (
                                    <DropdownMenuItem
                                        iconName="warning-triangle"
                                        disabled
                                    >
                                        <Trans>No app builds</Trans>
                                    </DropdownMenuItem>
                                )}
                                {auditBuilds.map(({ id: buildId, name }) => (
                                    <DropdownMenuItem
                                        iconName="browser-play"
                                        disabled={isCreatingScan}
                                        onClick={async () => {
                                            const { data } =
                                                await createLiveScan({
                                                    variables: {
                                                        auditId: id,
                                                        buildId,
                                                    },
                                                })
                                            history.push(
                                                `/audits/${id}/scans/${data.createLiveScan.scan.id}/live/mobile`
                                            )
                                        }}
                                    >
                                        {name}
                                    </DropdownMenuItem>
                                ))}
                            </>
                        )}
                    </>
                )}
            >
                <Button
                    loading={isLoading}
                    disabled={isLoading}
                    iconBeforeProps={{
                        name: 'scan',
                    }}
                >
                    <Trans>Start a new scan</Trans>
                </Button>
            </DropdownMenu>
        </div>
    )
}
