import React from 'react'
import { Trans } from '@lingui/macro'
import {
    SCAN_TRIGGER_LIVE,
    SCAN_STATUS_CREATED,
} from '@clew/shared/src/constants'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditHeader from '../../layout/AuditHeader'
import AuditsLivePageWebBrowser from './AuditsLivePageWebBrowser'
import AuditsLivePageSidebar from './AuditsLivePageSidebar'
import PageLoading from '../../utils/PageLoading'
import Alert from '../../utils/Alert'

const AuditsLivePage = ({
    error,
    scan,
    audit,
    liveUrl,
    isFetching,
    isStarting,
    isStarted,
    isStopping,
    isStopped,
    onStart,
    onStop,
    scanRequests,
    scanCookies,
}) => (
    <PageContainer data-testid="AuditLiveWebScanPage">
        <AuditHeader />
        <SidebarContainer>
            {(() => {
                if (isFetching) {
                    return (
                        <main className="flex flex-1 items-center justify-center">
                            <PageLoading />
                        </main>
                    )
                }
                if (
                    error ||
                    scan.trigger !== SCAN_TRIGGER_LIVE ||
                    scan.status !== SCAN_STATUS_CREATED
                ) {
                    let message
                    if (error) {
                        message = error.message
                    }
                    if (scan.trigger !== SCAN_TRIGGER_LIVE) {
                        message = <Trans>This is not a live scan</Trans>
                    }
                    if (scan.status !== SCAN_STATUS_CREATED) {
                        message = <Trans>This scan has already been run</Trans>
                    }
                    return (
                        <main className="flex flex-1 items-center justify-center">
                            <Alert title="Error">{message}</Alert>
                        </main>
                    )
                }
                return (
                    <main className="flex flex-1">
                        <div className="flex flex-1 items-center justify-center">
                            <AuditsLivePageWebBrowser
                                liveUrl={liveUrl}
                                scan={scan}
                                audit={audit}
                                isStarting={isStarting}
                                onStart={onStart}
                                isStopping={isStopping}
                                isStopped={isStopped}
                            />
                        </div>
                        <AuditsLivePageSidebar
                            scan={scan}
                            audit={audit}
                            isStarting={isStarting}
                            isStarted={isStarted}
                            isStopped={isStopped}
                            isStopping={isStopping}
                            scanRequests={scanRequests}
                            scanCookies={scanCookies}
                            onStart={onStart}
                            onStop={onStop}
                        />
                    </main>
                )
            })()}
        </SidebarContainer>
    </PageContainer>
)

export default AuditsLivePage
