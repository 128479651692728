import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import Button from '../../utils/Button'
import Tabs, { Tab } from '../../utils/Tabs'
import Tag from '../../utils/Tag'
import useAppetizeClient from '../../hooks/useAppetizeClient'
import useThrottledState from '../../hooks/useThrottledState'
import SaveLiveScanModalWithState from '../audits-scans-live-web/SaveLiveScanModalWithState'
import { useParseLiveMobileScanLogsQuery } from './useLiveMobileScanPageQueries'
import Alert from '../../utils/Alert'
import { LiveMobileScanPageSidebarRequestsTab } from './LiveMobileScanPageSidebarRequestsTab'
import { LiveMobileScanPageSidebarCookiesTab } from './LiveMobileScanPageSidebarCookiesTab'
import { useDeleteLiveScanMutation } from './useLiveMobileScanPageMutations'
import useNotifications from '../../hooks/useNotifications'

export default function LiveMobileScanPageSideBar({ scan, audit }) {
    const history = useHistory()
    const appetizeClient = useAppetizeClient()
    const networkEvents = useThrottledState(appetizeClient.networkEvents, 3000)
    const isStarted = !!appetizeClient.session
    const isStopped = !!appetizeClient.isStopped
    const [activeTab, setActiveTab] = useState('requests')
    const [saveModalIsOpen, setSaveModalIsOpen] = useState(false)
    const { dispatchGraphqlError } = useNotifications()
    const [deleteLiveScan] = useDeleteLiveScanMutation()

    const { deviceSettings } = appetizeClient

    const { isFetching, error, mobileScanLogs } =
        useParseLiveMobileScanLogsQuery({
            auditId: audit.id,
            scanId: scan.id,
            input: {
                networkEvents,
            },
        })
    const { scanRequests = [], scanCookies = [] } = mobileScanLogs
    return (
        <>
            <div className="flex w-1/3 flex-col border-l border-gray-200 bg-white">
                <div className="px-6 py-8">
                    <h3 className="text-2xl font-medium leading-6 text-gray-900">
                        <Trans>Record live scan</Trans>
                    </h3>
                </div>
                <Tabs hasMarginBottom={false} className="px-6">
                    <Tab
                        isActive={activeTab === 'requests'}
                        onClick={() => setActiveTab('requests')}
                    >
                        <Trans>Requests</Trans>
                        <Tag iconNameBefore="tracker">
                            {scanRequests.length}
                        </Tag>
                    </Tab>
                    <Tab
                        isActive={activeTab === 'cookies'}
                        onClick={() => setActiveTab('cookies')}
                    >
                        <Trans>Cookies</Trans>
                        <Tag iconNameBefore="cookie">{scanCookies.length}</Tag>
                    </Tab>
                    <Tab
                        isActive={activeTab === 'alerts'}
                        onClick={() => setActiveTab('alerts')}
                    >
                        <Trans>Alerts</Trans>
                    </Tab>
                </Tabs>
                <div className="flex-1 overflow-auto px-6 pt-6">
                    {activeTab === 'requests' && (
                        <LiveMobileScanPageSidebarRequestsTab
                            scanRequests={scanRequests}
                            isFetching={isFetching}
                            error={error}
                        />
                    )}
                    {activeTab === 'cookies' && (
                        <LiveMobileScanPageSidebarCookiesTab
                            scanCookies={scanCookies}
                            isFetching={isFetching}
                            error={error}
                        />
                    )}

                    {activeTab === 'alerts' && (
                        <Alert
                            type="warning"
                            className="mb-6"
                            title={<Trans>No alerts during live scan</Trans>}
                        >
                            <Trans>
                                We cannot show you alerts while you are
                                recording a live scan. Save the scan to see the
                                relevant alerts.
                            </Trans>
                        </Alert>
                    )}
                </div>
                <div className="flex justify-between border-t border-gray-200 px-6 py-4">
                    <div />
                    <div className="flex gap-2">
                        <Button
                            type="white"
                            onClick={async () => {
                                try {
                                    await deleteLiveScan({
                                        variables: { scanId: scan.id },
                                    })
                                    history.push(`/audits/${audit.id}/scans`)
                                } catch (e) {
                                    dispatchGraphqlError(e)
                                }
                            }}
                        >
                            <Trans>Cancel</Trans>
                        </Button>
                        {isStarted && !isStopped && (
                            <Button
                                type="danger"
                                iconBeforeProps={{
                                    name: 'stop',
                                }}
                                onClick={() => appetizeClient.stopSession()}
                            >
                                <Trans>Stop live scan</Trans>
                            </Button>
                        )}
                        {isStopped && (
                            <Button
                                onClick={() => {
                                    setSaveModalIsOpen(true)
                                }}
                            >
                                <Trans>Save live scan</Trans>
                            </Button>
                        )}
                        {!isStarted && !isStopped && (
                            <Button
                                onClick={() => appetizeClient.startSession()}
                                loading={appetizeClient.isStartingSession}
                                iconBeforeProps={{
                                    name: 'play',
                                }}
                            >
                                <Trans>Start live scan</Trans>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            {saveModalIsOpen && (
                <SaveLiveScanModalWithState
                    scanId={scan.id}
                    auditId={audit.id}
                    networkEvents={networkEvents}
                    deviceSettings={deviceSettings}
                    onDismiss={() => setSaveModalIsOpen(false)}
                    onCompleted={() => {
                        history.push(`/audits/${audit.id}/scans`)
                    }}
                />
            )}
        </>
    )
}
