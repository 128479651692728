import React from 'react'
import { Trans } from '@lingui/macro'
import {
    COOKIE_TABLE_ITEM_STATUS_ADDED,
    COOKIE_TABLE_ITEM_STATUS_DEPRECATED,
} from '@clew/shared/src/constants'
import ActionButton from '../../utils/ActionButton'
import DropdownMenu from '../../utils/DropdownMenu'
import DropdownMenuItem from '../../utils/DropdownMenuItem'
import useUpdateCookieTableItemMutation from './useUpdateCookieTableItemMutation'
import useNotifications from '../../hooks/useNotifications'

function DropdownMenuOptions({
    canBeAdded,
    canBeDeprecated,
    loading,
    onAddToList,
    onRemoveFromList,
}) {
    return (
        <>
            {canBeDeprecated && (
                <DropdownMenuItem
                    iconName="bin"
                    disabled={loading}
                    onClick={onRemoveFromList}
                >
                    <Trans>Remove from list</Trans>
                </DropdownMenuItem>
            )}
            {canBeAdded && (
                <DropdownMenuItem
                    disabled={loading}
                    iconName="plus"
                    onClick={onAddToList}
                >
                    <Trans>Re-add to list</Trans>
                </DropdownMenuItem>
            )}
        </>
    )
}

export default function CookieVersionCompareModalTableRowActions({
    cookieTableItemId,
    rowType,
    onUpdateCompleted,
}) {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [updateCookieTableItem, { loading }] =
        useUpdateCookieTableItemMutation()

    const onAddToList = async () => {
        try {
            const variables = {
                id: [cookieTableItemId],
                input: {
                    status: COOKIE_TABLE_ITEM_STATUS_ADDED,
                },
            }
            await updateCookieTableItem({ variables })
            dispatchSuccess({
                message: <Trans>Cookie re-added to list</Trans>,
            })
            onUpdateCompleted()
        } catch (error) {
            dispatchGraphqlError(error)
        }
    }

    const onRemoveFromList = async () => {
        try {
            const variables = {
                id: [cookieTableItemId],
                input: {
                    status: COOKIE_TABLE_ITEM_STATUS_DEPRECATED,
                },
            }
            await updateCookieTableItem({ variables })
            dispatchSuccess({
                message: <Trans>Cookie removed from list</Trans>,
            })
            onUpdateCompleted()
        } catch (error) {
            dispatchGraphqlError(error)
        }
    }
    const canBeDeprecated =
        rowType === 'new' || rowType === 'same' || rowType === 'modified'
    const canBeAdded = rowType === 'warning'
    const isDisabled = !canBeAdded && !canBeDeprecated
    return (
        <DropdownMenu
            as="span"
            position="bottom-start"
            autoClose
            content={
                <DropdownMenuOptions
                    canBeDeprecated={canBeDeprecated}
                    canBeAdded={canBeAdded}
                    loading={loading}
                    onAddToList={onAddToList}
                    onRemoveFromList={onRemoveFromList}
                />
            }
        >
            {!isDisabled && <ActionButton />}
        </DropdownMenu>
    )
}
