import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableRow from '../../utils/TableRow'
import Tooltip from '../../utils/Tooltip'
import PurposesTags from '../../utils/PurposesTags'
import Tag from '../../utils/Tag'
import DrawerTabLoading from '../../utils/DrawerTabLoading'
import DrawerTabError from '../../utils/DrawerTabError'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'

export function LiveMobileScanPageSidebarCookiesTab({
    error,
    isFetching,
    scanCookies,
}) {
    if (isFetching) {
        return <DrawerTabLoading />
    }

    if (error) {
        return <DrawerTabError error={error} />
    }

    const hasCookies = scanCookies.length > 0

    return (
        <Table hasMinWidth={false} isRounded hasHeader={false}>
            <TableBody>
                {!hasCookies && (
                    <DrawerEmptyCard
                        iconName="cookie"
                        title={<Trans>No cookies</Trans>}
                        description={<Trans>No cookies were found</Trans>}
                    />
                )}
                {scanCookies.map((scanCookie) => {
                    const { name, value, rootDomain, cookie, tracker } =
                        scanCookie
                    let vendor = null
                    let tagPurposes = []
                    if (cookie) {
                        tagPurposes = cookie.purposes
                        vendor = cookie.vendor
                    } else if (tracker) {
                        tagPurposes = tracker.purposes
                        vendor = tracker.vendor
                    }
                    return (
                        <TableRow isSelectable key={name}>
                            <TableCellAvatar
                                py="sm"
                                px="sm"
                                truncate
                                avatarIconName="cookie"
                                avatarSrc={vendor?.imageUrl}
                                className="w-2/3"
                                title={name}
                                text={rootDomain}
                            />
                            <TableCell py="sm">
                                <div className="flex justify-end gap-1">
                                    <PurposesTags
                                        showAll
                                        purposes={tagPurposes}
                                    />
                                    <Tooltip
                                        content={value}
                                        contentClassName="max-w-lg text-wrap"
                                    >
                                        <Tag type="gray">Show value</Tag>
                                    </Tooltip>
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
