import React from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormSelect from '../../utils/FormSelect'
import FormButtonGroup from '../../utils/FormButtonGroup'
import Button from '../../utils/Button'
import Card from '../../utils/Card'
import CardHeader from '../../utils/CardHeader'
import Toggle from '../../utils/Toggle'
import { DefaultsTooltip, OverrideCheckbox } from './AuditSettingsFormCard'

const AuditSettingsAlertsFormCard = ({
    organization,
    formState,
    cookieTables,
    vendorTables,
    isLoading,
    onSubmit,
}) => {
    const history = useHistory()
    const { values, handleChange } = formState
    let finalMaxCookieRetention = values.maxCookieRetention
    let finalVendorTableId = values.vendorTableId
    let finalCookieTableId = values.cookieTableId
    let finalShowCookieTableAlerts = values.showCookieTableAlerts
    let finalShowVendorTableAlerts = values.showVendorTableAlerts
    if (finalMaxCookieRetention === null) {
        finalMaxCookieRetention = organization.maxCookieRetention
    }
    if (finalVendorTableId === null) {
        finalVendorTableId = organization.vendorTable
            ? organization.vendorTable.id
            : null
    }
    if (finalCookieTableId === null) {
        finalCookieTableId = organization.cookieTable
            ? organization.cookieTable.id
            : null
    }
    if (finalShowCookieTableAlerts === null) {
        finalShowCookieTableAlerts = organization.showCookieTableAlerts
    }
    if (finalShowVendorTableAlerts === null) {
        finalShowVendorTableAlerts = organization.showVendorTableAlerts
    }
    return (
        <Card className="mb-8">
            <form>
                <CardHeader
                    title={<Trans>Alert rules</Trans>}
                    description={
                        <Trans>
                            Use these settings to modify your alerts and
                            notifications rules.
                        </Trans>
                    }
                />
                <div className="divide-y">
                    <FormGroup
                        isHorizontal
                        label={<Trans>Maximum cookie retention period</Trans>}
                        htmlFor="maxCookieRetention"
                    >
                        <div className="flex items-center justify-between">
                            <DefaultsTooltip
                                className="max-w-md flex-1"
                                enabled={values.maxCookieRetention === null}
                            >
                                <FormInput
                                    id="maxCookieRetention"
                                    className="max-w-xs flex-1"
                                    errors={formState.getErrorMessages(
                                        'maxCookieRetention'
                                    )}
                                    disabled={
                                        values.maxCookieRetention === null
                                    }
                                    value={finalMaxCookieRetention}
                                    onChange={(e) =>
                                        handleChange(
                                            'maxCookieRetention',
                                            e.target.value
                                        )
                                    }
                                />
                            </DefaultsTooltip>
                            <OverrideCheckbox
                                id="overrideMaxCookieRetention"
                                htmlFor="overrideMaxCookieRetention"
                                checked={values.maxCookieRetention !== null}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        handleChange(
                                            'maxCookieRetention',
                                            organization.maxCookieRetention
                                        )
                                    } else {
                                        handleChange('maxCookieRetention', null)
                                    }
                                }}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        isHorizontal
                        label={<Trans>Vendor Table</Trans>}
                        htmlFor="url"
                    >
                        <div className="flex items-center justify-between">
                            <DefaultsTooltip
                                className="max-w-md flex-1"
                                enabled={values.vendorTableId === null}
                            >
                                <div className="flex w-full gap-2">
                                    <FormSelect
                                        id="vendorTableId"
                                        className="w-full max-w-md flex-1"
                                        errors={formState.getErrorMessages(
                                            'vendorTableId'
                                        )}
                                        disabled={values.vendorTableId === null}
                                        value={finalVendorTableId}
                                        onChange={(e) =>
                                            handleChange(
                                                'vendorTableId',
                                                e.target.value
                                            )
                                        }
                                    >
                                        <option key={null} value="NONE">
                                            None
                                        </option>
                                        {vendorTables.map(({ id, name }) => (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        ))}
                                    </FormSelect>
                                    <div>
                                        <Button
                                            size="sm"
                                            disabled={
                                                values.vendorTableId === null
                                            }
                                            type="white"
                                            iconBeforeProps={{
                                                name: 'plus',
                                                size: 'sm',
                                            }}
                                            onClick={() =>
                                                history.push(
                                                    '/vendors/vendor-tables'
                                                )
                                            }
                                        >
                                            <Trans>Create</Trans>
                                        </Button>
                                    </div>
                                </div>
                            </DefaultsTooltip>
                            <OverrideCheckbox
                                id="overrideVendorTableId"
                                htmlFor="overrideVendorTableId"
                                checked={values.vendorTableId !== null}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        handleChange(
                                            'vendorTableId',
                                            organization.vendorTable
                                                ? organization.vendorTable.id
                                                : 'NONE'
                                        )
                                    } else {
                                        handleChange('vendorTableId', null)
                                    }
                                }}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        isHorizontal
                        label={<Trans>Vendor table alerts</Trans>}
                        htmlFor="showVendorTableAlerts"
                    >
                        <div className="flex items-center justify-between">
                            <DefaultsTooltip
                                enabled={values.showVendorTableAlerts === null}
                            >
                                <Toggle
                                    name="showVendorTableAlerts"
                                    disabled={
                                        values.showVendorTableAlerts === null
                                    }
                                    value={finalShowVendorTableAlerts}
                                    onClick={(value) =>
                                        formState.handleChange(
                                            'showVendorTableAlerts',
                                            value
                                        )
                                    }
                                />
                            </DefaultsTooltip>
                            <OverrideCheckbox
                                id="overrideShowVendorTableAlerts"
                                htmlFor="overrideShowVendorTableAlerts"
                                checked={values.showVendorTableAlerts !== null}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        handleChange(
                                            'showVendorTableAlerts',
                                            organization.showVendorTableAlerts
                                        )
                                    } else {
                                        handleChange(
                                            'showVendorTableAlerts',
                                            null
                                        )
                                    }
                                }}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        isHorizontal
                        label={<Trans>Cookie Table</Trans>}
                        htmlFor="url"
                    >
                        <div className="flex items-center justify-between">
                            <DefaultsTooltip
                                className="max-w-md flex-1"
                                enabled={values.vendorTableId === null}
                            >
                                <div className="flex w-full gap-2">
                                    <FormSelect
                                        id="cookieTableId"
                                        className="w-full max-w-md flex-1"
                                        errors={formState.getErrorMessages(
                                            'cookieTableId'
                                        )}
                                        disabled={values.cookieTableId === null}
                                        value={finalCookieTableId}
                                        onChange={(e) =>
                                            handleChange(
                                                'cookieTableId',
                                                e.target.value
                                            )
                                        }
                                    >
                                        <option key={null} value="NONE">
                                            None
                                        </option>
                                        {cookieTables.map(({ id, name }) => (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        ))}
                                    </FormSelect>
                                    <div>
                                        <Button
                                            size="sm"
                                            type="white"
                                            iconBeforeProps={{
                                                name: 'plus',
                                                size: 'sm',
                                            }}
                                            disabled={
                                                values.cookieTableId === null
                                            }
                                            onClick={() =>
                                                history.push(
                                                    '/vendors/cookie-tables'
                                                )
                                            }
                                        >
                                            <Trans>Create</Trans>
                                        </Button>
                                    </div>
                                </div>
                            </DefaultsTooltip>
                            <OverrideCheckbox
                                id="overrideCookieTableId"
                                htmlFor="overrideCookieTableId"
                                checked={values.cookieTableId !== null}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        handleChange(
                                            'cookieTableId',
                                            organization.cookieTable
                                                ? organization.cookieTable.id
                                                : 'NONE'
                                        )
                                    } else {
                                        handleChange('cookieTableId', null)
                                    }
                                }}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        isHorizontal
                        label={<Trans>Cookie table alerts</Trans>}
                        htmlFor="showCookieTableAlerts"
                    >
                        <div className="flex items-center justify-between">
                            <DefaultsTooltip
                                enabled={values.showCookieTableAlerts === null}
                            >
                                <Toggle
                                    name="showCookieTableAlerts"
                                    disabled={
                                        values.showCookieTableAlerts === null
                                    }
                                    value={finalShowCookieTableAlerts}
                                    onClick={(value) =>
                                        formState.handleChange(
                                            'showCookieTableAlerts',
                                            value
                                        )
                                    }
                                />
                            </DefaultsTooltip>
                            <OverrideCheckbox
                                id="overrideShowCookieTableAlerts"
                                htmlFor="overrideShowCookieTableAlerts"
                                checked={values.showCookieTableAlerts !== null}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        handleChange(
                                            'showCookieTableAlerts',
                                            organization.showCookieTableAlerts
                                        )
                                    } else {
                                        handleChange(
                                            'showCookieTableAlerts',
                                            null
                                        )
                                    }
                                }}
                            />
                        </div>
                    </FormGroup>
                    <FormButtonGroup>
                        <Button
                            onClick={onSubmit}
                            loading={isLoading}
                            disabled={isLoading}
                            className="w-md"
                        >
                            <Trans>Save changes</Trans>
                        </Button>
                    </FormButtonGroup>
                </div>
            </form>
        </Card>
    )
}

export default AuditSettingsAlertsFormCard
