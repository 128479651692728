import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableHeaderCell from '../../utils/TableHeaderCell'
import EmptyTable from '../../utils/EmptyTable'
import AuditSettingsPageAuditScenarioTableRow from './AuditSettingsPageAuditScenarioTableRow'

const AuditSettingsPageAuditScenariosTable = ({
    auditId,
    auditScenarios = [],
    onDeleteAuditScenario,
}) => (
    <Table data-testid="AuditSettingsPageAuditScenariosTable">
        <thead>
            <tr>
                <TableHeaderCell>
                    <Trans>Audit scenario</Trans>
                </TableHeaderCell>
                <TableHeaderCell>
                    <Trans>Device OS</Trans>
                </TableHeaderCell>
                <TableHeaderCell />
            </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
            {auditScenarios.length > 0 ? (
                auditScenarios.map((auditScenario) => (
                    <AuditSettingsPageAuditScenarioTableRow
                        key={auditScenario.id}
                        auditId={auditId}
                        auditScenario={auditScenario}
                        onDeleteAuditScenario={onDeleteAuditScenario}
                    />
                ))
            ) : (
                <EmptyTable
                    colSpan={3}
                    title={<Trans>No audit scenarios</Trans>}
                    description={
                        <Trans>
                            No audit scenarios were found for this audit.
                        </Trans>
                    }
                />
            )}
        </tbody>
    </Table>
)

export default AuditSettingsPageAuditScenariosTable
