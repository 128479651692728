import React from 'react'
import { Trans } from '@lingui/macro'
import ModalTitle from '../../utils/ModalTitle'
import Modal from '../../utils/Modal'
import Button from '../../utils/Button'
import useNotifications from '../../hooks/useNotifications'
import ModalFooter from '../../utils/ModalFooter'

const EmbedCookieTableModal = ({ onDismiss, onBack, onCompleted, link }) => {
    const { dispatchSuccess } = useNotifications()
    const handleCopy = () => {
        const codeBlock = document.getElementById('codeBlock')
        const textToCopy = codeBlock.innerText || codeBlock.textContent

        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                dispatchSuccess({ message: 'Copied to clipboard' })
                onDismiss()
            })
            .catch((err) => {
                console.error('Failed to copy code: ', err)
            })
    }

    return (
        <Modal className="max-w-2xl">
            <ModalTitle
                title={<Trans>Embed your table!</Trans>}
                description={
                    <Trans>You can embed the table on your own website</Trans>
                }
                iconName="palette"
                iconColor="none"
                iconSize="md"
                onClose={onDismiss}
                onCompleted={onCompleted}
                newModalTitle
            />

            <div className="relative mx-auto w-full rounded-md bg-gray-900 px-4 shadow-lg lg:max-w-2xl">
                <div className="flex items-center justify-between rounded-t-lg px-4 py-4">
                    <span className="text-xs font-semibold text-gray-400">
                        html
                    </span>
                </div>
                <pre className="overflow-x-auto pb-6 text-sm text-white">
                    <code id="codeBlock" className="block">
                        &lt;iframe src=&quot;{link}&quot;&gt;&lt;/iframe&gt;
                    </code>
                </pre>
            </div>

            <ModalFooter>
                <Button type="white" onClick={onBack || onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    iconAfterProps={{
                        name: 'code-01',
                        className: 'text-gray-800',
                        size: 'sm',
                    }}
                    type="white"
                    onClick={handleCopy} // Attach the copy handler here
                >
                    <Trans>Copy</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default EmbedCookieTableModal
