import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import StaffDataIdentifiersPageTableHeaderRow from './StaffDataIdentifiersPageTableHeaderRow'
import InfiniteScroll from '../../utils/InfiniteScroll'
import StaffDataIdentifiersPageTableRow from './StaffDataIdentifiersPageTableRow'

export default function StaffDataIdentifiersPageTable({
    dataIdentifiers = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    onDeleteCompleted,
    onUpdateCompleted,
}) {
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                <thead>
                    <StaffDataIdentifiersPageTableHeaderRow />
                </thead>
                <TableBody>
                    {dataIdentifiers.map((dataIdentifier) => (
                        <StaffDataIdentifiersPageTableRow
                            key={dataIdentifier.id}
                            dataIdentifier={dataIdentifier}
                            onDeleteCompleted={onDeleteCompleted}
                            onUpdateCompleted={onUpdateCompleted}
                        />
                    ))}
                </TableBody>
            </Table>
        </InfiniteScroll>
    )
}
