import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query auditHeader($id: ID!) {
        audit(id: $id) {
            id
            name
            scanFrequency
            createdAt
            imageUrl
            tags
            isMobile
            isWeb
            isCode
            consentProfiles {
                id
                name
                cmp {
                    id
                    name
                }
            }
            domains {
                id
                name
                url
            }
            auditScenarios {
                id
                name
                deviceOs
            }
            auditBuilds {
                id
                name
                deviceOs
            }
        }
    }
`

const useAuditHeaderQuery = () => {
    const { id } = useParams()
    const variables = { id }
    const { data = {}, loading: isFetching } = useQuery(QUERY, {
        variables,
    })
    return {
        isFetching,
        audit: data.audit || {},
    }
}

export default useAuditHeaderQuery
