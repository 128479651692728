/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef, useEffect } from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import Portal from './Portal'
import useDragResize from '../hooks/useDragResize'

export default function DrawerStack({
    className,
    children,
    initialWidth = 870,
    canResize = true,
    showComparingStack = false,
    comparingStack,
    ...props
}) {
    const ref = useRef()
    const { width, isDragging, handleDragStart, setWidth } = useDragResize(
        ref,
        {
            initialWidth,
            isEnabled: canResize,
            reverse: true,
        }
    )
    useEffect(() => {
        if (showComparingStack) {
            setWidth(window.innerWidth / 2)
        }
    }, [showComparingStack])
    return (
        <Portal querySelector="#drawers">
            <motion.div
                initial={{ x: '100%' }}
                animate={{ x: 0 }}
                exit={{ x: '100%' }}
                aria-modal="true"
                role="dialog"
                tabIndex="-1"
                className={clsx(
                    'fixed inset-y-0 right-0 flex items-center bg-white shadow-xl'
                )}
                {...props}
            >
                {showComparingStack && (
                    <div
                        className="relative h-full"
                        style={{ width: `${window.innerWidth - width}px` }}
                    >
                        <AnimatePresence mode="wait">
                            {comparingStack}
                        </AnimatePresence>
                    </div>
                )}
                <div
                    ref={ref}
                    style={{ width: `${width}px` }}
                    className={clsx(
                        'relative h-full border-l border-gray-200',
                        className
                    )}
                >
                    {canResize && (
                        <button
                            type="button"
                            className={clsx(
                                'absolute bottom-0 left-0 top-0 w-1 cursor-col-resize hover:bg-gray-300',
                                isDragging && 'bg-primary'
                            )}
                            onMouseDown={handleDragStart}
                        />
                    )}
                    <AnimatePresence mode="wait">{children}</AnimatePresence>
                </div>
            </motion.div>
        </Portal>
    )
}
