import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerContent from '../../utils/DrawerContent'
import DrawerDetailsItem, {
    DrawerDetailsItemText,
} from '../../utils/DrawerDetailsItem'
import PurposesTags from '../../utils/PurposesTags'

export default function TrackerDetailsDrawerDetailsTab({
    purposes,
    description,
}) {
    return (
        <DrawerContent className="flex flex-col gap-4">
            <DrawerDetailsItem isHorizontal label={<Trans>Types</Trans>}>
                <PurposesTags purposes={purposes} />
            </DrawerDetailsItem>
            <DrawerDetailsItemText
                isHorizontal
                truncate={false}
                label={<Trans>Description</Trans>}
            >
                {description}
            </DrawerDetailsItemText>
        </DrawerContent>
    )
}
