import React from 'react'
import { Trans } from '@lingui/macro'
import AnimatedTabs, { AnimatedTab } from '../../utils/AnimatedTabs'
import Tag from '../../utils/Tag'
import TableActionsContainer from '../../utils/TableActionsContainer'

const CookieTableVersionCompareModalTableActions = ({
    activeTab = 'all',
    setActiveTab,
    allCount,
    addedCount,
    deletedCount,
    deprecatedCount,
    modifiedCount,
}) => {
    return (
        <TableActionsContainer>
            <AnimatedTabs activeTab={activeTab}>
                <AnimatedTab
                    name="all"
                    className="px-3"
                    onClick={() => setActiveTab('all')}
                >
                    <div className="flex flex-row items-center gap-1">
                        <Trans>All</Trans>
                        <Tag>{allCount}</Tag>
                    </div>
                </AnimatedTab>
                <AnimatedTab name="new" onClick={() => setActiveTab('new')}>
                    <div className="flex flex-row items-center gap-1">
                        <Trans>New</Trans>
                        <Tag type="green">{addedCount}</Tag>
                    </div>
                </AnimatedTab>
                <AnimatedTab
                    name="modified"
                    className="px-3"
                    onClick={() => setActiveTab('modified')}
                >
                    <div className="flex flex-row items-center gap-1">
                        <Trans>Modified</Trans>
                        <Tag type="yellow">{modifiedCount}</Tag>
                    </div>
                </AnimatedTab>
                <AnimatedTab
                    className="px-3"
                    name="deleted"
                    onClick={() => setActiveTab('deleted')}
                >
                    <div className="flex flex-row items-center gap-1">
                        <Trans>Not found</Trans>
                        <Tag type="red">{deletedCount}</Tag>
                    </div>
                </AnimatedTab>
                <AnimatedTab
                    name="deprecated"
                    onClick={() => setActiveTab('deprecated')}
                >
                    <div className="flex flex-row items-center gap-1">
                        <Trans>Removed</Trans>
                        <Tag type="orange">{deprecatedCount}</Tag>
                    </div>
                </AnimatedTab>
            </AnimatedTabs>
        </TableActionsContainer>
    )
}

export default CookieTableVersionCompareModalTableActions
