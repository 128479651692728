import React from 'react'
import { Trans } from '@lingui/macro'
import Button from '../../utils/Button'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import FormGroup from '../../utils/FormGroup'
import FormTextarea from '../../utils/FormTextarea'
import ModalFooter from '../../utils/ModalFooter'

export default function UpdateActionModal({ formState, onDismiss, onSubmit }) {
    const { getErrorMessages, getNativeInputProps } = formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-2xl">
            <ModalTitle
                title={<Trans>Update action</Trans>}
                description={
                    <Trans>
                        Update the action used to interact with the mobile
                        device.
                    </Trans>
                }
                onClose={onDismiss}
            />
            <FormGroup label={<Trans>Action</Trans>}>
                <FormTextarea
                    rows={20}
                    errors={getErrorMessages('action')}
                    {...getNativeInputProps('action')}
                />
            </FormGroup>
            <ModalFooter>
                <Button type="white" onClick={onDismiss}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button onClick={onSubmit}>
                    <Trans>Update</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    )
}
