import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import UpdateActionModal from './UpdateActionModal'
import useUpdateActionFormState from './useUpdateActionFormState'

export default function UpdateActionModalWithState({
    id,
    action,
    onUpdateAction,
    onDismiss,
}) {
    const { dispatchSuccess, dispatchError } = useNotifications()
    const formState = useUpdateActionFormState({
        action: JSON.stringify(action, null, 2),
    })
    return (
        <UpdateActionModal
            onDismiss={onDismiss}
            formState={formState}
            isLoading={false}
            onSubmit={async () => {
                try {
                    onUpdateAction(id, formState.values.action)
                    onDismiss()
                } catch (error) {
                    dispatchError({ message: error.message })
                }
            }}
        />
    )
}
