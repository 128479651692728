import * as constants from '../constants'

const {
    SCAN_PAGE_STATUS_QUEUED: QUEUED,
    SCAN_PAGE_STATUS_COMPLETED: COMPLETED,
    SCAN_PAGE_STATUS_FAILED: FAILED,
} = constants

export const getScanStatusColor = (status) => {
    if (status === QUEUED) {
        return 'yellow'
    }
    if (status === COMPLETED) {
        return 'green'
    }
    if (status === FAILED) {
        return 'red'
    }
    return 'blue'
}

export const getAlertStatusColor = (status) => {
    if (status === QUEUED) {
        return 'yellow'
    }
    if (status === COMPLETED) {
        return 'green'
    }
    if (status === FAILED) {
        return 'red'
    }
    return 'blue'
}

export const getReportStatusColor = (status) => {
    if (status === QUEUED) {
        return 'yellow'
    }
    if (status === COMPLETED) {
        return 'green'
    }
    if (status === FAILED) {
        return 'red'
    }
    return 'blue'
}

export const getPageStatusColor = (status) => {
    if (status === QUEUED) {
        return 'yellow'
    }
    if (status === COMPLETED) {
        return 'green'
    }
    if (status === FAILED) {
        return 'red'
    }
    return 'blue'
}

export const getScanActionIcon = (type) => {
    switch (type) {
        case 'tap':
            return 'hand'
        case 'swipe':
            return 'paragraph-spacing'
        case 'keypress':
            return 'text-input'
        default:
            return 'hand'
    }
}
