import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query updateVendorTablePage($id: ID!) {
        vendorTable(id: $id) {
            id
            name
        }
    }
`

const useUpdateVendorTablePageQuery = ({ vendorTableId: id }) => {
    const variables = { id }
    const {
        data,
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        vendorTable: data?.vendorTable || {},
        isFetching,
        refetch,
    }
}

export default useUpdateVendorTablePageQuery

const VERSIONS_QUERY = gql`
    query vendorTableVersions(
        $vendorTableId: ID!
        $offset: Int
        $first: Int
        $orderBy: VendorTableVersionsOrderByInput
    ) {
        vendorTableVersions(
            vendorTableId: $vendorTableId
            offset: $offset
            first: $first
            orderBy: $orderBy
        ) {
            edges {
                node {
                    id
                    name
                    status
                    startDate
                    endDate
                    period
                    createdAt
                }
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`
export const useVendorTableVersionsQuery = ({
    vendorTableId,
    orderBy,
    first,
}) => {
    const variables = {
        vendorTableId,
        orderBy,
        first,
    }
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(VERSIONS_QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'vendorTableVersions'
    )
    return {
        vendorTableVersions:
            connectionToCollection(data.vendorTableVersions) || [],
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && hasNextPage,
    }
}
