/* eslint-disable no-nested-ternary */
import React from 'react'
import { Trans } from '@lingui/macro'
import { twMerge } from 'tailwind-merge'
import TableCell from '../../utils/TableCell'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import VendorTypeTags from '../../utils/VendorTypeTags'
import VendorVersionCompareModalTableRowActions from './VendorVersionCompareModalTableRowActions'
import Tooltip from '../../utils/Tooltip'
import { FieldUpdates } from '../vendors-cookie-tables-details/CookieTableVersionCompareModalTableRow'

// Maps for rowType => background class
const rowTypeToBgClass = {
    new: 'bg-green-50',
    danger: 'bg-red-50',
    warning: 'bg-orange-50',
    modified: 'bg-amber-50',
    same: 'bg-white',
}

// Maps for rowType => tag color
const rowTypeToTagType = {
    new: 'green',
    danger: 'red',
    warning: 'orange',
    modified: 'yellow',
    same: 'gray',
}

// Maps for rowType => text label
const rowTypeToTagLabel = {
    new: <Trans>Added</Trans>,
    danger: <Trans>Not found</Trans>,
    warning: <Trans>Removed</Trans>,
    modified: <Trans>Modified</Trans>,
    same: <Trans>Unchanged</Trans>,
}

const VendorVersionCompareModalTableRow = ({
    vendorItem,
    rowType,
    onUpdateCompleted,
}) => {
    const { imageUrl, types } = vendorItem.vendor
    const { name, domain } = vendorItem

    const backgroundClass = rowTypeToBgClass[rowType] || 'bg-white'
    const tagType = rowTypeToTagType[rowType] || 'gray'
    const tagLabel = rowTypeToTagLabel[rowType] || ''

    return (
        <TableRow className={twMerge('bg-white', backgroundClass)}>
            <TableCellAvatar
                truncate
                className="min-w-52 overflow-hidden"
                columnId="name"
                size="sm"
                avatarSrc={imageUrl}
                avatarIconName="building-07"
                title={name}
                text={
                    domain || (
                        <span className="text-slate-300">
                            <Trans>No domain found</Trans>
                        </span>
                    )
                }
            />
            <TableCell columnId="vendorTypes" size="sm">
                <VendorTypeTags types={types} />
            </TableCell>
            <TableCell columnId="state" className="w-fit text-right">
                {rowType === 'same' ? (
                    <Tag type={tagType}>{tagLabel}</Tag>
                ) : (
                    <Tooltip
                        content={
                            <div className="max-w-lg">
                                <h3 className="text-lg font-semibold">
                                    <Trans>Field updates</Trans>
                                </h3>
                                {vendorItem.changedFields?.map((field) => (
                                    <FieldUpdates
                                        key={field.fieldName}
                                        field={field}
                                    />
                                ))}
                            </div>
                        }
                    >
                        <Tag type={tagType} className="cursor-help">
                            {tagLabel}
                        </Tag>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell size="sm" className="text-right" columnId="actions">
                <VendorVersionCompareModalTableRowActions
                    vendorTableItemId={vendorItem.id}
                    rowType={rowType}
                    onUpdateCompleted={onUpdateCompleted}
                />
            </TableCell>
        </TableRow>
    )
}

export default VendorVersionCompareModalTableRow
