import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'
import { connectionToCollection } from '../../../utils/graphql'

const QUERY = gql`
    query updateCookieTablePage($id: ID!) {
        cookieTable(id: $id) {
            id
            name
        }
    }
`

const useUpdateCookieTablePageQuery = ({ cookieTableId: id }) => {
    const variables = { id }
    const {
        data,
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    return {
        error,
        cookieTable: data?.cookieTable || {},
        isFetching,
        refetch,
    }
}

export default useUpdateCookieTablePageQuery

const VERSIONS_QUERY = gql`
    query cookieTableVersions(
        $cookieTableId: ID!
        $offset: Int
        $first: Int
        $orderBy: CookieTableVersionsOrderByInput
    ) {
        cookieTableVersions(
            cookieTableId: $cookieTableId
            offset: $offset
            first: $first
            orderBy: $orderBy
        ) {
            edges {
                node {
                    id
                    name
                    status
                    startDate
                    endDate
                    period
                    createdAt
                }
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`
export const useCookieTableVersionsQuery = ({
    cookieTableId,
    orderBy,
    first,
}) => {
    const variables = {
        cookieTableId,
        orderBy,
        first,
    }
    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(VERSIONS_QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'cookieTableVersions'
    )
    return {
        cookieTableVersions:
            connectionToCollection(data.cookieTableVersions) || [],
        error,
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && hasNextPage,
    }
}
