/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import clsx from 'clsx'
import { cva } from 'cva'
import Alert from './Alert'
import PageLoading from './PageLoading'
import { getGraphqlErrorMessage } from '../../utils/graphql'

const sizeVariants = cva('mx-auto py-4', {
    variants: {
        size: {
            sm: 'max-w-3xl px-6',
            md: 'max-w-4xl px-6',
            default: 'max-w-7xl px-6',
            full: 'w-full px-6',
        },
    },
    defaultVariants: {
        size: 'default',
    },
})

const PageContent = ({
    centerContent = false,
    size = 'default',
    children,
    isFetching,
    error,
    className = '',
}) => {
    const shouldCenterContent = centerContent || isFetching || error
    return (
        <main
            className={clsx(
                'relative z-0 w-full flex-1 overflow-auto',
                shouldCenterContent && 'flex items-center justify-center',
                className
            )}
        >
            {(() => {
                if (isFetching) {
                    return <PageLoading />
                }
                if (error) {
                    let message = error?.message || ''
                    if (!message) {
                        message = getGraphqlErrorMessage(error)
                    }
                    return <Alert title="Error">{message}</Alert>
                }
                return (
                    <div className="min-w-[1024px]">
                        <div className={sizeVariants({ size })}>{children}</div>
                    </div>
                )
            })()}
        </main>
    )
}

export default PageContent
