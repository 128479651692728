import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import CollapseButton from '../../utils/CollapseButton'
import TableCellAvatar from '../../utils/TableCellAvatar'
import TableCell from '../../utils/TableCell'
import AuditRecordMobileActionsDropdownMenu from './AuditRecordMobileActionsDropdownMenu'
import ActionButton from '../../utils/ActionButton'
import TableRow from '../../utils/TableRow'
import Icon from '../../utils/Icon'
import Tooltip from '../../utils/Tooltip'
import { CodeBlock } from '../../utils/PostDataCodeBlock'
import Tag from '../../utils/Tag'
import { getScanActionIcon } from '../../../utils/entities'

export function AuditRecordMobileActionsTabTableRow({
    event,
    id,
    status,
    isRequired,
    onDeleteAction,
    onUpdateAction,
    onToggleRequiredAction,
}) {
    const [isCollapsed, setIsCollapsed] = useState(true)
    let text = ''
    if (event) {
        if (event.type === 'keypress') {
            text = event.character
        }

        if (event.element) {
            const { attributes = null } = event.element
            if (attributes) {
                text = attributes.text || attributes['resource-id']
            }
        }
    }
    const iconName = getScanActionIcon(event.type)
    return (
        <>
            <TableRow key={id}>
                <td className="w-6 pl-6">
                    <CollapseButton
                        isCollapsed={isCollapsed}
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsCollapsed(!isCollapsed)
                        }}
                    />
                </td>
                <TableCell className="w-6 pr-0">
                    {status?.status === 'success' && (
                        <Icon
                            name="check"
                            size="sm"
                            className="text-green-500"
                        />
                    )}
                    {status?.status === 'in-progress' && (
                        <Icon
                            name="spinner"
                            size="sm"
                            className="animate-spin"
                        />
                    )}
                    {status?.status === 'error' && (
                        <Tooltip
                            content={
                                <div className="max-w-lg">{status.error}</div>
                            }
                        >
                            <Icon name="x" size="sm" className="text-red-500" />
                        </Tooltip>
                    )}
                </TableCell>
                <TableCellAvatar
                    className="w-full"
                    truncate
                    title={event.type}
                    text={text}
                    avatarIconName={iconName}
                />
                <TableCell className="text-right">
                    {isRequired ? (
                        <Tag
                            isSelectable
                            type="primary"
                            onClick={() => onToggleRequiredAction(id)}
                        >
                            <Trans>Required</Trans>
                        </Tag>
                    ) : (
                        <Tag
                            isSelectable
                            onClick={() => onToggleRequiredAction(id)}
                        >
                            <Trans>Optional</Trans>
                        </Tag>
                    )}
                </TableCell>
                <TableCell className="text-right">
                    <AuditRecordMobileActionsDropdownMenu
                        onDeleteAction={onDeleteAction}
                        onUpdateAction={onUpdateAction}
                        onToggleRequiredAction={onToggleRequiredAction}
                        action={event}
                        eventActionId={id}
                    >
                        <ActionButton />
                    </AuditRecordMobileActionsDropdownMenu>
                </TableCell>
            </TableRow>
            {!isCollapsed && (
                <TableRow>
                    <td colSpan="5" className="max-w-full">
                        <CodeBlock
                            className="max-h-[600px]"
                            code={JSON.stringify(event, null, 2)}
                        />
                    </td>
                </TableRow>
            )}
        </>
    )
}
