import React from 'react'
import { Trans } from '@lingui/macro'
import Modal from '../../utils/Modal'
import ModalTitle from '../../utils/ModalTitle'
import ModalFooter from '../../utils/ModalFooter'
import Button from '../../utils/Button'
import StaffFeedbackButtons from '../../utils/StaffFeedbackButtons'
import useUpdateVendor from './useUpdateVendor'
import { VendorModalFormFields } from './VendorModalFormFields'

const UpdateVendorModal = ({ vendorId, onCompleted, onError, onDismiss }) => {
    const {
        feedbackId,
        handleSubmit,
        handleGenerate,
        handleFeedbackCompleted,
        formState,
        isGenerating,
        isUpdating,
    } = useUpdateVendor(vendorId, {
        onCompleted,
        onError,
        onDismiss,
    })
    return (
        <Modal onDismiss={onDismiss} className="max-w-3xl">
            <ModalTitle
                title={<Trans>Update vendor</Trans>}
                description={
                    <Trans>Fill in all details about the vendor below.</Trans>
                }
                onClose={onDismiss}
            />
            <VendorModalFormFields formState={formState} />
            <ModalFooter
                leftActions={
                    <>
                        {!feedbackId && (
                            <Button
                                type="white"
                                iconBeforeProps={{
                                    size: 'sm',
                                    name: 'ai-stars',
                                    color: 'primary',
                                }}
                                onClick={() => handleGenerate()}
                                loading={isGenerating}
                            >
                                <Trans>Autofill using AI</Trans>
                            </Button>
                        )}
                        {feedbackId && (
                            <StaffFeedbackButtons
                                feedbackId={feedbackId}
                                onCompleted={handleFeedbackCompleted}
                            />
                        )}
                    </>
                }
            >
                <Button
                    type="white"
                    onClick={() => {
                        formState.resetForm()
                        onDismiss()
                    }}
                >
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    onClick={async () => {
                        await handleSubmit()
                    }}
                    loading={isUpdating}
                >
                    <Trans>Update</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default UpdateVendorModal
