import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { connectionToCollection } from '../../../utils/graphql'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import useFetchMore from '../../hooks/useFetchMore'

const QUERY = gql`
    query staffScansFilterOrganizations(
        $after: String
        $search: String
        $orderBy: OrganizationOrderByInput
        $first: Int
    ) {
        staffOrganizations(
            after: $after
            search: $search
            orderBy: $orderBy
            first: $first
        ) {
            edges {
                cursor
                node {
                    id
                    name
                    imageUrl
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

const useStaffScansFilterOrganizationsQuery = ({ search, filters }) => {
    const variables = { search, filters }

    const {
        data = {},
        error,
        loading: isFetching,
        fetchMore,
        refetch,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })

    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'staffOrganizations'
    )

    return {
        error,
        organizations: connectionToCollection(data.staffOrganizations) || [],
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}

export default useStaffScansFilterOrganizationsQuery
