/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import tcfParse from 'tc-string-parse'
import TableRow from './TableRow'
import TableCell from './TableCell'
import Button from './Button'
import ConsentStringDetailsModal from './ConsentStringDetailsModal'

const RequestParamTableRow = ({ paramName, paramValue }) => {
    const [formatJson, setFormatJson] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    let consentModel
    let jsonValue
    let jsonValueIsObject = false
    try {
        consentModel = tcfParse(paramValue)
    } catch (e) {
        // ignore
    }
    try {
        jsonValue =
            typeof paramValue !== 'string' ? JSON.parse(paramValue) : paramValue
        jsonValueIsObject = jsonValue && typeof jsonValue === 'object'
    } catch (e) {
        // ignore
    }
    return (
        <>
            <TableRow key={paramName}>
                <TableCell py="sm" className="max-32 w-20">
                    {paramName}
                </TableCell>
                <TableCell py="sm">
                    {formatJson ? (
                        <pre className="inline-flex w-full items-center space-x-4 rounded-lg bg-gray-800 p-4 pl-6 text-left text-xs text-white">
                            {JSON.stringify(jsonValue, null, 2)}
                        </pre>
                    ) : (
                        paramValue
                    )}
                    {consentModel && (
                        <Button
                            type="white"
                            size="sm"
                            className="mt-2 max-w-[400px]"
                            onClick={() => setModalIsOpen(true)}
                        >
                            <Trans>Consent String found</Trans>
                        </Button>
                    )}
                    {jsonValueIsObject && !formatJson && (
                        <Button
                            type="white"
                            size="sm"
                            className="mt-2 max-w-[400px]"
                            onClick={() => setFormatJson(true)}
                        >
                            <Trans>Format json</Trans>
                        </Button>
                    )}
                </TableCell>
            </TableRow>
            {modalIsOpen && (
                <ConsentStringDetailsModal
                    isOpen={modalIsOpen}
                    consentModel={consentModel}
                    onDismiss={() => setModalIsOpen(false)}
                />
            )}
        </>
    )
}

export default RequestParamTableRow
